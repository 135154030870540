import React from 'react';
import autoBind from 'react-autobind';
import auth from 'services/Authed/Authed.js';

import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApModalInput from 'common/ApModalInput/ApModalInput.js';
import { ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import { ApInputSmall } from 'common/ApInputSmall/ApInputSmall.js';

import { getTypeIcon
       , getComponentTypeId
       , getTypeName } from 'modules/Storage/common/StorageHelpers.js';

import ComponentHeader from './ComponentHeader/ComponentHeader.js';
import ComponentRow from './ComponentRow/ComponentRow.js';

import './ComponentTable.css';
import { tr, groupify, roundToDecimals } from 'services/Helpers/Helpers.js';

class ComponentTable extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            currencySign: auth.getCurrencySign(),
            showEditComment: false,
            divideComponentShow: false,
            divideComponent: null,
        };
        
        autoBind(this);
    }

    cols()
    {
        const isHourly = this.props.project.billing_hourly;

        switch ( this.props.type ) {
            case 'work':
                if( isHourly ) return this.colsWorkHourly();
                return this.colsWork();
            case 'item':
                if( isHourly ) return this.colsItemHourly();
                return this.colsItem();
            case 'other':
                if( isHourly ) return this.colsExpenseHourly();
                return this.colsExpense();
            case 'storage':
                return this.colsStorage();
            default: return [];
        }
    }

    menuActions( c )
    {
        switch ( this.props.type ) {
            case 'work':
                return this.workMenu;
            case 'item':
                return this.itemMenu;
            case 'other':
                return this.expenseMenu;
            case 'storage':
                return this.storageMenu;
            default:
                return () => { console.error( 'menuAction function missing', this.props.type )};
        }
    }

    colsStorage()
    {
        const project = this.props.project;
        return [
            {
                label: 'Varastosaldo',
                value: 'balance',

            },
            {
                label: 'Varastosaldo(vapaa)',
                value: 'balance_free',
                funcName: 'changeWorkDone',
            }
        ];
    }
    
    colsWorkHourly()
    {
        const project = this.props.project;
        return [
            {
                label: tr('completed'),
                value: 'done',
                funcName: 'changeWorkDone',
                type: 'boolean',
                tooltip: tr('work_completed_info'),
                disabled: project.done,
            },
            {
                label: tr('installation_required'),
                value: 'installation_required',
                funcName: 'changeWorkRequiredInstallation',
                type: 'boolean',
                tooltip: tr('work_installation_required_info'),
                disabled: project.done,
            },
            {
                label: tr('will_be_billed'),
                value: 'billing',
                funcName: 'changeWorkBilling',
                type: 'boolean',
                tooltip: tr('will_be_billed_info'),
                disabled: project.done,
            },
             {
                label: tr('allocated'),
                value: 'alloc_count',
                funcName: 'changeWorkAllocCount',
                unit: 'h',
                decimal: 1,
            },{
                label: tr('recorded'),
                value: 'entry_count',
                funcName: null,
                unit: 'h',
                decimal: 1,
                disabled: true,
                infoButton: 'work_entries',
            },{
                type: 'not_billed',
                label: tr('not_in_billing'),
                tooltip: tr('not_in_billing_info'),
                value: 'not_billed_count',
                funcName: null,
                unit: 'h',
                decimal: 1,
                disabled: true,
                infoButton: 'work_entries_not_billed',
            },{
                label: tr('tax_free_billing_price'),
                type: 'billing',
            },
        ];
    }

    colsWork()
    {
        const colFilters = this.props.colFilters;
        const project = this.props.project;
        return [
            {
                label: tr('completed'),
                value: 'done',
                funcName: 'changeWorkDone',
                type: 'boolean',
                tooltip: tr('work_completed_info'),
                disabled: project.done,
            },
            {
                label: tr('installation_required'),
                value: 'installation_required',
                funcName: 'changeWorkRequiredInstallation',
                type: 'boolean',
                tooltip: tr('work_installation_required_info'),
                disabled: project.done,
            },
            {
                label: tr('estimate'),
                value: 'done_estimate',
                funcName: 'changeWorkDoneEstimate',
                unit: '%',
                decimal: 0,
                disabled: project.done,
                infoButton: 'done_estimate',
            },
            {
                label: tr('allocated'),
                toggle: 'workAllocAs',
                options: [
                    {   showAs: 'count',
                        label: 'N',
                        tooltip: tr('show_allocated_quantity'),
                        value: 'alloc_count',
                        funcName: 'changeWorkAllocCount',
                        unit: 'h',
                        decimal: 1,
                    },{
                        showAs: 'single',
                        label: `1x${ this.state.currencySign }`,
                        tooltip: tr('show_allocated_price_single_unit'),
                        value: 'alloc_price_single',
                        funcName: 'changeWorkAllocPriceSingle',
                        unit: this.state.currencySign,
                    },{
                        showAs: 'all',
                        label: this.state.currencySign,
                        tooltip: tr('show_allocated_price_full_quantity'),
                        value: 'alloc_price_all',
                        funcName: 'changeWorkAllocPriceAll',
                        unit: this.state.currencySign,
                    }
                ],
            },{
                label: tr('recorded'),
                toggle: 'workEntryAs',
                infoButton: 'work_entries',
                options: [
                    {   showAs: 'count',
                        label: 'N',
                        tooltip: tr('show_recorded_quantity'),
                        value: 'entry_count',
                        funcName: null,
                        unit: 'h',
                        disabled: true,
                        decimal: 1,
                    },{
                        showAs: 'percent',
                        label: `%`,
                        tooltip: tr('show_budget_spent_in_percentages'),
                        value: 'entry_percent',
                        funcName: null,
                        unit: '%',
                        disabled: true,
                        decimal: 1,
                    },{
                        showAs: 'price',
                        label: this.state.currencySign,
                        tooltip: tr('show_budget_spent_in_euros'),
                        value: 'entry_price',
                        funcName: null,
                        unit: this.state.currencySign,
                        disabled: true,
                    }
                ],
            },{
                label: tr('offer_calculation_own_cost'),
                toggle: 'workOfferAs',
                hidden: !colFilters.showOffer,
                options: [
                    {   showAs: 'count',
                        label: 'N',
                        tooltip: tr('show_budgeted_quantity_in_offer_calculation'),
                        value: 'offer_count',
                        funcName: null,
                        unit: 'h',
                        disabled: true,
                        decimal: 1,
                    },{
                        showAs: 'single',
                        label: `1x${ this.state.currencySign }`,
                        tooltip: tr('show_unit_cost_price_from_offer_calculation'),
                        value: 'offer_price_single',
                        funcName: null,
                        unit: this.state.currencySign,
                        disabled: true,
                    },{
                        showAs: 'all',
                        label: this.state.currencySign,
                        tooltip: tr('show_cost_price_full_quantity'),
                        value: 'offer_price_all',
                        funcName: null,
                        unit: this.state.currencySign,
                        disabled: true,
                    }
                ],
            },{
                label: tr('offer_calculation_offer'),
                toggle: 'workOfferSellAs',
                hidden: !colFilters.showOfferSell,
                options: [
                    {   showAs: 'count',
                        label: 'N',
                        tooltip: tr('show_budgeted_quantity_in_offer_calculation'),
                        value: 'offer_sell_count',
                        funcName: null,
                        unit: 'h',
                        disabled: true,
                        decimal: 1,
                    },{
                        showAs: 'single',
                        label: `1x${ this.state.currencySign }`,
                        tooltip: tr('show_unit_offer_price_from_offer_calculation'),
                        value: 'offer_sell_price_single',
                        funcName: null,
                        unit: this.state.currencySign,
                        disabled: true,
                    },{
                        showAs: 'all',
                        label: this.state.currencySign,
                        tooltip: tr('show_offer_price_full_quantity'),
                        value: 'offer_sell_price_all',
                        funcName: null,
                        unit: this.state.currencySign,
                        disabled: true,
                    }
                ],
            },
        ];
    }

    colsItemHourly()
    {
        const project = this.props.project;
        return [
            {
                label: tr('in_storage'),
                value: 'balance',
                unit: ( c ) => c.component ? c.component.unit: null,
                decimal: 1,
                type: 'storage_balance',
                disabled: true,
            },{
                label: tr('in_time_tracking'),
                value: 'in_timetracking',
                funcName: 'changeComponentInTimetracking',
                type: 'boolean',
                tooltip: tr('in_time_tracking_info'),
            },
            {
                label: tr('will_be_billed'),
                value: 'billing',
                funcName: 'changeComponentBilling',
                type: 'boolean',
                tooltip: tr('will_be_billed_info'),
                disabled: project.done,
            },
            {
                label: tr('allocated'),
                value: 'alloc_count',
                funcName: 'changeComponentAllocCount',
                unit: (c) => c.component ? c.component.unit : null,
                decimal: 1,
            },
            {
                label: tr('installed'),
                value: 'installed_count',
                funcName: 'changeComponentInstalledCount',
                unit: ( c ) => c.component ? c.component.unit: null,
                decimal: 1,
                disabled: true, // This is diabled, so now all entries need to be done from timetracking, so they go to billing correctly
                infoButton: 'items_installed',
            },{
                type: 'not_billed',
                label: tr('not_in_billing'),
                tooltip: tr('not_in_billing_info'),
                value: 'not_billed_count',
                funcName: null,
                unit: 'h',
                decimal: 1,
                disabled: true,
                infoButton: 'items_installed_not_billed',
            },{
                label: tr('tax_free_billing_price'),
                type: 'billing',
            },
        ];
    }


    colsItem()
    {
        const colFilters = this.props.colFilters;
        const project = this.props.project;
        return [
            {
                label: tr('in_storage'),
                value: 'balance',
                unit: ( c ) => c.component ? c.component.unit: null,
                decimal: 1,
                type: 'storage_balance',
                disabled: true,
            },{
                label: tr('completed'),
                value: 'done',
                funcName: 'changeComponentDone',
                type: 'boolean',
                tooltip: tr('component_completed'),
                disabled: project.done,
            },{
                label: tr('in_time_tracking'),
                value: 'in_timetracking',
                funcName: 'changeComponentInTimetracking',
                type: 'boolean',
                tooltip: tr('in_time_tracking_info'),
            },{
                label: tr('allocated'),
                toggle: 'componentAllocAs',
                options: [
                    {   showAs: 'count',
                        label: 'N',
                        tooltip: tr('show_allocated_quantity'),
                        value: 'alloc_count',
                        funcName: 'changeComponentAllocCount',
                        unit: ( c ) => c.component ? c.component.unit: null,
                        decimal: 1,
                    },{
                        showAs: 'single',
                        label: `1x${ this.state.currencySign }`,
                        tooltip: tr('show_allocated_price_single_unit'),
                        value: 'alloc_price_single',
                        funcName: 'changeComponentAllocPriceSingle',
                        unit: this.state.currencySign,
                    },{
                        showAs: 'all',
                        label: this.state.currencySign,
                        tooltip: tr('show_allocated_price_full_quantity'),
                        value: 'alloc_price_all',
                        funcName: 'changeComponentAllocPriceAll',
                        unit: this.state.currencySign,
                    }
                ],
            },{
                label: tr('installed'),
                toggle: 'componentInstalledAs',
                infoButton: 'items_installed',
                options: [
                    {   showAs: 'count',
                        label: 'N',
                        tooltip: tr('show_installed_quantity'),
                        value: 'installed_count',
                        funcName: 'changeComponentInstalledCount',
                        unit: ( c ) => c.component ? c.component.unit: null,
                        decimal: 1,
                    },{
                        showAs: 'percent',
                        label: `%`,
                        tooltip: tr('show_installed_quantity_percentage'),
                        value: 'installed_percent',
                        funcName: null,
                        unit: '%',
                        disabled: true,
                        decimal: 1,
                    }
                ],
            },{
                label: tr('offer_calculation'),
                toggle: 'componentOfferAs',
                hidden: !colFilters.showOffer,
                options: [
                    {   showAs: 'count',
                        label: 'N',
                        tooltip: tr('show_budgeted_quantity_in_offer_calculation'),
                        value: 'offer_count',
                        funcName: null,
                        unit: 'h',
                        disabled: true,
                        decimal: 1,
                    },{
                        showAs: 'single',
                        label: `1x${ this.state.currencySign }`,
                        tooltip: tr('show_offer_calculation_single_budget'),
                        value: 'offer_price_single',
                        funcName: null,
                        unit: this.state.currencySign,
                        disabled: true,
                    },{
                        showAs: 'all',
                        label: this.state.currencySign,
                        tooltip: tr('show_offer_calculation_full_quantity_budget'),
                        value: 'offer_price_all',
                        funcName: null,
                        unit: this.state.currencySign,
                        disabled: true,
                    }
                ],
            },{
                label: tr('offer_calculation_offer'),
                toggle: 'componentOfferSellAs',
                hidden: !colFilters.showOfferSell,
                options: [
                    {   showAs: 'count',
                        label: 'N',
                        tooltip: tr('show_budgeted_quantity_in_offer_calculation'),
                        value: 'offer_sell_count',
                        funcName: null,
                        unit: 'h',
                        disabled: true,
                        decimal: 1,
                    },{
                        showAs: 'single',
                        label: `1x${ this.state.currencySign }`,
                        tooltip: tr('show_unit_offer_price_from_offer_calculation'),
                        value: 'offer_sell_price_single',
                        funcName: null,
                        unit: this.state.currencySign,
                        disabled: true,
                    },{
                        showAs: 'all',
                        label: this.state.currencySign,
                        tooltip: tr('show_offer_price_full_quantity'),
                        value: 'offer_sell_price_all',
                        funcName: null,
                        unit: this.state.currencySign,
                        disabled: true,
                    }
                ],
            },
        ];
    }

    colsExpenseHourly()
    {
        const project = this.props.project;
        return [
            {
                label: tr('in_time_tracking'),
                value: 'in_timetracking',
                funcName: 'changeExpenseInTimetracking',
                type: 'boolean',
                tooltip: tr('in_time_tracking_info'),

            },{
                label: tr('will_be_billed'),
                value: 'billing',
                funcName: 'changeExpenseBilling',
                type: 'boolean',
                tooltip: tr('will_be_billed_info'),
                disabled: project.done,
            },
            {
                label: tr('allocated'),
                value: 'alloc_count',
                funcName: 'changeExpenseAllocCount',
                unit: (c) => c.component ? c.component.unit : null,
                decimal: 1,
            },
            {
                label: tr('recorded'),
                value: 'chargeable_quantity',
                funcName: null,
                tooltip: 'Kirjattu laskutettavaksi',
                unit: ( c ) => c.component ? c.component.unit: null,
                disabled: true,
                infoButton: 'expense_chargeable',
            },{
                type: 'not_billed',
                label: tr('not_in_billing'),
                tooltip: tr('not_in_billing_info'),
                value: 'not_billed_count',
                funcName: null,
                unit: 'h',
                decimal: 1,
                disabled: true,
                infoButton: 'expense_chargeable_not_billed',
            },{
                label: tr('tax_free_billing_price'),
                type: 'billing',
            },
        ];
    }


    colsExpense()
    {
        const colFilters = this.props.colFilters;
        return [
            {
                label: tr('in_time_tracking'),
                value: 'in_timetracking',
                funcName: 'changeExpenseInTimetracking',
                type: 'boolean',
                tooltip: tr('in_time_tracking_info'),
            },{
                label: tr('allocated'),
                toggle: 'expenseAllocAs',
                options: [
                    {   showAs: 'count',
                        label: 'N',
                        tooltip: tr('show_allocated_quantity'),
                        value: 'alloc_count',
                        funcName: 'changeExpenseAllocCount',
                        unit: ( c ) => c.component ? c.component.unit: null,
                        decimal: 1,
                    },{
                        showAs: 'single',
                        label: `1x${ this.state.currencySign }`,
                        tooltip: tr('show_allocated_price_single_unit'),
                        value: 'alloc_price_single',
                        funcName: 'changeExpenseAllocPriceSingle',
                        unit: this.state.currencySign,
                    },{
                        showAs: 'all',
                        label: this.state.currencySign,
                        tooltip: tr('show_allocated_price_full_quantity'),
                        value: 'alloc_price_all',
                        funcName: 'changeExpenseAllocPriceAll',
                        unit: this.state.currencySign,
                    }
                ],
            },{
                label: tr('offer_calculation'),
                toggle: 'expenseOfferAs',
                hidden: !colFilters.showOffer,
                options: [
                    {   showAs: 'count',
                        label: 'N',
                        tooltip: tr('show_budgeted_quantity_in_offer_calculation'),
                        value: 'offer_count',
                        funcName: null,
                        unit: 'h',
                        disabled: true,
                        decimal: 1,
                    },{
                        showAs: 'single',
                        label: `1x${ this.state.currencySign }`,
                        tooltip: tr('show_offer_calculation_single_budget'),
                        value: 'offer_price_single',
                        funcName: null,
                        unit: this.state.currencySign,
                        disabled: true,
                    },{
                        showAs: 'all',
                        label: this.state.currencySign,
                        tooltip: tr('show_offer_calculation_full_quantity_budget'),
                        value: 'offer_price_all',
                        funcName: null,
                        unit: this.state.currencySign,
                        disabled: true,
                    }
                ],
            },{
                label: tr('offer_calculation_offer'),
                toggle: 'expenseOfferSellAs',
                hidden: !colFilters.showOfferSell,
                options: [
                    {   showAs: 'count',
                        label: 'N',
                        tooltip: tr('show_budgeted_quantity_in_offer_calculation'),
                        value: 'offer_sell_count',
                        funcName: null,
                        unit: 'h',
                        disabled: true,
                        decimal: 1,
                    },{
                        showAs: 'single',
                        label: `1x${ this.state.currencySign }`,
                        tooltip: tr('show_unit_offer_price_from_offer_calculation'),
                        value: 'offer_sell_price_single',
                        funcName: null,
                        unit: this.state.currencySign,
                        disabled: true,
                    },{
                        showAs: 'all',
                        label: this.state.currencySign,
                        tooltip: tr('show_offer_price_full_quantity'),
                        value: 'offer_sell_price_all',
                        funcName: null,
                        unit: this.state.currencySign,
                        disabled: true,
                    }
                ],
            },
        ];
    }

    workMenu( c )
    {
        // let canMerge = false;
        // let removeSelected = false;

        /*
        const selectedComponents = this.state.selectedComponents;
        if( selectedComponents.length >= 2 )
        {
            // Show remove selected menu only on selected components
            let found = selectedComponents.find( sc => sc.id === c.id );
            if( found ) removeSelected = true;

            if( c.component && selectedComponents[ 0 ].component )
            {
                let projectId = selectedComponents[ 0 ].project_id;
                let componentId = selectedComponents[ 0 ].component.id;
                if( c.component.id === componentId )
                {
                    canMerge = selectedComponents.every( sc => {
                        if( !sc.component ) return false;
                        return ( sc.project_id === projectId && componentId === sc.component.id );
                    });
                }
            }
        }
        */

        let menuActions = [];
        const notRemovable = this.props.notRemovable( this.props.type, c );

        menuActions.push({
            label: tr('show_storage_component'),
            icon: "cube",
            disabled: ( this.props.locked || !c.component_id ),
            action: ( item, closeFunc ) => {
                this.props.openComponentEdit( c.component_id );
                closeFunc();
            }
        });

        if (!this.props.project.billing_hourly) {
            menuActions.push({
                label: tr('divide_storage_component'),
                icon: "divide",
                disabled: ( !c.alloc_count || this.state.locked ),
                action: ( item, closeFunc ) => {
                    this.divideOpen( c );
                    closeFunc();
                },
            })
        }

        menuActions.push({
            label: tr('delete_work'),
            icon: "trash-alt",
            disabled: this.props.locked || Boolean( notRemovable ),
            tooltip: notRemovable,
            action: ( item, closeFunc ) => {
                this.props.componentRemoveClick( c );
                closeFunc();
            }
        });

        menuActions.push({
            label: tr('download_qr'),
            icon: "qrcode",
            disabled: ( this.state.locked ),
            action: ( item, closeFunc ) => {
                this.props.downloadQRcode( c.id );
                closeFunc();
            },
        });
        
        menuActions.push({
            label: tr('update_price'),
            icon: "money-bill-wave",
            disabled: ( this.state.locked || !c.component ),
            action: ( item, closeFunc ) => {
                this.props.updateSellPrice( this.props.project, false, c );
                closeFunc();
            },
        });

        let selected = [...this.props.selected];
        if (selected.filter(s => s.id === c.id).length > 0) {
            menuActions.push({
                label: tr('add_as_group'),
                icon: "object-group",
                action: (item, closeFunc) => {
                    this.createAndAddToGroup();
                    closeFunc();
                },
            });
        }
        /*
        menuActions.push({
            label: "Jaa varastonimike",
            icon: "divide",
            disabled: ( this.state.locked ),
            action: ( item, closeFunc ) => {
                this.divideOpen( project, c );
                closeFunc();
            },
        });
        */

        /*
        if( canMerge )
        {
            menuActions.push({
                label: "Yhdistä valitut",
                icon: "link",
                disabled: ( !c.count || this.state.locked ),
                action: ( item, closeFunc ) => {
                    this.mergeSelectedComponents();
                    closeFunc();
                },
            });
        }
        */

        /*
        if( removeSelected )
        {
            menuActions.push({
                label: "Poista valitut",
                icon: "trash",
                disabled: this.state.locked,
                action: ( item, closeFunc ) => {
                    this.removeComponentClick( 'selected' );
                    closeFunc();
                }
            });

        }
        */
        return menuActions;
    }

    itemMenu( c )
    {
        // let canMerge = false;
        // let removeSelected = false;

        let menuActions = [];

        const notRemovable = this.props.notRemovable( this.props.type, c );

        menuActions.push({
            label: tr('show_storage_component'),
            icon: "cube",
            disabled: ( this.props.locked || !c.component_id ),
            action: ( item, closeFunc ) => {
                this.props.openComponentEdit( c.component_id );
                closeFunc();
            }
        });

        if (!this.props.project.billing_hourly) {
            menuActions.push({
                label: tr('divide_storage_component'),
                icon: "divide",
                disabled: ( !c.alloc_count || this.state.locked ),
                action: ( item, closeFunc ) => {
                    this.divideOpen( c );
                    closeFunc();
                },
            })
        }

        menuActions.push({
            label: tr('delete_component'),
            icon: "trash-alt",
            disabled: this.props.locked || Boolean( notRemovable ),
            tooltip: notRemovable,
            action: ( item, closeFunc ) => {
                this.props.componentRemoveClick( c );
                closeFunc();
            }
        });

        menuActions.push({
            label: tr('update_price'),
            icon: "money-bill-wave",
            disabled: ( this.state.locked || !c.component ),
            action: ( item, closeFunc ) => {
                this.props.updateSellPrice( this.props.project, false, c );
                closeFunc();
            },
        });

        if (this.props.selected.filter(s => s.id === c.id).length > 0) {
            menuActions.push({
                label: tr('add_as_group'),
                icon: "object-group",
                action: (item, closeFunc) => {
                    this.createAndAddToGroup();
                    closeFunc();
                },
            });
        }
        return menuActions;
    }

    expenseMenu( c )
    {
        // let canMerge = false;
        // let removeSelected = false;

        let menuActions = [];

        const notRemovable = this.props.notRemovable( this.props.type, c );

        menuActions.push({
            label: tr('show_storage_component'),
            icon: "cube",
            disabled: ( this.props.locked || !c.component_id ),
            action: ( item, closeFunc ) => {
                this.props.openComponentEdit( c.component_id );
                closeFunc();
            }
        });

        if (!this.props.project.billing_hourly) {
            menuActions.push({
                label: tr('divide_storage_component'),
                icon: "divide",
                disabled: ( !c.alloc_count || this.state.locked ),
                action: ( item, closeFunc ) => {
                    this.divideOpen( c );
                    closeFunc();
                },
            })
        }

        menuActions.push({
            label: tr('delete_cost'),
            icon: "trash-alt",
            disabled: this.props.locked || Boolean( notRemovable ),
            tooltip: notRemovable,
            action: ( item, closeFunc ) => {
                this.props.componentRemoveClick( c );
                closeFunc();
            }
        });

        menuActions.push({
            label: tr('update_price'),
            icon: "money-bill-wave",
            disabled: ( this.state.locked || !c.component ),
            action: ( item, closeFunc ) => {
                this.props.updateSellPrice( this.props.project, false, c );
                closeFunc();
            },
        });

        if (this.props.selected.filter(s => s.id === c.id).length>0) {
            menuActions.push({
                label: tr('add_as_group'),
                icon: "object-group",
                action: (item, closeFunc) => {
                    this.createAndAddToGroup();
                    closeFunc();
                },
            });
        }
        
        return menuActions;
    }

    divideOpen( component )
    {
        this.setState({
            divideComponentShow: true,
            divideComponent: component,
        });
    }

    divideSubmit( value )
    {
        value = parseFloat( value );

        if( isNaN( value ) )
        {
            window.emitter.emit('popper', {
                type: 'warning',
                content: <strong>{ tr('value_not_numeric') }</strong>
            });
            return null;
        }

        if( parseFloat( value ) >= parseFloat( this.state.divideComponent.alloc_count ) )
        {
            window.emitter.emit('popper', {
                type: 'warning',
                content: <strong>{ tr('separately_transferred_quantity_greater') }</strong>
            });
            return null;
        }

        let project = this.props.project;
        let component = this.state.divideComponent;

        let countForNew = roundToDecimals( value, 2 );
        let countForOld = roundToDecimals( component.alloc_count - countForNew, 2 );

        let { id, ...newComponent } = component;
        newComponent.alloc_count = countForNew;
        newComponent.id = newComponent.component.id;

        console.log( 'newComponent', newComponent );
        console.log('project id' , project.id);

        const keys = {
            1: 'components',
            2: 'works',
            3: 'expenses',
        };

        this.props.componentAdd( newComponent.type_id, newComponent );
        this.props.updateTreeItem( project.id, (project) => {
            const key = keys[ newComponent.type_id ];
            const items = project[key].map((c) => {
                if (c.id === component.id)
                    c.alloc_count = countForOld;
                return c;
            });
            project[key] = items;
            return project;
        });

        // this.syncWorksIfNeeded();
    }

    storageMenu( c )
    {
        // let canMerge = false;
        // let removeSelected = false;

        let menuActions = [];

        const notRemovable = this.props.notRemovable( this.props.type, c );

        menuActions.push({
            label: tr('show_storage_component'),
            icon: "cube",
            disabled: ( this.props.locked || !c.component_id ),
            action: ( item, closeFunc ) => {
                this.props.openComponentEdit( c.component_id );
                closeFunc();
            }
        });

        return menuActions;
    }

    renderShowOfferToggles()
    {
        const colFilters = this.props.colFilters;

        let budgetToggleClass = [ 'option' ];
        if( colFilters.showOffer )
            budgetToggleClass.push( 'selected' );

        let budgetSellToggleClass = [ 'option' ];
        if( colFilters.showOfferSell )
            budgetSellToggleClass.push( 'selected' );

        return <React.Fragment>
            <div className={ budgetToggleClass.join(" ") } onClick={ () => { this.props.changeColFilters({ showOffer: !colFilters.showOffer }) } } style={{ cursor: "pointer" }}>
                <ApTooltip text={ tr('show_offer_calculation_cost_price') } block position="bottom">
                    <SvgIcon icon="calculator" type="solid" />
                </ApTooltip>
            </div>
            <div className={ budgetSellToggleClass.join(" ") } onClick={ () => { this.props.changeColFilters({ showOfferSell: !colFilters.showOfferSell }) } } style={{ cursor: "pointer" }}>
                <ApTooltip text={ tr('show_offer_calculation_offer_price') } block position="bottom">
                    <SvgIcon icon="tag" type="solid" />
                </ApTooltip>
            </div>
        </React.Fragment>
    }

    renderFilterBar()
    {
        const isHourly = this.props.project.billing_hourly;

        if( isHourly )
            return null;

        return <div className="filterBar">
            <div className="viewsTitle">
                { tr('show') }:
            </div>
            <div className="viewsSelect">
                <div className={ "filter active" }>
                    <div className="apOptionBar smallerIcons">
                        { this.renderShowOfferToggles() }
                    </div>
                </div>
            </div>
            <div className="rightFilter">
            </div>
        </div>
    }

    renderAddComponentInput()
    {

        if( this.props.locked || this.props.type=='storage' ) return null;

        const type = this.props.type;

        let menuActions = [];

        /*
        menuActions.push({
            label: 'Lisää useita',
            icon: 'stream',
            action: ( item, closeFunc ) => {
                console.log('Todo');

                let tree = JSON.parse(JSON.stringify( this.state.tree ));

                tree = mapTree( tree, ( item ) => {
                    return {
                        __open: false,
                        __selected: false,
                        id: item.id,
                        name: item.name,
                        project_code: item.project_code,
                        components: [],
                        children: item.children,
                    };
                });

                this.setState({
                    addMultiple: {
                        show: true,
                        project: [ this.state.selectedProject ],
                        tree: tree,
                        components: [],
                    }
                });

                closeFunc();
            },
        });
        */

        menuActions.push({ "divider": tr('add_external_component') });

        menuActions.push({
            label: getTypeName( type ),
            icon: getTypeIcon( type ),
            action: ( item, closeFunc ) => {
                this.props.componentAdd( type );
                closeFunc();
            },
        });
        return <ApInputStack gap="0">
            <ApSelect
                label={ tr('add_storage_component') }
                loading={ this.props.loading }
                value=""
                optionRenderer="component_detail"
                onChange={ ( c ) => this.props.componentAdd( type, c ) }
                objKeyId="id"
                objKeyValue="name"
                apiUrl="storage/components"
                apiData={{
                    include: [ 'identifiers' ],
                    formatter: 'offer_calculate',
                    type: getComponentTypeId( type ),
                }}
            />
            <ApAddon noLeftBorder width="40px">
                <ApDropdown
                    actions={ menuActions }
                    button={ <SvgIcon icon="ellipsis-h" type="solid" /> }
                />
            </ApAddon>
        </ApInputStack>

    }
    openEditComment(commentData) {
        this.setState({ showEditComment: true, commentData:commentData});
    }
    saveComment(comment) {
        

        this.props.updateTreeItem(this.state.commentData.projectId, item => {
            let component = this.state.commentData.component
            switch (this.state.commentData.type) {
                case "item":
                    item.components = item.components.map((c) => {
                        if (c.id === component.id)
                            c.comment = comment;
                        return c;
                    });
                    return item;
                    
                case "work":
                    item.works = item.works.map((c) => {
                        if (c.id === component.id)
                            c.comment = comment;
                        return c;
                    });
                    return item;
                
                case "other":
                    item.expenses = item.expenses.map((c) => {
                        if (c.id === component.id)
                            c.comment = comment;
                        return c;
                    });
                    return item;
            }
            
            
            
        
        });

        this.setState({ showEditComment: false });
    }
    openGroup(group) {
        let groups = this.props.groups.slice(0);
        groups = groups.map(g => {
            if (g.id === group.id)
                g.open = !g.open;
            return g;
        });
        this.props.groupsChange(groups,false);
    }
    setGroupName(group, value) {
        let groups = this.props.groups.slice(0);
        groups = groups.map(g => {
            if (g.id === group.id)
                g.name = value;
            return g;
        });
        this.props.groupsChange(groups);
    }
    
    removeGroup(group) {
        let groups = this.props.groups.slice(0);
        groups = groups.filter(g => {
            return group.id != g.id;
        });
        this.props.groupsChange(groups);
    }
    addGroup(group) {
        let groups = this.props.groups.slice(0);
        groups.push(group);
        this.props.groupsChange(groups);
    }

    addToGroup(g) {
        this.props.updateTreeItem(this.props.project.id, item => {
            let selected = this.props.selected.slice(0);
            //console.log("selected!!", selected)
            switch (this.props.type) {
                case "item":
                    item.components = item.components.map((c) => {
                        for (let s of selected) {
                            if (c.id === s.id)
                                c.group_id = g.id;
                        }
                        return c;
                        
                    });
                    return item;

                case "work":
                    item.works = item.works.map((c) => {
                        for (let s of selected) {
                            if (c.id === s.id)
                                c.group_id = g.id;
                        }
                        return c;
                    });
                    return item;

                case "other":
                    item.expenses = item.expenses.map((c) => {
                        for (let s of selected) {
                            if (c.id === s.id)
                                c.group_id = g.id;
                        }
                        return c;
                    });
                    return item;
            }

        });
        this.props.clearSelected(this.props.type);
        
    }

    removeFromGroup(g) {

        this.props.updateTreeItem(this.props.project.id, item => {
            let selected = this.props.selected.slice(0);

            switch (this.props.type) {
                case "item":
                    item.components = item.components.map((c) => {
                        for (let s of selected) {
                            if (c.id === s.id)
                                c.group_id = null;
                        }
                        return c;
                    });
                    return item;

                case "work":
                    item.works = item.works.map((c) => {
                        for (let s of selected) {
                            if (c.id === s.id)
                                c.group_id = null;
                        }
                        return c;
                    });
                    return item;

                case "other":
                    item.expenses = item.expenses.map((c) => {
                        for (let s of selected) {
                            if (c.id === s.id)
                                c.group_id = null;
                        }
                        return c;
                    });
                    return item;
            }

        });
        
    
        
        
    }

    breakGroup(g) {
        //console.log('break group', g );

        this.props.updateTreeItem(this.props.project.id, item => {
            switch (this.props.type) {
                case "item":
                    item.components = item.components.map((c) => {
                        if (g.id === c.group_id)
                            c.group_id = null;
                        return c;

                    });
                    return item;

                case "work":
                    item.works = item.works.map((c) => {
                        if (g.id === c.group_id)
                            c.group_id = null;
                        return c;
                    });
                    return item;

                case "other":
                    item.expenses = item.expenses.map((c) => {
                        
                        if (g.id === c.group_id)
                            c.group_id = null;
                        return c;
                        
                    });
                    return item;
            }

        });
        this.removeGroup(g);

        
    }
    createAndAddToGroup() {
        
        const gId = `new${this.props.getNewCounter()}`;
        let name = tr('new_group');
        let group = {
                id: gId,
                name: name,
                open: true,
                project_id:this.props.project.id
            };
        let groups = this.props.groups.slice(0);
        groups.push(group);
        this.props.groupsChange(groups);
        this.addToGroup(group); 

        
    }

    renderGroupMenu(g) {
        let actions = [];

        let disabled = false;
        if (this.props.orderPriceLocked) disabled = true;

        if (!disabled) {
            actions.push({
                label: tr('disassemble_group'),
                icon: "object-ungroup",
                action: (id, closeFunc) => {
                    this.breakGroup(g);
                    closeFunc();
                },
            });
        }

        if (actions.length)
            return <ApDropdown actions={actions} />
        return null;
    }
    //---------------------------------------------------------------
    renderGroup(g, rows, colSpan, groupData = {}) {
        let disabled = false;
        if (this.props.orderPriceLocked) disabled = true;

        let rowDoms = [];
        let totalPrice = 0;
        let countError = null;
        let priceError = null;

        rows.forEach((r, key) => {
            if (g.open)
                rowDoms.push(<ComponentRow
                    key={r.id}
                    loading={this.props.loading}
                    c={r}
                    cols={this.cols() }
                    type={this.props.type}
                    project={this.props.project}
                    selected={this.props.selected}
                    selectComponent={this.props.selectComponent}
                    updateTreeItem={this.props.updateTreeItem}
                    tallyShow={this.props.tallyShow}
                    actions={this.menuActions()}
                    colShow={this.props.colShow}
                    openEditComment={this.openEditComment}
                    className="groupRow"
                />);

            totalPrice += (r.count * r.order_price);
            if (!countError && !r.count)
                countError = 'error';

            if (!priceError && !r.order_price)
                priceError = 'error';
        });

        let singlePrice = totalPrice / g.count;
        let trClass = ['groupRow', "groupRow"];
        if (g.open) trClass.push('open')
        if (!g.count) countError = 'error';

        let iconDom = null;
        let groupFunc = () => { };
        if (this.props.selected.length) {
            iconDom = <SvgIcon icon="plus-square" type="solid" />
            groupFunc = () => this.addToGroup(g);

            if (this.props.rows.every(r => {
                
                for (let s of this.props.selected) {
                    if (s.id === r.id) {
                        return (r.group_id === g.id);
                    }
                }
                // if (this.props.selected.includes(r.id))
                //     return (r.group_id === g.id)
                return true;
            })) {
                iconDom = <SvgIcon icon="minus-square" type="solid" />
                groupFunc = () => this.removeFromGroup(g);
            }
        }

        
        let inputrows = [];
        if (this.props.project.billing_hourly) {
            inputrows.push(<td></td>);
            inputrows.push(<td></td>);
            if (this.props.type === "item") {
                inputrows.push(<td></td>);
                inputrows.push(<td></td>);
                inputrows.push(<td style={{ textAlign: "center", fontWeight: "bold" }}>{groupData.installedCountSum}</td>);
                inputrows.push(<td style={{ textAlign: "center", fontWeight: "bold" }}>{Math.round(groupData.notBilledCountSum * 100) / 100}</td>);
                inputrows.push(<td style={{ textAlign: "center", fontWeight: "bold" }}>{Math.round(groupData.billingPricetSum * 100) / 100}</td>);
            }
            if (this.props.type === "work") {
                inputrows.push(<td></td>);
                inputrows.push(<td style={{ textAlign: "center", fontWeight: "bold" }}>{groupData.entryCountSum }</td>);
                inputrows.push(<td style={{ textAlign: "center", fontWeight: "bold" }}>{Math.round(groupData.notBilledCountSum * 100) / 100}</td>);
                inputrows.push(<td style={{ textAlign: "center", fontWeight: "bold" }}>{Math.round(groupData.billingPricetSum * 100) / 100}</td>);
            }
            if (this.props.type === "other") {
                inputrows.push(<td></td>);
                inputrows.push(<td style={{ textAlign: "center", fontWeight: "bold" }}>{groupData.entryCountSum}</td>);
                inputrows.push(<td style={{ textAlign: "center", fontWeight: "bold" }}>{Math.round(groupData.notBilledCountSum * 100) / 100}</td>);
                inputrows.push(<td style={{ textAlign: "center", fontWeight: "bold" }}>{Math.round(groupData.billingPricetSum * 100) / 100}</td>);
            }
            
        }
        else {
            inputrows.push(<td></td>);
            inputrows.push(<td></td>);
            if (this.props.type === "item") {
                inputrows.push(<td></td>);
                inputrows.push(<td></td>);
                inputrows.push(<td style={{ textAlign: "center", fontWeight: "bold" }}>{Math.round(groupData.allocCountSum * 100) / 100}</td>);
                inputrows.push(<td style={{ textAlign: "center", fontWeight: "bold" }}>{Math.round(groupData.installedCountSum * 100) / 100}</td>);
            }
            if (this.props.type === "work") {
                inputrows.push(<td style={{ textAlign: "center", fontWeight: "bold" }}>{groupData.doneEstimate + " %"}</td>);
                inputrows.push(<td style={{ textAlign: "center", fontWeight: "bold" }}>{Math.round(groupData.allocCountSum * 100) / 100}</td>);
                inputrows.push(<td style={{ textAlign: "center", fontWeight: "bold" }}>{Math.round(groupData.installedCountSum * 100) / 100}</td>);
            }
            if (this.props.type === "other") {
                inputrows.push(<td style={{ textAlign: "center", fontWeight: "bold" }}>{Math.round(groupData.allocCountSum * 100) / 100}</td>);

            }

            
            
             
            
            
        }

        return <React.Fragment key={g.id}>
            <tr className={trClass.join(' ')}>
                <td className="selectTd" onClick={groupFunc}>
                    {iconDom}
                </td>
                <td className="groupTd" onClick={() => this.openGroup(g)}>
                    <SvgIcon icon="angle-right" type="solid" />
                </td>
                <td></td>
                <td className="nameTd">
                    <ApInputSmall
                        style={{ textAlign: "left" }}
                        type="text"
                        value={g.name ? g.name : ''}
                        onChange={(e) => { this.setGroupName(g, e.target.value) }}
                        disabled={disabled}
                        validationState={(g.name) ? null : 'error'}
                    />
                </td>
                <td style={{ color: groupData.commentCount != 0 ? '#ffa726' : null, fontWeight: "bold" }}>{groupData.commentCount +" "+ tr("pcs")}</td>
                {inputrows}
                
                
                {/* <td>&nbsp;</td> */}
                {/* {(this.state.type === 'delivery') ? <td key="reserve" className="fixed c-target"></td> : <td key="entry" className="fixed c-entry"></td>} */}
                
                {/* <td key="vat" className="fixed vat">
                    {"this.renderGroupVat(g)"}
                </td>
                <td key="netvisor-vat" className="fixed vat">
                    {"this.renderOrderNetvisorGroupVat(g)"}
                </td>
                {this.props.costCenterEnabled &&
                    <td key="cost-center" className="fixed vat">
                        {"this.renderGroupCostCenter(g)"}
                    </td>}
                <td key="tota_price" className="fixed total">
                      {formatMoney(totalPrice)} {this.state.currencySign} 
                </td>
                <td key="dispatch_ref" className="fixed dispatch-ref">

                </td>*/}
                
                <td className="fixed actions">
                    {this.renderGroupMenu(g)}
                </td> 
            </tr>
            {rowDoms}
        </React.Fragment>

        /*
        onChange={ ( e ) => { this[ o.funcName ]( c, e.target.value ) } }
        onBlur={   ( e ) => { this[ o.funcName ]( c, e.target.value, true ) } }
        disabled={ o.disabled || this.props.locked }
        unit={ unit }
        noFocusDecimals={ decimal }
        tooltip= { tooltip }
        */
    }
//--------------------------------------------------------------
    
    renderTable()
    {
        let rows = this.props.rows;


        if( !rows || rows.length === 0 )
        {
            const messages = {
                work: tr('no_works_added'),
                item: tr('no_components_added'),
                other: tr('no_costs_added'),
                storage: tr('storage_empty'),
            }
            return <div className="apMsg">{ messages[ this.props.type ] }</div>
        }

        const cols = this.cols();
//--------------------------------
        let componentGroups = [];
        let others = this.props.rows;
        

        let cGroups = groupify(this.props.rows, "group_id");
        //console.log("cGroups", cGroups);
        //console.log("rows", rows);

        let gIds = [];
        let groups = [];
        //console.log("groups",this.props.groups)
        
        for (let key in cGroups) {
            if (key !== "undefined") {


                let groupData = {
                    commentCount: 0,
                    doneEstimate: 0,
                    allocCountSum: 0,
                    installedCountSum: 0,
                    billingPricetSum: 0,
                    entryCountSum:0,
                    notBilledCountSum:0,
                }
                 //cGroups[key].filter(x => x.comment).length;
                
              
                let estimateSum = 0;
               
                for (let c of cGroups[key]) {
                    estimateSum += parseFloat(c.done_estimate ? c.done_estimate : 0);
                    if (this.props.project.billing_hourly) {
                        //component
                        if (this.props.type === "item") {
                            if (this.props.colShow.componentAllocAs === "count") {
                                groupData.allocCountSum += parseFloat(c.alloc_count ? c.alloc_count : 0);
                            }
                            if (this.props.colShow.componentAllocAs === "single") {
                                groupData.allocCountSum += parseFloat(c.alloc_price_single ? c.alloc_price_single : 0);
                            }
                            if (this.props.colShow.componentAllocAs === "all") {
                                groupData.allocCountSum += parseFloat(c.alloc_price_all ? c.alloc_price_all : 0);
                            }

                            if (this.props.colShow.componentInstalledAs === "count") {
                                groupData.installedCountSum += parseFloat(c.installed_count ? c.installed_count : 0);
                            }
                            if (this.props.colShow.componentInstalledAs === "percent") {
                                groupData.installedCountSum += parseFloat(c.installed_percent ? c.installed_percent : 0);
                            }
                            groupData.notBilledCountSum += parseFloat(c.not_billed_count ? c.not_billed_count : 0);
                            groupData.billingPricetSum += parseFloat(c.billing_price ? c.billing_price : 0);

                        }
                        //work
                        if (this.props.type === "work") {

                            // if (this.props.colShow.workAllocAs === "count") {
                            //     groupData.allocCountSum += parseFloat(c.alloc_count ? c.alloc_count : 0);
                            // }
                            // if (this.props.colShow.workAllocAs === "single") {
                            //     groupData.allocCountSum += parseFloat(c.alloc_price_single ? c.alloc_price_single : 0);
                            // }
                            // if (this.props.colShow.workAllocAs === "all") {
                            //     groupData.allocCountSum += parseFloat(c.alloc_price_all ? c.alloc_price_all : 0);
                            // }

                            // if (this.props.colShow.workEntryAs === "count") {
                            //     groupData.installedCountSum += parseFloat(c.entry_count ? c.entry_count : 0);
                            // }
                            // if (this.props.colShow.workEntryAs === "percent") {
                            //     groupData.installedCountSum += parseFloat(c.entry_percent ? c.entry_percent : 0);
                            // }
                            // if (this.props.colShow.workEntryAs === "price") {
                            //     groupData.installedCountSum += parseFloat(c.entry_price ? c.entry_price : 0);
                            // }
                            groupData.notBilledCountSum += parseFloat(c.not_billed_count ? c.not_billed_count : 0);
                            groupData.billingPricetSum += parseFloat(c.billing_price ? c.billing_price : 0);
                            if (this.props.colShow.workEntryAs === "count") {
                                groupData.entryCountSum += parseFloat(c.entry_count ? c.entry_count : 0);
                            }

                            
                        }

                        //expence
                        if (this.props.type === "other") {

                            if (this.props.colShow.expenseAllocAs === "count") {
                                groupData.allocCountSum += parseFloat(c.alloc_count ? c.alloc_count : 0);
                            }
                            if (this.props.colShow.expenseAllocAs === "single") {
                                groupData.allocCountSum += parseFloat(c.alloc_price_single ? c.alloc_price_single : 0);
                            }
                            if (this.props.colShow.expenseAllocAs === "all") {
                                groupData.allocCountSum += parseFloat(c.alloc_price_all ? c.alloc_price_all : 0);
                            }
                            groupData.notBilledCountSum += parseFloat(c.not_billed_count ? c.not_billed_count : 0);
                            groupData.billingPricetSum += parseFloat(c.billing_price ? c.billing_price : 0);
                            groupData.entryCountSum += parseFloat(c.chargeable_quantity ? c.chargeable_quantity : 0);
                            
                        }
                        
                    }
                    else {
                        
                        //component
                        if (this.props.type === "item") {
                            if (this.props.colShow.componentAllocAs === "count") {
                                groupData.allocCountSum += parseFloat(c.alloc_count ? c.alloc_count : 0);
                            }
                            if (this.props.colShow.componentAllocAs === "single") {
                                groupData.allocCountSum += parseFloat(c.alloc_price_single ? c.alloc_price_single : 0);
                            }
                            if (this.props.colShow.componentAllocAs === "all") {
                                groupData.allocCountSum += parseFloat(c.alloc_price_all ? c.alloc_price_all : 0);
                            }
    
                            if (this.props.colShow.componentInstalledAs === "count") {
                                groupData.installedCountSum += parseFloat(c.installed_count ? c.installed_count : 0);
                            }
                            if (this.props.colShow.componentInstalledAs === "percent") {
                                groupData.installedCountSum += parseFloat(c.installed_percent ? c.installed_percent : 0);
                            }
                            
                        }
                        //work
                        if (this.props.type === "work") {
    
                            if (this.props.colShow.workAllocAs === "count") {
                                groupData.allocCountSum += parseFloat(c.alloc_count ? c.alloc_count : 0);
                            }
                            if (this.props.colShow.workAllocAs === "single") {
                                groupData.allocCountSum += parseFloat(c.alloc_price_single ? c.alloc_price_single : 0);
                            }
                            if (this.props.colShow.workAllocAs === "all") {
                                groupData.allocCountSum += parseFloat(c.alloc_price_all ? c.alloc_price_all : 0);
                            }
    
                            if (this.props.colShow.workEntryAs === "count") {
                                groupData.installedCountSum += parseFloat(c.entry_count ? c.entry_count : 0);
                            }
                            if (this.props.colShow.workEntryAs === "percent") {
                                groupData.installedCountSum += parseFloat(c.entry_percent ? c.entry_percent : 0);
                            }
                            if (this.props.colShow.workEntryAs === "price") {
                                groupData.installedCountSum += parseFloat(c.entry_price ? c.entry_price : 0);
                            }
                        }
                        
                        //expence
                        if (this.props.type === "other") {
                            
                            if (this.props.colShow.expenseAllocAs === "count") {
                                groupData.allocCountSum += parseFloat(c.alloc_count ? c.alloc_count : 0);
                            }
                            if (this.props.colShow.expenseAllocAs === "single") {
                                groupData.allocCountSum += parseFloat(c.alloc_price_single ? c.alloc_price_single : 0);
                            }
                            if (this.props.colShow.expenseAllocAs === "all") {
                                groupData.allocCountSum += parseFloat(c.alloc_price_all ? c.alloc_price_all : 0);
                            }
                        }
                    }
                    
                    //installedCountSum += parseFloat(c.installed_count ? c.installed_count : 0);
                    
                    if (c.comment) {
                        groupData.commentCount++;
                    }
                    
                }
                groupData.doneEstimate = estimateSum / cGroups[key].length;
                

                groups.push(cGroups[key][0].group);
                let group = this.props.groups.find(x => x.id == key);
                
                componentGroups.push(this.renderGroup(group, cGroups[key] || [], null, groupData));
            }
        }

        // componentGroups = groups.map(g => {
        //     gIds.push(g.id);
        //     let group=this.props.groups.find(gg=>gg.id==g.id)
        //     return this.renderGroup(group, cGroups[g.id] || []);
        // });
        others = cGroups[undefined];
        

        let componentRows = (others) ? others.map(c => {
            return <ComponentRow
                key={c.id}
                loading={this.props.loading}
                c={c}
                cols={cols}
                type={this.props.type}
                project={this.props.project}
                selected={this.props.selected}
                selectComponent={this.props.selectComponent}
                updateTreeItem={this.props.updateTreeItem}
                tallyShow={this.props.tallyShow}
                actions={this.menuActions()}
                colShow={this.props.colShow}
                openEditComment={this.openEditComment}
            />
        }) : [];

        // if (!rows.length && !groups.length)
        //     rows.push(this.renderCTRowEmpty(colSpan));
//-------------------------------------
       

        
        return <table className="componentTable">
            <thead>
                <ComponentHeader
                    cols={ cols }
                    colShow={ this.props.colShow }
                    changeColShow={ this.props.changeColShow }
                />
            </thead>
            <tbody>
                {componentGroups}
                {componentRows}
            
            </tbody>
        </table>
    }

    render()
    {
        return <div>
            { this.renderFilterBar() }
            <div className="padding">
                { this.renderTable() }
                { this.renderAddComponentInput() }
            </div>
            <ApModalInput
                show={this.state.showEditComment}
                value={this.state.commentData?this.state.commentData.component.comment:null}
                type={"textarea"}
                onSave={this.saveComment}
                onCancel={()=>{this.setState({showEditComment:false})}}
                title={tr("comment")}
                info={"info"}
            />

            <ApModalInput
                show={ this.state.divideComponentShow }
                title={ tr('components_moved_separately') }
                value={ `${ this.state.divideComponent?.alloc_count / 2 }` }
                onSave={ this.divideSubmit }
                onClose={ () => { this.setState({ divideComponentShow: false }) } }
            />

        </div>
    }
}

export default ComponentTable;
