import React from 'react';
import autoBind from 'react-autobind';
import api, { apiurl } from 'services/Api/Api.js';

import { errorPopper, getFileById, keyExists, tr } from 'services/Helpers/Helpers.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApAddon, ApInput, ApInputStack } from 'common/ApInput/ApInput.js';
import ApModal            from 'common/ApModal/ApModal.js';
import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import ApValidate         from 'services/ApValidate/ApValidate.js';
import ApSelect from 'common/ApSelect/ApSelect';
import ApFileUpload from 'common/ApFileUpload/ApFileUpload';
import ApTooltip from 'common/ApTooltip/ApTooltip';

export default class EditNewsModal extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			item: null,
			header: null,
			content: null,
			limited: false,
			role: null,
			files: [],
			newFiles: [],
		};

		this.validator = new ApValidate( this, {
			"header":           { filter: 'required', state: 'warning', text: tr('mandatory')}
		  });

		autoBind(this);

	}

	componentDidUpdate(prevProps)
	{

		if (this.props.itemId && prevProps.itemId !== this.props.itemId) this.getNewsitem(this.props.itemId);

	}

	getNewsitem(id)
	{

		api({
			method: 'get',
			url: 'company/settings/getNewsItem',
			params: { id: id }
		}).then((response) => {
			this.setState({ 
				item: response,
				header: response.header,
				content: response.content,
				limited: response.limited,
				role: response.role,
				files: response.files,
				newFiles: [],
			});
		}).catch((error) => {
			console.log(error);
		});

	}

	saveItem()
	{
		const newFileIdsAndNames = this.state.newFiles.map(file => {
			return {
				id: file.id,
				name: file.name,
			};
		});
		const savedFiles = this.state.files.map(file => {
			return {
				id: file.file_id,
				name: file.name,
			};
		});
		const all_files = [...savedFiles, ...newFileIdsAndNames];
		api({
			method: 'post',
			url: 'company/settings/saveNewsItem',
			data: {
				id: this.props.itemId,
				header: this.state.header,
				content: this.state.content,
				limited: this.state.limited,
				role:this.state.role,
				all_files,
			}
		}).then((response) => {
			this.props.onClose();
			this.clear();
			this.fileUploadRef?.resetFiles();
		}).catch((error) => {
			console.log(error);
			errorPopper(error, tr('save_error'));
		});

	}

	deleteItem()
	{

		api({
			method: 'post',
			url: 'company/settings/deleteNewsItem',
			data: {
				id: this.props.itemId,
			}
		}).then((response) => {
			this.props.onClose();
		}).catch((error) => {
			console.log(error);
		});

	}

	clickSaveButton()
	{
		this.saveItem();
	}

	clickCancelButton()
	{
		this.props.onClose();
		this.clear();
	}

	clear()
	{
		this.setState({
			item: null,
			header: null,
			content: null,
			limited: false,
			role: null,
			unsavedChanges: false,
			files: [],
			newFiles: [],
		})
		this.fileUploadRef?.resetFiles();
	}

	handleChange(e)
	{	
		this.setState({
			[e.target.name] :e.target.value,
			unsavedChanges: true,
		});
	}

	renderFoobar()
    {
		return (
			<ApSelect
				label={ tr('personnel_group') }
				value={ keyExists( this.state, "role.name", true, null ) }
				valueRenderer={(item) => <div>{item.name}</div>}
				optionRenderer={(item) => <div>{item.name}</div>}
				onChange={ ( role ) => this.setState({ role }) }
				objKeyId="id"
				clearable
				apiUrl="usermanagement/personnelGroups"
			/>
		);
    }

	filesChange(files) {
		this.setState({ newFiles: files, unsavedChanges: true });
	}

	removeFile(id) {
		const files = this.state.files.filter(file => file.id !== id);
		this.setState({ files, unsavedChanges: true });
	}

	renderForm()
	{
		const listDoms = this.state.files.map((file, index) => {
			return <div key={file.id}>
				<ApInputStack gap="0">
					<ApAddon>
						<div
							onClick={() => { getFileById(file.file_id, file.name) }}
							className="pointer"
						>
							<SvgIcon
								className="pointer"
								icon="file"
								type="solid"
							/>
							{file.name}
						</div>
					</ApAddon>
					<ApAddon noLeftBorder width="50px">
						<ApTooltip block text={tr('delete')}>
							<SvgIcon
								className="pointer"
								icon="trash"
								type="solid"
								onClick={() => { this.removeFile(file.id) }}
							/>
						</ApTooltip>
					</ApAddon>
				</ApInputStack>
			</div>
		});

		const form = <div>
						<ApInput
							name="header"
							id="header"
							label={ tr('header') }
							value={this.state.header}
							type="text"
							onChange={this.handleChange}
							validationState={ this.validator.getState('header') }
						/>
						<ApInput
							name="content"
							id="content"
							label={ tr('content') }
							value={this.state.content}
							type="textarea"
							rows={ 5 }
							onChange={this.handleChange}
						/>

						{listDoms}
						<ApFileUpload
							ref={ref => this.fileUploadRef = ref}
							onChange={this.filesChange}
							description={"Company news file for id: " + this.props.itemId}
							maxSize={1024 * 1024 * 20} // 20 Mt
							disabled={this.state.loading}
							noFilesLabel={tr('add_files')}
							addButtonLabel={`${tr('add_file')}...`}
						/>

						<strong>{ tr('send_to_selected_group') }:</strong>
						{this.renderFoobar()}
					</div>;

		return form;

	}

	render()
	{

		const form = this.renderForm();

		return(
			<div id='edit-news-modal'>
				<ApModal
					show={this.props.show}
					backdrop="static"
					className="narrow"
					handleClose={ this.clickCancelButton }
					header={ 
						<div className="padding-small">
							<h4>{this.props.itemId === null ? tr('new') : tr('edit') }</h4>
						</div>
					}
					body={
						<div className="padding" style={{ marginTop: 30 }}>
						{form}
						</div>
					}
					footer={
						<div className="padding" style={{display: 'flex', flexDirection: 'row'}}>
			
						<ApButton className="cancel" onClick={() => {this.clickCancelButton(null)}}>
							<SvgIcon icon="times" type="solid" />
							{ tr('cancel') }
						</ApButton>

						{ this.props.itemId && 
							<ApButton className="remove" color="red" style={{marginLeft: 20}} onClick={ this.deleteItem }>
							<SvgIcon icon="trash" type="solid" />
							{ tr('delete') }
							</ApButton>
						}
			
						<ApButton							
							className={ "save" + ( this.state.unsavedChanges ? " highlight" : "" ) }
							color="blue"
							onClick={ this.clickSaveButton }
							loading={ this.state.loading }
							disabled={ Boolean(this.validator.getState('header')) }
							style={{marginLeft: 'auto'}}
						>
							<SvgIcon icon="save" type="solid" />
							{ tr('save') }
						</ApButton>
						
						</div>
					}
				/>
			</div>

		);

	}

}