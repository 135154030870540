/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import axios from 'axios';
import { debounce } from 'throttle-debounce';

import './OldHours.css';

import { groupify, tr } from  'services/Helpers/Helpers.js';

import Week from '../Week/Week.js';
import UserBrowser from '../UserBrowser/UserBrowser.js';
import moment from 'moment';

export default class OldHours extends React.Component {

    constructor( props ) 
    {
        super(props);
        this.state = {
            loading:            false,
            loadingEntries:     false,
            settings:           null,

            users:              [],
            selectedUser:       null,

            weeks: [],
            selectedWeeks: [],

            entries:            [],
            selectedEntries:    [],

            salaryExtras:       [],
            showSalaryExtras:   true,

            changes:            [],

            commentEntryId:     null,
            commentText:        "",
        };
        this.statusLoop = [ "submitted", "approved", "rejected" ];
        autoBind(this);

        this.getEntriesDebounced = debounce( 300 , this.getEntries );
    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers( user = null )
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'timetracking/approval/usersold',
        }).then(( response ) => {

            //console.log('USERS', response.users );
            //console.log('SETTINGS', response.settings );
         
            this.setState({
                loading: false,
                users: response.users,
                settings: response.settings,
                changes: [],
            }, () => {

                if( response.users.length > 0 )
                {
                    if( !user || response.users.findIndex( r => r.id == user ) == -1 ) {
                        const foundUser = response.users?.find(u => {
                            if (!u.is_active) return false;

                            if (moment(u.contract_start).isAfter(moment())) return false;
                            if (u.contract_end && moment(u.contract_end).isBefore(moment())) return false;

                            return true;
                        })
                        if (foundUser) {
                            user = foundUser.id;
                        } else {
                            user = response.users[0].id;
                        }
                    }
                  
                    this.changeUser( user );
                }
                else 
                    this.setState({ 
                        selectedUser:       null,
                        entries:            [],
                        selectedEntries:    [],
                    });

            });

        }).catch( ( error ) => {
            console.log( 'getUsers ERROR', error );
            this.setState({ loading: false });
        });
    }

    changeUser( id )
    {
        //console.log('changeUser', id );

        // Cancel possible old query 
        if( this.state.cancelToken )
            this.state.cancelToken.cancel('Operation canceled by the user.');
           
        this.setState({ 
            selectedUser: id,
            selectedWeeks: [],
            loadingEntries: false,
            loading: true,
            entries: [],
            cancelToken: axios.CancelToken.source(),
        }, () => {
            api({
                cancelToken: this.state.cancelToken.token,
                method: 'post',
                url: 'timetracking/approval/weeks',
                data: { 
                    user: id
                }
            }).then(( response ) => {

                //console.log('USER ENTRIES', response );

                this.setState({
                    loading: false,
                    weeks: response,
                    selectedEntries: [],
                    cancelToken: null,
                });

            }).catch( ( error ) => {
                if( axios.isCancel( error ) ) return null;
                console.log('ERROR', error );
            });
        });
    }

    getEntries( )
    {
        // Cancel possible old query 
        if( this.state.cancelToken )
            this.state.cancelToken.cancel('Operation canceled by the user.');
           
        this.setState({ 
            loadingEntries: true,
            cancelToken: axios.CancelToken.source(),
        }, () => {
            api({
                cancelToken: this.state.cancelToken.token,
                method: 'post',
                url: 'timetracking/approval/oldentries',
                data: { 
                    user: this.state.selectedUser,
                    weeks: this.state.selectedWeeks,
                }
            }).then(( response ) => {

                //console.log('USER ENTRIES', response.salary_extras );

                const round = (value) => {
                    return Math.round(value * 1000) / (1000);
                };
                response.entries.forEach(entry => {
                    if (entry.time_attendance_save_point != undefined) {
                        entry.timeAttendanceTimestampStart = entry.time_attendance_save_point.last_edit && entry.time_attendance_save_point.last_edit.timestamp_start != null ?
                            entry.time_attendance_save_point.last_edit.timestamp_start :
                            entry.time_attendance_save_point.timestamp_start;
                        entry.timeAttendanceTimestampStop = entry.time_attendance_save_point.last_edit && entry.time_attendance_save_point.last_edit.timestamp_end != null ?
                            entry.time_attendance_save_point.last_edit.timestamp_end :
                            entry.time_attendance_save_point.timestamp_end;

                        entry.timeAttendanceTimestampStart = entry.timeAttendanceTimestampStart ? moment(entry.timeAttendanceTimestampStart) : null;
                        entry.timeAttendanceTimestampStop = entry.timeAttendanceTimestampStop ? moment(entry.timeAttendanceTimestampStop) : null;

                        entry.timeAttendanceTime = (entry.timeAttendanceTimestampStart && entry.timeAttendanceTimestampStop) ?
                            round(moment.duration(entry.timeAttendanceTimestampStop.diff(entry.timeAttendanceTimestampStart)).asHours()) :
                            null;

                    }
                });

                this.setState({
                    loadingEntries: false,
                    entries: response.entries,
                    selectedEntries: [],
                    salaryExtras: response.salary_extras,
                    cancelToken: null,
                });

            }).catch( ( error ) => {
                if( axios.isCancel( error ) ) {
                    return null;
                }
                console.log('ERROR', error );
                
            });
        });
    }

    // Group entries to weeks and days
    groupifyEntries()
    {
        let parsed = {};
        const weeks = groupify( this.state.entries, "week" );
        Object.keys( weeks ).forEach( ( week ) => {
            const days = groupify( weeks[ week ], "date" );
            parsed[ week ] = days;
        });
        return parsed;
    }

    handleWeek(week)
    {
        let selectedWeeks = [...this.state.selectedWeeks];
        const index = selectedWeeks.indexOf(week);
        if (index > -1) 
        {
            selectedWeeks.splice(index, 1);
        } 
        else
        {
            selectedWeeks.push(week);
        }
        this.setState({ selectedWeeks: selectedWeeks });

        this.getEntriesDebounced();
    }

    renderContent()
    {
        if (this.state.loading)
        {
            return (
                <div className="apLoader"></div>
            )
        }
        else if (!this.state.loading && this.state.users.length === 0)
        {
            return (
                <div className="apSuccessMsg">
                    <h4>{ tr('no_rows_found') }</h4>
                </div>
            )
        }
        else {
            const weeks = this.groupifyEntries();
            return (
            <div className="padding">
                <div className="weekBrowser">
                    <div className="weeks">
                        { this.state.weeks.map( week => {
                            let classes = [ "week" ];
                        
                            if( this.state.selectedWeeks.includes(week) ) 
                                classes.push( "selected" )

                            return (
                                <div onClick={() => this.handleWeek(week)} className={ classes.join(" ") } key={ week }>Viikko { week.split("-")[ 1 ] } ({week.split("-")[ 0 ]})</div>
                            );
                        })}
                    </div>
                </div>
                { !this.state.loadingEntries ? Object.keys( weeks ).map( week => {
                    return (  
                        <Week
                            key={ week }
                            week={ week }
                            days={ weeks[ week ] }
                            selectedEntries={ this.state.selectedEntries }
                            changes={ this.state.changes }
                            settings={ this.state.settings }
                            showReadOnly={ true }
                            showSalaryExtras={ this.state.showSalaryExtras }
                            salaryExtras={ this.state.salaryExtras }
                            statuses={ this.statusLoop }
                            onToggleReadOnly={ ( value ) => this.setState({ showReadOnly: value }) }
                            old={true}
                        />
                    );
                }) : <div className="apLoader"></div>}
            </div>
            )
        }
    }

    render()
    {
        return (
            <div id="pageTimetrackingApproval">
                <div className="apBox">
                    <div className="apBoxHeader">
                        <p>{ tr('subordinates_previous_entries_info') }</p>

                    </div>

                    <UserBrowser 
                        users={ this.state.users } 
                        selectedUser={ this.state.selectedUser }
                        onChangeUser={ this.changeUser }
                    />

                </div>

                <div className={"entries"} id="oldHours">

                    {this.renderContent()}

                </div>

            </div>
        );
    }
}
