import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import { Container, Row, Col, Modal } from 'react-bootstrap';
import ApButton from 'common/ApButton/ApButton.js';
import { ApAddon, ApInput, ApInputStack } from 'common/ApInput/ApInput.js';
import ApFileUpload from 'common/ApFileUpload/ApFileUpload';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import { tr } from 'services/Helpers/Helpers';
import ApTooltip from 'common/ApTooltip/ApTooltip';

export default class EditNewsModal extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			item: null,
			header: null,
			content: null,
			files: [],
			newFiles: [],
		};

		autoBind(this);

	}

	UNSAFE_componentWillMount()
	{

		//console.log(this.props.itemId);

		if (this.props.itemId !== null) this.getNewsitem(this.props.itemId);

	}

	getNewsitem(id)
	{

		api({
			method: 'get',
			url: 'crm/extranet/getNewsitem',
			params: { id: id }
		}).then((response) => {
			//console.log(response);
			this.setState({ 
				item: response,
				header: response.header,
				content: response.content,
				files: response.files,
				newFiles: [],
			});
		}).catch((error) => {
			console.log(error);
		});

	}

	saveItem()
	{
		const newFileIdsAndNames = this.state.newFiles.map(file => {
			return {
				id: file.id,
				name: file.name,
			};
		});
		const savedFiles = this.state.files.map(file => {
			return {
				id: file.file_id,
				name: file.name,
			};
		});
		const all_files = [...savedFiles, ...newFileIdsAndNames];

		api({
			method: 'post',
			url: 'crm/extranet/saveNewsitem',
			data: {
				id: this.props.itemId,
				header: this.state.header,
				content: this.state.content,
				all_files,
			}
		}).then((response) => {
			//console.log("save successful");
			this.props.onClose();
		}).catch((error) => {
			console.log(error);
		});

	}

	clickSaveButton()
	{
		this.saveItem();
	}

	clickCancelButton()
	{
		this.props.onClose();
	}

	handleChange(e)
	{
		this.setState({ [e.target.name]: e.target.value });
	}

	filesChange(files) {
		this.setState({ newFiles: files, unsavedChanges: true });
	}

	removeFile(id) {
		const files = this.state.files.filter(file => file.id !== id);
		this.setState({ files, unsavedChanges: true });
	}

	renderForm()
	{
		const listDoms = this.state.files.map((file, index) => {
			return <div key={file.id}>
				<ApInputStack gap="0">
					<ApAddon>
						<div
							onClick={() => { getFileById(file.file_id, file.name) }}
							className="pointer"
						>
							<SvgIcon
								className="pointer"
								icon="file"
								type="solid"
							/>
							{file.name}
						</div>
					</ApAddon>
					<ApAddon noLeftBorder width="50px">
						<ApTooltip block text={tr('delete')}>
							<SvgIcon
								className="pointer"
								icon="trash"
								type="solid"
								onClick={() => { this.removeFile(file.id) }}
							/>
						</ApTooltip>
					</ApAddon>
				</ApInputStack>
			</div>
		});

		const form = <Container fluid={true}>
				<Row>
					<Col>
						<ApInput
							name="header"
							id="header"
							label="Otsikko"
							value={this.state.header}
							type="text"
							onChange={this.handleChange}
						/>
						<ApInput
							name="content"
							id="content"
							label="Sisältö"
							value={this.state.content}
							type="textarea"
							onChange={this.handleChange}
						/>
						{listDoms}
						<ApFileUpload
							ref={ref => this.fileUploadRef = ref}
							onChange={this.filesChange}
							description={"Extranet news file for id: " + this.props.itemId}
							maxSize={1024 * 1024 * 20} // 20 Mt
							disabled={this.state.loading}
							noFilesLabel={tr('add_files')}
							addButtonLabel={`${tr('add_file')}...`}
						/>
					</Col>
				</Row>
			</Container>;

		return form;

	}

	render()
	{

		const form = this.renderForm();

		return(

			<Modal
				show={this.props.show}
				backdrop="static"
				bsSize="large"
			>

				<Modal.Header>
					<Modal.Title> {this.props.itemId === null ? "Uusi uutinen" : "Muokkaa uutista"} </Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{form}
				</Modal.Body>

				<Modal.Footer>
					<ApButton color="red" onClick={() => {this.clickCancelButton(null)}}>
						Peruuta
					</ApButton>
					<ApButton color="blue" onClick={this.clickSaveButton}>
						Tallenna
					</ApButton>
				</Modal.Footer>

			</Modal>

		);

	}

}