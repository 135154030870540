/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
//import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';

import './WeekDay.css';

import { entryIcon, statusText } from  'modules/Timetracking/TimetrackingHelpers.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';

//import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import { ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
// import ApSwitch from 'common/ApSwitch/ApSwitch.js';
// import ApRangeSlider from 'common/ApRangeSlider/ApRangeSlider.js';
import ApPath from 'common/ApPath/ApPath.js';
//import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';

import { keyExists, formatMoney, groupify, tr, currentLang } from  'services/Helpers/Helpers.js';

import { Collapse } from 'react-bootstrap';

export default class WeekDay extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            showDetails: false
        };
        autoBind(this); 
    }

    toggleDetails( e )
    {
        if( !this.actionsRef || !this.actionsRef.contains(e.target) || e == undefined )
            this.setState({ showDetails: !this.state.showDetails }); 
    }

    resolveStatus( data )
    {
        let result = {
            name: false,
            text: false,
            icon: false,
            info: false,
        };

        let entry = data;
        if( Array.isArray( data ) && data.length > 0 )
        {
            const statuses = groupify( data, "status" );

            // At least one entry is rejected 
            if( "rejected" in statuses )
                entry = { status: "rejected" };

            // At least one entry still waiting for approval (submitted)
            else if ( "submitted" in statuses )
                entry = { status: "submitted" };

            // All entries are approved 
            else if ( !( "open" in statuses ) && !( "suggested" in statuses ) )
                entry = { status: "approved" };
        }
    
        if( entry && entry.status )
        {
            result.name = entry.status;
            result.text = statusText( entry.status );
            switch( entry.status )
            {
                case "suggested":
                    result.icon = "question";
                    break;

                //case "open":
                case "submitted":
                    result.icon = "paper-plane";
                    break;

                case "rejected":
                    result.icon = "times";
                    break;

                case "approved":
                case "confirmed":
                case "payrolled":
                case "paid":
                    result.icon = "check";
                    break;

                default: break;
            }

            if( keyExists( entry, "payroll_batch.payment_date", true ) )
                result.info = `${tr('date_of_payment')} ` + moment( entry.payroll_batch.payment_date ).format('DD.MM.YYYY');
        }
        return result;
    }

    renderEntry( data, locked )
    {
        let classes = [ "entry" ];
        let icon = data.type;
        let title = tr('no_description');
        let desc = tr('no_additional_info');
        let amounts = [];
        let status = this.resolveStatus( data );
        
        if( data.description && data.customer_work_number )
            title = data.description + " / " + data.customer_work_number;

        else if ( data.description )
            title = data.description;
        
        else if ( data.customer_work_number )
            title = data.customer_work_number;

        classes.push( data.type );
        classes.push( status.name );

        if( data.is_misentry )
            classes.push( "misentry" );

        if( [ "submitted", "approved", "confirmed", "payrolled", "paid" ].indexOf( data.status ) != -1 )
            locked = true;

        if( data.status == "rejected" )
        {
            locked = false;
        
            if( data.approver_comment ) {
                if (data.approver_comment !=null) {
                    status.text += " - " + data.approver_comment;
                }               
            }
            if (data.approver_user_person_detail) {               
                status.text = data.approver_user_person_detail.full_name + " : " + status.text;
            }
        }



        if( status.info )
            status.text = <span>{ status.text }<br />{ status.info }</span>;

        else if( data.status_changed_at )
            status.text = <span>{ status.text }<br />{ moment( data.status_changed_at ).locale(currentLang()).format('LLL') }</span>;

        if( data.type === "hour" )
        {
            desc = data.detail.projectwork.name;

            if( data.detail.description_long )
                title = <span>{ title } <SvgIcon className="longDescIcon" icon="info-circle" type="solid" /></span>;

            data.detail.rows.forEach( r => {
                amounts.push({ 
                    name: this.props.settings.hour_types.find( t => t.id == r.hour_type_id ).name, 
                    value: r.hours, 
                    unit: "h" 
                });
            });   
        }

        else if( data.type === "travel" )
        {
            desc = data.detail.route;
            if( data.detail.distance > 0 )
                amounts.push({
                    name: this.props.settings.vehicles.find( v => v.id == data.detail.vehicle_id ).name, 
                    value: data.detail.distance, 
                    unit: "km" 
                });
            if( data.detail.hours > 0 )
                amounts.push({
                    name: tr('travel_hours'), 
                    value: data.detail.hours, 
                    unit: "h" 
                });
        }

        else if( data.type === "expense" )
        {
            desc = data.detail.payment_card_id ? tr('company_payment_card') : tr('payment_from_own_money');
            
            if( data.detail.event )
                desc = data.detail.event + ", " + desc;

            let sum = 0;
            data.detail.rows.forEach( r => {
                sum += ( r.cost / ( r.currency_rate && r.currency_rate != 0 ? r.currency_rate : 1 ) )
            });
            amounts.push({
                name: tr('expense_compensation'), 
                value: formatMoney( sum ), 
                unit: auth.getCurrencySign(), 
            });
            if( data.detail.files.length > 0 )
                amounts.push({
                    name: tr('attachments'), 
                    value: data.detail.files.length, 
                    unit: tr('pcs')
                });
        }

        else if( data.type === "allowance" )
        {
            const converTime = ( time ) => {
                const parts = time.split(":");
                return parts.length >= 2 ? parts[0] + "." + parts[1] : time;
            };

            if( data.detail.start_time && data.detail.end_time )
                desc = converTime( data.detail.start_time ) + " - " + converTime( data.detail.end_time );

            if( desc == "00.00 - 24.00" )
                desc = tr('full_day' );

            let compensation = data.detail.compensation;
            if( !compensation && this.props.settings.allowances.findIndex( a => a.id == data.detail.allowance_id ) != -1 )
                compensation = this.props.settings.allowances.find( a => a.id == data.detail.allowance_id ).compensation;

            amounts.push({
                name: keyExists( data, "detail.allowance.name", true , tr('compensation') ), 
                value: formatMoney( compensation ),
                unit: auth.getCurrencySign(), 
            });
        }

        else if( data.type === "absence" )
        {
            const absence_id = keyExists( data, "detail.absence_id", true );
            if( absence_id )
            {
                let absence = keyExists( this.props, "settings.absences", true, [] ).find( a => a.id == absence_id );
                if( absence )
                    desc = absence.name || tr('unknown_absence');
            }

            if( data.detail.is_all_day )
            {
                title = desc;
                desc = tr('full_day_absence');
            }
            else 
                amounts.push({
                    name: tr('absent'), 
                    value: data.detail.hours, 
                    unit: "h" 
                });
        }

        /*
        if( data.additional_values )
        {
            Object.keys( data.additional_values ).forEach( id => {
                const addField = keyExists( data, "project.additional_fields", true, [] ).find( f => f.field_id == id );
                amounts.push({
                    name: keyExists( addField, "field.name", true, "Nimetön" ),
                    value: data.additional_values[ id ],
                    unit: keyExists( addField, "field.unit", true, null ),
                });
            });
        }
        */
        /*
        keyExists( data, "installations", true, [] ).forEach( i => {
            // TODO
            amounts.push({
                name: keyExists( item, "component.name", true, "Nimetön" ),
                value: i.value,
                unit: keyExists( item, "component.unit", true, null ),
            });
        });
        */
            
        return (
            <div className={ classes.join( " " ) } key={ data.id }>
                <div className="description" onClick={ () => this.props.onEditEntry( data ) }>
                    <SvgIcon className="icon hideUnder400" icon={ entryIcon( icon ) } type="solid" />
                    <strong className="title">{  data.description }
                    { data.detail.description_long &&
                        <ApTooltip text = { data.detail.description_long }  position="top">   
                            { status.icon && <SvgIcon className="longDescIcon" icon="info-circle" type="solid" /> }     
                        </ApTooltip>
                    }
                    </strong><br/>
                    <small className="desc">{ desc }</small>
                </div>
                <div className="amount">
                    { amounts.map( ( a, index ) => {
                        return (
                            <div key={ data.id + "_" + index }>
                                <small className="name">{ a.name }: </small><strong className="value">{ a.value } { a.unit }</strong>
                            </div>
                        );
                    })}
                </div>

                { !locked && ( !data.is_misentry || ( data.is_misentry && data.status === "rejected" ) ) &&
                    <ApDropdown 
                        actionId={ data }
                        actions={ this.getDropDownActions( "entry", data.is_misentry ) }
                    />
                }
                <div className={ "status " + status.name }>
                    <ApTooltip text={ status.text } block position="left">
                        { status.icon && <SvgIcon icon={ status.icon } type="solid" /> }
                    </ApTooltip>
                </div>
            </div>
        );
    }

    getDropDownActions( type, secondary = false )
    {
        let actions = [];

        const entriesCount = keyExists( this.props, "data.entries", true, [] ).length;
        const emptyProjectCount = keyExists( this.props, "data.emptyProjects", true, [] ).length;

        switch( type )
        {
            case "day":
                actions.push({
                    label: tr('full_day_absence'),
                    icon: entryIcon("absence"),
                    disabled: keyExists( this.props, "settings.absences", true, [] ).length == 0, 
                    action: ( date, closeFunc ) => { 
                        this.addEntry( "absence_allday", null );
                        closeFunc(); 
                    }
                });
                if (this.props.userHasHoursDaily) {
                    actions.push({
                        label: tr('set_week_to_annual_leave'),
                        icon: "suitcase",
                        disabled: keyExists(this.props, "settings.absences", true, []).length == 0,
                        action: (date, closeFunc) => {
                            this.addEntry("absence_allday", null, true);

                            closeFunc();
                        }
                    });
                }
                
                actions.push({ divider: tr('copy') })
                actions.push({
                    label: tr('for_next_day'),
                    icon: "copy",
                    disabled: entriesCount == 0 && emptyProjectCount == 0,
                    action: ( date, closeFunc ) => { 
                        this.props.onCopyDate( date, "next-day" );
                        closeFunc(); 
                    }
                });
                actions.push({
                    label: tr('for_rest_week'),
                    icon: "copy",
                    disabled: entriesCount == 0 && emptyProjectCount == 0,
                    action: ( date, closeFunc ) => { 
                        this.props.onCopyDate( date, "rest-of-the-week" );
                        closeFunc(); 
                    }
                });
                actions.push({ divider: tr("copy_from_last_week") });
                actions.push({
                    label: tr('for_rest_of_the_week'),
                    icon: "copy",
                    disabled: !this.props.copyFromLastWeekAllowed,
                    action: (date, closeFunc) => {
                        this.props.onCopyDate(date, "rest-of-the-week-from-last-week");
                        closeFunc();
                    }
                });
                
                actions.push({ divider: true });
                actions.push({
                    label: tr('clear'),
                    icon: "backspace",
                    disabled: entriesCount == 0 && emptyProjectCount == 0,
                    action: ( date, closeFunc ) => { 
                        this.props.onClearDate( date );
                        closeFunc(); 
                    }
                });
                break;


            case "lockedDay":
                actions.push({ divider: tr('copy') })
                actions.push({
                    label: tr('for_next_day'),
                    icon: "copy",
                    disabled: entriesCount == 0 && emptyProjectCount == 0,
                    action: ( date, closeFunc ) => { 
                        this.props.onCopyDate( date, "next-day" );
                        closeFunc(); 
                    }
                });
                actions.push({
                    label: tr('for_rest_week'),
                    icon: "copy",
                    disabled: entriesCount == 0 && emptyProjectCount == 0,
                    action: ( date, closeFunc ) => { 
                        this.props.onCopyDate( date, "rest-of-the-week" );
                        closeFunc(); 
                    }
                });
                break;


            case "entry":
                actions.push({
                    label: tr('edit'),
                    icon: "edit",
                    action: ( item, closeFunc ) => { 
                        this.props.onEditEntry( item );
                        closeFunc(); 
                    }
                });
                actions.push({ divider: true });
                actions.push({
                    label: secondary ? tr('undo_deletion') : tr('delete'),
                    icon: secondary ? "times" : "trash",
                    action: ( item, closeFunc ) => { 
                        this.props.onRemoveEntry( item );
                        closeFunc(); 
                    }
                });
                break;

            case "projectAdd":
                actions.push({
                    label: tr('add_entry'),
                    icon: entryIcon( "hour" ),
                    disabled: keyExists( this.props, "settings.hour_types", true, [] ).length == 0,
                    action: ( project, closeFunc ) => { 
                        this.addEntry( "hour", project );
                        closeFunc(); 
                    }
                });
                actions.push({
                    label: tr('add_travel'),
                    icon: entryIcon( "travel" ),
                    disabled: keyExists( this.props, "settings.vehicles", true, [] ).length == 0,
                    action: ( project, closeFunc ) => { 
                        this.addEntry( "travel", project );
                        closeFunc(); 
                    }
                });
                actions.push({
                    label: tr('add_expense_compensation'),
                    icon: entryIcon( "expense" ),
                    action: ( project, closeFunc ) => { 
                        this.addEntry( "expense", project );
                        closeFunc(); 
                    }
                });
                break;
            default: break;
        }
        return actions;
    }

    addEntry( type, project = null, setWeekHolidays=false )
    {   
        let data = {
            id: 'new' + this.props.getNextId(),
            date: this.props.data.date,
            type: type,
            project_id: project ? project.id : null,
            assignment_id: project?( project.assignment?project.assignment.id : null ) : null,
            project: project,
            detail: {},
            status: "open",
            description: project?.assignment?.timetracking_entry_description,
        };
        let skipUI=false;

        if( type === "hour" )
        {
            if( project.works.length > 0 )
            {
                data.detail.project_work_id = project.works[0].id;
                data.detail.projectwork = project.works.find( w => w.id == data.detail.project_work_id );
            }

            // Automatically add 'default' hour types for new entry
            let rows = [];
            this.props.settings.hour_types.forEach( t => {
                if( t.is_default )
                    rows.push({
                        hours: null,
                        hour_type_id: t.id,
                        is_overtime: t.is_overtime,
                    });
            });
            data.detail.rows = rows;
        }

        else if ( type === "travel" )
        {
            // Automatically pick first item from vehicle list
            data.detail.vehicle_id = this.props.settings.vehicles[0].id;
        }

        else if ( type === "expense" )
        {
            // Expenses does NOT need any description so we can provide it automatically
            data.description = "Kulukorvaus";

            // Automatically add first row (with company default currency)
            data.detail.rows = [{
                description: '',
                const: '',
                currency: auth.getCurrency(),
                currency_rate: 1,
            }];
            data.detail.files = [];
        }

        else if ( type === "allowance" )
        {
            // There can be just one allowance per day. If already have allowance entry we just edit that entry. 
            const allowanceEntry = keyExists( this.props, "data.entries", true, [] ).find( e => e.type === "allowance" );
            if( allowanceEntry )
                data = allowanceEntry;
            
            // No allowance found for this day. So lets add new entry. 
            else 
            {
                // Allowances does NOT need any description so we use selected allowance name instead.  
                data.description = this.props.settings.allowances[0].name;

                // Automatically pick first item from allowance reasons
                data.detail.allowance_id = this.props.settings.allowances[0].id;

                 // Set default time frame
                data.detail.start_time = '00:00:00';
                data.detail.end_time = '24:00:00';
            }
        }

        else if ( type === "absence" || type === "absence_allday" )
        {

            if( type === "absence_allday" )
            {
                data.type = "absence";
                // Check if user has regular working hours set. If not, entry can't be all day absence.
                if( this.props.settings?.user?.hours_daily )
                    data.detail.is_all_day = true;
            }

            // Automatically pick first item from absense reasons (not flexitime usage)
            data.detail.absence_id = this.props.settings.absences.filter(a => !a.flexitime_usage)[0].id;
            if (setWeekHolidays) {
                data.detail.absence_id = this.props.settings.absences.filter(a => a.holiday_usage)[0].id;
                skipUI = true;
            }
            data.detail.hours = null;
        }

        this.props.onEditEntry(data, skipUI);
        //console.log( 'addEntry', type, project );
    }


    renderBars( summary, dayLocked )
    {
        const round = ( value ) => {
            return Math.round( value * 100 ) / 100;
        };

        const haveFlexitime = keyExists( this.props, "settings.user.flex_time_allowed", true, false );
        const dailyTargetCurrent = parseFloat( keyExists( this.props, "settings.user.hours_daily", true ) );
        const dailyTarget = parseFloat( keyExists( this.props, "data.required_hours_daily", true, dailyTargetCurrent ) );

        let total = summary.hours.regular + 
                    summary.travels.hours + 
                    summary.hours.overtime + 
                    summary.absences.hours;

        let tooltip = false;
        if( total > 0 )
            tooltip = <div>
                <div>{ tr('working_hours') }: { round( summary.hours.regular ) } h { dailyTarget && <span>/ { dailyTarget } h</span> }</div>
                <div>{ tr('travel_hours') }: { round( summary.travels.hours ) } h</div>
                <div>{ tr('overtime_hours') }: { round( summary.hours.overtime ) } h</div>
                <div>{ tr('supplements') }: { round( summary.hours.notation ) } h</div>
                <div>{ tr('absences') }: { round( summary.absences.hours ) } h</div>
                {keyExists( this.props, "settings.user.flex_time_allowed", true, false ) && 
                    <div>{ tr('flexitime_change') }: { round( summary.hours.flexi ) } h</div>
                }
            </div>;

        if( !tooltip && haveFlexitime && summary.hours.flexi != 0 )
            tooltip = <div>
                <div>{ tr('flexitime_change') }: { round( summary.hours.flexi ) } h </div>
            </div>;

        if( dailyTarget )
            total = Math.max( total, dailyTarget );

        const regular = ( ( summary.hours.regular + summary.travels.hours ) / total * 100 );
        const overtime = ( summary.hours.overtime / total * 100 );
        const absence = ( summary.absences.hours / total * 100 );
        const flexi = ( Math.abs( summary.hours.flexi ) / total * 100 );

        return (
            <ApTooltip text={ tooltip }>
                <div className={ "bars" + ( haveFlexitime ? " withFlexi" : "" ) }>
                    { regular > 0 && 
                        <div className="bar regular" style={{ width: regular + "%" }}>
                            { round( summary.hours.regular ) } h
                            { summary.travels.hours > 0 && 
                                <span> + { summary.travels.hours } h</span>
                            }
                        </div>
                    }
                    { overtime > 0 && 
                        <div className="bar overtime" style={{ width: overtime + "%" }}>
                            { round( summary.hours.overtime ) } h
                        </div> 
                    }
                    { absence > 0 && 
                        <div className="bar absence" style={{ width: absence + "%" }}>
                            { round( summary.absences.hours ) } h
                        </div> 
                    }
                    { haveFlexitime && dayLocked && summary.hours.flexi < 0 &&
                        <div className="bar flexiUsed" style={{ width: flexi + "%" }}>
                            { round( summary.hours.flexi ) } h
                        </div>
                    }
                    { haveFlexitime && dayLocked && summary.hours.flexi > 0 &&
                        <div className="bar flexiGain" style={{ width: flexi + "%" }}>
                            +{ round( summary.hours.flexi ) } h
                        </div>
                    }
                </div>
                { haveFlexitime &&
                    <div className={ "flexi" + ( summary.hours.flexi > 0 ? " plus" : summary.hours.flexi < 0 ? " minus" : "" ) }>
                        { summary.hours.flexi > 0 && <span>+</span> }{ summary.hours.flexi } h
                    </div>
                }
            </ApTooltip>
        );
    }

    render() 
    {
        const locked = this.props.data.locked;
        const dayErrors = this.props.summary.errors.filter( e => e.date == this.props.data.date );
        //console.log("state", this.state);
        //console.log("props", this.props);
        let classes = [ "WeekDay" ];
        let status = this.resolveStatus( this.props.data.entries );
     
        if( this.props.data.date == moment().format('YYYY-MM-DD') )
            classes.push( "today" );

        if( this.props.loading ) 
            classes.push( "loading" );

        if( this.state.showDetails )
            classes.push( "detailsOpen" );

        if( this.props.unselected )
            classes.push( "unselected" );

        if( locked ) 
        {
            classes.push("locked");
            
            if( !status.icon )
                status.icon = "lock";
        }

        if( dayErrors.length > 0 )
        {
            classes.push( dayErrors.findIndex( e => e.type === "error" ) != -1 ? "error" : "warning" );
            status.icon = "exclamation-triangle";
        }

        const groupedEntries = groupify( this.props.data.entries, "project_id" );
        let projects = [];

        for( const projectId in groupedEntries )
        {
            if( projectId != "undefined" )
                projects.push({
                    id: projectId,
                    project: groupedEntries[ projectId ][0].project,
                    entries: groupedEntries[ projectId ],
                });
        }

        // Entries that does not have project
        let dailyEntries = [];
        if( "undefined" in groupedEntries )
            dailyEntries = groupedEntries[ "undefined" ];

        if( this.props.data.emptyProjects )
        {
            this.props.data.emptyProjects.forEach( p => {
                projects.unshift({
                    id: p.id,
                    project: p,
                    entries: [],
                });
            });
        }
            
        const daySummary = this.props.summary.days[ this.props.data.date ];
        const haveTravels = daySummary.travels.count > 0 
            ? <div>{ daySummary.travels.distance } km</div> 
            : false ;
        const haveExpenses = daySummary.expenses.count > 0
            ? <div>{ formatMoney( daySummary.expenses.costs ) } { auth.getCurrencySign() }</div>
            : false ;
        const haveAllowance = daySummary.allowances.count > 0
            ? <div>{ formatMoney( daySummary.allowances.compensation ) } { auth.getCurrencySign() }</div>
            : false ;
        /*
        const haveNotations = daySummary.hours.notation > 0
            ? <div>{ daySummary.hours.notation } h</div>
            : false ;
        */
        const haveAllDayAbsence = dailyEntries.findIndex( e => e.type == "absence" && keyExists( e, "detail.is_all_day", true ) ) != -1;         
        const allowanceLocked = dailyEntries.findIndex( e => e.type == "allowance" && [ "open", "rejected" ].indexOf( e.status ) == -1 ) != -1 ;

        return (
            <div className={ classes.join(" ") }>
                
                <div className="header">

                    <SvgIcon className="indicator" icon="chevron-right" type="solid" />
                
                    <div className="clickarea" onClick={ this.toggleDetails }>

                        <div className="date">
                            <span className="weekday">
                                { moment( this.props.data.date ).locale(currentLang()).format( 'dddd' ) }
                                { this.props.data.date == moment().format('YYYY-MM-DD') && <span style={{color: '#999', fontWeight: 'normal'}}> ({tr('today')})</span> }
                            </span>
                            <span className="date">{ moment( this.props.data.date ).format( 'D.M.YYYY' ) }</span>
                        </div>

                        <div className="overview hideUnder500">

                            <div className="hours">
                                { this.renderBars( daySummary, locked ) }
                            </div>

                            <ApTooltip text={ haveTravels }>
                                <SvgIcon className={ "hideUnder800 travels" + ( !haveTravels ? " empty" : "" ) } icon={ entryIcon( "travel" ) } type="solid" />
                            </ApTooltip>

                            <ApTooltip text={ haveExpenses }>
                                <SvgIcon className={ "hideUnder800 expenses" + ( !haveExpenses ? " empty" : "" ) } icon={ entryIcon( "expense" ) } type="solid" />
                            </ApTooltip>

                            <ApTooltip text={ haveAllowance }>
                                <SvgIcon className={ "hideUnder800 allowance" + ( !haveAllowance ? " empty" : "" ) } icon={ entryIcon( "allowance" ) } type="solid" />
                            </ApTooltip>
                            {/* 
                            <ApTooltip text={ haveNotations }>
                                <SvgIcon className={ "notation" + ( !haveNotations ? " empty" : "" ) } icon={ entryIcon( "notation" ) } type="solid" />
                            </ApTooltip>
                            */}
                        </div>
                    
                    </div>

                    <ApDropdown 
                        actionId={ this.props.data.date }
                        actions={ this.getDropDownActions( locked || this.props.data.is_unlocked ? "lockedDay" : "day" ) }
                    />

                    <div className={ "status " + status.name } onClick={ this.toggleDetails }>
                        <ApTooltip text={ status.text } block position="topright">
                            { status.icon && <SvgIcon icon={ status.icon } type="solid" /> }
                        </ApTooltip>
                    </div>

                </div>

            
                <Collapse in={ this.state.showDetails }>
                    <div>
                        <div className="details">
                            <div className="padding">
                                
                                { locked &&
                                    <div className="apMsg">
                                        <SvgIcon className="small-inline" icon="lock" type="solid" /> { tr('day_locked') }
                                    </div>
                                }


                                { !locked && !haveAllDayAbsence && 
                                    <div className="addBar">
                                        <div className="hideUnder800">
                                        <ApInputStack gap="0">
                                                <ApAddon noRightBorder width="50px">
                                                    <SvgIcon icon="folder-plus" type="solid" />
                                                </ApAddon>
                                                <ApSelect
                                                    label={`${tr('add_project')}...`}
                                                    value={ "" }
                                                    optionRenderer="project_assignment"
                                                    onChange={ (e) => this.props.onAddProject( this.props.data.date, e ) }
                                                    objKeyId="id"
                                                    clearable
                                                    request_time= {true}
                                                    apiUrl="timetracking/projects"
                                                    apiData={{ 
                                                        date: this.props.data.date,
                                                        exlude: projects.map( p => p.id ),
                                                    }}
                                                />

                                                { keyExists( this.props, "settings.absences", true, [] ).length > 0 && 
                                                    <ApAddon 
                                                        width="75px" 
                                                        gapLeft="10" 
                                                        onClick={ () => this.addEntry( "absence" ) }
                                                        tooltip={ tr('add_absence') }
                                                        tooltipPosition="topright"
                                                    >
                                                        <SvgIcon icon={ entryIcon( "absence" ) } type="solid" />
                                                    </ApAddon>
                                                }
                                                { !allowanceLocked && keyExists( this.props, "settings.allowances", true, [] ).length > 0 &&
                                                    <ApAddon 
                                                        width="75px" 
                                                        gapLeft="10" 
                                                        onClick={ () => this.addEntry( "allowance" ) }
                                                        tooltip={ !haveAllowance ? tr('add_daily_allowance') : tr('edit_daily_allowance') }
                                                        tooltipPosition="topright"
                                                    >
                                                        <SvgIcon icon={ entryIcon( "allowance" ) } type="solid" />
                                                    </ApAddon>
                                            }
                                            </ApInputStack>
                                        </div>

                                        <div className="showUnder800">
                                        <ApInputStack gap="0">
                                            <div>
                                            <ApSelect
                                                label={`${tr('add_project')}...`}
                                                value={ "" }
                                                optionRenderer="project"
                                                onChange={ (e) => this.props.onAddProject( this.props.data.date, e ) }
                                                objKeyId="id"
                                                clearable
                                                request_time= {true}
                                                apiUrl="timetracking/projects"
                                                apiData={{ 
                                                    date: this.props.data.date,
                                                    exlude: projects.map( p => p.id ),
                                                }}
                                            />
                                            <div>
                                            <div style={{marginBottom: 5}}>
                                            { keyExists( this.props, "settings.absences", true, [] ).length > 0 && 
                                                <ApAddon
                                                    width="75px" 
                                                    gapLeft="10" 
                                                    onClick={ () => this.addEntry( "absence" ) }
                                                    tooltipPosition="topright"
                                                >
                                                    <SvgIcon icon={ entryIcon( "absence" ) } type="solid" /> 
                                                    &nbsp;{ tr('add_absence') }
                                                </ApAddon>
                                            }
                                            </div>
                                            { !allowanceLocked && keyExists( this.props, "settings.allowances", true, [] ).length > 0 &&
                                                <ApAddon 
                                                    width="75px" 
                                                    gapLeft="10" 
                                                    onClick={ () => this.addEntry( "allowance" ) }
                                                    tooltipPosition="topright"
                                                >
                                                    <SvgIcon icon={ entryIcon( "allowance" ) } type="solid" /> 
                                                    &nbsp;{ !haveAllowance ? tr('add_daily_allowance') : tr('edit_daily_allowance') }
                                                </ApAddon>
                                            }
                                            </div>
                                            </div>
                                            </ApInputStack>
                                        </div>                 
                                    </div>
                                }

                                <div className="dailyEntries">
                                    { dailyEntries.map( e => this.renderEntry( e, locked ) ) }
                                </div>

                                { projects.map( p => {
                                    
                                    let projectPath = [];
                                    if( p.project.ancestors.length > 0 )
                                    {
                                        p.project.ancestors.forEach( a => {
                                            projectPath.push( a.name );
                                        })
                                    }
                                    projectPath.push( p.project.name );

                                    const lockedEntriesCount = p.entries.filter( e => [ "submitted", "approved", "confirmed", "payrolled", "paid" ].indexOf( e.status ) != -1 ).length;

                                    return (
                                        <div className={ "project" + ( p.entries.length == 0 ? " empty" : "" ) } key={ this.props.data.date + "_" + p.id }>

                                            <div className="title">

                                                <SvgIcon icon={ p.entries.length > 0 ? "folder-open" : "folder" } type="solid" className="hideUnder800"/>

                                                <div className="name">
                                                	{p.project.assignment!==undefined? <strong>{p.project.assignment.name}<br /></strong>:""}
                                                    <strong><ApPath items={ projectPath } boldLast /></strong><br />
                                                    {/* 
                                                    <strong className={ "projectPath count-" + projectPath.length }>
                                                        { projectPath.map( ( name, index ) =>
                                                            <span className={ ( index == 0 ? "root" : "child level-" + index ) + ( index + 1 == projectPath.length ? " selected" : "" ) }>{ name }</span> 
                                                        )}
                                                    </strong><br />
                                                    */}
                                                    <small>{ p.project.project_code }</small>
                                                </div>

                                                { !locked &&
                                                <div className="addEntryButtons">
                                                    { p.entries.length == 0 &&
                                                        <ApButton className="addHours" color="blue" size="small" onClick={ () => this.addEntry( "hour", p.project ) } disabled={ keyExists( this.props, "settings.hour_types", true, [] ).length == 0}>
                                                            <SvgIcon icon={ entryIcon( "hour" ) } type="solid" />
                                                            <span>{ tr('add_entry') }</span>
                                                        </ApButton>
                                                    }
                                                           
                                                    <ApDropdown 
                                                        position={moment( this.props.data.date ).day()==0?'up':'down'}
                                                        button={
                                                            <ApButton color="blue" size="small">
                                                                <SvgIcon icon="plus" type="solid" />
                                                            </ApButton>
                                                        }
                                                        actionId={ p.project }
                                                        actions={ this.getDropDownActions( "projectAdd" ) }
                                                    />
                                             
                                                    <ApButton className="remove" color="white" size="small" onClick={ () => this.props.onRemoveProject( this.props.data.date, p.id, p.entries.length ) } disabled={ lockedEntriesCount > 0 }>
                                                        <SvgIcon icon="trash" type="solid" />
                                                    </ApButton>
                                                </div>
                                                }
                                              

                                            </div>
                                            <div className="entries">
                                                { p.entries.map( e => this.renderEntry( e, locked ) ) }
                                            </div> 
                                        </div>
                                    );
                                })}

                            </div>


                        </div>
                    </div>
                </Collapse>
               
            </div>
        );
    }
}
