import React, { useState, useEffect, useMemo, useCallback } from 'react';
import propTypes from 'prop-types';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import moment from 'moment';
import './ApCalendar.css';
import { adjustBrightness, capitalize, errorPopper, getLuminance, getWeekdays, tr } from 'services/Helpers/Helpers';
import CalendarModal from './CalendarModal';
import axios from 'axios';
import api from 'services/Api/Api.js';
import { connect } from 'react-redux';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import { READ_ONLY_TYPES, PRIVATE_ICON, DEFAULT_COLOR, BACKGROUND_SHIFT_PERCENT } from './CalendarHelpers';
import CalendarConfirmRecurrenceModal from './CalendarConfirmRecurrenceModal';

const gutterWidth = 60;
const gutterStyles = {
    width: gutterWidth,
    minWidth: gutterWidth,
    maxWidth: gutterWidth,
}

const CustomTimeGutter = (props) => {
    // Render all the timeslot and if the time is between 8am and 5pm, add a class to it
    const timeslots = [];
    for (let i = 0; i < 24; i++) {
        const date = new Date(props.date);
        const highlightTimeStart = 8;
        const highlightTimeEnd = 16;
        date.setHours(i);
        timeslots.push(
            <div className={`rbc-timeslot-group ${i >= highlightTimeStart && i < highlightTimeEnd ? 'highlight-time' : ''}`} key={i}>
                <div className={`rbc-time-slot`}>
                    <div className='rbc-label'>
                        {i < 10 ? `0${i}` : i}:00
                    </div>
                </div>
                <div className="rbc-time-slot"></div>
            </div>
        );
    }
    return (
        <div className="rbc-time-gutter rbc-time-column" style={{...gutterStyles, textAlign: 'center'}}>
            {timeslots}
        </div>
    );
};

const DragAndDropCalendar = withDragAndDrop(Calendar);

const views = ['month', 'week', 'day', 'agenda'];
let tempSaveData = null;
let tempDeleteId = null;

const ApCalendar = (props) => {
    // get current path for local storage key
    const currentPath = window.location.pathname;

    const [selectedEvent, setSelectedEvent] = useState(null);
    const [view, setView] = useState(views.includes(localStorage.getItem(currentPath + 'calendarView'))
        ? localStorage.getItem(currentPath + 'calendarView')
        : 'week');
    const [range, setRange] = useState(null);
    const [loading, setLoading] = useState(true);
    // axios cancel token
    const [cancelToken, setCancelToken] = useState(null);
    const [events, setEvents] = useState([]);
    const [colors, setColors] = useState([]);
    const [eventTypeFilters, setEventTypeFilters] = useState(JSON.parse(localStorage.getItem('apCalendarEventFilters')));
    const [confirmRecurrence, setConfirmRecurrence] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const components = useMemo(() => ({
        // event: MyEvent, // used by each view (Month, Day, Week)
        // eventWrapper: MyEventWrapper,
        // eventContainerWrapper: MyEventContainerWrapper,
        // dateCellWrapper: MyDateCellWrapper,
        // dayColumnWrapper: MyDayColumnWrapper,
        // timeSlotWrapper: MyTimeSlotWrapper,
        timeGutterHeader: () => {
            if (range && range[0]) {
                const weekText = tr('week_abbr') + ' ' + moment(range[0]).isoWeek();
                return <div className="bold" style={{...gutterStyles, textAlign: 'center'}}>{weekText}</div>;
            }
            return null;
        },
        timeGutterWrapper: CustomTimeGutter,
        // resourceHeader: MyResourceHeader,
        // toolbar: MyToolbar,
        agenda: {
            event: ({ event }) => {
                return (
                    <div className='pointer'>
                        <div className='bold'>{event.is_private && <SvgIcon icon={PRIVATE_ICON} className="size-small" type="solid" />} {event.title}</div>
                        {event.description && <p className='description'>{event.description}</p>}
                    </div>
                );
            },
            // time: MyAgendaTime,
            // date: MyAgendaDate,
        },
        day: {
            // header: MyDayHeader,
            event: ({ event }) => {
                let all_day = event.all_day;
                // if event not marked as all day, check if duration is more than 1 day
                if (!all_day && !moment(event.start).isSame(moment(event.end), 'day')) {
                    all_day = true;
                }

                return (
                    <div className='pointer'>
                        <div>
                            {event.is_private && <SvgIcon icon={PRIVATE_ICON} className="size-small" type="solid" />} {event.title}
                        </div>
                        {event.project_id && <p className='eventProject'>{event.project?.project_code} - {event.project?.project_search?.full_name}</p>}
                        {event.description && !all_day && <p className='eventDescription'>{event.description}</p>}
                    </div>
                );
            },
        },
        week: {
            event: ({ event }) => {
                let all_day = event.all_day;
                // if event not marked as all day, check if duration is more than 1 day
                if (!all_day && !moment(event.start).isSame(moment(event.end), 'day')) {
                    all_day = true;
                }

                return (
                    <div className='pointer'>
                        <div>
                            {event.is_private && <SvgIcon icon={PRIVATE_ICON} className="size-small" type="solid" />}
                            {event.title}
                            {event.project_id && (
                                <span className='eventProject'>
                                    &nbsp;/ {event.project?.project_code} - {event.project?.project_search?.full_name}
                                </span>
                            )}
                        </div>
                        {event.description && !all_day && <p className='eventDescription'>{event.description}</p>}
                    </div>
                );
            },
        },
        month: {
            dateHeader: ({ date }) => {
                let dateNum = date.getDate();
                if (dateNum < 10) {
                    dateNum = '0' + dateNum;
                }

                // Only show week number on mondays
                const weekNumber = date.getDay() === 1 ? moment(date).isoWeek() : null;
                let weekText = '';
                if (weekNumber) {
                    weekText = tr('week_abbr') + ' ' + weekNumber
                }
                return (
                    <div className='justify-space-between'>
                        <div className='bold'>{weekText}</div>
                        <div>{dateNum}</div>
                    </div>
                );
            },
            event: ({ event }) => {
                return (
                    <div className='pointer'>
                        <div className='eventContainer'>
                            {event.is_private && <SvgIcon icon={PRIVATE_ICON} className="size-small" type="solid" />} 
                            {event.title}
                            {event.project_id && <span className='eventProject'>&nbsp;/ {event.project?.project_code} - {event.project?.project_search?.full_name}</span>}
                        </div>
                        {/* {event.description && <p className='description'>{event.description}</p>} */}
                    </div>
                );
            }
        },
    }), [range]);

    const getEvents = () => {
        if (props.manualEvents) {
            return;
        }

        if (!range) {
            return;
        }

        if (cancelToken) {
            cancelToken.cancel("Operation canceled by the user.");
        }

        // In month and agenda view we get start and end dates
        // In week and day view we get an array of dates
        const start = range.start ?? range[0];
        const end = range.end ?? range[range.length - 1];

        setLoading(true);
        const newCancelToken = axios.CancelToken.source();
        setCancelToken(newCancelToken);
        api({
            method: 'get',
            url: 'calendar/events',
            params: {
                start: start,
                end: end
            },
            cancelToken: newCancelToken.token,
        }).then((response) => {
            if (Array.isArray(response.data)) {
                response.data = response.data.map(formatEvent);
            }
            setEvents(response.data);
            setCancelToken(null);
            setLoading(false);

        }).catch((error) => {
            if (axios.isCancel(error)) {
                return;
            }
            console.error(error);
            errorPopper(error, tr('get_error'));
            setLoading(false);

        });
    }

    const saveEvent = async (event, confirmed = false, dragEvent = false) => {
        if (event.type && READ_ONLY_TYPES.includes(event.type))
            return;

        // if event is recurring and not confirmed, show confirmation modal on what to do with recurring events
        if (event.recurring_event_id && event.frequency && !confirmed) {
            setConfirmRecurrence(true);
            tempSaveData = event;
            tempSaveData.dragEvent = dragEvent;
            return false;
        }

        let dateStart = new Date(event.start);
        let dateEnd = new Date(event.end);
        if (event.all_day) {
            dateStart.setHours(0, 0, 0, 0);
            dateEnd.setHours(23, 59, 59, 999);
        }

        setLoading(true);

        return api({
            url: 'calendar/event',
            method: 'post',
            data: {
                ...event,
                start: dateStart,
                end: dateEnd,
                dragEvent: dragEvent
            }
        }).then(response => {
            // console.log("RESPONSE", response);
            const ids = response.data.map(e => e.id);
            const parsedEvents = response.data.map(formatEvent);
            let prevEvents = [...events];
            if (event.recurring_event_id) {
                // Filter out all prev events that are part of the same recurring event and add new events from response
                prevEvents = prevEvents.filter(e => e.recurring_event_id !== response.data[0].recurring_event_id);

                if (event.recurrence_handle_type === 'this') {
                    // Remove the event that was edited from prevEvents
                    prevEvents = prevEvents.filter(e => e.id !== event.id);
                }

                // If was dragEvent and recurrence handle type 'future', filter out all events that are part of the same recurring event and start after the event's original start
                if (dragEvent && event.recurrence_handle_type === 'future') {
                    prevEvents = prevEvents.filter(e => {
                        if (e.recurring_event_id === event.recurring_event_id) {
                            const eStart = new Date(e.start);
                            const eventStart = new Date(event.originalStart);
                            return eStart < eventStart;
                        }
                        return true;
                    });
                }
            }
            else if (event.id) {
                prevEvents = prevEvents.filter(e => !ids.includes(e.id));
            }
            setEvents([...prevEvents, ...parsedEvents]);
            setSelectedEvent(null);
            errorPopper(null, tr('saved'), 2000, 'success');
            return response;
        }).catch(error => {
            console.error("ERROR", error);
            errorPopper(error, tr('save_error'));
            return error;
        }).finally(() => {
            setLoading(false);
            tempSaveData = null;
        });
    }

    const handleRecurrenceType = (type) => {
        if (type === tr('recur_event_all')) {
            return 'all';
        } else if (type === tr('recur_event_future')) {
            return 'future';
        } else if (type === tr('recur_event_this')) {
            return 'this';
        }
    }

    const formatEvent = (event) => {
        event.start = new Date(event.start);
        event.originalStart = new Date(event.start);
        event.end = new Date(event.end);
        // Recurrence start needs to be in Y-m-d format because Flatpickr uses it. The main calendar uses JS Date objects.
        event.recurrence_start = moment(event.start).format('YYYY-MM-DD');
        if (event.recurring_event) {
            event.frequency = event.recurring_event.frequency;
            event.recurrence_start = event.recurring_event.start_date;
            event.recurrence_end = event.recurring_event.end_date;
            event.interval = event.recurring_event.interval;
            const weekDays = getWeekdays();
            event.days = []
            event.recurring_event.rules?.forEach(rule => {
                const foundDay = weekDays.find(day => day.value === rule.day_of_week);
                if (foundDay) {
                    event.days.push(foundDay);
                }
            })
            event.last_of_month = event.recurring_event.last_of_month;
        }
        event.installations_components = [];
        event.installations_expenses = [];

        // If event has a timetracking entries, check installation components and expenses and format them
        if (event.timetracking_entries) {
            event.timetracking_entries.forEach(entry => {
                // If entry has installations or chargeables, format them
                if (entry.project_component_installations.length) {
                    entry.project_component_installations.forEach(installation => {
                        const cInstallation = {};
                        cInstallation.id = installation.project_component_id;
                        cInstallation.time = parseFloat(installation.hours) * 60;
                        cInstallation.count = installation.quantity;
                        cInstallation.type = 'components';
                        
                        event.installations_components.push(cInstallation);
                    });
                }
                if (entry.project_expense_chargeables.length) {
                    entry.project_expense_chargeables.forEach(chargeable => {                        
                        const cChargeable = {};
                        cChargeable.id = chargeable.expense_id;
                        cChargeable.time = parseFloat(chargeable.hours) * 60;
                        cChargeable.count = chargeable.quantity;
                        cChargeable.type = 'expenses';
                        
                        event.installations_expenses.push(cChargeable);
                    });
                }
            });
        }
        return event;
    }

    const handleEventSave = (event, dragEvent = false) => {
        // if all day event, subtract 1 second from end time
        if (event.event.all_day) {
            event.end.setSeconds(event.end.getSeconds() - 1);
        }

        // if start and end are same, add 30 minutes to end date object
        if (moment(event.start).isSame(moment(event.end))) {
            event.end.setMinutes(event.end.getMinutes() + 30);
        }

        saveEvent({
            ...event.event,
            start: event.start,
            end: event.end,
            // all_day: event.event.all_day,
        }, false, dragEvent);
    };

    const deleteEvent = (id, confirmed = false, type = null) => {
        // if event is recurring and not confirmed, show confirmation modal on what to do with recurring events
        const event = events.find(e => e.id === id);
        if (!event) return;
        if (event.recurring_event_id && !confirmed) {
            setConfirmDelete(true);
            tempDeleteId = id;
            return false;
        }

        api({
            url: 'calendar/delete-event',
            method: 'post',
            data: {
                id: id,
                recurrence_handle_type: type,
                recurring_event_id: event.recurring_event_id,
            }
        }).then(response => {
            let newEvents = [];
            if (event.recurring_event_id) {
                if (type === 'all') {
                    newEvents = events.filter(e => e.recurring_event_id !== event.recurring_event_id);
                }
                else if (type === 'future') {
                    newEvents = events.filter(e => {
                        // Filter out all events that are part of the same recurring event and start before the deleted event
                        if (e.recurring_event_id === event.recurring_event_id) {
                            const eStart = new Date(e.start);
                            const eventStart = new Date(event.start);
                            
                            return eStart < eventStart;
                        }
                        return true;
                    });
                } else if (type === 'this') {
                    // Filter out the event
                    newEvents = events.filter(e => e.id !== id);
                    if (response?.data?.recurringEventData) {
                        // Map through the events and if event exists in response data, 
                        // change recurring event id and the recurrence start and end
                        newEvents = newEvents.map(e => {
                            const previousRecurringCalendarEvent = response.data.recurringEventData.previousRecurringEvent;
                            const foundEvent = previousRecurringCalendarEvent.calendar_events.find(re => re.id === e.id);
                            if (foundEvent) {
                                e.recurring_event_id = previousRecurringCalendarEvent.id;
                                e.recurrence_start = previousRecurringCalendarEvent.start_date;
                                e.recurrence_end = previousRecurringCalendarEvent.end_date;
                            }

                            const upcomingRecurringCalendarEvent = response.data.recurringEventData.upcomingRecurringEvent;
                            const foundEvent2 = upcomingRecurringCalendarEvent.calendar_events.find(re => re.id === e.id);
                            if (foundEvent2) {
                                e.recurring_event_id = upcomingRecurringCalendarEvent.id;
                                e.recurrence_start = upcomingRecurringCalendarEvent.start_date;
                                e.recurrence_end = upcomingRecurringCalendarEvent.end_date;
                            }

                            return e;
                        });
                    }
                }
            } else {
                newEvents = events.filter(e => e.id !== id);
            }
            setEvents(newEvents);
            setSelectedEvent(null);
            errorPopper(null, tr('deleted'), 2000, 'success');
        }).catch(error => {
            console.error("ERROR", error);
            errorPopper(error, tr('delete_error'));
        });
    }

    const getUsedColors = () => {
        api({
            url: 'calendar/colors',
            method: 'get',
        }).then(response => {
            if (response) {
                setColors(response);
            } else {
                setColors([]);
            }
        }).catch(error => {
            console.log("ERROR", error);
            errorPopper(error, tr('get_error'));
        });
    }

    useEffect(() => {
        if (props.initialView && views.includes(props.initialView)) {
            setView(props.initialView);
        }
    }, [props.initialView]);

    useEffect(() => {
        if (!range) {
            if (view === 'month' || view === 'agenda') {
                setRange({
                    start: moment().startOf('month').toDate(),
                    end: moment().endOf('month').toDate()
                });
            } else if (view === 'week') {
                setRange([
                    moment().startOf('week').toDate(),
                    moment().endOf('week').toDate()
                ]);
            } else if (view === 'day') {
                setRange([
                    moment().startOf('day').toDate(),
                    moment().endOf('day').toDate()
                ]);
            }
        }

        localStorage.setItem(currentPath + 'calendarView', view);

        if (props.getRange && typeof props.getRange === 'function') {
            props.getRange(range);
        }

        getEvents();
    }, [view, range]);

    useEffect(() => {
        if (props.getLoading && typeof props.getLoading === 'function') {
            props.getLoading(loading);
        }
    }, [loading]);

    useEffect(() => {
        getUsedColors();
    }, [events]);

    const handleEventProps = (event, start, end, isSelected) => {
        let styles = {
            border: '1px solid #d7d7d7',
        };
        if (view !== 'agenda') {
            if (event.color) {
                styles.backgroundColor = event.color
            } else {
                styles.backgroundColor = DEFAULT_COLOR;
            }
            const luminance = styles.backgroundColor ? getLuminance(styles.backgroundColor) : 0;
            styles.color = 'var(--clr-font-' + (luminance > 0.5 ? 'dark' : 'light') + ')';

            // Adjust dark colors to be darker, light colors to be lighter
            const adjustedColor = adjustBrightness(styles.backgroundColor, luminance > 0.5 ? BACKGROUND_SHIFT_PERCENT : -BACKGROUND_SHIFT_PERCENT);
            styles.background = `linear-gradient(145deg, ${styles.backgroundColor}, ${styles.backgroundColor}, ${adjustedColor})`;
        }

        let className = '';

        return {
            style: styles,
            className: className
        };
    }

    const handleSlotSelect = (slotInfo) => {
        const { start, end } = slotInfo;
        let all_day = false;

        if (view === 'month') {
            // subtract 1 second if view is month so end date is correct
            end.setSeconds(end.getSeconds() - 1);
            all_day = true;
        }
        else if (view === 'week' || view === 'day') {
            // subtract 1 second if end date is not same as start date
            if (!moment(start).isSame(moment(end), 'day')) {
                end.setSeconds(end.getSeconds() - 1);
                all_day = true;
            }
        }
        setSelectedEvent({
            start: start,
            end: end,
            // type: 'new_event',
            new: true,
            description: '',
            new: true,
            all_day: all_day,
        });
    }

    const calendarStyles = { height: 'auto' };
    if (view === 'month') {
        calendarStyles.height = '650px';
    } else if (view === 'agenda') {
        calendarStyles.maxHeight = '500px';
        calendarStyles.overflow = 'auto';
    }

    const handleEventFilters = (id) => {        
        setEventTypeFilters((prevFilters) => {
            // If the ID exists in the current filters, remove it; otherwise, add it to the filters
            let newFilters;
            if (prevFilters && prevFilters.includes(id)) {
                newFilters = prevFilters.filter(filter => filter !== id);
            } else {
                newFilters = prevFilters ? [...prevFilters, id] : [id];
            }
            localStorage.setItem('apCalendarEventFilters', JSON.stringify(newFilters));
            return newFilters;
        });
    }

    const renderEventTypeFilters = () => {
        if (!props.apGeneralSettings || !props.apGeneralSettings.calendarEventTypes) {
            return null;
        }

        const calendarEventTypes = props.apGeneralSettings.calendarEventTypes;
        return (
            <div className='eventTypeFilters'>
                {calendarEventTypes.map(eventType => {
                    let disabled = false;
                    if (eventTypeFilters && eventTypeFilters.includes(eventType.id)) {
                        disabled = true;
                    }
                    return (
                        <div onClick={() => handleEventFilters(eventType.id)} key={eventType.id} className={'eventType' + (disabled ? ' disabled' : '')}>
                            <span className='apBadge' style={{ backgroundColor: (disabled ? '#666' : eventType.color) }}></span>&nbsp;
                            {eventType.is_static ? tr(eventType.name) : eventType.name}
                        </div>
                    );
                })}
            </div>
        );
    }

    const removeColor = (color) => {
        api({
            url: 'calendar/color/remove',
            method: 'post',
            data: {
                color: color
            }
        }).then(response => {
            // Loop through events and check if using removed color. If so, set to default color
            const prevEvents = [...events];
            const newEvents = prevEvents.map(event => {
                if (event.color === color) {
                    event.color = DEFAULT_COLOR;
                }
                return event;
            });

            setEvents(newEvents);
            setColors(response);
        }).catch(error => {
            console.log("ERROR", error);
            errorPopper(error, tr('get_error'));
        });
    }

    let filteredEvents = props.manualEvents ? props.events : events;
    if (eventTypeFilters && eventTypeFilters.length > 0) {
        filteredEvents = filteredEvents.filter(event =>
            !eventTypeFilters.includes(parseInt(event.company_event_type_id))
        );
    }


    return (
        <div id={'apCalendar'} className={'calendar-view-'+view} style={calendarStyles}>
            {renderEventTypeFilters()}
            <DragAndDropCalendar
                events={filteredEvents}
                eventPropGetter={(event, start, end, isSelected) => {
                    return handleEventProps(event, start, end, isSelected);
                }}

                view={view}
                views={views}

                startAccessor={'start'}
                endAccessor={'end'}
                allDayAccessor={'all_day'}

                scrollToTime={new Date().setHours(6, 0)}

                localizer={momentLocalizer(moment)}

                onSelectEvent={event => setSelectedEvent(event)}
                onSelectSlot={(slotInfo) => handleSlotSelect(slotInfo)}
                onEventDrop={event => handleEventSave(event, true)}
                onEventResize={handleEventSave}
                onRangeChange={(range) => setRange(range)}
                onView={(view) => setView(view)}
                // onNavigate={(date, view) => console.log("navigate", date, view)}

                popup={true}

                draggableAccessor={props.readOnly ? false : 'editable'}
                selectable={props.readOnly ? false : 'editable'}
                resizable={props.readOnly ? false : 'editable'}
                resizableAccessor={props.readOnly ? false : 'editable'}

                components={components}
                messages={{
                    allDay: tr('all_day'),
                    previous: tr('previous'),
                    next: tr('next'),
                    today: capitalize(tr('today')),
                    month: capitalize(tr('month')),
                    week: capitalize(tr('week')),
                    day: capitalize(tr('day')),
                    agenda: capitalize(tr('agenda')),
                    date: capitalize(tr('day')),
                    time: tr('time'),
                    event: tr('event'),
                    noEventsInRange: tr('calendar_no_events'),
                }}
            />

            <CalendarModal
                loading={loading}
                selectedEvent={selectedEvent}
                readOnly={!!props.readOnly}
                mode={selectedEvent?.new ? 'create' : 'edit'}
                view={view}
                colors={colors}

                closeModal={() => setSelectedEvent(null)}
                onSave={saveEvent}
                onDelete={deleteEvent}
                removeColor={removeColor}
            />

            {/* Confirm recurrence change modal */}
            <CalendarConfirmRecurrenceModal
                show={confirmRecurrence}
                onClose={() => {
                    setConfirmRecurrence(false);
                    tempSaveData = null;
                }}
                onConfirm={async (type) => {
                    await saveEvent({
                        ...tempSaveData,
                        recurrence_handle_type: handleRecurrenceType(type),
                    }, true, tempSaveData.dragEvent);
                    setConfirmRecurrence(false);
                }}
                loading={loading}
            />

            {/* Confirm delete modal */}
            <CalendarConfirmRecurrenceModal
                show={confirmDelete}
                onClose={() => {
                    setConfirmDelete(false);
                    tempDeleteId = null;
                }}
                onConfirm={(type) => {
                    setConfirmDelete(false);
                    deleteEvent(tempDeleteId, true, handleRecurrenceType(type));
                }}
            />
        </div>
    );
}

ApCalendar.propTypes = {
    events: propTypes.array,
    initialView: propTypes.string, // set the initial view when component mounts

    getRange: propTypes.func, // returns range object
    getLoading: propTypes.func, // returns true if calendar is loading

    manualEvents: propTypes.bool, // if true, events are handled outside of this component
}

const mapStateToProps = (state) => ({
    apTimetrackingSettings: state.apTimetrackingSettings,
    apGeneralSettings: state.apGeneralSettings,
});

export default connect(mapStateToProps)(ApCalendar);
