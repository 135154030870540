import React from 'react';
import autoBind from 'react-autobind';

import api               from 'services/Api/Api.js';
import ApTooltip         from 'common/ApTooltip/ApTooltip.js';
import SvgIcon           from 'common/SvgIcon/SvgIcon.js';
import ApModal           from 'common/ApModal/ApModal.js';
import ApDropdown        from 'common/ApDropdown/ApDropdown.js';
import ApButton          from 'common/ApButton/ApButton.js';
import ApSteps           from 'common/ApSteps/ApSteps.js';
import ComponentTooltip from 'modules/Storage/common/ComponentTooltip/ComponentTooltip.js';
import ApSelect from 'common/ApSelect/ApSelect.js';

import { ApInput
       , ApInputStack
       , ApAddon  }      from 'common/ApInput/ApInput.js';

import { mapTree
       , keyExists
       , findItemById
       , tr }  from 'services/Helpers/Helpers.js';

import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';

import ApTree from 'common/ApTree/ApTree.js';

import './Code.css';
import ApList from 'common/ApList/ApList';
import { connect } from 'react-redux';
import ApSwitch from 'common/ApSwitch/ApSwitch';
import ComponentEdit from 'modules/Storage/Components/ComponentEdit/ComponentEdit';

const typeIcons = {
    item: 'puzzle-piece',
    work: 'user-clock',
    other: 'money-bill-wave',
};

class Code extends React.Component {
    constructor(props)
    {
        super(props);

        this.newCounter = this.props.tree?.length ?? 0;

        this.state = {
            selectedDetail: 'components_count',
            selectedDetailCumulative: true,


            selectedCode: null,
            components: [],

            removeCodeConfirmShow: false,
            removeCodeConfirmData: null,

        };
        autoBind(this);
    }

    getComponents( codeId )
    {
        this.setState({
            componentsLoading: true,
            components: [],
        });

        return api({
            method: 'get',
            url: `storage/settings/code/${ codeId }/components`,
        }).then((response) => {
            this.setState({
                componentsLoading: false,
                components: response,
            });
        }).catch((error) => {
            console.error(error);
            this.setState({ componentsLoading: false });
        });

    }


    toggleTreeCumulativeDetails()
    {
        this.setState({ selectedDetailCumulative: !this.state.selectedDetailCumulative });
    }

    selectNode( id )
    {
        let tree = JSON.parse(JSON.stringify( this.props.tree ));
        tree = mapTree( tree, ( item, parent ) => {

            item.__selected = false;
            if( id === item.id )
            {
                item.__selected = true;

                if( item.children )
                    item.__open = true;
            }

            return item;
        });
        this.props.onTreeChange( tree );
    }

    handleCodeSelect( code )
    {
        // Unsaved code id starts with word "new" therefore they cannot be parsed to integer
        const unsaved = isNaN( parseInt( code.id, 10 ) );

        this.updateSelectedCodeSettings();
        this.selectNode( code.id );

        if( code.components )
        {
            const components = JSON.parse( JSON.stringify( code.components ) );
            this.setState({
                components: components,
            });
        }
        else
        {
            if( unsaved || this.props.isOrgChart || this.props.isCostCenter )
                this.setState({
                    components: [],
                });
            else
                this.getComponents( code.id );
        }
        this.setState({ selectedCode: code });
    }

    /*
     * For performance reasons we only update settings to tree when
     * selected is changed or when user clicks save button
     *
     * Otherwise we should loop whole tree structure on each change
     */
    updateSelectedCodeSettings()
    {
        const selected = this.state.selectedCode;
        if( !selected )
            return false;

        const components = JSON.parse( JSON.stringify( this.state.components ) );

        this.editCode({
            id:          selected.id,
            name:        selected.name,
            value:       selected.value,
            description: selected.description,
            components: components,
            costCenterGroup: selected.costCenterGroup,
            supplier_added: selected.supplier_added,
            supplier_removed:selected.supplier_removed,
            show_to_online_store: selected.show_to_online_store,
            hide_from_online_store: selected.hide_from_online_store,
            groups: selected.groups,
            users: selected.users,
            netvisor_dimension_key: selected.netvisor_dimension_key,
            netvisor_dimension_name: selected.netvisor_dimension_name,
            netvisor_dimension_father_id: selected.netvisor_dimension_father_id,
            netvisor_dimension_header_key: selected.netvisor_dimension_header_key,
            netvisor_dimension_header_name: selected.netvisor_dimension_header_name
        });
    }

    editCode( code )
    {
        if( !code.id )
            return false;

        let tree = this.props.tree.slice(0);
        tree = mapTree( tree, ( item, parent ) => {
            if( item.id === code.id )
            {
                for( const prop in code )
                {
                    if( prop === "id" ) continue;
                    item[ prop ] = code[ prop ];
                }
            }

            if( !parent )
                item.code = item.value;
            else
                item.code = `${ parent.code }${ this.props.separator }${ item.value }`;

            return item;
        });
        this.props.onTreeChange( tree );
    }

    codeInfoChange( e )
    {
        //console.log(e);
        let selectedCode = { ...this.state.selectedCode };
        selectedCode[e.target.name] = e.target.value;
        if (typeof this.props.changeSaveButton === 'function') {
            this.props.changeSaveButton();
        }
        this.setState({ selectedCode });
    }
    handleNetvisorDimensionChange(e) {
        let selectedCode = { ...this.state.selectedCode };
        const value = e.target.value;
        const foundDimension = this.props.netvisorDimensions.find(d => d.value === value);
        if (foundDimension) {
            selectedCode.netvisor_dimension_key = foundDimension.value;
            selectedCode.netvisor_dimension_name = foundDimension.dimensionName;
            selectedCode.netvisor_dimension_father_id = foundDimension.dimensionFatherId;
            selectedCode.netvisor_dimension_header_key = foundDimension.headerKey;
            selectedCode.netvisor_dimension_header_name = foundDimension.headerName;
        } else {
            selectedCode.netvisor_dimension_key = null;
            selectedCode.netvisor_dimension_name = null;
            selectedCode.netvisor_dimension_father_id = null;
            selectedCode.netvisor_dimension_header_key = null;
            selectedCode.netvisor_dimension_header_name = null;
        }
        this.setState({ selectedCode });
    }
    changeSupplierLists(list, variableName) {
        let selectedCode = { ...this.state.selectedCode };
        selectedCode[variableName] = list;
        this.setState({ selectedCode });
    }

    toggleShowToOnlineStore(key)
    {
        let selectedCode = { ...this.state.selectedCode };
        selectedCode[key] = !selectedCode[key];
        if (typeof this.props.changeSaveButton === 'function') {
            this.props.changeSaveButton();
        }

        this.editCode({
            id: selectedCode.id,
            [key]: selectedCode[key],
        });
        this.setState({ selectedCode });
    }

    renderTreeNode( code )
    {
        let mode = ( this.state.selectedDetailCumulative ) ? 'all' : 'own';

        let value = null;
        let valueDom = null;
        if( keyExists( code.calculated, mode ) )
        {
            value = code.calculated[ mode ].components_count;
            valueDom = ( value ) ? <div className="value">{ code.calculated[ mode ].components_count  }</div> : null;
        }

        return <div>
            { code.code }<br />
            <small>{ code.name }</small>
            { valueDom }
        </div>
    }

    renderSelected()
    {
        const code = this.state.selectedCode;

        if( !code )
            return this.renderInfo();

        const parent = findItemById( this.props.tree, code.parent_id, 'children' );

        let componentTab = null;
        if (code.level === this.props.levels.length && (!this.props.isCostCenter && !this.props.isOrgChart))
        {
            const components = this.state.components;
            let codeValue = code.value;
            if( parent ) codeValue = `${ parent.code }${ this.props.separator }${ code.value }`

            componentTab = <ApTab icon="cubes" label={ tr('storage_components') }>
                <div className="padding">
                    { this.renderCodeComponents( components, codeValue ) }
                </div>
            </ApTab>
        }

        return <div>
            { this.renderSelectedPath() }
            <ApTabs fullWidth>
                <ApTab icon="ellipsis-h" label={ tr('code_info') }>
                    <div className="padding">
                        { this.renderCodeInfo( code, parent ) }
                    </div>
                </ApTab>
                { componentTab }
            </ApTabs>
        </div>
    }

    removeCodeClick( item )
    {
        this.setState({
            removeCodeConfirmShow: true,
            removeCodeConfirmData: item,
        });
    }

    removeCode()
    {
        const code = this.state.removeCodeConfirmData;
        const parentId = code.parent_id;

        let tree = this.props.tree.slice(0);

        if( parentId )
        {
            tree = mapTree( tree, ( item ) => {
                if( item.id === parentId )
                {
                    // Remove the location
                    item.children = item.children.filter( ( child ) => {
                        return ( child.id !== code.id );
                    });
                }
                return item;
            });
        }
        else
        {
            // Remove the root location
            tree = tree.filter( ( child ) => {
                return ( child.id !== code.id );
            });
        }
        this.props.onTreeChange( tree );
        if (typeof this.props.changeSaveButton === 'function') {
            this.props.changeSaveButton();
        }

        this.setState({
            removeCodeConfirmShow: false,
            removeCodeConfirmData: null,
            selectedCode: null,
            components: [],
        });
    }

    addCodeClick( item = null )
    {
        let tree = this.addCodeToTree( this.props.tree, item );
        this.props.onTreeChange( tree );
        const code = findItemById( tree, `new${ this.newCounter }` );
        this.handleCodeSelect( code );
    }

    addCodeToTree( tree, parent = null )
    {
        this.newCounter++;

        let newLocation = {
            id: `new${ this.newCounter }`,
            parent_id: parent ? parent.id : null,
            name: '',
            value: '',
            description: '',
            children: [],
            invalid: true,
            level: 1,
            supplier_added:[],
            supplier_removed:[],
            show_to_online_store: false,
            hide_from_online_store: false,
        };

        if( parent )
        {
            newLocation.level = parent.level + 1;
            tree = mapTree( tree, ( item ) => {
                if( item.id === parent.id )
                {
                    item.children.push( newLocation );
                }
                return item;
            });
        }
        else
        {
            tree.push( newLocation );
        }
        return tree;
    }

    handleAddPersonnel(values) {
        let selectedCode = { ...this.state.selectedCode };

        values = Array.isArray(values) ? values : [values];
        selectedCode.users = values

        if (typeof this.props.changeSaveButton === 'function') {
            this.props.changeSaveButton();
        }

        let treeCopy = this.props.tree.slice(0);
        const tree = mapTree(treeCopy, item => {
            if (item.id !== selectedCode.id) {
                if (item.users && Array.isArray(item.users)) {
                    const ids = values.map(user => user.id);
                    item.users = item.users.filter(user => !ids.includes(user.id));
                }
            }
            return item;
        })
        this.setState({ selectedCode });
        this.props.onTreeChange(tree);
    }

    renderSelectedPath()
    {
        const current = this.state.selectedCode;
        if( !current )
            return null;

        const withDropdown = ( item ) => {

            const root = ( current.parent_id ? false : true );

            let actions = [];

            if( current.level < this.props.levels.length || this.props.isOrgChart )
            {
                actions.push({
                    label: tr('add_lower_code'),
                    icon: "plus",
                    action: ( item, closeFunc ) => {
                        this.addCodeClick( item );
                        closeFunc();
                    },
                });
            }

            actions.push({
                label: tr('delete_code'),
                icon: "trash-alt",
                action: ( item, closeFunc ) => {
                    this.removeCodeClick( item );
                    closeFunc();
                }
            })

            return (
                <div className={ "inner current" + ( root ? " withIcon" : "" ) }>
                    { root && <SvgIcon className="stepIcon" icon={ item.icon } type="solid" /> }
                    <strong>{ current.code }&nbsp;</strong>
                    <small>{ current.name }&nbsp;</small>

                    <ApDropdown
                        actionId={ current }
                        actions={ actions }
                    />
                </div>
            );
        };

        const forComponents = ( item ) => {

            let code = current.code
            let tail = 'x...x';

            if( this.props.mode.need_length )
            {
                let length = this.props.tailLength;
                tail = 'x'.repeat( length );
            }
            code = `${ code }${ this.props.separator}${tail}`

            return <div className="componentBadgeContainer">
                <div className={ "inner current" }>
                    <strong>{ code }</strong>
                    <small>{ tr('storage_components') }</small>
                </div>
                <span className="apBadge blue">{ this.state.components.length }</span>
            </div>
        };


        let items = [];
        const iterate = ( id, selected = false ) => {
            const code = findItemById( this.props.tree, id, 'children' );
            if( code )
            {
                items.unshift({
                    icon:           ( !code.parent_id ? "list-ol" : false ),
                    selected:       selected,
                    onClick:        ( selected ? false : () => this.handleCodeSelect( code ) ),
                    name:           code.code,
                    description:    code.name,
                    customRenderer: ( selected ? withDropdown : false ),
                });

                if( code.parent_id )
                    iterate( code.parent_id );
            }
        };

        iterate( current.id, true );

        let flatEnd = false;

        if (current.level === this.props.levels.length && !this.props.isCostCenter)
        {
            flatEnd = true;
            if (!this.props.isOrgChart) {
                items.push({
                    customRenderer: forComponents,
                    selected: true,
                });
            }
        }

        return (
            <ApSteps className="codePath" steps={ items } flatEnd={ flatEnd } />
        )
    }

    renderCodeInfo( selected, parent )
    {
        let parentCode = null;
        if( parent )
        {
            parentCode = <ApInput
                id="parent-code"
                name="parent-code"
                width="200px"
                label={ tr('upper_code') }
                value={ `${ parent.code}${ this.props.separator }` }
                disabled={ true }
            />
        }

        let missingChildrenDom = null;
        if (selected.missing_children && !(this.props.isCostCenter || this.props.isOrgChart))
        {
            missingChildrenDom = <div>
                <div className="apInfo error">
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('subcode_storage_components_info') }
                </div>
            </div>
        }

        let invalidChildrenDom = null;
        if (selected.child_invalid && !(this.props.isCostCenter || this.props.isOrgChart))
        {
            invalidChildrenDom = <div>
                <div className="apInfo error">
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('errors_in_subcodes') }
                </div>
            </div>
        }

        let codeValidMessage = this.props.isCodeInvalid( selected );

        let onlineStoreEnabled = false;
        if (this.props.woocommerce_enabled) {
            onlineStoreEnabled = true;
        }

        let groups = [];
        if (this.props.isOrgChart) {
            if (Array.isArray(this.props.groups)) {
                groups = this.props.groups.filter(group => Number(group.id) && !group.removed);
            }
        }

        let showNetvisorDimensions = false;
        if (Array.isArray(this.props.netvisorDimensions) && this.props.netvisorDimensions.length > 0) {
            showNetvisorDimensions = true;
        }

        return <div>
            {this.props.isCostCenter &&
                <ApSelect
                label={tr("group")}
                value={this.state.selectedCode ? this.state.selectedCode.costCenterGroup: ""}
                optionRenderer={(item) => {

                    return <div>
                        <strong>{item.name}</strong><br/>
                        <small>{item.code}</small>
                    </div>
                }}
                onChange={(item) => {
                    let selectedCode = { ...this.state.selectedCode };
                    selectedCode.costCenterGroup = item;
                    selectedCode.value = (selectedCode.value === null || selectedCode.value === "") ? item.code : selectedCode.value;
                    selectedCode.name = (selectedCode.name === null || selectedCode.name === "") ? item.name : selectedCode.name;
                    selectedCode.description = (selectedCode.description === null || selectedCode.description === "") ? item.description : selectedCode.description;
                    if (typeof this.props.changeSaveButton === 'function') {
                        this.props.changeSaveButton();
                    }
                    this.setState({ selectedCode });
                }}
                objKeyId="id"
                objKeySearchable="name"
                objKeyValue="name"
                clearable

                options={this.props.costCenterGroups ? this.props.costCenterGroups : []}

                loading={this.state.loading}
                disabled={this.state.loading}
            />/*
                <ApInput
                type="select"
                id="group"
                name="group"
                label={tr('group')}
                autoComplete="off"
                options={this.props.costCenterGroups}
                value={selected.group}
                onChange={this.codeInfoChange}
            />*/
            }

            <ApInputStack gap="0">
                { parentCode }
                <ApInput
                    type="text"
                    id="name"
                    name="value"
                    label={ `${tr('code')} *` }
                    autoComplete="off"
                    value={ selected.value }
                    onChange={ this.codeInfoChange }
                    validationState={ ( codeValidMessage ) ? 'error' : null }
                    tooltip={ codeValidMessage ? codeValidMessage : null }
                />
            </ApInputStack>

            <ApInput
                type="text"
                id="name"
                name="name"
                label={ tr('name') }
                autoComplete="off"
                value={ selected.name }
                onChange={ this.codeInfoChange }
            />

            {this.props.isOrgChart &&
            <div>
                <ApSelect
                    id="groups_select"
                    label={tr("group")}
                    multiselect
                    value={this.state.selectedCode ? this.state.selectedCode.groups : []}
                    valueRenderer={(item) => {
                        return <div>
                            <strong>{item.name}</strong><br/>
                        </div>
                    }}
                    optionRenderer={(item) => {
                        return <div>
                            <strong>{item.name}</strong><br/>
                        </div>
                    }}
                    onChange={(values) => {
                        let selectedCode = { ...this.state.selectedCode };
                        if (!Array.isArray(values)) {
                            selectedCode.groups = [values];
                        } else {
                            selectedCode.groups = values
                        }
                        if (typeof this.props.changeSaveButton === 'function') {
                            this.props.changeSaveButton();
                        }
                        this.setState({ selectedCode });
                    }}
                    objKeyId="id"
                    objKeySearchable="name"
                    objKeyValue="name"
                    clearable

                    options={groups}

                    loading={this.state.loading}
                    disabled={this.state.loading}
                />
                <ApSelect
                    id="personnel_select"
                    label={tr("personnel")}
                    multiselect
                    value={this.state.selectedCode ? this.state.selectedCode.users : []}
                    valueRenderer={ 'user' }
                    optionRenderer={ 'user' }
                    onChange={this.handleAddPersonnel}
                    objKeyId="id"
                    objKeySearchable="name"
                    objKeyValue="name"
                    clearable

                    apiUrl="search/user"
                    apiData={{
                        is_active: true,
                        active_contracts_only: true
                    }}

                    options={groups}

                    loading={this.state.loading}
                    disabled={this.state.loading}
                />
            </div>}

            <ApInput
                type="textarea"
                id="description"
                name="description"
                label={ tr('description') }
                autoComplete="off"
                value={ selected.description }
                onChange={ this.codeInfoChange }
            />

            {showNetvisorDimensions &&
                <>
                    <ApInputStack gap="0">
                        <ApAddon width="200px">
                            {tr('netvisor_dimension')}
                        </ApAddon>
                        <ApInput
                            type="select"
                            id="netvisor_dimension_key"
                            name="netvisor_dimension_key"
                            label={tr('netvisor_dimension')}
                            options={this.props.netvisorDimensions}
                            value={selected.netvisor_dimension_key}
                            onChange={this.handleNetvisorDimensionChange}
                        />
                    </ApInputStack>
                    <div className='apInfo small'>
                        <SvgIcon icon="info-circle" type="solid" />&nbsp;
                        {tr('netvisor_dimension_info')}
                    </div>
                </>
            }
            {this.props.supplierEnabled &&
                <ApInputStack gap="0">
                
                <ApSelect
                    label={`${tr('add_supplier_visible')} *`}
                    value={selected.supplier_added }
                    valueRenderer={"storage_supplier"}
                    optionRenderer={"storage_supplier"}
                    onChange={(list) => { this.changeSupplierLists(list, "supplier_added") } }
                    objKeyId="id"
                    objKeyValue="name"
                    apiUrl="storage/components/storage/settings/supplier/find"
                    loading={this.state.loading}
                    disabled={ false}
                    multiselect
                    
                   
                />
                <ApSelect
                    label={`${tr('remove_supplier_visible')} *`}
                    value={selected.supplier_removed }
                    valueRenderer="storage_supplier"
                    optionRenderer="storage_supplier"
                    onChange={(list) => { this.changeSupplierLists(list, "supplier_removed") }}
                    objKeyId="id"
                    objKeyValue="name"
                    apiUrl="storage/components/storage/settings/supplier/find"
                    loading={this.state.loading}
                    disabled={ !parent}
                    multiselect
                    
                />
                
            </ApInputStack>}

            {onlineStoreEnabled && !this.props.isCostCenter && !this.props.isOrgChart

            ? <div>
                <div className={ "apFormGroup" + ( selected.show_to_online_store ? " success" : "" ) }>
                    <div className="apSwitchBlock">
                        <label htmlFor={"show_to_online_store"+selected.id} className="info">
                            { tr('show_to_online_store') }
                        </label>
                        <ApSwitch
                            id={"show_to_online_store"+selected.id}
                            name="show_to_online_store"
                            on={ selected.show_to_online_store }
                            onChange={ () => this.toggleShowToOnlineStore("show_to_online_store") }
                            disabled={ this.props.loading }
                        />
                    </div>
                </div>
                <div className={ "apFormGroup" + ( selected.hide_from_online_store ? " success" : "" ) }>
                    <div className="apSwitchBlock">
                        <label htmlFor={"hide_from_online_store"+selected.id} className="info">
                            { tr('hide_from_online_store') }
                        </label>
                        <ApSwitch
                            id={"hide_from_online_store"+selected.id}
                            name="hide_from_online_store"
                            on={ selected.hide_from_online_store }
                            onChange={ () => this.toggleShowToOnlineStore("hide_from_online_store") }
                            disabled={ this.props.loading }
                        />
                    </div>
                </div>
            </div>
            : null}

            { invalidChildrenDom }
            { missingChildrenDom }
        </div>
    }

    renderCodeComponents( components, parentValue )
    {
        const componentRows = components.map(( c, cIndex ) => {

            const codeValue = `${ parentValue }${ this.props.separator }${ c.code_tail }`;

            return <tr key={ c.id } className={ "component" }>
                <td className="icon">
                    { this.renderComponentInfo( c, codeValue ) }
                </td>
                <td className="code">
                    { codeValue }
                </td>
                <td className="name">
                    {  c.name }
                </td>
                <td className="text-right">
                    <ApDropdown
                        actionId={ 1 }
                        actions={[
                            {
                                label: tr('show_storage_component'),
                                icon: "edit",
                                action: ( item, closeFunc ) => {
                                    this.setState({
                                        componentEditShow: true,
                                        componentEditId: c.id,
                                    });
                                    closeFunc();
                                }
                            }
                        ]}
                    />
                </td>
            </tr>
        });

        let table = null;

        if( components && components.length > 0 )
        {
            table = <table className="componentTable">
                <thead>
                    <tr>
                        <th></th>
                        <th>{ tr('code') }</th>
                        <th>{ tr('name') }</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    { componentRows }
                </tbody>
            </table>
        }

        return <div>
            { table }
        </div>
    }

    renderComponentInfo( c, codeValue )
    {
        const tooltip = <ComponentTooltip
            component={ c }
            code={ codeValue }
        />
        const icon = <SvgIcon icon={ typeIcons[ c.type_name ] } type="solid" />

        return <ApTooltip position="top" text={ tooltip }>{ icon }</ApTooltip>
    }

    renderInfo()
    {
        let header = tr('storage_component_code_editing');
        if (this.props.isCostCenter) header = tr('cost_center_code_editing');
        if (this.props.isOrgChart) header = tr('org_chart_code_editing');
        return <div className="apForm">
            <h3 className="apFormHeader">{ header }</h3>
            <div className="apFormColumn fullColumn">
                {/*
                <div className="apInfo small">
                    <SvgIcon icon="sitemap" type="solid" />
                    Varastot voivat sisältää useita alivarastoja, jotka jälleen voivat sisältää useita alivarastoja.
                </div>

                <div className="apInfo small">
                    <SvgIcon icon="cube" type="solid" />
                    Jokainen varasto voi sisältää useita varastonimikkeitä.
                </div>

                <div className="apInfo small">
                    <SvgIcon icon="save" type="solid" />
                    Varastopaikkojen muokkaus ei tallenna mitään enenkuin sivun alalaidassa olevaa "<strong>Tallenna</strong>" nappulaa painetaan.
                </div>

                <div className="apInfo small">
                    <SvgIcon icon="truck-loading" type="solid" />
                    Varastot jotka on on merkitty "<strong>Ottaa vastan ja lähettää varastonimikkeitä</strong>" pystyvät vastaanottaan ostotilauksia ja lähetyksiä. <br />
                    Mikäli varastopaikka ei ole täten merkity varaston alivarastoina, tavaran tilaus kyseisiin varastoihin ei ole mahdollista.
                </div>

                <div className="apInfo small">
                    <SvgIcon icon="cubes" type="solid" />
                    Varastoihin jotka on on merkitty "<strong>Kirjataan varastonimikkeitä</strong>" pystyy kirjaamaan vasastonimikkeitä. <br />
                    Tällä rajoitetaan varastonimikkeen hallinnassa olevaa lista ainoastaan niihin varastopaikkoihin mihin tavaraa pystyy varastoimaan.
                </div>
                */}
            </div>
        </div>
    }

    renderDetailPicker()
    {
        return <div className="detailPicker">
            <ApInputStack gap="0">
                <ApAddon noRightBorder width="50px">
                    <ApTooltip block text={ this.state.selectedDetailCumulative ? tr('subcodes_included') : tr('subcodes_not_included') }>
                        <div className="apSimpleButton" onClick={ this.toggleTreeCumulativeDetails }>
                            <SvgIcon icon={ this.state.selectedDetailCumulative ? "stream" : "minus" } type="solid" />
                        </div>
                    </ApTooltip>
                </ApAddon>
                <ApInput
                    type="select"
                    options={[
                        { value: "components_count", label: tr('components_count') },
                    ]}
                    id="codeTreeDetail"
                    name="codeTreeDetail"
                    value={ this.state.selectedDetail }
                    onChange={ () => { } }
                />
            </ApInputStack>
        </div>
    }

    renderConfirmCodeRemove()
    {
        const data = this.state.removeCodeConfirmData;
        let body = <div></div>;
        if( data )
        {
            if (this.props.isCostCenter) {
                body = <div className="padding">
                    <div>{tr('delete_cost_center_code_confirm', [<strong>{data.code}</strong>])}
                        </div>
                </div>
            }
            else if (this.props.isOrgChart) {
                body = <div className="padding">
                    <div>{tr('delete_organization_chart_code_confirm', [<strong>{data.code}</strong>])}
                        </div>
                </div>
            }
            else {
                body = <div className="padding">
                    <div>{tr('delete_storage_code_confirm', [<strong>{data.code}</strong>])}
                        {tr('delete_storage_code_confirm_info')}</div>
                    <div className="padding">
                        <div className="apInfo small error">
                            <SvgIcon icon="info-circle" type="solid" />
                            {tr('delete_storage_code_info', [<strong>{data.calculated.all.components_count}</strong>])}
                        </div>
                    </div>
                </div>
            }
            
        }

        return <div id="confirmCodeRemove" className="ApModalConfirm">
            <ApModal
                show={ this.state.removeCodeConfirmShow }
                handleClose={ () => this.setState({ removeCodeConfirmShow: false }) }
                closeFromBg
                className="narrow"
                header={
                    <div className="padding-small">
                        <h4>
                            { tr('delete_component_code_confirm_header') }
                        </h4>
                    </div>
                }
                body={ body }
                footer={
                    <div className="footer padding">
                        <ApButton className="cancel" onClick={ () => this.setState({ removeCodeConfirmShow: false }) }>
                            <SvgIcon icon="times" type="solid" />
                            { tr('no') }
                        </ApButton>

                        <ApButton className="save" color="green" onClick={ this.removeCode }>
                            <SvgIcon icon="check" type="solid" />
                            { tr('yes') }
                        </ApButton>
                    </div>
                }
            />
        </div>
    }

    render()
    {
        let body = null;
        if( this.props.loading )
            body = <div className="apLoader"></div>
        else if( this.props.formatChanged )
            body = <div className="apInfo">
                <SvgIcon icon="info-circle" type="solid" />
                <strong>{ tr('save_changes_code_info') }</strong>
            </div>
        else
        {
            body = <div className="splitView">
                <div className="left">
                    {!this.props.isOrgChart 
                    ? this.renderDetailPicker() 
                    : null }
                    <ApTree
                        tree={ this.props.tree }
                        nodeRenderer={ this.renderTreeNode }
                        onTreeChange={ this.props.onTreeChange }
                        onNodeClick={ this.handleCodeSelect }
                    />

                    <hr />

                    <ApButton fullWidth color="white" size="small" onClick={ () => this.addCodeClick() } disabled={ this.props.locked }>
                        <SvgIcon icon="plus" type="solid" />
                        { tr('new_code') }
                    </ApButton>

                </div>
                <div className="right">
                    <div className="details">
                        <div className="padding">
                            { this.renderSelected() }
                        </div>
                    </div>
                </div>
            </div>
        }
        return <div id="codeSettings">
            <div className="treeContainer">
                { body }
            </div>
            { this.renderConfirmCodeRemove() }
            <ComponentEdit
                id={   this.state.componentEditId }
                show={ this.state.componentEditShow }
                onClose={ () => { this.setState({ componentEditShow: false, componentEditId: null }) } }
                onSave={ () => {
                    this.getComponents(this.state.selectedCode.id);
                    this.setState({ componentEditShow: false, componentEditId: null })
                }}
            />
        </div>
    }
}

export default Code;
