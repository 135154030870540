import React from 'react';
import moment from 'moment';
import autoBind from 'react-autobind';

import Measure from 'react-measure';
import ApStickyBar from 'common/ApStickyBar/ApStickyBar.js';

import { Collapse } from 'react-bootstrap';

import auth            from 'services/Authed/Authed.js';
import api             from 'services/Api/Api.js';
import SvgIcon         from 'common/SvgIcon/SvgIcon.js';
import ApButton        from 'common/ApButton/ApButton.js';
import ApTooltip       from 'common/ApTooltip/ApTooltip.js';
import ApValidate      from 'services/ApValidate/ApValidate.js';
import { errorPopper
       , removeRoundOff
       , dateInputToSql, 
       tr}  from 'services/Helpers/Helpers.js'

import DataEdit            from './DataEdit/DataEdit.js';
import DataSummary         from './DataSummary/DataSummary.js';
import ComponentInstanceTable from 'modules/Storage/common/ComponentInstanceTable/Cit.js';

import './ArrivalEdit.css';

class ArrivalEdit extends React.Component {

    constructor(props)
    {
        super(props);

        let relatedId = null;
        let type = null;
        const params = this.props.match.params;
        if( params.id )
            relatedId = parseInt( params.id, 10 );
        if( params.type )
            type = params.type;

        this.state = {
            editShow: ( type === 'new' ),
            errorMessage: null,
            loadingCounter: 0,

            type: type,
            relatedNumber: null,
            relatedType: null,
            relatedId: relatedId,

            secondRelatedNumber: null,
            secondRelatedType: null,
            secondRelatedId: null,

            creatorName: auth.getName(),
            number: null,
            numberReserved: ( type === 'show' ),
            date: moment().format('DD.MM.YYYY'),

            senderLocked: ( type !== 'new' ),
            senderTitle: null,
            senderType: null,
            senderId: null,

            receiverLocked: ( type !== 'new' ),
            receiverTitle: null,
            receiverType: null,
            receiverId: null,

            referenceYour: '',
            referenceOur: '',
            comment: '',
            arrivalCountHasOne:false,
            components: [],
        };

        const componentsExistFilter = () => {
            if ( this.state.components.length > 0 )
                return this.state.components.some( c => parseFloat( c.count ) );
            return false;
        }

        const countFilter = ( value, index ) => {
            const component = this.state.components[index];
            if (!this.state.arrivalCountHasOne) {
                return false;
            }

            // if( !Boolean( component.arrival_count ) )
            //     return false;
            if( parseFloat( component.arrival_count ) >  parseFloat( component.count ) )
                return false;
            return true;
        }

        const senderIdValidate = () => {
            if( this.state.senderType === 'other' )
                return true;
            return Boolean( this.state.senderId );
        }

        this.validator = new ApValidate(this, {
            date: {filter: 'required', textAll: 'Päivämäärää ei valittu'},
            components_needed: {filter:  componentsExistFilter, textAll: 'Yhtään varastonimikettä ei valittu' },

            senderType:   {filter: 'required', textAll: 'Vastaanottajaa ei valittu'},
            senderId:     {filter: senderIdValidate, textAll: 'Vastaanottajaa ei valittu'},

            receiverType: {filter: 'required', textAll: 'Vastaanottajaa ei valittu'},
            receiverId:   {filter: ( value ) =>{ return (value || !this.state.receiverId) ? true : false },
                textAll: 'Vastaanottajaa ei valittu'},

            components: {
                filters: {
                    id: { filter: 'required', state: 'error', textAll: 'Varastonimikkeissä virheitä'},
                    arrival_count: { filter: countFilter, state: 'error', textAll: tr('errors_in_quantity_of_storage_components')},
                    // target_id: { filter: 'required', state: 'error', textAll: 'Varastonimikkeen projektia tai varastoa ei valittu'},
                }
            },
        });
        autoBind(this);
    }

    componentDidMount()
    {
        this.getRelated( this.state.type, this.state.relatedId );
    }

    getRelated( type, id )
    {
        if (id == undefined) {
            id = this.state.relatedId;
        }
        this.setState({loadingCounter: this.state.loadingCounter + 1});

        api({
            method: 'get',
            url: 'storage/ar/related',
            params: {
                type: type,
                related_id: id,
            },
        }).then((response) => {

            //console.log('Response', response);

            if( response.creator_name )
                this.setState({ creatorName: response.creator_name });

            response.components = response.components.map(component => {
                if (component.conversion_unit_id) {
                    component.selectedUnit = component.conversion_units.find(unit => unit.id == component.conversion_unit_id);
                    component.count = parseFloat(component.count) * parseFloat(component.selectedUnit.conversion_factor);
                    component.arrival_count = parseFloat(component.arrival_count) * parseFloat(component.selectedUnit.conversion_factor);
                }
                return component;
            });

            this.setState( {
                number: response.number,

                relatedNumber: response.related_number,
                relatedType: response.related_type,
                relatedId:   response.related_id,

                secondRelatedNumber: response.second_related_number,
                secondRelatedType:   response.second_related_type,
                secondRelatedId:     response.second_related_id,

                senderTitle: response.sender_title,
                senderType:  response.sender_type,
                senderId:    response.sender_id,

                receiverTitle: response.receiver_title,
                receiverType:  response.receiver_type,
                receiverId:    response.receiver_id,

                referenceYour: response.reference_your,
                referenceOur:  response.reference_our,

                components: response.components,
            });

            this.setState({loadingCounter: this.state.loadingCounter - 1});

        }).catch((error) => {
            console.error(error);
            errorPopper(error, 'Saapumisen haussa tapahtui virhe');
            this.setState({
                loadingCounter: this.state.loadingCounter + 1,
                errorMessage: 'Saapumisen haussa tapahtui virhe',
            });
        });
    }

    saveChanges()
    {
        this.setState({loadingCounter: this.state.loadingCounter + 1});

        const components = this.state.components.map( c => {
            //console.log( 'c', c );
            return {
                id: c.id,
                cID : c.component_id,
                price : c.purchase_order_price,
                arrival_count: c.selectedUnit ? Number((c.arrival_count / parseFloat(c.selectedUnit.conversion_factor)).toFixed(5)) : c.arrival_count,
            }
        });

        let data = {
            related_type:   this.state.relatedType,
            related_id:     this.state.relatedId,

            sender_type:    this.state.senderType,
            sender_id:      this.state.senderId,
            receiver_type:  this.state.receiverType,
            receiver_id:    this.state.receiverId,
            date:           dateInputToSql( this.state.date ),
            reference_your: this.state.referenceYour,
            reference_our:  this.state.referenceOur,
            comment:        this.state.comment,

            components: components,
        };

        api({
            method: 'post',
            url: 'storage/ar/save',
            data: data,
        }).then((response) => {
            //console.log('Response', response);
            window.emitter.emit('goTo', {pathname: `/storage/logistic/arrival/show/${response.id}`});
            this.setState({
                //relatedId: response.id,
                type: 'show',
            });
            this.getRelated( 'show', this.state.relatedId);
            this.setState({loadingCounter: this.state.loadingCounter - 1});

            //Vedä käyttäjä takaisin ostotilausnäkymään
            setTimeout(function(){ window.history.go(-2); }, 600);
        }).catch((error) => {
            errorPopper(error, 'Saapumisen tallennuksessa tapahtui virhe');

            this.setState({loadingCounter: this.state.loadingCounter - 1});
        });

    }

    dataChange( data )
    {
        this.setState( data );
    }

    componentsChange( components )
    {
        let arrivalCountHasOne = components.some(component => parseFloat(component.arrival_count) > 0);
        this.setState({ components: components, arrivalCountHasOne: arrivalCountHasOne });
    }

        /*
    handleChange(e)
    {
        if(this.state.type === 'show')
            return false;

        const value = e.target.value ? e.target.value : null;
        this.setState({ [e.target.name]: value });
    }

    goToPo()
    {
        window.emitter.emit('goTo', {
            pathname: `/storage/purchase/order/id/${ this.state.po.id }`
        });
    }

    getRelatedDom()
    {
        if( this.state.po )
        {
            return <div>

                <ApInputStack gap="0">
                    <ApInput
                        type="text"
                        id="number"
                        name="number"
                        label="Ostotilauksen numero"
                        value={ this.state.po.number }
                        disabled={ true }
                    />
                    <ApAddon noLeftBorder>
                        <div className="view-po" onClick={ this.goToPo }>
                            <ApTooltip text="Näytä ostotilaus">
                                <SvgIcon icon="edit" type="solid" />
                            </ApTooltip>
                        </div>
                    </ApAddon>
                </ApInputStack>

                <div className="referenceContainer">
                    <div>Viittemme: <span className="rValue">{ this.state.po.reference_our }</span></div>
                    <div>Viitteenne: <span className="rValue">{ this.state.po.reference_your }</span></div>
                </div>
                <div>
                    <div className="supplierContainer">
                        <strong>Toimittaja</strong>
                        <div>{ this.state.po.supplier_name }</div>
                        <div>{ this.state.po.supplier_contact_name }</div>
                        <div>{ this.state.po.supplier_address }</div>
                        <div>{ this.state.po.supplier_country }</div>
                        <div>{ this.state.po.supplier_city }</div>
                    </div>
                    <div className="receiverContainer">
                        <strong>Vastaanottaja</strong>
                        <div>{ this.state.po.receiver_name }</div>
                        <div>{ this.state.po.receiver_contact_name }</div>
                        <div>{ this.state.po.receiver_address }</div>
                        <div>{ this.state.po.receiver_country }</div>
                        <div>{ this.state.po.receiver_city }</div>
                    </div>
                </div>

            </div>
        }
        if( this.state.type == 'New')
        {
            return <div>
                Kirjaa uusi saapuminen
            </div>

        }
        return null;
    }
*/

    allArrived()
    {
        let components = this.state.components.slice( 0 );
        let arrivalCountHasOne = false;
        components.map((c) => {
            const multiplier = c.selectedUnit ? parseFloat(c.selectedUnit.conversion_factor) : 1;
            const missing = removeRoundOff( c.count - (c.purchase_order_arrived * multiplier), 5 );
            if (missing > 0) {
                c.arrival_count = missing;
                arrivalCountHasOne= true;
            }
            else
                c.arrival_count = '0';
            return c;
        });
        this.setState({ components: components, arrivalCountHasOne: arrivalCountHasOne })
    }

    setRestToZero()
    {
        let components = this.state.components.slice( 0 );
        components.map(( c ) => {
            if( !c.count )
                c.count = '0';
            return c;
        });
        this.setState({ components: components })
    }

    handleUnitSelect(c, unitId) {
        const components = this.state.components.slice();
        const foundComponent = components.find(component => component.id == c.id);
        if (foundComponent) {
            let prevConversionFactor = null;
            if (foundComponent.selectedUnit) {
                prevConversionFactor = foundComponent.selectedUnit.conversion_factor;
            }
            foundComponent.selectedUnit = foundComponent.conversion_units.find(item => item.id == unitId);
            if (prevConversionFactor) {
                foundComponent.arrival_count = foundComponent.arrival_count 
                    ? Number((parseFloat(foundComponent.arrival_count) / parseFloat(prevConversionFactor)).toFixed(5))
                    : '';
                foundComponent.count = foundComponent.count 
                    ? Number((parseFloat(foundComponent.count) / parseFloat(prevConversionFactor)).toFixed(5))
                    : '';
            }
            if (foundComponent.selectedUnit) {
                foundComponent.arrival_count = foundComponent.arrival_count 
                    ? Number((parseFloat(foundComponent.arrival_count) * parseFloat(foundComponent.selectedUnit.conversion_factor)).toFixed(5) )
                    : '';
                foundComponent.count = foundComponent.count 
                    ? Number((parseFloat(foundComponent.count) * parseFloat(foundComponent.selectedUnit.conversion_factor)).toFixed(5) )
                    : '';
            }
            this.setState({components});
        }
    }

    render()
    {
        const type = this.state.type;

        if( this.state.loadingCounter > 0 )
            return 'Lataa';

        if( this.state.errorMessage )
            return <div> { this.state.errorMessage  } </div>

        let dataEdit = <Collapse in={ this.state.editShow }><div>
            <DataEdit
                summary={ true }
                onSummaryClick={ () => { this.setState({ editShow: false } )} }

                validator={ this.validator }
                onChange={ this.dataChange }

                senderLocked={ this.state.senderLocked }
                senderTitle={  this.state.senderTitle }
                senderType={   this.state.senderType }
                senderId={     this.state.senderId }

                receiverLocked={ this.state.receiverLocked }
                receiverTitle={  this.state.receiverTitle }
                receiverType={   this.state.receiverType }
                receiverId={     this.state.receiverId }

                number={ this.state.number }
                numberReserved={ this.state.numberReserved }

                date={ this.state.date }
                referenceYour={ this.state.referenceYour }
                referenceOur={ this.state.referenceOur }
                comment={ this.state.comment }
            />
        </div></Collapse>

        let dataSummary = <Collapse in={ !this.state.editShow }><div>
            <DataSummary
                editable={ !( this.state.type === 'show' ) }
                onEditClick={ () => { this.setState({ editShow: true } )} }

                senderTitle={ this.state.senderTitle }
                senderType={ this.state.senderType }

                receiverTitle={ this.state.receiverTitle }
                receiverType={ this.state.receiverType }

                number={ this.state.number }
                numberReserved={ this.state.numberReserved }

                creatorName={ this.state.creatorName }

                relatedNumber={ this.state.relatedNumber }
                relatedType={   this.state.relatedType }
                relatedId={     this.state.relatedId }

                secondRelatedNumber={ this.state.secondRelatedNumber }
                secondRelatedType={   this.state.secondRelatedType }
                secondRelatedId={     this.state.secondRelatedId }

                date={ this.state.date }
                referenceYour={ this.state.referenceYour }
                referenceOur={ this.state.referenceOur }
                comment={ this.state.comment }
            />
        </div></Collapse>

        let allArrivedButton = null;

        if(type === 'po')
        {
            allArrivedButton = <ApButton color="blue" onClick={ this.allArrived } >
                <SvgIcon icon="clipboard-check" type="solid" />
                {tr('all_has_arrived')}
            </ApButton>
        }

        let saveBox = null;
        if( type !== 'show' )
        {
            const validatorMessage = this.validator.getStatusAll();
            saveBox = <div className="savebox">
                <ApTooltip text={ validatorMessage }>
                    <ApButton disabled={ Boolean(validatorMessage) } color="blue" onClick={ this.saveChanges }>
                        {tr('save')}
                    </ApButton>
                </ApTooltip>
            </div>

            saveBox = <ApStickyBar zIndex={ 10 } bottomMode ref={ node => this.stickyBar= node }>
                <div className="saveBar">
                    <div className="left">
                    </div>
                    <div className="right">

                        <ApTooltip text={ validatorMessage }>
                            <ApButton disabled={ Boolean(validatorMessage) } color="blue" onClick={ this.saveChanges }>
                                <SvgIcon icon="save" type="solid" />
                                {tr('save')}
                            </ApButton>
                        </ApTooltip>
                    </div>
                </div>
            </ApStickyBar>


        }

        // TODO target should be locked if we have realated order
        
        return <div>
            <div className="pageBackButton" onClick={ () => window.history.go(-1) }>
                <SvgIcon icon="arrow-left" type="solid" size="small" />
                {tr('back_to_purchase_order')}
            </div>
            <Measure
            onResize={ () => {
                if( this.stickyBar )
                    this.stickyBar.updateBounds();
            }}
        >
        {({ measureRef }) => <div ref={ measureRef } className="apBox" id="pageArrivalEdit">
                <div className="apBoxHeader">
                    <h1>
                            {tr('reception_log')}
                    </h1>
                        <p> {tr('receive_arrived_goods')} </p>
                </div>
                <div className="padding">

                    <div className="apForm">
                        <div className="apFormColumn fullColumn">
                            { dataEdit }
                            { dataSummary }
                        </div>
                    </div>

                    <div className="apForm">
                        <div className="apFormColumn fullColumn">
                            <div className="text-right">
                                { allArrivedButton }
                            </div>

                            <ComponentInstanceTable
                                componentsChange={ this.componentsChange }
                                components={ this.state.components }

                                instance={ 'arrival' }

                                arrivalReceiverType={ this.state.receiverType }
                                arrivalReceiverId={ this.state.receiverId }

                                arrivalCountLocked={ type === 'show' }
                                removeComponentLocked={  type === 'show' }
                                addComponentLocked={ true }
                                handleUnitSelect={this.handleUnitSelect}

                            />
                        </div>
                    </div>
                </div>
                { saveBox }
            </div>
        }
        </Measure>
        </div>
    }
}

export default ArrivalEdit;
