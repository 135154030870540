import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import ApModal from 'common/ApModal/ApModal';
import ApButton from 'common/ApButton/ApButton';
import { tr } from 'services/Helpers/Helpers';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import { connect } from 'react-redux';
import { READ_ONLY_TYPES, DEFAULT_COLOR, PRIVATE_ICON, defaultEvent, getEventDataErrors } from './CalendarHelpers';
import ApDropdown from 'common/ApDropdown/ApDropdown';
import CalendarModalBody from './CalendarModalBody';
import moment from 'moment';
import ApTooltip from 'common/ApTooltip/ApTooltip';

const CalendarModal = (props) => {
    // Deep copy of defaultEvent to avoid changing the defaultEvent object
    const [eventData, setEventData] = useState(JSON.parse(JSON.stringify(defaultEvent))); 

    const updateEventData = (key, value) => {
        if (key == 'project' && value) {
            // when project is changed, set project_work_id to first project work id
            value.project_work_id = value.works[0].id;
            setEventData(prevState => ({
                ...prevState,
                project_work_id: value.works[0].id,
                type: prevState.type || 'hour',
            }));
        } else if (key == 'project' && !value) {
            setEventData(prevState => ({
                ...prevState,
                project_work_id: null,
                type: null,
            }));
        } else if (key == 'entry_type') {
            if (!eventData.vehicle_id) {
                // Get the first item from the list of vehicles from timetracking settings
                const vehicle = props.apTimetrackingSettings.vehicles[0];
                if (vehicle) {
                    setEventData(prevState => ({
                        ...prevState,
                        vehicle_id: vehicle.id,
                    }));
                }
            }
        }
        setEventData(prevState => ({
            ...prevState,
            [key]: value,
        }));
    }

    const saveEvent = async () => {
        let dateStart = new Date(eventData.start);
        let dateEnd = new Date(eventData.end);
        if (eventData.all_day) {
            dateStart.setHours(0, 0, 0, 0);
            dateEnd.setHours(23, 59, 59, 999);
        }
        const event = await props.onSave({
            ...eventData,
            id: props.selectedEvent?.id,
            start: dateStart,
            end: dateEnd,
            project_id: eventData.project?.id,
        })
    }

    const deleteEvent = () => {
        props.onDelete(props.selectedEvent.id);
    }

    useEffect(() => {
        if (props.selectedEvent) {
            // Convert start and end dates to string
            let { start, end } = props.selectedEvent;
            if (props.selectedEvent.start && props.selectedEvent.start instanceof Date) {
                // Flatpickr calendar wants date as string, not Date object
                start = moment(props.selectedEvent.start).format('YYYY-MM-DDTHH:mm:ss');
            }
            if (props.selectedEvent.end && props.selectedEvent.end instanceof Date) {
                // Flatpickr calendar wants date as string, not Date object
                end = moment(props.selectedEvent.end).format('YYYY-MM-DDTHH:mm:ss');
            }

            setEventData({
                ...JSON.parse(JSON.stringify(defaultEvent)),
                ...props.selectedEvent,
                all_day: !!props.selectedEvent.all_day,
                color: props.selectedEvent.color || DEFAULT_COLOR,
                start,
                end,
            });
        } else {
            setEventData(JSON.parse(JSON.stringify(defaultEvent)));
        }

    }, [props.selectedEvent]);

    const renderHeader = () => {
        return (
            <div className='padding'>
                <h4>
                    {eventData.is_private && <SvgIcon icon={PRIVATE_ICON} type="solid" />} {props.selectedEvent && props.selectedEvent.title || tr('new_event')}
                </h4>
            </div>
        );
    };

    const renderFooter = () => {
        const isEventOver24h = new Date(eventData.end) - new Date(eventData.start) > 86400000;
        const eventDataErrors = getEventDataErrors(eventData);
        const saveBtnInvalid = eventDataErrors.length > 0 || props.loading;
        const saveBtn = props.readOnly || READ_ONLY_TYPES.includes(props.selectedEvent?.type)
            ? null
            : <ApTooltip text={eventDataErrors.map(error => <div>{error}</div>)}>
                <ApButton onClick={() => saveEvent(false)} color='blue' disabled={saveBtnInvalid}>
                    <SvgIcon icon='save' type='solid' /> {tr('save')}
                </ApButton>
            </ApTooltip>;
        const dropdown = <ApDropdown
            button={<ApButton><SvgIcon icon="ellipsis-h" type="solid" /></ApButton>}
            position='top'
            actions={[
                {
                    label: tr('delete'),
                    action: deleteEvent,
                    icon: 'trash',
                    closeAfter: true,
                    disabled: props.readOnly || READ_ONLY_TYPES.includes(props.selectedEvent?.type) || !props.selectedEvent?.id
                },
            ]}
        />
        return (
            <div className={'padding justify-space-between'}>
                <ApButton onClick={props.closeModal}><SvgIcon icon='times' type='solid' />{tr('cancel')}</ApButton>
                <div>
                    {/* {deleteBtn} */}
                    {saveBtn}
                    {dropdown}
                </div>
            </div>
        );
    }

    return (
        <div id='apCalendarModal'>
            <ApModal
                className='narrow'
                header={renderHeader()}
                body={
                    <CalendarModalBody
                        loading={props.loading}
                        selectedEvent={props.selectedEvent}
                        colors={props.colors}
                        readOnly={!!props.readOnly}

                        eventData={eventData}
                        setEventData={setEventData}
                        updateEventData={updateEventData}
                        removeColor={(color) => {
                            updateEventData('color', DEFAULT_COLOR);
                            props.removeColor(color);
                        }}
                    />
                }
                footer={renderFooter()}
                handleClose={props.closeModal}
                show={!!props.selectedEvent}
            />
        </div>
    );
}

CalendarModal.propTypes = {
    selectedEvent: propTypes.object,
    closeModal: propTypes.func.isRequired,
    readOnly: propTypes.bool,
    colors: propTypes.array, // Previously used colors
}

const mapStateToProps = (state) => {
    return {
        apGeneralSettings: state.apGeneralSettings,
        apTimetrackingSettings: state.apTimetrackingSettings,
        apOrderSettings: state.apOrderSettings,
    }
}

export default connect(mapStateToProps)(CalendarModal);