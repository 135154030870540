/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import axios from 'axios';

import ApFormPage from 'common/ApFormPage/ApFormPage.js';

import ApButton from 'common/ApButton/ApButton.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApModalInput from 'common/ApModalInput/ApModalInput.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import moment from 'moment';


import './Approve.css';

import { groupify, keyExists, tr } from  'services/Helpers/Helpers.js';

import Week from '../Week/Week.js';
import UserBrowser from '../UserBrowser/UserBrowser.js';


export default class TimetrackingApprove extends React.Component {

    constructor( props ) 
    {
        super(props);
        this.state = {
            loading:            false,  
            settings:           null,

            users:              [],
            selectedUser:       null,

            entries:            [],
            selectedEntries:    [],

            salaryExtras:       [],
            showSalaryExtras:   false,

            showReadOnly:       false,

            changes:            [],

            commentEntryId:     null,
            commentText:        "",
        };
        this.statusLoop = [ "submitted", "approved", "rejected" ];
        autoBind(this);
    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers( user = null )
    {
        this.setState({ loading: true });
        // console.log('getUsers', user);
        api({
            method: 'get',
            url: 'timetracking/approval/users',
        }).then(( response ) => {

            //console.log('USERS', response.users );
            //console.log('SETTINGS', response.settings );
         
            this.setState({
                loading: false,
                users: response.users,
                settings: response.settings,
                changes: [],
            }, () => {

                if( response.users.length > 0 )
                {
                    if( !user || response.users.findIndex( r => r.id == user ) == -1 ){
                        const foundUser = response.users?.find(u => {
                            if (!u.is_active) return false;

                            if (moment(u.contract_start).isAfter(moment())) return false;
                            if (u.contract_end && moment(u.contract_end).isBefore(moment())) return false;

                            return true;
                        })
                        if (foundUser) {
                            user = foundUser.id;
                        } else {
                            user = response.users[0].id;
                        }
                    }
                  
                    this.changeUser( user );
                }
                else 
                    this.setState({ 
                        selectedUser:       null,
                        entries:            [],
                        selectedEntries:    [],
                    });

            });

        }).catch( ( error ) => {
            console.log( 'getUsers ERROR', error );
            this.setState({ loading: false });
        });
    }

    changeUser( id )
    {
       // console.log('changeUser', id );

        // Cancel possible old query 
        if( this.state.cancelToken )
            this.state.cancelToken.cancel('Operation canceled by the user.');
           
        this.setState({ 
            selectedUser: id,
            loading: true,
            cancelToken: axios.CancelToken.source(),
        }, () => {
            api({
                cancelToken: this.state.cancelToken.token,
                method: 'post',
                url: 'timetracking/approval/entries',
                data: { user: id }
            }).then(( response ) => {

                //console.log('USER ENTRIES', response );
                const round = (value) => {
                    return Math.round(value * 1000) / (1000);
                };
                response.entries.forEach(entry => {
                    if (entry.time_attendance_save_point != undefined) {
                        entry.timeAttendanceTimestampStart = entry.time_attendance_save_point.last_edit && entry.time_attendance_save_point.last_edit.timestamp_start != null ?
                            entry.time_attendance_save_point.last_edit.timestamp_start :
                            entry.time_attendance_save_point.timestamp_start;
                        entry.timeAttendanceTimestampStop = entry.time_attendance_save_point.last_edit && entry.time_attendance_save_point.last_edit.timestamp_end != null ?
                            entry.time_attendance_save_point.last_edit.timestamp_end :
                            entry.time_attendance_save_point.timestamp_end;

                        entry.timeAttendanceTimestampStart = entry.timeAttendanceTimestampStart ? moment(entry.timeAttendanceTimestampStart) : null;
                        entry.timeAttendanceTimestampStop = entry.timeAttendanceTimestampStop ? moment(entry.timeAttendanceTimestampStop) : null;

                        entry.timeAttendanceTime = (entry.timeAttendanceTimestampStart && entry.timeAttendanceTimestampStop) ?
                            round(moment.duration(entry.timeAttendanceTimestampStop.diff(entry.timeAttendanceTimestampStart)).asHours()) :
                            null;

                    }
                });

                this.setState({
                    loading: false,
                    entries: response.entries,
                    selectedEntries: [],
                    salaryExtras: response.salary_extras,
                    cancelToken: null,
                });

            }).catch( ( error ) => {
                if( axios.isCancel( error ) ) return null;
                console.log('ERROR', error );
            });
        });
    }
    
    newChange( id, data, changes = null )
    {
        if( !changes )
            changes = this.state.changes.slice();

        const index = changes.findIndex( c => c.id == id );

        if( index == -1 )
            changes.push({
                id: id,
                status: 'submitted',
                approver_comment: null,
                ...data,
            });

        else 
            changes[ index ] = { ...changes[ index ], ...data };

        return changes;
    }

    onToggleStatus( id )
    {
        let changes = this.state.changes.slice();
        let status = this.statusLoop[ 1 ];
        const index = changes.findIndex( c => c.id == id );
        if( index != -1 )
        {
            let statusIndex = this.statusLoop.indexOf( changes[ index ].status );
            statusIndex++;

            if( statusIndex >= this.statusLoop.length )
                statusIndex = 0;

            status = this.statusLoop[ statusIndex ];
        }

        changes = this.newChange( id, { status: status }, changes );
        this.setState({ changes });
    }

    onEditComment( id, currentComment = "" )
    {
        let changes = this.state.changes.slice();
        const index = changes.findIndex( c => c.id == id );

        if( index != -1 && changes[ index ].status == "rejected" )
            this.setState({ 
                commentText: currentComment,
                commentEntryId: id,
            });
    }

    saveComment( comment )
    {
        let changes = this.state.changes.slice();
        const update = {
            status: "rejected",
            approver_comment: comment,
        };

        if( this.state.commentEntryId === "selected" )
        {
            this.state.selectedEntries.forEach( id => {
                changes = this.newChange( id, update, changes );
            });
        }
        else 
        {
            changes = this.newChange( this.state.commentEntryId, update, changes );
        }

        this.setState({ 
            changes: changes,
            commentText: "",
            commentEntryId: null 
        });
    }

    closeComment()
    {
        this.setState({ 
            commentText: "",
            commentEntryId: null 
        });
    }

    isWeekSelected( week )
    {
        let selected = true;
        const entries = this.state.entries.filter( e => e.week === week && !e.readOnly );
        entries.forEach( e => {
            if( this.state.selectedEntries.indexOf( e.id ) == -1 )
                selected = false;
        });
        return selected;
    } 

    isDaySelected(date) {
        let selected = true;
        const entries = this.state.entries.filter(e => e.date === date && !e.readOnly);
        if (entries.length==0) {
            selected = false;
        }
        entries.forEach(e => {
            if (this.state.selectedEntries.indexOf(e.id) == -1)
                selected = false;
        });
        return selected;
    } 

    onWeekSelect( week, selected )
    {
        let selectedEntries = this.state.selectedEntries.slice();

        const entries = this.state.entries.filter( e => e.week === week && !e.readOnly );
        entries.forEach( e => {
            const index = selectedEntries.indexOf( e.id );
            
            // Select all day entries that are not yet selected
            if( selected && index == -1 )
                selectedEntries.push( e.id );

            // Unselect all day entries that are selected
            else if ( !selected && index != -1 )
                selectedEntries.splice( index, 1 );
        });

        this.setState({ selectedEntries });
    }

    onDaySelect(date, selected) {
        let selectedEntries = this.state.selectedEntries.slice();

        const entries = this.state.entries.filter(e => e.date === date && !e.readOnly);

        entries.forEach(e => {
            const index = selectedEntries.indexOf(e.id);

            // Select all day entries that are not yet selected
            if (selected && index == -1)
                selectedEntries.push(e.id);

            // Unselect all day entries that are selected
            else if (!selected && index != -1)
                selectedEntries.splice(index, 1);
        });

        this.setState({ selectedEntries });
    }

    onEntrySelect( id )
    {
        let selectedEntries = this.state.selectedEntries.slice();

        if( id === "all" )
        {
            const allEntries = this.state.entries.filter( e => e.readOnly == false );

            if( selectedEntries.length == allEntries.length )
                selectedEntries = [];
            else 
                selectedEntries = allEntries.map( e => e.id );
        }
        else 
        {
            const index = selectedEntries.indexOf( id );
            if( index === -1 )
                selectedEntries.push( id );
            else 
                selectedEntries.splice( index, 1 );
        }

        this.setState({ selectedEntries });
    }

    updateSelected( status )
    {
        if( status === "rejected" )
        {
            this.setState({ 
                commentText: "",
                commentEntryId: "selected",
            }); 
        }
        else 
        {
            let changes = this.state.changes.slice();
            this.state.selectedEntries.forEach( id => {
                changes = this.newChange( id, { status: status }, changes );
            });
            this.setState({ changes });
        }
    }

    toggleTypeFilter( id )
    {
        let filtered_types = this.state.filtered_types.slice();
        const index = filtered_types.indexOf( id );
        
        if( index == -1 )
            filtered_types.push( id );
        else 
            filtered_types.splice( index, 1 );

        this.setState({ filtered_types });
    }

    resetFilters()
    {
        this.setState({
            filtered_users: [],
            filtered_projects: [],
            filtered_types: [],
        });
    }

    removeFilter( type, index = null )
    {
        if( [ "filtered_users", "filtered_projects", "filtered_types" ].indexOf( type ) != -1 )
        {
            let items = this.state[ type ];
            items.splice( index, 1 );
            this.setState({ [ type ]: items });
        }
    }

    filteredEntries()
    {
        let entries = this.state.entries.slice();
        const users =  keyExists( this.state, "filtered_users", true, [] ).map( u => u.id );
        const projects =  keyExists( this.state, "filtered_projects", true, [] ).map( p => p.id );
        const types = keyExists( this.state, "filtered_types", true, [] );
        
        entries = entries.filter( e => {

            if( users.length > 0 && users.indexOf( e.user_id ) == -1 )
                return false;

            if( projects.length > 0 && projects.indexOf( e.project_id ) == -1 )
                return false;

            if( types.length > 0 && types.indexOf( e.type ) == -1 )
                return false;

            return true;
        });

        return entries;
    }

    save()
    {
        //console.log( 'Save...', this.state.entries );

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'timetracking/approval/save',
            data: {
                changes: this.state.changes,
            },
        }).then(( response ) => {
            this.props.setChanges(response);
            //console.log( 'saved!', response );
            this.getUsers( this.state.selectedUser );

        }).catch((error) => {
            console.error(error);
            this.setState({ loading: false });
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{ tr('approve_entries_error') }</strong>,
            });
        });
    }


    renderFooter()
    {
        const approved = this.state.changes.filter( c => c.status === "approved" ).length;
        const rejected = this.state.changes.filter( c => c.status === "rejected" ).length;
        const total = this.state.users.reduce( ( total, u ) => total + (isNaN(u.entry_count) ? 0 : parseFloat(u.entry_count)), 0 );
        const haveChanges = approved + rejected > 0;
  
        return (
            <div className="customFooter">

                <div className="tools">

                    <div className="selection">

                        <div className="selectAll" onClick={ () => this.onEntrySelect( "all" ) }>
                            <input 
                                type="checkbox"
                                disabled={ this.state.entries.length == 0 }
                                checked={ this.state.selectedEntries.length == this.state.entries.filter( e => !e.readOnly ).length }
                                readOnly
                            />
                            { this.state.selectedEntries.length > 0 &&
                                <span className="apBadge blue">{ this.state.selectedEntries.length }</span>
                            }
                        </div>

                        <ApDropdown
                            position="topright"
                            disabled={ this.state.entries.length == 0 }
                            actions={[
                                { 
                                    icon: "check",
                                    label: tr('approve_selected'),
                                    disabled: this.state.selectedEntries.length == 0,
                                    action: ( id, close ) => {
                                        this.updateSelected( "approved" );
                                        close();
                                    }
                                },
                                { 
                                    icon: "times",
                                    label: tr('reject_selected'),
                                    disabled: this.state.selectedEntries.length == 0,
                                    action: ( id, close ) => {
                                        this.updateSelected( "rejected" );
                                        close();
                                    }
                                },
                                { 
                                    icon: "question",
                                    label: tr('annul_selected'),
                                    disabled: this.state.selectedEntries.length == 0,
                                    action: ( id, close ) => {
                                        this.updateSelected( "submitted" );
                                        close();
                                    }
                                },
                            ]}
                        />
                    </div>
                    
                </div>

                <div className="action">

                    <div className="totals">
                        <div className="total">
                            <ApTooltip text={tr('waiting_entries')} block>
                                <SvgIcon icon="question" type="solid" />
                                <span>{ total - approved - rejected }</span>
                            </ApTooltip>
                        </div>
                        <div className={ "total" + ( approved > 0 ? " approve" : "" ) }>
                            <ApTooltip text={tr('approved_entries')} block>
                                <SvgIcon icon="check" type="solid" />
                                <span>{ approved }</span>
                            </ApTooltip>
                        </div>
                        <div className={ "total " + ( rejected > 0 ? " reject" : "" ) }>
                            <ApTooltip text={tr('rejected_entries')} block>
                                <SvgIcon icon="times" type="solid" />
                                <span>{ rejected }</span>
                            </ApTooltip>
                        </div>
                    </div>

                    <ApButton
                        className={ "save" + ( haveChanges && !this.state.loading ? " highlight" : "" ) }
                        color="blue"
                        onClick={ this.save }
                        disabled={ !haveChanges || this.state.loading }
                        loading={ this.state.loading }
                    >
                        <SvgIcon icon="clipboard-check" type="solid" />
                        { tr('save_changes') }
                    </ApButton>

                </div>

            </div>
        );
    }

    // Group entries to weeks and days
    groupifyEntries()
    {
        let parsed = {};
        const weeks = groupify( this.state.entries, "week" );
        Object.keys( weeks ).forEach( ( week ) => {
            const days = groupify( weeks[ week ], "date" );
            parsed[ week ] = days;
        });
        return parsed;
    }

    render()
    {

        const haveChanges = this.state.changes.filter( c => c.status === "approved" || c.status === "rejected" ).length > 0;
        const weeks = this.groupifyEntries();

        const weeksKeys = Object.keys(weeks)

        weeksKeys.forEach(week => {

            const dayKeys = Object.keys(weeks[week])

            dayKeys.forEach(day => {
                weeks[week][day].forEach(entry => {
                    if (entry.type === 'allowance') {
                        entry.money_allowance = entry.total_money
                    }

                    if (entry.type === 'travel') {
                        if (entry.project) {
                            let newTotalMoney = 0

                            if (entry.project.billing_travel_distance) {
                                newTotalMoney += Number(entry.project.billing_travel_distance) * Number(entry.total_distance)
                            } else {
                                newTotalMoney += Number(entry.detail.compensation) * (Number(entry.project.billing_margin_travel) / 100) * Number(entry.detail.distance)
                            }

                            if (entry.project.billing_travel_hours) {
                                newTotalMoney += Number(entry.project.billing_travel_hours) * Number(entry.total_hours)
                            } else {
                                newTotalMoney += Number(entry.detail.compensation_hours) * (Number(entry.project.billing_margin_travel) / 100) * Number(entry.detail.hours)
                            }

                            if (newTotalMoney !== 0) {
                                // entry.money_travel = newTotalMoney
                                // entry.total_money = newTotalMoney
                             }
                        }
                    }
                })
            })
        })
      
        return (
            <div id="pageTimetrackingApproval">
                <ApFormPage 
                    unsaved={ haveChanges }
                    className="timetrackingApprovalForm"
                    customFooter={ this.renderFooter }
                >
                    <div className="apBox">
                        <div className="apBoxHeader">

                            <div className="apBoxCorner">

                                { !this.state.loading && this.state.users?.length > 0 &&
                                <div>
                                <label htmlFor="show-readOnly-switch">
                                    { tr('show_all_entries') }
                                </label>
                                <ApSwitch
                                    small
                                    inline
                                    id="show-readOnly-switch"
                                    on={ this.state.showReadOnly }
                                    onChange={ () => this.setState({ showReadOnly: !this.state.showReadOnly }) }
                                />
                                </div> }
                              
                                {/* 
                                <div>
                                    <label htmlFor="show-salary-extras-switch">
                                        Näytä palkkiot ja lisät
                                    </label>
                                    <ApSwitch
                                        small
                                        inline
                                        id="show-salary-extras-switch"
                                        on={ this.state.showSalaryExtras }
                                        onChange={ () => this.setState({ showSalaryExtras: !this.state.showSalaryExtras }) }
                                    />
                                </div>
                                */}
                            </div>
                        </div>

                        <UserBrowser 
                            users={ this.state.users } 
                            selectedUser={ this.state.selectedUser }
                            onChangeUser={ this.changeUser }
                        />
    
                        {/*
                        <div className="weekBrowser">
                            <div className="weeks">
                                { Object.keys( weeks ).map( week => {
                                    return (
                                        <div className="week" key={ week }>Viikko { week.split("-")[ 1 ] }</div>
                                    );
                                })}
                            </div>
                        </div>
                        */}
                    </div>

                    <div className={ "entries" + ( this.state.loading ? " loading" : "" ) + ( this.state.showReadOnly ? "" : " hideReadOnly" ) }>

                        { this.state.loading && <div className="apLoader"></div> }

                        { !this.state.loading && this.state.users.length == 0 &&
                            <div className="apSuccessMsg">
                                <SvgIcon block icon="thumbs-up" type="solid" size="huge" />
                                <h4>{ tr('no_approvable_entries') }</h4>
                                <p>{ tr('no_approvable_entries_note') }</p>
                            </div>
                        }

                        { Object.keys( weeks ).map( week => {
                            return (
                                <Week
                                    key={ week }
                                    week={ week }
                                    days={ weeks[ week ] }
                                    weekSelected={this.isWeekSelected(week)}
                                    daySelected={this.isDaySelected}
                                    selectedEntries={ this.state.selectedEntries }
                                    changes={ this.state.changes }
                                    settings={ this.state.settings }
                                    showReadOnly={ this.state.showReadOnly }
                                    showSalaryExtras={ this.state.showSalaryExtras }
                                    salaryExtras={ this.state.salaryExtras }
                                    statuses={ this.statusLoop }
                                    onToggleReadOnly={ ( value ) => this.setState({ showReadOnly: value }) }
                                    onToggleStatus={ this.onToggleStatus }
                                    onEntrySelect={ this.onEntrySelect }
                                    onWeekSelect={this.onWeekSelect}
                                    onDaySelect={this.onDaySelect}
                                    onEditComment={ this.onEditComment }
                                />
                            );
                        })}

                    </div>

                
                </ApFormPage>

                <ApModalInput 
                    show={ this.state.commentEntryId !== null }
                    value={ this.state.commentText }
                    onSave={ this.saveComment }
                    onCancel={ this.closeComment }
                    title={ tr('rejection_reason') }
                    info={ this.state.commentEntryId == "selected" ? `${tr('selected')} ` + this.state.selectedEntries.length + ` ${tr('rows_plural')} ` : undefined }
                />

            </div>
        );
    }
}
