import React, { useState } from 'react'
import './ApCalendar.css';
import ApModal from 'common/ApModal/ApModal';
import ApButton from 'common/ApButton/ApButton';
import { tr } from 'services/Helpers/Helpers';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import ApRadioSwitch from 'common/ApRadioSwitch/ApRadioSwitch';

const radioOptions = [
    tr('recur_event_all'),
    tr('recur_event_future'),
    tr('recur_event_this'),
];

const CalendarConfirmRecurrenceModal = (props) => {
    const [checkedOption, setCheckedOption] = useState(radioOptions[0]);

    const renderHeader = () => {
        return (
            <div className='padding'>
                <h5>{tr('edit_recurrence')}</h5>
            </div>
        )
    }

    const renderBody = () => {
        return (
            <div className='padding'>
                <ApRadioSwitch
                    options={radioOptions}
                    onClick={setCheckedOption}
                    checked={checkedOption}
                />
            </div>
        )
    }

    const renderFooter = () => {
        return (
            <div className='padding justify-space-between'>
                <ApButton onClick={props.onClose}>
                    <SvgIcon icon="times" type="solid" /> {tr('cancel')}
                </ApButton>
                <ApButton
                    onClick={() => props.onConfirm(checkedOption)}
                    color='green'
                    disabled={props.loading}
                    loading={props.loading}
                >
                    {tr('continue')} <SvgIcon icon="arrow-right" type="solid" />
                </ApButton>
            </div>
        )
    }

    return (
        <div>
            <ApModal
                show={props.show}
                handleClose={props.onClose}
                className='narrow'

                header={renderHeader()}
                body={renderBody()}
                footer={renderFooter()}
            />
        </div>
    )
}

export default CalendarConfirmRecurrenceModal