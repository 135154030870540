/* eslint-disable eqeqeq */
import React from 'react';
import moment   from 'moment';
import autoBind from 'react-autobind';
import { Link } from 'react-router-dom';

import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';
import { pluralizeCount, tr, currentLang, getFileById } from 'services/Helpers/Helpers.js';
import { entryIcon } from  'modules/Timetracking/TimetrackingHelpers.js';
import ApButton           from 'common/ApButton/ApButton.js';
import ApModal            from 'common/ApModal/ApModal.js';
import { hasPermissions,
         sqlToDateInput } from 'services/Helpers/Helpers.js';
import ApProgressbar from '../../common/ApProgressbar/ApProgressbar.js';
import './Home.css';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import { ApAddon, ApInput, ApInputStack } from 'common/ApInput/ApInput.js';
import { matchSorter } from 'match-sorter';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import { connect } from 'react-redux';
import ApCalendar from 'common/ApCalendar/ApCalendar.js';

/*
*  TODO list: 
*   - More blocks 
*   - Customizable blocks
*       - Save settings to user profile
*       - Toggle blocks visible/hidden
*       - Drag to re-order blocks
*/

class Home extends React.Component {
    constructor(props) 
    {
        super(props);
        this.state = {
            loading: false,
            blocks: false,
            showNewsModal: false,
            currentNews: null,
            news: null,
            skippedCompanyNews: [],
            showNotificationsModal: false,
            ownAssignmentsFilter: null,
            freeAssignmentsFilter: null,
        };
        autoBind(this); 

        this.crmEventIcons = {
            "call":     "phone",
            "email":    "at",
            "visit":    "coffee",
            "-":        "question",
        }

        this.assignmentFilterKeys = [
            'name',
            'customer.name',
            'project.name',
            'project.project_code'
        ]
    }

    componentDidMount()
    {
        this.getBlocks();
        this.getNews();
    }

    getBlocks()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'currentUser/blocks',
        }).then(( response ) => {

            //console.log('user blocks', response );

            response.forEach( block => {
                this.getBlockContent( block.id );
            });

            this.setState({ 
                loading: false,
                blocks: response
            });

        }).catch((error) => {
            console.log( error );
            this.setState({ 
                loading: false
            });
        });
    }

    getBlockContent( block )
    {
        api({
            method: 'get',
            url: 'currentUser/block/' + block,
        }).then(( response ) => {

            //console.log('block content', block, response );

            if (block === "news") 
            {
                this.checkUnreadCompanyNews(response);
            }

            if (block === "news") 
            {
                this.checkUnreadCompanyNews(response);
            }

            let blocks = this.state.blocks.slice();
            const index = blocks.findIndex( b => b.id == block );
            if( index > -1 )
            {
                blocks[ index ].data = response.data;
                blocks[ index ].loaded = true;

                if( blocks[ index ].stats )
                {
                    for( const statId in response.stats )
                    {
                        const statIndex = blocks[ index ].stats.findIndex( s => s.id == statId );
                        if( statIndex > -1 )
                            blocks[ index ].stats[ statIndex ] = { ...blocks[ index ].stats[ statIndex ], ...response.stats[ statId ] };
                    }
                }

                if (block === 'purchases') {

                    blocks[ index ].data.active_purchase_orders.sort((a, b) => {

                        //Push orders without any dates to last
                        if (a.status_name === 'order_confirm' || a.status_name === 'open') return 1  
                        
                        const deliveryDateA = moment(a.delivery_date, 'YYYY-MM-DD')
                        const deliveryDateB = moment(b.delivery_date, 'YYYY-MM-DD')

                        if (a.status_name === 'late' && b.status_name === 'late') {
                            if (deliveryDateB.isBefore(deliveryDateA, 'day')) {
                                return 1
                            }
                            return 0
                        }

                        if (b.status_name === 'late') return 1
    
                        //Sort late payments after late deliveries

                        if (b.paid_late && !a.paid_late && b.status_name !== 'late' && a.status_name !== 'late') return 1

                        //Sort rest by delivery & due dates

                        if (!b.paid_late && !a.paid_late && b.status_name !== 'late' && a.status_name !== 'late') {
                            if (a.status_name === 'arrived') return 1

                            if (deliveryDateB.isBefore(deliveryDateA, 'day')) return 1

                            const dueDateA = moment(a.due_date, 'YYYY-MM-DD')
                            const dueDateB = moment(b.due_date, 'YYYY-MM-DD')

                            if (dueDateB.isBefore(dueDateA, 'day')) return 1

                            if (!deliveryDateB.isBefore(deliveryDateA, 'day') && dueDateB.isBefore(dueDateA, 'day')) return 0
                        }

                        return -1
                    })
                }

                this.setState({ blocks });
            }            

        }).catch((error) => {
            console.log( error );
            // TODO retry or fail? 
        });
    }

    getStats()
    {
        if( !this.state.blocks || this.state.blocks.length == 0 )
            return false;

        let stats = [];
        this.state.blocks.forEach( block => {
            if( block.stats )
            {
                block.stats.forEach( stat => {
                    let data = stat;
                    data[ 'loaded' ] = block.loaded;
                    stats.push( data );
                });
            }
        });
        return stats;
    }

    getNews()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'currentUser/getnews',
        }).then(( response ) => {

            //console.log('news', response );

            this.setState({ 
                loading: false,
                news: response
            });

        }).catch((error) => {
            console.log( error );
            this.setState({ 
                loading: false
            });
        });
    }

    checkUnreadCompanyNews(response)
    {
        response.data.latest_news.map(item => {
            if (item.read === false && this.state.skippedCompanyNews.indexOf(item.id) === -1) {
                this.setState({ currentNews: item, showNewsModal: true })
            }
        })
    }

    markAsRead(main = false)
    {
        api({
            method: 'post',
            url: 'currentUser/markasread',
            data: {
                id: this.state.currentNews.id,
                main: main
            }
        }).then((response) => {
            //console.log("save successful");
            this.getBlockContent( "news" );
            this.getNews();
            this.toggleNewsModal(null);
        }).catch((error) => {
            console.log(error);
        });
    }

    handleCompanyNewsModal(item)
    {
        let currentNews = null;
        let showNewsModal = false;
        let skippedCompanyNews = [...this.state.skippedCompanyNews];
        if (item.read === false && skippedCompanyNews.indexOf(item.id) === -1)
        {
            skippedCompanyNews.push(item.id);
        }

        let index = null
        for (let i = 0; i < this.state.blocks.length; i++) {
            if (this.state.blocks[i].id === 'news') {
                index = i
                break
            }
        }
        this.state.blocks[index].data.latest_news.map(newsitem => {
            if (newsitem.read === false && skippedCompanyNews.indexOf(newsitem.id) === -1) 
            {
                currentNews = newsitem;
                showNewsModal = true;
            }
        })

        this.setState({ showNewsModal: showNewsModal, currentNews: currentNews, skippedCompanyNews: skippedCompanyNews });
    }

    toggleNewsModal(item, mainNews)
    {
        this.setState(prevState => ({
            showNewsModal: !prevState.showNewsModal,
            currentNews: item,
            mainNews: mainNews
          }));
    }

    getOwnAssignmentText(status_id) {
        return status_id <=3 ? tr('assignment_status_control_4') : tr('mark_assignment_as_completed');
    }
    getFreeAssignmentText(status_id) {
        if (status_id == 2) {
            return tr('assignment_status_control_3');
        }

        if (status_id == 3) {
            return tr('assignment_status_control_4');
        }

        return tr('mark_assignment_as_completed');
    }
    getOwnAssignmentColor(status_id) {
        return status_id <= 3 ? "blue" : "green";
    }
    getFreeAssignmentColor(status_id) {
        if (status_id <= 3) {
            return "blue";
        }

        else if (status_id <= 7) {
            return "blue";
        }

        return "green";
    }
    getOwnAssignmentIcon(status_id) {
        return status_id <= 3 ? "play" : "check";
    }
    getFreeAssignmentIcon(status_id) {
        // if (status_id == 2) {
        //     return "check";
        // }

        if (status_id <= 3) {
            return "play";
        }

        return "check";
    }

    assignmentButtonClick(block, status, assignment_id) {
        let newStatus = 3;
        if (block == "own") {
            if (status <= 3) {
                newStatus = 4;
            }
            else if (status <= 7) {
                newStatus = 9;
            }
        }
        else if (block == "free") {
            if (status <= 2) {
                newStatus = 3;
            }
            else if (status <= 3) {
                newStatus = 4;
            }
            else if (status <= 7) {
                newStatus = 9;
            }
        }
        
        api({
            method: 'post',
            url: 'assignment/status',
            data: {
                assignment_id: assignment_id,
                status: newStatus,
                use_group_when_finished: true
            }
        }).then((response) => {

            let keys = [];
            if (block == "own") {
                keys.push("assignments");
                // this.getBlockContent("assignment");
                if (typeof this.props.timerUpdate === 'function') {
                    this.props.timerUpdate();
                }
            }
            else {
                keys.push("assignments");
                keys.push("assignments.work");
                // this.getBlockContent("assignment");
                // this.getBlockContent("assignment.work");
            }
            
            keys.forEach(key => {
                
                let blocks = this.state.blocks.slice();
                const index = blocks.findIndex(b => b.id == key);
                if (index > -1) {
                    blocks[index].loaded = false;
                    this.setState({ blocks });
                }
                this.getBlockContent(key);
            });

        }).catch((error) => {
            console.log(error);
            // TODO retry or fail? 
        });

        
    }

    renderBlockContent( block )
    {
        let dayIslocked = this.props.apTimer?.dayIsLocked;
        let timerPlaying = this.props.apTimer?.status === "playing";
        let timeAttendanceRequiredNotSignedIn = this.props.apTimetrackingSettings?.company?.timer_time_attendance_required 
            && this.props.apTimer?.isSignedInTimeAttendance === false
            && this.props.apProjectSettings?.assignment_startclock;

        const getAssignmentWarningText = (item) => {
            let assignmentWarning = null;
            if (item.status_id < 4) {
                if (timerPlaying) {
                    assignmentWarning = tr('timer_playing_warning');
                } else if (dayIslocked) {
                    assignmentWarning = tr('day_locked');
                } else if (timeAttendanceRequiredNotSignedIn) {
                    assignmentWarning = tr('timer_time_attendance_required');
                }
            }
            return assignmentWarning;
        }

        switch( block.id )
        {
            case "timetracking.entries":
                return (
                    <div className="timetrackingEntries">

                        <div className="list rejected">
                            <h5><SvgIcon className="small-inline" icon="times-circle" type="solid" /> { tr('rejected_entries') } </h5>
                            { block.data.rejected.map( ( entry, index ) => {
                                return (
                                    <Link to={ "/timetracking/entry/" + moment( entry.date ).format( 'YYYY[/]w' ) } key={ index } className="listItem timetrackingEntry rejected">
                                        <SvgIcon className="type" icon={ entryIcon( entry.type ) } type="solid" />
                                        <strong>{ entry.description }</strong> { entry.approver_comment && <span>- { entry.approver_comment }</span> }<br />
                                        <small>{ moment( entry.date ).format('DD.MM.YYYY') }</small>
                                        <SvgIcon className="arrow" icon="arrow-right" type="solid" />
                                    </Link> 
                                );
                            })}
                            { block.data.rejected.length == 0 &&
                                <div className="apMsg">{ tr('no_rejected_entries') }</div>
                            }
                        </div>
                    
                        {/* 
                        <div className="list today">
                            <h5><SvgIcon className="small-inline" icon="calendar-alt" type="solid" /> Kirjaukset tänään</h5>
                            { block.data.entries.map( ( entry, index ) => {
                                return (
                                    <Link to={ "/timetracking/entry/" + moment().format('YYYY[/]w') } key={ index } className="listItem timetrackingEntry">
                                    
                                        <SvgIcon className="type" icon={ entryIcon( entry.type ) } type="solid" />
                                        <strong>{ entry.description }</strong><br />
                                        <small>{ entry.total_hours || 0 } h, { entry.total_money || 0 } €, { entry.total_distance || 0 } km</small>
                                        <SvgIcon className="arrow" icon="arrow-right" type="solid" />
                                    </Link> 
                                );
                            })}
                            { block.data.entries.length == 0 &&
                                <div className="apMsg">Ei yhtään kirjausta vielä</div>
                            }
                        </div>
                        */}
                        
                        { block.data.approvable > 0 &&
                            <Link to="/timetracking/approval" className="linkBox">
                                <SvgIcon icon="calendar-check" type="solid" />
                                <span>{ tr('waiting_for_approval2') } <strong>{ pluralizeCount( block.data.approvable, tr('pluralize_entry'), tr('pluralize_entries') ) }</strong></span>
                                <SvgIcon className="arrow" icon="arrow-right" type="solid" />
                            </Link> 
                        }


                        { block.data.confirmable > 0 &&
                            <Link to="/timetracking/confirm" className="linkBox">
                                <SvgIcon icon="check-circle" type="solid" />
                                <span>{ tr('waiting_for_confirm') } <strong>{ pluralizeCount( block.data.confirmable, tr('pluralize_entry'), tr('pluralize_entries') ) }</strong></span>
                                <SvgIcon className="arrow" icon="arrow-right" type="solid" />
                            </Link> 
                        }
                        

                    </div>
                );
            case "projects.overseer":
                return (
                    <div className="list projects">
                        <h5><SvgIcon className="small-inline" icon="running" type="solid" />{ tr('running') }</h5>
                        { block.data.running_projects.map( ( project, index ) => {
                            let start = null;
                            let end = null;
                            if (project.begin_date) {
                                start = tr('begins') + ': ' + moment(project.begin_date).format('DD.MM.YYYY');
                            }
                            if (project.end_date) {
                                if (moment() < moment(project.end_date)) {
                                    end = <> <br />{' '+tr('ends') + ': ' + moment(project.end_date).format('DD.MM.YYYY')}</>
                                }
                                else {
                                    end = <> <br /><strong><font color='var(--clr-warning-main)'>{' ' + tr('ends') + ': ' + moment(project.end_date).format('DD.MM.YYYY')}</font></strong> </>
                                }
                            }


                            if (hasPermissions('projects.overseer'))
                            {
                                return (
                                    <Link to={ "/projects/" + project.id + "/management" } key={ index } className="listItem project">
                                        <strong>{project.name} </strong><small>({project.project_code})<br /> {start}{end}</small><br />
                                        <div>{ typeof project.work === 'string' ? project.work : (project.work ? project.work.map(work => work).join(', ') : null) }</div>
                                        <SvgIcon className="arrow" icon="arrow-right" type="solid" />
                                    </Link> 
                                );
                            }
                            return (
                                <div key={ index } className="listItem project" style={{cursor: 'default'}}>
                                    <strong>{project.name} </strong><small>({project.project_code}) <br /> {start}{end}</small> <br />
                                    <div>{ typeof project.work === 'string' ? project.work : (project.work ? project.work.map(work => work).join(', ') : null) }</div>
                                </div>
                            )
                        })}
                        { block.data.running_projects.length == 0 &&
                            <div className="apMsg">{ tr('no_running_projects') }</div>
                        }
                        
                        <h5><SvgIcon className="small-inline" icon="calendar-alt" type="solid" />{ tr('upcoming') }</h5>
                        { block.data.future_projects.map( ( project, index ) => {

                            let start = null;
                            let end = null;
                            if (project.begin_date) {
                                start = tr('begins') + ': ' + moment(project.begin_date).format('DD.MM.YYYY');
                            }
                            if (project.end_date) {
                                if (moment() < moment(project.end_date)) {
                                    end = <> <br />{' ' + tr('ends') + ': ' + moment(project.end_date).format('DD.MM.YYYY')}</>
                                }
                                else {
                                    end = <> <br /><strong><font color='var(--clr-warning-main)'>{' ' + tr('ends') + ': ' + moment(project.end_date).format('DD.MM.YYYY')}</font></strong> </>
                                }
                            }
                            if (hasPermissions('projects.overseer'))
                            {
                                return (
                                    <Link to={ "/projects/" + project.id + "/management" } key={ index } className="listItem project">
                                        <strong>{project.name} </strong><small>({project.project_code}) <br />  {start}{end}</small><br />
                                        <div>{ typeof project.work === 'string' ? project.work : (project.work ? project.work.map(work => work).join(', ') : null) }</div>
                                        <SvgIcon className="arrow" icon="arrow-right" type="solid" />
                                    </Link> 
                                );
                            }
                            return (
                                <div key={ index } className="listItem project" style={{cursor: 'default'}}>
                                    <strong>{project.name} </strong><small>({project.project_code}) <br />  {start}{end}</small><br />
                                    <div>{ typeof project.work === 'string' ? project.work : (project.work ? project.work.map(work => work).join(', ') : null) }</div>
                                </div>
                            )
                        })}
                        { block.data.future_projects.length == 0 &&
                            <div className="apMsg">{ tr('no_upcoming_projects') }</div>
                        }
                    </div>
                );
            case "crm.clients":
                return (
                    <div className="list latestCrmCompanies">
                        { block.data.latest_companies.map( ( crm_company, index ) => {
                            return (
                                <Link to={ "/crm/company/" + crm_company.id } key={ index } className="listItem crmCompany">
                                    <strong>{ crm_company.name }</strong><br />
                                    <small>{ crm_company.taxnumber || "Ei Y-tunnusta" }</small>

                                    <SvgIcon className="arrow" icon="arrow-right" type="solid" />
                                </Link> 
                            );
                        })}
                        { block.data.latest_companies.length == 0 &&
                            <div className="apMsg">{ tr('no_customers') }</div>
                        }
                    </div>
                );

            case "crm.events":
                return (
                    <div className="list latestCrmEvents">
                        { block.data.latest_events.map( ( event, index ) => {
                            return (
                                <Link to={ "/crm/company/" + event.crm_company_id + "/events/" + event.id } key={ index } className="listItem event">
                                    
                                    <SvgIcon className={ "type " + event.type } icon={ this.crmEventIcons[ event.type ] } type="solid" />

                                    <strong>{ event.title }</strong> - { event.crm_company.name }<br />
                                    <small>{ moment( event.occurred_at ).locale(currentLang()).format('LLL') }</small>

                                    <SvgIcon className="arrow" icon="arrow-right" type="solid" />
                                </Link> 
                            );
                        })}
                        { block.data.latest_events.length == 0 &&
                            <div className="apMsg">{ tr('no_events') }</div>
                        }
                    </div>
                );
            case "news":
                return (
                    <div className="list latestnews">
                        { block.data.latest_news.map( ( item, index ) => {
                            return (
                                <div onClick={() => this.toggleNewsModal(item, false)} key={ index } id={ index } className="listItem">

                                    <strong>{ item.header }</strong><br />
                                    <small>{ item.content ? (item.content.length > 150 ? item.content.slice(0,150) + "..." : item.content) : null }</small>

                                    <SvgIcon className={`arrow ${item.read ? "" : "unread"}`} icon="arrow-right" type="solid" />
                                </div> 
                            );
                        })}
                        { block.data.latest_news.length == 0 &&
                            <div className="apMsg">{ tr('no_news') }</div>
                        }
                    </div>
                );
            case "assignments":
                let ownAssignments = block.data.assignments;
                if (ownAssignments != 0 && this.state.ownAssignmentsFilter) {
                    ownAssignments = matchSorter(
                        ownAssignments, 
                        this.state.ownAssignmentsFilter, 
                        {
                            keys: this.assignmentFilterKeys
                        }
                    );
                }

                return (
                    <div className="list assignments">
                    { block.data.assignments != 0 &&
                        <ApInputStack gap="0">
                            <ApAddon width="50px">
                                <SvgIcon type="solid" icon="search" />
                            </ApAddon>
                            <ApInput
                                type="text"
                                label={ tr('search') }
                                value={ this.state.ownAssignmentsFilter }
                                onChange={ (e) => this.setState({ ownAssignmentsFilter: e.target.value }) }
                            />
                        </ApInputStack>
                    }
                    { ownAssignments.map( ( item, index ) => {
                        const isUnderExecution = item.status_id === 4;
                        let isLate = false;
                        const underExecutionEl = <ApTooltip text={tr('work_in_progress')} position="topleft">
                            <SvgIcon type="regular" icon="clock" fill="var(--clr-success-main)" />
                        </ApTooltip>;
                        const isLateEl = <ApTooltip text={tr('task_delayed')} position="topleft">
                            <SvgIcon type="solid" icon="exclamation-circle" fill="var(--clr-error-main)" />
                        </ApTooltip>;

                        if (item.end_at && moment(item.end_at).isBefore(moment())) {
                            isLate = true;
                        }

                        let assignmentWarning = getAssignmentWarningText(item);

                        return (
                            <div className="listItem project" key={index}>
                                <Link to={"/assignments/" + item.id + "/1"} key={index}>
                                    <table><tbody>
                                        <tr><td valign="top">
                                            {isLate && <>{isLateEl}&nbsp;</>}
                                            {isUnderExecution && <>{underExecutionEl}&nbsp;</>}
                                            <strong>{item.name}</strong><br />
                                            <small>{item.description ? (item.description.length > 150 ? item.description.slice(0, 150) + "..." : item.description) : null}</small><br />
                                        </td><td>
                                                {item.customer != null &&
                                                    <small>{tr('customer')}: {item.customer.name}<br /></small>
                                                }
                                                {item.begin_at != null &&
                                                    <small>{tr('start_date')}: {moment(item.begin_at).format("L HH:mm")}<br /></small>
                                                }
                                                {item.end_at != null &&
                                                    <small>{tr('completed')}: {moment(item.end_at).format("L HH:mm")} {item.deadline == true ? ` ${tr('by').toLowerCase()} ` : ""}<br /></small>
                                                }
                                            </td></tr>
                                    </tbody></table>
                                    
                                        <SvgIcon className="arrow" icon="arrow-right" type="solid" />
                                        </Link>
                                {block.data.showAssignmentButtons && <ApTooltip text={assignmentWarning}><ApButton className="assignmentButton" color={this.getOwnAssignmentColor(item.status_id)} size="tiny" onClick={() => this.assignmentButtonClick('own', item.status_id, item.id)} disabled={!!assignmentWarning && [2,3].includes(item.status_id)}>
                                    <SvgIcon icon={this.getOwnAssignmentIcon(item.status_id)} type="solid" style={{ paddingRight: 4 }} />
                                    {this.getOwnAssignmentText(item.status_id)}
                                </ApButton></ApTooltip>}
                            </div>	 
                        );
                    })}
                    { block.data.assignments == 0 &&
                        <div className="apMsg">{ tr('no_work_tasks') }</div>
                    }
                    </div>
                );
            case "assignments.work":
                let freeAssignments = block.data.assignments_works;
                if (freeAssignments != 0 && this.state.freeAssignmentsFilter) {
                    freeAssignments = matchSorter(
                        freeAssignments, 
                        this.state.freeAssignmentsFilter, 
                        {
                            keys: this.assignmentFilterKeys,
                        }
                    );
                }
                return (
                    <div className="list assignments">
                    { block.data.assignments_works != 0 &&
                        <ApInputStack gap="0">
                            <ApAddon width="50px">
                                <SvgIcon type="solid" icon="search" />
                            </ApAddon>
                            <ApInput
                                type="text"
                                label={ tr('search') }
                                value={ this.state.freeAssignmentsFilter }
                                onChange={ (e) => this.setState({ freeAssignmentsFilter: e.target.value }) }
                            />
                        </ApInputStack>
                    }
                    { freeAssignments.map( ( item, index ) => {
                        let assignmentWarning = getAssignmentWarningText(item);
                        return (
                            <div className="listItem project" key={index}>	
                            <Link to={"/assignments/" + item.id + "/0"} key={index}>
		                       		<table><tbody>
		                    		 <tr><td valign="top">
		                		 	
		                                <strong>{ item.name }</strong><br />
		                                <small>{ item.description ? (item.description.length > 150 ? item.description.slice(0,150) + "..." : item.description) : null }</small><br />
		                            </td><td>
		                            {item.customer!=null && 
		                            	<small>{ tr('customer') }: {item.customer.name}<br /></small>
		                            }
		                            {item.begin_at!=null && 
		                            	<small>{ tr('start_date') }: {moment(item.begin_at).format("L HH:mm")}<br /></small>
		                            }
		                            {item.end_at!=null && 
		                            	<small>{ tr('done') }: {moment(item.end_at).format("L HH:mm")}{item.deadline==true ? ` ${tr('by').toLowerCase()} ` : ""}<br /></small>
		                            }
		                             </td></tr>
		                            </tbody></table>
		                            <SvgIcon className="arrow" icon="arrow-right" type="solid" />
                                </Link>
                                {block.data.showAssignmentButtons && <ApTooltip text={assignmentWarning}><ApButton className="assignmentButton" color={this.getFreeAssignmentColor(item.status_id)} size="tiny" onClick={() => this.assignmentButtonClick('free', item.status_id, item.id)} disabled={!!assignmentWarning}>
                                    <SvgIcon icon={this.getFreeAssignmentIcon(item.status_id)} type="solid" style={{ paddingRight: 4 }} />
                                    {this.getFreeAssignmentText(item.status_id)}
                                </ApButton></ApTooltip>}
                            </div>	

                        );
                    })}
                    { block.data.assignments_works == 0 &&
                        <div className="apMsg">{ tr('no_work_tasks') }</div>
                    }
                    </div>
                );
            case "work_tasks":
                return (
                    <div className="list assignments">
                    { block.data.work_tasks.map( ( item, index ) => {
                        let isToday = false;
                        const now = moment();
                        const start = moment(item.start).format('YYYY-MM-DD');
                        const end = moment(item.end).format('YYYY-MM-DD');
                        if (now.isBetween(start, end, 'day', '[]')) {
                            isToday = true;
                        }
                        if (item.project) {
                            return (
                                <Link to={ hasPermissions('projects') ? "/projects/" + item.project_id + "/management" : '' } key={ index } className="listItem project">
	                        		<table><tbody>
                                        <tr>
                                            <td valign="top">
                                                <small style={{color: '#388e3c'}}>{isToday && `(${tr('today')}) `}</small>
                                                <strong>{ item.project.name }</strong><br />
                                                <small>{ item.project.description ? (item.project.description.length > 150 ? item.project.description.slice(0,150) + "..." : item.project.description) : null }</small><br />
                                            </td>
                                            <td>
                                            {item.project.customer!=null && 
                                                <small>{ tr('customer') }: {item.project.customer.name}<br /></small>
                                            }
                                            {item.start!=null && 
                                                <small>{ tr('start_date') }: {moment(item.start).locale(currentLang()).format("(dd) L")}<br /></small>
                                            }
                                            {item.end!=null && 
                                                <small>{ tr('completed') }: {moment(item.end).locale(currentLang()).subtract(1,'second').format("(dd) L")}<br /></small>
                                            }
                                            </td>
                                            <td>
                                                {item.project.begin_date!=null && 
		                            	        <small>{ tr('project_start_date') }: {moment(item.project.begin_date).format("L HH:mm")}<br /></small>}
                                                {item.project.end_date!=null && 
                                                <small>
                                                { tr('project_end_date') }: {moment(item.project.end_date).format("L HH:mm")}
                                                <br />
                                                </small>}
                                            </td>
                                        </tr>
                                    </tbody></table>
		                             <SvgIcon className="arrow" icon="arrow-right" type="solid" />
                                </Link>
                            );
                        }
                        else if (item.assignment) {
                            return (
                                <Link to={ "/assignments/" + item.assignment_id + "/1" } key={ index } className="listItem project">
	                        		<table><tbody>
                                        <tr>
                                            <td valign="top">
                                            <small style={{color: '#388e3c'}}>{isToday && `(${tr('today')}) `}</small>
                                            <strong>{ item.assignment.name }</strong><br />
                                                <small>{ item.assignment.description ? (item.assignment.description.length > 150 ? item.assignment.description.slice(0,150) + "..." : item.assignment.description) : null }</small><br />
                                            </td>
                                            <td>
                                            {item.assignment.customer!=null && 
                                                <small>{ tr('customer') }: {item.assignment.customer.name}<br /></small>
                                            }
                                            {item.start!=null && 
                                                <small>{ tr('start_date') }: {moment(item.start).locale(currentLang()).format("(dd) L")}<br /></small>
                                            }
                                            {item.end!=null && 
                                                <small>{ tr('completed') }: {moment(item.end).locale(currentLang()).subtract(1,'second').format("(dd) L")} {item.deadline==true ? ` ${tr('by').toLowerCase()} ` : ""}<br /></small>
                                            }
                                            </td>
                                            <td>
                                                {item.assignment.begin_at!=null && 
		                            	        <small>{ tr('assignment_start_date') }: {moment(item.assignment.begin_at).format("L HH:mm")}<br /></small>}
                                                {item.assignment.end_at!=null && 
                                                <small>
                                                { tr('assignment_end_date') }: {moment(item.assignment.end_at).format("L HH:mm")}
                                                <br />
                                                </small>}
                                            </td>
                                        </tr>
                                    </tbody></table>
		                             <SvgIcon className="arrow" icon="arrow-right" type="solid" />
                                </Link>
                            );
                        }
                        else {
                            return (
                                <Link to={ "/assignments/" + item.id + "/1" } key={ index } className="listItem project">
                                    <table><tbody>
                                    <tr><td valign="top">
                                    
                                    <strong>{ item.name }</strong><br />
                                    <small>{ item.description ? (item.description.length > 150 ? item.description.slice(0,150) + "..." : item.description) : null }</small><br />
                                    </td><td>
                                    {item.customer!=null && 
                                        <small>{ tr('customer') }: {item.customer.name}<br /></small>
                                    }
                                    {item.begin_at!=null && 
                                        <small>{ tr('start_date') }: {moment(item.begin_at).locale(currentLang()).format("(dd) L HH:mm")}<br /></small>
                                    }
                                    {item.end_at!=null && 
                                        <small>{ tr('completed') }: {moment(item.end_at).locale(currentLang()).format("(dd) L HH:mm")} {item.deadline==true ? ` ${tr('by').toLowerCase()} ` : ""}<br /></small>
                                    }
                                    </td></tr>
                                    </tbody></table>
                                    <SvgIcon className="arrow" icon="arrow-right" type="solid" />
                                </Link>
                            );
                        }
                    })}
                    { block.data.work_tasks == 0 &&
                        <div className="apMsg">{ tr('no_work_tasks') }</div>
                    }
                    </div>
                );
                case "purchases":
                    const purchases = block.data.active_purchase_orders.slice(0, 11)
    
                    return (
                        <div className="list latestCrmCompanies">    
                            { purchases.map( ( order, index ) => {   
                                if (!order.archived) {    
                                    let orderStatus = sqlToDateInput(order.delivery_date)   
                                    let paymentStatus = `${tr('due_date').toLowerCase()}: ` + sqlToDateInput(order.due_date)   
                                    let showLateIcon = false
    
                                    if (order.status_name === 'arrived' || order.status_name === 'late') {    
                                        orderStatus = tr(order.status_title)
                                    }
    
                                    if (order.status_name === 'arrival') {  
                                        orderStatus = `${tr('waiting_for_arrival')}: ` + sqlToDateInput(order.delivery_date)
                                    }
    
                                    if (order.status_name === 'late') {
                                        orderStatus = tr(order.status_title) + ': ' + sqlToDateInput(order.delivery_date)
                                        showLateIcon = true
                                    }
    
                                    if (order.paid) {
                                        paymentStatus = tr('paid')
                                    }
    
                                    if (order.paid_late) {
                                        paymentStatus = `${tr('late_payment')}: ` + sqlToDateInput(order.due_date)
                                    }
    
                                    return (
                                        <Link to={ "/storage/purchase/order/" + order.number } key={ index } className="listItem crmCompany">
                                            <strong>{ order.name }</strong><br />
                                            {showLateIcon ? <SvgIcon className="small-inline" icon="calendar-times" fill="red" /> : null}
                                            {order.paid_late ? <SvgIcon className="small-inline" icon="money-bill-alt" fill="orange" /> : null}{' '}
                                            <small>{ orderStatus || order.status_title } {order.due_date ? <span>, {paymentStatus}</span> : null} </small>
    
                                            <SvgIcon className="arrow" icon="arrow-right" type="solid" />
                                        </Link> 
                                    );
                                }                         
                            })}
    
                            { block.data.active_purchase_orders.length == 0 && 
                                <div className="apMsg">{ tr('no_purchase_orders') }</div>
                            }
                        </div>
                );
            case "globaladmin.status":
                let data = block.data;
                return (
                    <div className="list ">
                        <div className="text">
                            <strong>{data.disk_used + " Gt/" + data.disk_total + " Gt"}</strong><br />
                        </div>
                        <ApProgressbar
                            value={data.percent}
                            errorLimit={data.goal}
                            warningLimit={data.goal + 0.1}
                            tooltip={<div>
                                <strong>{tr('used')} {data.percent.toFixed(2) * 100}%</strong><br />
                                {/* <small>{(offset > 0 ? "edellä " + offset + "%" : "jäljessä " + Math.abs(offset) + "%")}</small> */}
                            </div>}
                        />
                    </div>
                );
            case 'calendar':
                return (
                    <div className='padding'>
                        <ApCalendar
                            readOnly={true}
                        />
                    </div>
                );
            default: 
                return (
                    <div className="padding">
                        <div className="apMsg">
                            <strong>Virheellinen pyyntö</strong>
                            <p>{ JSON.stringify( block ) }</p>
                        </div>
                    </div>
                );
        }
    }

    renderBlockHeaderContent(block) {
        if (block.id === 'calendar') {
            return <div className='justify-space-between'>
                <div><SvgIcon icon={block.icon} type="solid" /> {tr(block.header)}</div>
                <ApButton
                    color="green"
                    onClick={() => { window.emitter.emit('goTo', { pathname: "/timetracking/calendar" }) }}
                    size='small'
                >
                    {tr('go_to_calendar')} <SvgIcon icon="arrow-right" type="solid" />
                </ApButton>
            </div>
        }

        return <>
            <SvgIcon icon={block.icon} type="solid" />
            {tr(block.header)}
        </>
    }

    renderNewsItemFiles() {
        const newsItemHasFiles = this.state.currentNews && 
            Array.isArray(this.state.currentNews.files) && 
            this.state.currentNews.files.length > 0;

        if (!newsItemHasFiles) return null;

        return this.state.currentNews.files.map(file => {
            return <div key={file.id}>
                <ApInputStack gap="0">
                    <ApAddon>
                        <div
                            onClick={() => { getFileById(file.file_id, file.name) }}
                            className="pointer"
                        >
                            <SvgIcon
                                className="pointer"
                                icon="file"
                                type="solid"
                            />
                            {file.name}
                        </div>
                    </ApAddon>
                </ApInputStack>
            </div>
        })
    }

    render() 
    {
        const user = auth.getUser();
        const stats = this.getStats();

        return (
            <div id="Home">
                <div className="apBox">

                    <div className="apBoxHeader">
                        <div className={this.state.news && this.state.news.length < 2 ? "homeHeader" : "homeHeader headerOver1"}>
                        <div style={{minWidth: 200}}>
                            <h1>{ tr('welcome') }, { user.first_name }</h1>
                            { user.last_login && <p>{ tr('last_login') } { moment( user.last_login ).locale(currentLang()).format('LLL') }</p> }
                        </div>
                        
                        <div className="stats newscontainer">
                        {this.state.news && !this.state.loading ?
                           this.state.news.map((item, index) => {
                            return (
                                <div onClick={() => this.toggleNewsModal(item, true)} className={this.state.news && this.state.news.length < 2 ? "stat news" : "stat news newsOver1"} key={index}>
                                    <SvgIcon icon="info-circle" fill="#0099CC" type="solid" />
                                    <div className="value">{item.header.length > 20 ? item.header.slice(0,20) + "..." : item.header}</div>
                                    <div>{item.content ? (item.content.length > 20 ? item.content.slice(0,20) + "..." : item.content) : null}</div>
                                </div>
                            )
                        })
                        :
                        <div className="apLoader"></div>
                        }
                        </div>
                        

                        </div>
                    </div>

                    <div className="padding">

                        { this.state.loading && 
                            <div className="apMsg">
                                <div className="apInlineLoader"></div>
                            </div>
                        }

                        { stats && 
                            <div className="stats">
                                { stats.map( ( stat, index ) => {
                                    return (
                                        <div className={ "stat" + ( !stat.loaded ? " loading" : "" ) } key={ index }>

                                            { !stat.loaded && <div className="apLoader"></div> }

                                            <SvgIcon icon={ stat.icon } type="solid" />
                                            <div className="title">{ tr(stat.title) }</div>
                                            <div className="value">{ stat.loaded ? stat.value : "..." } { stat.unit && <small className="unit">{ stat.unit }</small> }</div>
                                            { stat.info && stat.subtitle && <div className="info">{ stat.info } { tr(stat.subtitle) }</div> }
                                        </div>
                                    )
                                })}
                            </div>
                        }                     
                       
                    </div>

                </div>

                { this.state.blocks && this.state.blocks.map( block => {

                    if( !block.icon || !block.header )
                        return false;

                    return (
                        <div className={ "apBox block" + ( !block.loaded ? " loading" : "" ) } key={ block.id }>
                            <div className="apBoxHeader">
                                <h3>
                                    { this.renderBlockHeaderContent(block) }
                                </h3>
                            </div>
                            <div className="blockContent">
                                { block.loaded ? this.renderBlockContent( block ) : <div className="apLoader"></div> }
                            </div>
                        </div>
                    );
                })}

            <ApModal
                show={this.state.showNewsModal}
                className="narrow"
                backdrop="static"
                header={ 
                <div className="padding-small">
                    <h4>{this.state.currentNews ? this.state.currentNews.header : null}</h4>
                </div>
                }
                body={
                <div className="padding" style={{ marginTop: 10, whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>
                    {this.state.currentNews ? (this.state.currentNews.content ? this.state.currentNews.content : "-") : null}<br /><br />
                    {this.state.mainNews ?
                        <small>{this.state.currentNews ? moment( this.state.currentNews.created_at ).locale(currentLang()).format('LLL') : null}</small>
                        :
                        <small>{this.state.currentNews ? (this.state.currentNews.full_name + ", " + moment( this.state.currentNews.created_at ).locale(currentLang()).format('LLL')) : null}</small> 
                    }
                    {this.renderNewsItemFiles()}
                </div>
                }
                footer={
                    <div style={{padding: '1em'}}>
                        {this.state.mainNews ? 
                        <ApButton
                        style={{float: 'right'}}
                        className="save"
                        color="blue"
                        onClick={() => this.toggleNewsModal(null)}
                        >
                            <SvgIcon icon="check" type="solid" />
                            { tr('continue') }
                        </ApButton>
                        :
                        <ApButton
                        style={{float: 'right'}}
                        className="save"
                        color="blue"
                        onClick={() => this.handleCompanyNewsModal(this.state.currentNews)}
                        >
                            <SvgIcon icon="check" type="solid" />
                            { tr('continue') }
                        </ApButton>
                        }
                        {this.state.mainNews ? 
                        <ApButton
                        style={{float: 'left'}}
                        className="save"
                        color="green"
                        onClick={() => this.markAsRead(this.state.mainNews)}
                        >
                            <SvgIcon icon="envelope-open" type="solid" />
                            { tr('mark_as_read') }
                        </ApButton>
                        :
                        this.state.currentNews && !this.state.currentNews.read ?
                        <ApButton
                        style={{float: 'left'}}
                        className="save"
                        color="green"
                        onClick={() => this.markAsRead()}
                        >
                            <SvgIcon icon="envelope-open" type="solid" />
                            { tr('mark_as_read') }
                        </ApButton>
                        :
                        null
                        }
                    
                    </div>
                }
				/>

            </div>
        );
    }
}

const mapStateToProps = ( state ) => {
    return {
        apTimer: state.apTimer,
		apTimetrackingSettings: state.apTimetrackingSettings,
        apProjectSettings: state.apProjectSettings,
    };
};
  
export default connect(mapStateToProps)(Home);
