/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';

import api from 'services/Api/Api.js';
//import auth from 'services/Authed/Authed.js';

import './SubordinatesOverview.css';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApOptionInput from 'common/ApOptionInput/ApOptionInput.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';

import { statusText } from  'modules/Timetracking/TimetrackingHelpers.js';

import { groupify, sqlToDateInput, tr, currentLang, keyExists } from 'services/Helpers/Helpers.js';
import ApButton from 'common/ApButton/ApButton';

export default class SubordinatesOverview extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            date_start: moment().startOf('week').subtract(2, 'weeks').format('YYYY-MM-DD'),
            date_end: moment().endOf('week').format('YYYY-MM-DD'),
            entries: [],
            days: [],
            users: [],
            subordinates: null,
            loadingElement: false,
            selectedCells: [],
            usersWithoutEntries: [],
            badgeStyles: {
                open: {
                    icon: "pen",
                    color: "gray",
                    info: tr('open')
                },
                submitted: {
                    icon: "paper-plane",
                    color: "blue",
                    info: tr('sent')
                },
                rejected: {
                    icon: "times",
                    color: "red",
                    info: tr('rejected')
                },
                approved: {
                    icon: "check",
                    color: "green",
                    info: tr('accepted')
                },
                confirmed: {
                    icon: "check-double",
                    color: "green",
                    info: tr('confirmed')
                },
                payrolled: {
                    icon: "hand-holding-usd",
                    color: "teal",
                    info: tr('payrolled')
                },
                paid: {
                    icon: "wallet",
                    color: "teal",
                    info: tr('paid')
                },
            },
            deleteConfirmModal: {},

        };
        autoBind(this); 
    }

    componentDidMount() 
    {

        this.getSubordinatesCount();
        this.getEntries();
        
    }

    componentDidUpdate(prevProps) {
        if (prevProps.changes !== this.props.changes) {
            const entries = this.state.entries.map(entry => {
                const foundEntry = this.props.changes?.find(change => change.id === entry.id );
                return foundEntry ? foundEntry : entry;
            })
            this.setState({entries})
        }
    }

    getEntries()
    {
        if( !this.state.date_start || !this.state.date_end )
            return false;

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'timetracking/subordinatesoverview/entries',
            data: { 
                date_start: this.state.date_start,
                date_end: this.state.date_end,
            },
            errorPopper: tr('get_error')
        }).then(( response ) => {
            if (response.users) {
                response.users.sort( (a,b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                    return 0;
                });
            }
            if (response.usersWithoutEntries) {
                response.usersWithoutEntries.sort( (a,b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                    return 0;
                });
            }
            this.setState({
                loading: false,
                entries: response.entries,
                days: response.days,
                users: response.users,
                selectedCells: [],
                usersWithoutEntries: response.usersWithoutEntries
            });

        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    getSubordinatesCount()
    {
        this.setState({ loadingElement: true });
        api({
            method: 'get',
            url: 'currentUser/getSubordinatesCount',
        }).then(( response ) => {
            this.setState({
                loadingElement: false,
                subordinates: response,
            });
        }).catch( ( error ) => {
            this.setState({ loadingElement: false });
        });
    }

    getDates()
    {
        var dates = [ this.state.date_start ];

        var currDate = moment( this.state.date_start ).startOf('day');
        var lastDate = moment( this.state.date_end ).startOf('day');

        while( currDate.add( 1, 'days' ).diff( lastDate ) <= 0 ) {
            dates.push( currDate.clone().format('YYYY-MM-DD') );
        }

        return dates;
    }

    isCellSelected( date, user )
    {
        const id = date + "/" + user.id;
        return this.state.selectedCells.findIndex( s => s === id ) != -1;
    }

    toggleSelection( date, user )
    {
        const entries = this.state.entries.filter( e => e.user_id === user.id && e.date === date );
        const day = this.state.days?.find( d => d.user_id === user.id && d.date === date );

        if( !day && entries.length == 0 )
            return false;

        if( entries.findIndex( e => [ "approved", "confirmed", "payrolled", "paid" ].indexOf( e.status ) != -1 ) != -1 )
            return false;

        const id = date + "/" + user.id;
        let selectedCells = this.state.selectedCells.slice();

        const index = selectedCells.findIndex( s => s === id );
        if( index == -1 )
            selectedCells.push( id );
        else 
            selectedCells.splice( index, 1 );
        
        this.setState({ selectedCells });
    }

    eraseSelected()
    {
        let days = {};

        // Group selection by date 
        this.state.selectedCells.forEach( c => {
            const cell = c.split('/');
            if( !(cell[0] in days) )
                days[ cell[0] ] = [];
            
            days[ cell[0] ].push( parseInt( cell[1] ) );
        });

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'timetracking/subordinatesoverview/erase',
            data: { days: days },
            errorPopper: tr('format_entries_error')
        }).then(( response ) => {
            this.getEntries();
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    renderCell( date, user, index )
    {
        const entries = this.state.entries.filter( e => e.user_id === user.id && e.date === date );
        const day = this.state.days?.find( d => d.user_id === user.id && d.date === date );

        if( entries.length === 0 )
        {
            if( day )
            {
                return <SvgIcon className="lockedDay" icon="lock" type="solid" />
            }
            return null;
        }

        const groupified = groupify( entries, "status" );
        let statuses = [];
        const notApproved = new Set();
        Object.keys( groupified ).forEach( status => {
            statuses.push({
                status: status,
                entries: groupified[ status ].length,
                hours: groupified[ status ].reduce( (a, b) => a + ( parseFloat( b.total_hours ) || 0 ), 0 ),
            });
            if (status === 'submitted') {
                groupified[status].forEach(entry => {
                    if (Array.isArray(entry.entry_hour_approvers)) {
                        entry.entry_hour_approvers.forEach(entryApprover => {
                            if (!entryApprover.approved) {
                                if (keyExists(entryApprover, 'person_detail.full_name',true)) {
                                    notApproved.add(entryApprover.person_detail.full_name);
                                }
                            }
                        });
                    }
                });
            }
        });
        const tooltip = (
            <div>
                <strong>{ user.name } - { sqlToDateInput( date ) }</strong><br />
                { tr('entries') }: <br />
                { statuses.map( ( s, index ) => {
                    return (
                        <div key={ index }>
                            { statusText( s.status ) }: { s.entries } { tr('pcs') } ({ isNaN(s.hours) ? null : s.hours.toFixed(2) } h)
                        </div>
                    );
                })}
                {notApproved.size > 0 &&
                <div>
                    {tr('missing_approvals')}
                    {Array.from(notApproved).map((approver, index) => 
                        <div key={index}>{approver}</div>
                    )}
                </div>
                }
            </div>
        );

        let color = "";
        let icon = "question";
        let approvedClass = '';
        let badgeStyles = this.state.badgeStyles;
        if( statuses.length === 1 && statuses[0].status in badgeStyles )
        {
            if(statuses[0].status == 'approved')
                approvedClass = ' confirmed';
            color = badgeStyles[ statuses[0].status ].color;
            icon = badgeStyles[ statuses[0].status ].icon;
        }
        
        else if( statuses.findIndex( s => s.status === "rejected" ) != -1 )
        {
            color = badgeStyles.rejected.color;
            icon = badgeStyles.rejected.icon;
        }
        else if( statuses.findIndex( s => s.status === "submitted" ) != -1 )
        {
            color = badgeStyles.submitted.color;
            icon = badgeStyles.submitted.icon;
        }
        
        return (
            <ApTooltip position={index == 0 ? 'bottom' : 'top'} text={ tooltip }>
                <span className={ "apBadge " + color + approvedClass }>
                    <SvgIcon className="small-inline" icon={ icon } type="solid" />
                    { entries.length }
                </span>
            </ApTooltip>
        );
    }

    renderTotalCell( user )
    {
        const entries = this.state.entries.filter( e => e.user_id === user.id );
        const totalHours = entries.reduce( (a, b) => a + ( parseFloat( b.total_hours ) || 0 ), 0 );

        return (
            <span>{ entries.length } { tr('pcs') } ({ totalHours.toFixed(2) } h)</span>
        );
    }

    render() 
    {

        const dates = this.getDates();

        const badges = Object.entries(this.state.badgeStyles)
  
        let element;
        if (this.state.subordinates && !this.state.loadingElement) {
            element = 
        <div className="padding">
            <ApInputStack gap="0">
                <ApInput
                    width="180"
                    type="datetimeV2"
                    id="date_start"
                    name="date_start"
                    value={ this.state.date_start || '' }
                    onChange={ (e) => this.setState({ date_start: e }, this.getEntries ) }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                    clearable
                    weekNumbers
                />
                <ApAddon width="20" noLeftBorder noRightBorder> - </ApAddon>
                <ApInput
                    width="180"
                    type="datetimeV2"
                    id="date_end"
                    name="date_end"
                    value={ this.state.date_end || '' }
                    onChange={ (e) => this.setState({ date_end: e }, this.getEntries ) }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                    clearable
                    weekNumbers
                />
                <ApAddon custom gapLeft="10">

                    <ApOptionInput
                        value={[ this.state.date_start, this.state.date_end ]}
                        onChange={ ( values ) => { this.setState({ date_start: values[0], date_end: values[1] }, this.getEntries ) } }
                        options={[ "month-3", "month-2", "month-1", "month" ]}
                    />

                    <ApOptionInput
                        value={[ this.state.date_start, this.state.date_end ]}
                        onChange={ ( values ) => { this.setState({ date_start: values[0], date_end: values[1] }, this.getEntries ) } }
                        options={[ "week-3", "week-2", "week-1", "week" ]}
                    />

                </ApAddon>
            </ApInputStack>

            <div className="guides">
                { badges.map(badge =>
                    <div className="guide" key={badge[1].icon} style={{paddingRight: 20}}>
                        <span className={ "apBadge " + badge[1].color + `${badge[0] == 'approved' ? ' confirmed' : ''}` } style={{marginRight: 5}}>
                            <SvgIcon className="small-inline" icon={ badge[1].icon } type="solid" />
                        </span>
                        { badge[1].info }
                    </div>
                )}
            </div>
            
        </div>
        } else if (this.state.subordinates === 0 && !this.state.loadingElement) {
            element = 
            <div className="padding">
                <div>{ tr('user_not_supervisor') }</div>
            </div>
        } else {
            element = 
            <div className="padding">
                <div className="apLoader"></div>
            </div>
        }

        return (
            <div className="apBox" id="pageTimetrackingOverview">
                <div className="apBoxHeader">
                    <p>{ tr('subordinates_overview_info') }</p> 
                </div>
                    {element}            
                { (this.state.users.length > 0 || this.state.usersWithoutEntries.length > 0) &&
                    <div className="entries padding">
                        <div className="scroller">
                            <table className={ "entryTable" + ( this.state.loading ? " loading" : "" ) }>
                                <thead>
                                    <tr>
                                        <th className="user"></th>
                                        { dates.map( d => 
                                            <th key={d} className="date">
                                                { moment( d ).locale(currentLang()).format('dd') }<br />
                                            <small>{ moment( d ).format('D.M') }</small>
                                            </th> 
                                        )}
                                        <th className="totals">{ tr('in_total') }</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { this.state.users.map( (user, index) =>
                                    <tr key={user.id}>
                                        <td className="user">{ user.name }</td>
                                        { dates.map( d => {
                                            return (
                                                <td 
                                                    key={d}
                                                    className={ "date" + ( this.isCellSelected( d, user ) ? " selected" : "" ) } 
                                                    onClick={ () => this.toggleSelection( d, user ) }
                                                >
                                                    { this.renderCell( d, user, index ) }
                                                </td>
                                            );
                                        })}
                                        <td className="totals">{ this.renderTotalCell( user ) }</td>
                                    </tr>
                                )}
                                { this.state.usersWithoutEntries.map( user => 
                                    <tr key={user.id}>
                                        <td className="user">{ user.name } ({tr('no_entries')})</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                        <div className="apButtonGroup">
                            <ApButton
                                color="white"
                                size="small"
                                disabled={ this.state.selectedCells.length == 0 }
                                onClick={ () => this.setState({ selectedCells: [] }) }
                            >
                                <SvgIcon icon="ban" type="solid" />
                                { tr('clear_selection') } ({ this.state.selectedCells.length })
                            </ApButton>            
                            <ApButton
                                color="red"
                                size="small"
                                disabled={ this.state.selectedCells.length == 0 }
                                // onClick={ this.eraseSelected }
                                onClick={ () => {this.setState({deleteConfirmModal: { show: true}}) } }
                            >
                                <SvgIcon icon="eraser" type="solid" />
                                { tr('format_selected_days') }
                            </ApButton>

                            <ApConfirm
                                show={ Boolean( this.state.deleteConfirmModal.show ) }
                                onClose={ () => { this.setState( { deleteConfirmModal: {} } )  } } 
                            
                                onConfirm = { this.eraseSelected }
                                header={ tr('confirm_format') }
                                body={ tr('confirm_format_info') }
                              
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }
}
