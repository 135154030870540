import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import Notfound  from 'layout/Notfound/Notfound';
import 'moment/locale/fi';

import auth from 'services/Authed/Authed.js';
import api from 'services/Api/Api.js';

import ApConfirm from 'common/ApConfirm/ApConfirm.js'
import ApModal from 'common/ApModal/ApModal.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
import ApReactTable, { colPreset } from 'common/ApReactTable/ApReactTable.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import { Collapse } from 'react-bootstrap';

import DepthInput from './DepthInput/DepthInput.js';
import './Reports.css';

import { keyExists, hasPermissions, validateEmail, errorPopper, tr, capitalize, formatCurrency, getBillingEntryTypes } from  'services/Helpers/Helpers.js';
import { profitPercentToSellPrice } from 'services/Helpers/Helpers.js';

const permissions = 'reports';

const types = [
    { value: 'none', label: `-- ${tr('select').toUpperCase()} --` },
    { value: 'tt', label: tr('timetracking_entries'),
        splitOptions: [
            { value: 'project', label: tr('projects'), depth: true },
            { value: 'user', label: tr('persons') },
            { value: 'description', label: tr('description') },
            { value: 'description_long', label: tr('additional_information') },
            { value: 'component', label: tr('works') },
            { value: 'absence', label: tr('absence_reasons') },
            { value: 'date', label: tr('days_count') },
            { value: 'customer_work_number', label: tr('customer_work_numbers') },
            { value: 'customer', label: tr('customer') },
            { value: 'allowance', label: tr('allowance') },
            { value: 'cost_center', label: tr('cost_center') },
            { value: 'status', label: tr('entry_status') },
            { value: 'salary_extras', label: tr('fees_supplements') },
            { value: 'vehicle_type', label: tr('vehicle_type') },
            { value: 'timer_in', label: tr('timer_save_points')},
        ],
        columnOptions: [
            { value: 'hour_rows', label: tr('hours') },
            { value: 'project_expense_chargeables', label: tr('billables') },
            { value: 'project_component_installations', label: tr('installations') },
            { value: 'absence', label: tr('absence_amount') },
            { value: 'flextime', label: tr('flexitime_count') },
            { value: 'hours', label: tr('travel_hours') },
            { value: 'distance', label: tr('kilometers') },
            { value: 'compensation_total', label: tr('compensation') },
            { value: 'allowance_sum', label: tr('allowance_sum') },
            { value: 'money_expense', label: tr('expense_compensation') },
            { value: 'salary_extra_name', label: `${tr('fees_supplements')} (${tr('name')})` },
            { value: 'salary_extra_price', label: `${tr('fees_supplements')} (${tr('price')})` },
            { value: 'address', label: tr('address') },
            { value: 'distance_to_project', label: tr('distance_to_project') },
        ],
    },
    { value: 'project', label: tr('projects'),
        splitOptions: [
            { value: 'project', label: tr('projects'), depth: true },
            { value: 'work', label: tr('work')},
            { value: 'item', label: tr('components_alt')},
            { value: 'expense', label: tr('costs')},
        ],

        columnOptions: [
            { value: "work_alloc", label: tr('work_allocated') },
            { value: "work_goal", label: tr('work_goal') },
            { value: "work_entry", label: tr('work_recorded') },
            { value: "work_remaining", label: tr('work_remaining') },
            { value: "work_alloc_used_percent", label: tr('work_used_percentage') },
            { value: "work_billing_price", label: `${tr('work')} ${tr('billing_price').toLowerCase()} (${tr('hourly_billing').toLowerCase()})` },
            { value: "work_alloc_price", label: `${tr('work_cost')} (${tr('allocation').toLowerCase()})` },
            { value: "work_entry_price", label: `${tr('work_cost')} (${tr('recorded').toLowerCase()})` },
            { value: "work_alloc_entry_difference", label: `${tr('work_costs_difference')} (${tr('recorded').toLowerCase()} - ${tr('allocation').toLowerCase()})` },
            { value: "work_estimate_percent", label: `${tr('work_estimate')} %` },
            { value: "work_estimate_count", label: tr('work_estimated_hours') },
            { value: "work_entry_price_total", label: `${tr('work_cost')} (${tr('all_personnel_costs').toLowerCase()})` },

            { value: "work_future_difference_percent", label: `${tr('work')} ${tr('over_or_under')} %` },

            { value: "item_alloc", label: tr('component_allocated') },
            { value: "item_installed_count", label: tr('component_installed') },
            { value: "item_installed_percent", label: `${tr('component_installed')} %` },
            { value: "item_installed_price", label: `${tr('component_po_price')}` },
            { value: "offer_po_price", label: `${tr('component_offer_po_price')}` },
            { value: "offer_price", label: `${tr('component_offer_price')}` },

            { value: "expense_alloc", label: tr('costs_allocated') },
            { value: "expense_chargeable", label: tr('costs_billable') },

            { value: "po_price_total", label: `${tr('purchase_order')} - ${tr('total_sum')}` },
            { value: "po_item_price", label: `${tr('purchase_order')} - ${tr('component_price_per')}` },
            { value: "po_item_count", label:  `${tr('purchase_order')} - ${tr('components_purchased')}` },
            { value: "po_item_total_price", label:`${tr('purchase_order')} - ${tr('components_total_sum')}` },

            { value: "sales_order_billed", label: `${tr('sales_order')} - ${tr('billed')}` },
            { value: "sales_order_paid", label: `${tr('sales_order')} - ${tr('paid')}` },
            { value: "sales_order_total", label: `${tr('sales_order')} - ${tr('total_sum')}` },
            
            { value: "sales_margin", label: `${tr('sales_margin')}` },
            { value: "sales_margin_percentage", label: `${tr('sales_margin_percentage')}` },

            { value: 'cost_center', label: tr('cost_center') },

            { value: 'po_by_type', label: `${tr('purchase_order')} - ${tr('by_type')}` },
            { value: 'tt_entries_by_type', label: `${tr('work_cost')} - ${tr('by_type')}` },

            { value: 'storage_transfer', label: `${tr('storage_transfers')} (${tr('cost')})` },
        ],

    },

    { value: 'project_installation_entries', label: tr('project_installations'),
        splitOptions: [
            { value: 'component', label: tr('storage_component') },
            { value: 'project', label: tr('projects'), depth: true },
            { value: 'user', label: tr('persons') },
        ],
        columnOptions: [
            { value: "hours", label: tr('installation_time') },
            { value: "quantity", label: tr('amount') },
            { value: "trackingunits", label: tr('monitoring_units') },
            { value: 'cost_center', label: tr('cost_center') },
            { value: 'meters', label: tr('meters')},
            { value: 'efficiency', label: tr('efficiency') },
        ],

    },
    /*
    { value: 'project_results', label: tr('project_result_report'),
        typeOptions: [
            { value: 'overview', label: tr('project_report_overview') },
            { value: 'component_view', label: tr('component_separation') },
        ],
        splitOptions: [
            { value: 'components', label: tr('components_alt') },
            { value: 'work', label: tr('works') },
            { value: 'expense', label: tr('costs') },
            { value: 'difference', label: tr('difference') },
            { value: 'profit', label: tr('gross_profit') }, 
            { value: 'payment_data', label: tr('payment') }
        ],
        columnOptions: [
            { value: 'project_name', label: tr('project_name') },
            { value: "project_code", label: tr('project_code') },
            { value: "item_alloc", label: tr('component_allocated') },
            { value: "item_installed_count", label: tr('component_installed') },
            { value: "item_installed_percent", label: `${tr('component_installed')} %` },
            { value: "item_types", label: tr('type') },
            { value: "total_sum", label: tr('sum') },
            { value: "profit_perc", label: tr('gross_profit_percent') },
            { value: "profit_singular", label: tr('single_gross_profit') },
            { value: "profit_all", label: tr('total_gross_profit') }
        ],

    },*/

    { value: 'clients', label: tr('customers'),
        splitOptions: [
            {value: 'crm_company', label: tr('company')},
            {value: 'contacts',  label: tr('contact_persons')},
            {value: 'client_group', label: tr('customer_groups')},
            {value: 'account_manager',  label: tr('customer_manager')},
        ],
        columnOptions: [
            { value: "taxnumber", label: tr('business_id') },
            { value: "turnover", label: tr('turnover') },
            { value: "employs", label: tr('personnel') },
            //{ value: 'city', label: tr('locality')},
            { value: 'account_manager',  label: tr('customer_manager') },
            { value: 'industry', label: tr('industry')},
            { value: "email", label: tr('email') },
            { value: "phone", label: tr('phone') },
            { value: "website", label: tr('website') },
            { value: "title", label: tr('title') },
            { value: "contact_email", label: tr('contact_email') },
            { value: "contact_phones", label: tr('contact_phones') },
            //{ value: "zip", label: tr('postal_code') },
            //{value: 'contact_group', label: tr('contact_groups')},
            { value: 'cost_center', label: tr('cost_center') },
            { value: 'project_searches', label: tr('income') },
            { value: 'income_percentages', label: tr('income_percentages') },
        ],

    },
    { 
        value: 'crm_events', label: tr('crm_entries'),
        splitOptions: [
            {value: 'event', label: tr('event')},
            {value: 'crmCompany',  label: tr('customer')},
            {value: "user", label: tr('person')},
            { value: "type", label: tr('type') },
        ],
        columnOptions: [
            { value: "occurred_at", label: tr('occurred_at') },
            { value: 'description', label: tr('description') },
            { value: 'action', label: tr('actions') },
            { value: 'reminded_at', label: tr('reminder') },
            { value: 'further_actions', label: tr('further_actions') },
            { value: 'count', label: tr('amount') },
        ],
    },
    { value: 'cash_sale_report', label: tr('checkout_sale_report'),
        splitOptions: [
            {value: 'cash_report', label: tr('cash_sale')},
            {value: 'card_report', label: tr('card_sale')},
        ],
        columnOptions: [
            { value: "price", label: tr('amount') },
        ],

    },
    { value: 'tt_travel', label: tr('travel_records'),
        splitOptions: [
            { value: 'project', label: tr('projects'), depth: true },
            { value: 'user', label: tr('persons') },
            { value: 'date', label: tr('days_count') },
            { value: 'customer', label: tr('customer') },
            { value: 'route', label: tr('route') },
            { value: 'vehicle_type', label: tr('vehicle_type') },
        ],
        columnOptions: [
            { value: 'hours', label: tr('travel_hours') },
            { value: 'distance', label: tr('kilometers') },
            { value: 'compensation_total', label: tr('compensation') },
        ],
    },

    { value: 'tt_expense', label: tr('expense_compensation'),
        splitOptions: [
            { value: 'project', label: tr('projects'), depth: true },
            { value: 'user', label: tr('persons') },
            { value: 'date', label: tr('days_count') },
            { value: 'customer', label: tr('customer') },
            { value: 'event', label: tr('event') },
            { value: 'event_details', label: tr('event_information') },
        ],
        columnOptions: [
            { value: 'money_expense', label: tr('cost') },
        ],
    },
    /*
    { value: 'storage_report_old', label: 'vanha varastoraportti',
        splitOptions: [
            { value: 'prices', label: tr('prices') },
            { value: 'events', label: tr('events') }
            //{ value: 'inventory', label: 'Inventaario' }
        ],
        columnOptions: [
            { value: 'storage_name', label: tr('storage_name') },
            { value: 'storage_code', label: tr('storage_code') },
            { value: 'storage_type', label: tr('storage_type') },
            { value: 'unit', label: tr('unit')},
            { value: "balance", label: tr('balance') },
            { value: "balance_free", label: tr('incoming_balance_changes') },
            { value: "storage_value", label: tr('storage_value') },
            { value: "event_type", label: tr('event_type') },
            { value: "event_date", label: tr('event_date') },
            { value: "event_recipient", label: tr('event_recipient') },
            { value: "event_id", label: tr('event_id') },
            { value: "event_link", label: tr('event_link') }
        ],
    },
    */
    { value: 'storage_report_new', label: tr('storage_report'),
    splitOptions: [
        { value: 'location', label: "Varastopaikat"},
        { value: 'component', label: "Komponentit" },
        //{ value: 'prices', label: tr('prices') },
        //{ value: 'events', label: tr('events') }
        //{ value: 'inventory', label: 'Inventaario' }
    ],
    columnOptions: [
        { value: 'component_name', label: tr('component_name') },
        { value: 'storage_name', label: tr('storage_name') },
        { value: 'storage_code', label: tr('storage_code') },
        { value: 'storage_type', label: tr('storage_type') },
        { value: 'unit', label: tr('unit')},
        { value: 'components_price', label: 'varastopaikan arvo' },
        { value: 'stock_price_buy', label: tr('components_price') },
        { value: 'stock_price_sell', label: tr('components_price_sell') },
        { value: 'avg_price_buy', label: tr('storage_avg_price_buy') },
        { value: 'balance', label: tr('storage_balance') },
        { value: 'balance_available', label: tr('available_balance')  },
        { value: 'price', label: tr('price')  },
        { value: 'price_sell', label: tr('selling_price') },
        { value: 'last_price', label: tr('last_purchase_price')  },
        { value: 'avg_price', label: tr('average_purchase_price')  },
        { value: 'last_inventory', label: tr('last_inventory')  },


        /*{ value: 'unit', label: tr('unit')},
        { value: "balance", label: tr('balance') },
        { value: "balance_free", label: tr('incoming_balance_changes') },
        { value: "storage_value", label: tr('storage_value') },
        { value: "event_type", label: tr('event_type') },
        { value: "event_date", label: tr('event_date') },
        { value: "event_recipient", label: tr('event_recipient') },
        { value: "event_id", label: tr('event_id') },
        { value: "event_link", label: tr('event_link') }*/
    ],
},
    { value: 'clock_report', label: tr('clock_report'),
        splitOptions: [

        ],
        columnOptions: [
            { value: 'date', label: tr('date') },
            { value: 'type', label: tr('type') },
            { value: 'location', label: tr('location') },
            { value: 'timestamp', label: tr('event_date') },
            { value: 'distance_to_project', label: tr('distance_to_project') },
            { value: 'customer_work_number', label: tr('customer_work_number') },
            { value: 'description', label: tr('description') },
        ],
    },
    /*
    { value: 'component', label: 'Varastonimikkeet',
        splitOptions: [
            { value: 'component', label: 'Komponentit' },
        ],
        columnOptions: [
        ],
    },
    */
    { 
        value: 'storage_inventory', label: tr('inventory'),
        splitOptions: [
            { value: 'location', label: "Varastopaikat"},
            { value: 'component', label: "Komponentit" },
        ],
        columnOptions: [
            { value: 'component_name', label: tr('component_name') },
            { value: 'unit', label: tr('unit')},
            { value: 'storage_name', label: tr('storage_name') },
            { value: 'storage_code', label: tr('storage_code') },
            { value: 'storage_type', label: tr('storage_type') },
            { value: 'balance_before', label: tr('balance_before')  },
            { value: 'balance_change', label: tr('balance_change')  },
            { value: 'balance_after', label: tr('balance_after')  },
            { value: 'components_value_before', label: tr('components_value_before')  },
            { value: 'storage_value_change', label: tr('storage_value_change')  },
            { value: 'components_value_after', label: tr('components_value_after')  },
            { value : 'order_amount', label: tr('order_amount') },
            { value : 'other_changes_amount', label: tr('other_changes') },
            { value: 'last_inventory', label: tr('last_inventory')  },
            { value: 'date', label: tr('date')  },
        ],
    },
    {
        // myyntitilaukset
        value: 'sales_orders', label: tr('sales_orders'),
        splitOptions: [
            { value: 'order', label: tr('invoice_number') },
            { value: 'customer', label: tr('customer') },
            { value: 'customer_manager', label: tr('customer_manager') },
            { value: 'project', label: tr('project'), depth: true},
            { value: 'project_manager', label: tr('project_manager') },
            { value: 'paid', label: tr('paid') },
            { value: 'cost_center', label: tr('cost_center') },
        ],
        columnOptions: [
            { value: 'date_of_billing', label: tr('date_of_billing') },
            { value: 'due_date', label: tr('due_date') },
            { value: 'delivery_date', label: tr('delivery_date') },
            { value: 'need_date', label: tr('day_of_need') },
            { value: 'amount', label: tr('amount') },
            { value: 'price_tax_free', label: tr('tax_free_price') },
            { value: 'price_total', label: tr('price_total') + ' (' + tr('inc_vat') + ')'  },
        ],
    },
    {
        // ostotilaukset
        value: 'purchase_orders', label: tr('purchase_orders'),
        splitOptions: [
            { value: 'po', label: tr('invoice_number') },
            { value: 'supplier', label: tr('supplier') },
            { value: 'customer_manager', label: tr('customer_manager') },
            { value: 'project', label: tr('project'), depth: true},
            { value: 'project_manager', label: tr('project_manager') },
            { value: 'paid', label: tr('paid') },
            { value: 'cost_center', label: tr('cost_center') },
        ],
        columnOptions: [
            { value: 'date_of_billing', label: tr('date_of_billing') },
            { value: 'due_date', label: tr('due_date') },
            { value: 'delivery_date', label: tr('delivery_date') },
            { value: 'need_date', label: tr('day_of_need') },
            { value: 'amount', label: tr('amount') },
            { value: 'price_total', label: tr('price_total') },
        ],
    },
    {
        // Laskutustyöt
        value: 'billing_entries', label: tr('billing_work'),
        splitOptions: [
            { value: 'project', label: tr('project'), depth: true},
            { value: 'customer', label: tr('customer') },
            { value: 'user', label: tr('person') },
            { value: 'type', label: tr('type') },
            { value: 'status', label: tr('status') },
            { value: 'cost_center', label: tr('cost_center') },
            { value: 'date', label: tr('date') },
            { value: 'customer_work_number', label: tr('customer_work_number') },
            { value: 'description', label: tr('storage_component') }, // Description used because allowances have no component_id
            { value: 'id', label: capitalize(tr('pluralize_entry'))},
        ],
        columnOptions: [
            { value: 'entry_description', label: tr('description') },
            { value: 'amount', label: tr('amount') },
            { value: 'unit', label: tr('unit') },
            { value: 'unit_price', label: tr('unit_price') },
            { value: 'price_total', label: tr('price_total') },
            // { value: 'project_name', label: tr('project') },
            // { value: 'project_code', label: tr('project_code') },
        ],
    },
    {
        // Kustannuspaikat
        value: 'cost_center', label: tr('cost_center'),
        splitOptions: [
            { value: 'cost_center', label: tr('cost_center'), depth: true },
            { value: 'group', label: tr('group') },
        ],
        columnOptions: [
            { value: 'order_components', label: tr('sales') },
            { value: 'order_components_vat', label: tr('sales') + ` (${tr('inc_vat')})` },
            { value: 'po_components', label: tr('purchases') },
            { value: 'timetracking_entries', label: tr('personnel_costs') },
            { value: 'income' , label: tr('income') },
        ],
    }
];

const dateModeOptions = [
    { value: 'none', label: tr('no_time_limit') },
    { value: 'dates', label: tr('time_period') },
    { value: 'week', label: capitalize(tr('week')) },
    { value: 'month', label: tr('month') },
    { value: 'year', label: tr('year') },
];

const ttStatustOptions = [
    { value: "suggested", label: tr('suggested') },
    { value: "open", label: tr('open2'), },
    { value: "submitted", label: tr('sent'), },
    { value: "rejected", label: tr('rejected'), },
    { value: "approved", label: tr('approved_by_supervisor'), },
    { value: "confirmed", label: tr('accepted'), },
    { value: "payrolled", label: tr('transferred_to_payment'), },
    { value: "paid", label: tr('paid'), },
];

const paidStatusOptions = [
    { value: "paid", label: tr('paid'), },
    { value: "not_paid", label: tr('not_paid') },
];

const billingEntryStatusOptions = [
    { value: "open", label: tr('open2'), },
    { value: 'pending', label: tr('waiting_for_approval1') },
    { value: 'rejected', label: tr('rejected') },
    { value: 'approved', label: tr('ready_for_invoicing') },
    { value: 'billed', label: tr('billed') },
    { value: 'not_billable', label: tr('not_billable') },
    {value: 'archived', label: tr('archived')},
];

class Reports extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            loading: false,

            data: [],
            related: {},
            companyIdentificators: null,

            // Saved forms
            forms: [],

            form: {},
            formSubmitted: null,

            formModal: {},
            formDelete: {},

            currencySign: auth.getCurrencySign(),
            location: null,
            totals: {},
        }

        this.currentYear = new Date().getFullYear();
        this.startYear = this.currentYear - 5;
        this.endYear = this.currentYear + 1;

        this.tabsRef = React.createRef();


        autoBind(this);
    }

    componentDidMount()
    {
        if( !hasPermissions( permissions ) ) return null;
        this.fetchSavedForms();
    }

    fetchSavedForms()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: `report/forms`,
            errorPopper: tr('get_error'),
        }).then(( response ) => {

            //console.log( 'get', response );

            const forms = response.map( item => {
                const type = types.find( f => f.value === item.options.type );
                item.options.type = type;

                if( item.options.splits )
                    item.options.splits = type.splitOptions.filter( f => item.options.splits.includes( f.value ) )

                if( item.options.columns )
                    item.options.columns = type.columnOptions.filter( f => item.options.columns.includes( f.value ) )

                if( item.options.autoTime )
                {
                    const mode = item.options.autoTime
                    item.options.dateMode = mode;

                    if( mode === 'dates' )
                    {
                        if( item.options.autoTimeDiff )
                            item.options.start = moment().add( item.options.autoTimeDiff, 'days').format('YYYY-MM-DD');
                        if( item.options.autoTimeDiff2 )
                            item.options.end = moment().add( item.options.autoTimeDiff2, 'days').format('YYYY-MM-DD');
                    }
                    else if( mode === 'week' )
                    {
                        const dMoment = moment().add( item.options.autoTimeDiff, 'weeks');
                        item.options.dateModeYear = dMoment.get('year');
                        item.options.dateModeValue = dMoment.get('week');
                        item.options.start = dMoment.startOf('week').format('YYYY-MM-DD');
                        item.options.end = dMoment.endOf('week').format('YYYY-MM-DD');
                    }
                    else if( mode === 'month' )
                    {
                        const dMoment = moment().add( item.options.autoTimeDiff, 'months');
                        item.options.dateModeYear = dMoment.get('year');
                        item.options.dateModeValue = dMoment.get('month');
                        item.options.start = dMoment.startOf('month').format('YYYY-MM-DD');
                        item.options.end = dMoment.endOf('month').format('YYYY-MM-DD');
                    }
                    else if( mode === 'year' )
                    {
                        const dMoment = moment().add( item.options.autoTimeDiff, 'years');
                        item.options.dateModeYear = dMoment.get('year');
                        item.options.start = dMoment.startOf('year').format('YYYY-MM-DD');
                        item.options.end = dMoment.endOf('year').format('YYYY-MM-DD');
                    }
                }
                return item;
            });

            this.setState({
                loading: false,
                forms: forms,
            });
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    getUniqueIdentificators() {
        api({
            method: 'get',
            url: `/report/identificators/get/unique`,
            errorPopper: tr('get_error'),
        }).then(( response ) => {
            let identificators = [], object;
            response.forEach(r => {
                object = { value: r, label: r};
                identificators.push(object);
            });

            this.setState({ 
                loading: false,
                companyIdentificators: identificators
            });

        }).catch( ( error ) => {
            console.warn(`Virhe tunnisteiden hakemisessa: ${error}`);
            this.setState({ loading: false });
        });
    }

    getSubmitData()
    {
        let data = {};
        const form = this.state.form;
        
        for (let key in form ) {
            switch (key) {
                case 'type':
                    data[ key ] = form[ key ].value;
                    break;
                case 'date':
                case 'start':
                case 'end':
                case 'depths':                
                    if( form[ key ] ) data[ key ] = form[ key ];
                    break;
                case 'ctype':
                    if( form[ key ] && form[ key ].length ) data[ key ] = form[ key ];
                    break;
                case 'splits':
                case 'columns':                
                case 'ttstatus':
                    data[ key ] = form[ key ].map( i => i.value );
                    break;
                case 'users':
                case 'project_managers':
                case 'components':
                case 'groups':
                case 'contacts':
                case 'projects':
                case 'project_status':
                case 'cost_centers':
                case 'cost_center_groups':
                    data[ key ] = form[ key ].map( i => i.id );
                    break;
                case 'project_types':
                    data[ key ] = form[ key ].map( i => i.code_prefix ); // code_prefix instead of id because sub projects have no type_id saved
                    break;
                case 'locality':
                    data[ key ] = form[ key ].map( i => i.city);
                    break;
                case 'industry':
                    data[ key ] = form[ key ].map( i => i.name);
                    break;
                case 'turnover':
                    data[ key ] = form[ key ];
                    break;
                case 'staff':
                    data[ key ] = form[ key ];
                    break;
                case 'offer':
                    data[ key ] = form[ key ];
                    break;
                case 'paidStatus':
                    data[ key ] = form[ key ].map( i => i.value );
                    break;
                case 'crmCompanies':
                    data[ key ] = form[ key ].map( i => i.id );
                    break;
                case 'billingEntryStatus':
                    data[ key ] = form[ key ].map( i => i.value );
                    break;
                default: break;
            }
        }

        // console.log( "Data: ", data );
        
        if (form.type.value == 'project_results') { 
            //Lisää vielä defaultit jotka tulee aina olla mukana

            //Katso sitten myös formin data
            if (form.searchType && form.searchType.length) {
                data['searchType'] = form.searchType;
            }

            if (form.project_status) {
                data['project_status'] = form.project_status;
            }
        } else if (form.type.value == 'project_installation_entries') {
            if (form.columns?.find(col => col.value === 'efficiency' || col.value === 'meters')) {
                if (!data.columns) {
                    data.columns = [];
                }
                if (!form.columns.find(col => col.value === 'hours')) {
                    data.columns.push('hours')
                }
                if (!form.columns.find(col => col.value === 'quantity')) {
                    data.columns.push('quantity')
                }
                data.columns.push('calculatable_values');
            }
        } else if (form.type.value == 'storage_report_new') {
            if (form.storage_type && form.storage_type.length) {
                data['storage_type'] = form.storage_type;
            }

            if (form.storages && form.storages.length) {
                data['storages'] = form.storages;
            }

            if (form.events_count) { data['events_count'] = form.events_count; }
            if (form.filtered_out) { data['filtered_out'] = form.filtered_out; }
        } else if ( form.type.value == 'storage_inventory' ) {
            if (form.storages && form.storages.length) {
                data['storages'] = form.storages;
            }
            if (!data.splits) {
                data.splits = [];
            }
            data.splits.push("inventory");
        }  else if (form.type.value == 'clock_report') {
            data.splits = [];
            data.splits.push("entries");
            data.splits.push("user");
        }
        if (!data.columns) {
            data.columns = [];
        }

        this.setState({ summary: null });
        return data;
    }

    selectForm( row )
    {
        let options = row.options;

        if (options.ttstatus!=undefined) {
            options.ttstatus = options.ttstatus.map( s => {
                let status = ttStatustOptions.find( o => o.value === s );
                return status;
            });
        }

        this.setState({
            form: options,
            formSubmitted: null,
            data: [],
            related: {},
        }, () => {
            if( this.tabsRef.current )
                this.tabsRef.current.changeTab( 1 );
        });
    }

    formSave()
    {

        const formModal = this.state.formModal;

        const data  = {
            name: formModal.name,
            id: this.state.formModal.id || undefined,
        }

        if( !data.id )
        {
            const form = this.state.form;
            data.options = this.getSubmitData();

            if( formModal.autoTime )
            {
                data.options.autoTime = formModal.autoTimeMode;
                data.options.autoTimeDiff = formModal.autoTimeDiff;
                if( formModal.autoTimeMode === 'dates' )
                    data.options.autoTimeDiff2 = formModal.autoTimeDiff2;
            }
            else if( form.dateMode )
            {
                data.options.dateMode = form.dateMode;
                data.options.dateModeYear = form.dateModeYear;
                data.options.dateModeValue = form.dateModeValue;
            }
        }

        // console.log('d', formModal );
        // console.log('Savemodalsave', data.options )

        this.setState({ loading: true });

        api({
            method: 'post',
            url: `report/form/save`,
            errorPopper: tr('save_error'),
            data: data,
        }).then(( response ) => {
            //console.log( 'Save', response );
            this.setState({
                loading: false,
                formModal: {}
            });
            this.fetchSavedForms();
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    formatResults(response) {
        const overView = this.state.form.searchType[0].value == 'overview';
        const data = overView ? this.formatOverView(response) : this.formatComponentView(response);
        return data;
    }
    
    formatComponentView(response) {
        let project_total = 0, offer_total = 0, profit_total = 0;
        let data = [];

        response.forEach(r => {
            data.push(r);
            if (r.type != "Lasku") { project_total += parseFloat(r.total_sum); }

            if (r.offer) { 
                const ind = r.offer;
                offer_total += ind.total_sum; 
                let val = profitPercentToSellPrice( ind.unit_price, ind.balance_percent );
                profit_total += parseFloat((val - ind.unit_price) * ind.count);
            } else {
                if (r.profit_sum) { profit_total += parseFloat(r.profit_sum); }
            }
        });

        let summary = {
            offer_total,
            project_total,
            profit_total
        };
       
        this.setState({ summary });
        return data;
    }

    formatOverView(response) {
        let project_total = 0, offer_total = 0, profit_total = 0;
        let data = [];

        response.forEach(r => {
            data.push(r);
            project_total += parseFloat(r.total_sum);
            offer_total += r.offer_sum;

            if (r.offer) {
                const ind = r.offer;
                let val = profitPercentToSellPrice( ind.unit_price, ind.balance_percent );
                profit_total += parseFloat((val - ind.unit_price) * ind.count);
            } else {
                if (r.profit_sum) { profit_total += parseFloat(r.profit_sum); }
            }
        });

        let summary = {
            offer_total,
            project_total,
            profit_total
        };
       
        this.setState({ summary });
        return data;
    }




    defineTypes() {
        let types = [];
        this.state.form.splits.forEach(s => {
            if (s.value == "components") {
                types.push(1);
            }

            if (s.value == "work") {
                types.push(2);
            }
        });

        return types;
    }



    fetchReport()
    {
        this.setState({ loading: true, data: [] });
        let data = this.getSubmitData();

        api({
            method: 'post',
            url: `report/get`,
            errorPopper: tr('save_error'),
            data: data,
        }).then(( response ) => {
            // console.table(response.data);
            let responseData = [];
            if (data.type == 'project_results') {
                responseData = this.formatResults(response.data);
            } else if (data.type === 'clients') {
                responseData = response.data;
                if (data.columns.includes('project_searches') && data.columns.includes('income_percentages')) {
                    this.calculateTotalsForClientsReport(responseData);
                }
            } else {
                responseData = response.data;
            }
            //console.log( 'Report', response );
            // console.log( 'Data from response', response.data );
            this.setState({
                loading: false,
                data: responseData,
                related: response.related || {},
                formSubmitted: data,
            });

        }).catch( ( error ) => {
            errorPopper(error, tr('get_error'))
            console.error('error', error);
            this.setState({ loading: false });
        });
    }

    fetchExcel()
    {
        this.setState({ loading: true });
        const data = this.getSubmitData();

        api({
            method: 'post',
            url: 'report/get/excel',
            responseType: 'blob',
            errorPopper: tr('save_error'),
            data: data,
        }).then(( response ) => {
            const url = window.URL.createObjectURL( new Blob([ response ]) );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${tr('report')}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                loading: false,
            });
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    setForm( data, func )
    {
        // console.log('data', data)
        //console.log('func', func)

        let currentForm = { ...this.state.form };

        if (data.columns && data.columns.findIndex(c => c.value === 'flextime') > -1) {
            if (
                !this.state.form ||
                !this.state.form.splits ||
                this.state.form.splits.findIndex(s => s.value === 'user') < 0 ||
                this.state.form.splits.findIndex(s => s.value === 'date') < 0
            ) {
                data.columns = data.columns.filter(c => c.value !== 'flextime');
                window.emitter.emit('popper', {
                    type: 'warning',
                    content: <strong>{ tr('reports_flexitime_error') }</strong>,
                });
            }
        }

        if (
            this.state.form &&
            this.state.form.columns &&
            this.state.form.columns.findIndex(c => c.value === 'flextime') > -1
        ) {
            if (
                this.state.form.splits &&
                this.state.form.splits.findIndex(s => s.value === 'user') > -1 &&
                this.state.form.splits.findIndex(s => s.value === 'date') > -1
            ) {
                if (
                    data.splits &&
                    (data.splits.findIndex(s => s.value === 'user') < 0 ||
                    data.splits.findIndex(s => s.value === 'date') < 0)
                ) {
                    currentForm.columns = currentForm.columns.filter(c => c.value !== 'flextime')
                    window.emitter.emit('popper', {
                        type: 'warning',
                        content: <strong>{ tr('reports_flexitime_error') }</strong>,
                    });
                }
            }
        }

        // check if report type is tt and columns contain address and splits does not contain timer_in
        // Timer save points are needed for address
        const addressIndex = data.columns?.findIndex(c => c.value === 'address')
            ?? currentForm?.columns?.findIndex(c => c.value === 'address')
            ?? -1;
        const distanceToProjectIndex = data.columns?.findIndex(c => c.value === 'distance_to_project')
            ?? currentForm?.columns?.findIndex(c => c.value === 'distance_to_project')
            ?? -1;
        const timerInIndex = data.splits?.findIndex(s => s.value === 'timer_in') 
            ?? currentForm?.splits?.findIndex(s => s.value === 'timer_in')
            ?? -1;
        // check if address is in columns and timer_in is not in splits
        if (currentForm?.type?.value === 'tt' && addressIndex > -1 && timerInIndex === -1) {
            window.emitter.emit('popper', {
                type: 'warning',
                content: <strong>{ tr('reports_address_error') }</strong>,
            });
            data.columns = currentForm.columns?.filter(c => c.value !== 'address') || [];
        }
        // check if distance_to_project is in columns and timer_in is not in splits
        if (currentForm?.type?.value === 'tt' && distanceToProjectIndex > -1 && timerInIndex === -1) {
            window.emitter.emit('popper', {
                type: 'warning',
                content: <strong>{ tr('reports_distance_to_project_error') }</strong>,
            });
            data.columns = currentForm.columns?.filter(c => c.value !== 'distance_to_project') || [];
        }

        // Clients report column validation
        if (currentForm?.type?.value === 'clients') {
            const projectSearchesIndex = data.columns?.findIndex(c => c.value === 'project_searches')
            const incomePercentagesIndex = data.columns?.findIndex(c => c.value === 'income_percentages')
            // check if percentages selected without project searches
            if (incomePercentagesIndex > -1 && projectSearchesIndex === -1) {
                window.emitter.emit('popper', {
                    type: 'warning',
                    content: <strong>{ tr('income_percentages_error') }</strong>,
                });
                data.columns = currentForm.columns?.filter(c => c.value !== 'income_percentages') || [];
            }
        }

        let form = { ...currentForm, ...data };
        this.setState({ form: form }, func );
    }

    setType( value )
    {
        if( value === 'none')
            value = null;
        else
        {
           if (value == 'project' && this.state.companyIdentificators == null) { 
               this.getUniqueIdentificators(); 
            }
            const options = types;
            value = options.find( f => f.value === value );
        }
        // Reset all form data
        this.setState({
            form: { type: value },
            formSubmitted: null,
            summary: null
        });
    }

    setSplits( values )
    {
        let depths = { ...this.state.depths };

        // Add new values to depths
        values.forEach( v => {
            if( v.depth && !depths[ v.value ])
                depths[ v.value ] = 0;
        });

        // Remove old values from depths
        for(let depth in depths )
        {
            let found = values.find( v => v.value === depth );
            if( !found ) delete depths[ depth ];
        }

        this.setForm({
            splits: values,
            depths: depths,
        });
    }

    setTypes( values ) {
        //Nollaa data
        this.setState({data: []});

        let val = [];
        if (values.length > 0) { 
            val.push(values[values.length -1 ]); 
            this.setForm({searchType: val});
        } 
    }

    setStorageType(values) {
        let val = [];
        if (values.length > 0) { 
            val.push(values[values.length -1 ]); 
            this.setForm({storage_type: val});
        } 
    }

    renderSplitsSelect()
    {
        const options = keyExists( this.state.form, 'type.splitOptions', true, [] );
        if( !options.length ) return null;

        let selectDiv = <ApSelect key="rowsToRead"
            label={ tr('separation_into_columns') }
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'splits', true, [] ) }
            onChange={ this.setSplits }
            objKeyId="value"
            multiselect
            options={ options }
            optionRenderer="label"
            objKeySearchable="label"
            valueRenderer="label"
            keepFocusOnSelect
            validationState={ keyExists( this.state.form, 'splits', true, [] ).length ? '' : 'error' }
        />
        

        if (this.state.form.type.value == 'project_results') {
            const typeOptions = keyExists(this.state.form, 'type.typeOptions', true, [] );
            if (typeOptions.length) {
                selectDiv = <div>
                    <ApSelect key="rowsToSplit"
                        label={ tr('separation_into_types') }
                        loading={ this.state.loading }
                        value={ keyExists( this.state.form, 'searchType', true, [] ) }
                        onChange={ this.setTypes }
                        objKeyId="value"
                        multiselect
                        options={ typeOptions }
                        optionRenderer="label"
                        objKeySearchable="label"
                        valueRenderer="label"
                    />
                    <ApSelect key="rowsToRead"
                        label={ tr('separation_into_columns') }
                        loading={ this.state.loading }
                        value={ keyExists( this.state.form, 'splits', true, [] ) }
                        onChange={ this.setSplits }
                        objKeyId="value"
                        multiselect
                        options={ options }
                        optionRenderer="label"
                        objKeySearchable="label"
                        valueRenderer="label"
                    />
                </div>
            }
            
        }

        return selectDiv;
    }

    renderColumnSelect()
    {
        let options = keyExists( this.state.form, 'type.columnOptions', true, [] );
        if( !options.length ) return null;
        
        if (this.state.form.type.value == 'project' && this.state.companyIdentificators!=null && this.state.companyIdentificators.length>0) {
             options = options.concat(this.state.companyIdentificators);
        }

        return <ApSelect key="columnsSelect"
            label={ tr('other_columns') }
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'columns', true, [] ) }
            onChange={ ( values ) => this.setForm({ columns: values }) }
            objKeyId="value"
            multiselect
            options={ options }
            optionRenderer="label"
            objKeySearchable="label"
            valueRenderer="label"
            keepFocusOnSelect
        />
    }

    renderDepthInputs()
    {
        let depths = keyExists( this.state.form, 'depths', true, '' )

        let doms = [];
        const titles = {
            project: tr('project_depth'),
            cost_center: tr('grouping_depth'),
        }
        for( let split in depths ) {
            let type = null;
            if (split === 'cost_center') {
                type = 'general';
            }
            doms.push( <div className="depthInputContainer" key={ split }>
                <ApInputStack gap="0">
                    <ApAddon noRightBorder>
                        { titles[ split ] }
                    </ApAddon>
                    <ApAddon width="50px">
                        <DepthInput 
                            depth={ depths[ split ] || 0 } 
                            onChange={ ( value ) => this.setDepth( split, value ) } 
                            type={type} 
                        />
                    </ApAddon>
                </ApInputStack>
            </div>);
        }
        return doms;
    }

    setDepth( name, value )
    {
        let depths = { ...this.state.depths };
        depths[ name ] = value;
        this.setForm({ depths: depths });
    }

    setTurnover(type, value) {
        let turnOver;
        if (this.state.form.turnover) {
            turnOver = this.state.form.turnover;
            type == 'min' ? turnOver.min = value : turnOver.max = value;
        } else {
            let min, max = null;
            type == 'min' ? min = value : max = value;
            turnOver = { min, max };
        }

        this.setForm({turnover: turnOver});
    }

    setStaff(type, value) {
        let staff;
        if (this.state.form.staff) {
            staff = this.state.form.staff;
            type == 'min' ? staff.min = value : staff.max = value;
        } else {
            let min, max = null;
            type == 'min' ? min = value : max = value;
            staff = { min, max };
        }

        this.setForm({staff: staff});
    }

    // ----------------------------------------
    //  Common options
    // ----------------------------------------
    
    // client group can be choosen
    renderClientGroup()
    {
        return <ApSelect key="clientGroupFilter"
            label={ tr('customer_group_type') } 
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'groups', true, [] ) }
            valueRenderer="group"
            optionRenderer="group"
            onChange={ ( values ) => this.setForm({ groups: values }) }
            objKeyId="id"
            multiselect
            apiUrl="report/groups/find"
            apiData={{ exluded: [] }}         
            keepFocusOnSelect
        />
    }

    // contact group can be choosen
    renderContactGroup()
    {
        return <ApSelect key="contactContactFilter"
            label={ tr('contact_group_type') } 
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'contacts', true, [] ) }
            valueRenderer="contact"
            optionRenderer="contact"
            onChange={ ( values ) => this.setForm({ contacts: values }) }
            objKeyId="id"
            multiselect
            apiUrl="report/contacts/find"
            apiData={{ exluded: [] }}
            keepFocusOnSelect
        />
    }
    
    // filters for client reports
    renderAccountManager()
    {
        return <ApSelect
            key={"accountManager"}
            label={ tr('customer_manager') }
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'users', true, [] ) }
            valueRenderer="user"
            optionRenderer="user"
            onChange={ ( values ) => this.setForm({ users: values }) }
            objKeyId="id"
            multiselect
            apiUrl="report/users/find"
            apiData={{ 
                exluded: [],
                client_manager: true,
            }}
            keepFocusOnSelect
        />
    }
    renderLocality()
    {
        return <ApSelect 
            key={"locality"}
            label={ tr('locality') }
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'locality', true, [] ) }
            valueRenderer="locality"
            optionRenderer="locality"
            onChange={ ( values ) => this.setForm({ locality: values }) }
            objKeyId="id"
            multiselect
            apiUrl="report/localities/find"
            apiData={{ exluded: [] }}
            keepFocusOnSelect
        />
    }
    renderIndustry()
    {
       return <ApSelect 
            key={"industry"}
            label={ tr('industry') }
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'industry', true, [] ) }
            valueRenderer="industry"
            optionRenderer="industry"
            onChange={ ( values ) => this.setForm({ industry: values }) }
            objKeyId="id"
            multiselect
            apiUrl="report/industries/find"
            apiData={{ exluded: [] }}
            keepFocusOnSelect
        />
    }
    renderTurnover()
    {
       return <ApInputStack gap="0" key={"turnover"}>
            <ApAddon width="100px">
                { tr('turnover') }
            </ApAddon>
            <ApAddon noRightBorder width="60px">
                MIN
            </ApAddon>
            <ApInput
                type="text"
                id="turnover_min"
                name="turnover_min"
                align="right"
                value={ keyExists( this.state.form, 'turnover.min', true, [] )}
                onChange={ (e) => this.setTurnover('min', e.target.value) }
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />
            <ApAddon noRightBorder width="60px">
                MAX
            </ApAddon>
            <ApInput
                type="text"
                id="turnover_max"
                name="turnover_max"
                align="right"
                value={ keyExists( this.state.form, 'turnover.max', true, [] )}
                onChange={ (e) => this.setTurnover('max', e.target.value) }
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />
            <ApAddon noLeftBorder width="50px">
                EUR
            </ApAddon>
        </ApInputStack>

    }
    renderStaff()
    {
        return <ApInputStack gap="0" key={"staff"}>
            <ApAddon width="100px">
                { tr('personnel') }
            </ApAddon>
            <ApAddon noRightBorder width="60px">
                MIN
            </ApAddon>
            <ApInput
                type="text"
                id="staff_min"
                name="staff_min"
                align="right"
                value={ keyExists( this.state.form, 'staff.min', true, [] )}
                onChange={ (e) => this.setStaff('min', e.target.value) }
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />
            <ApAddon noRightBorder width="60px">
                MAX
            </ApAddon>
            <ApInput
                type="text"
                id="staff_max"
                name="staff_max"
                align="right"
                value={ keyExists( this.state.form, 'staff.max', true, [] )}
                onChange={ (e) => this.setStaff('max', e.target.value) }
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />
            <ApAddon noLeftBorder width="50px">
                { tr('number_of_abb') }
            </ApAddon>
        </ApInputStack>
    }



    renderUserFilter(options = {}, label = tr('person'), stateKey='users', elKey='userFilter')
    {
        return <ApSelect key={elKey}
            label={ label }
            loading={ this.state.loading }
            value={ keyExists( this.state.form, stateKey, true, [] ) }
            valueRenderer="user"
            optionRenderer="user"
            onChange={ ( values ) => this.setForm({ [stateKey]: values }) }
            objKeyId="id"
            multiselect
            apiUrl="report/users/find"
            apiData={{ exluded: [], ...options }}
            keepFocusOnSelect
        />
    }

    renderComponentFilter()
    {
        return <ApSelect key="componentFilter"
            label={ tr('work') }
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'components', true, [] ) }
            valueRenderer="component"
            optionRenderer="component_detail"
            onChange={ ( values ) => this.setForm({ components: values }) }
            objKeyId="id"
            multiselect
            apiUrl="report/components/find"
            apiData={{
                type: 'work',
                status: 'all',
            }}
            keepFocusOnSelect
        />
    }

    renderCountFilter() {
        return <ApInputStack gap="0">
            <ApAddon width="250px">
                { tr('number_of_events') } [MAX]
            </ApAddon>
            <ApInput
                type="text"
                id="events_max"
                name="events_max"
                align="right"
                value={ keyExists( this.state.form, 'events_count', true, [] )}
                onChange={ (e) => this.setForm({events_count: e.target.value}) }
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />
            <ApAddon noLeftBorder width="50px">
                { tr('number_of_abb') }
            </ApAddon>
        </ApInputStack>
    }

    renderEmptyFilter() {

        const options = [
            { id: 0, label: tr('show_all')},
            { id: 1, label: tr('filter_projects_without_components')},
        ];

        return <ApSelect key="emptyFilter"
            options={options}
            label={ tr('filter_projects') }
            loading={ this.state.loading }
            value={this.state.form.filtered_out ? this.state.form.filtered_out.label : null }
            optionRenderer={ (item) => item.label }
            onChange={(value) => {this.setForm({filtered_out: value})}}
            objKeyId="id"
            objKeySearchable="name"
        />
    }

    renderActivityFilter() {

        const options = [
            { id: 0, label: tr('project_running')},
            { id: 0.5, label: `${tr('project_running')} (${tr('continuous')})` },
            { id: 1, label: tr('project_ended')},
            { id: 2, label: tr('project_completed')},
            { id: 3, label: tr('offer_in_calculation')},
            { id: 4, label: tr('offer_open') },
            { id: 5, label: tr('offer_rejected') },
        ];

        return <ApSelect key="projectActivityFilter"
            options={options}
            label={ tr('project_status') }
            value={ keyExists( this.state.form, 'project_status', true, [] ) }
            optionRenderer={ (item) => item.label }
            valueRenderer={ (item) => item.label }
            onChange={(value) => {this.setForm({project_status: value})}}
            objKeyId="id"
            objKeySearchable="label"
            clearable
            keepFocusOnSelect
            multiselect
        />
    }

    renderProjectTypeFilter() {
        return <ApSelect key="projectFilter"
            label={ tr('project_type') }
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'project_types', true, [] ) }
            valueRenderer={item => <div>{`${item.code_prefix.padEnd(item.code_default_length, '0')}`} - {item.name}</div>}
            optionRenderer={item => <div>{`${item.code_prefix.padEnd(item.code_default_length, '0')}`} - {item.name}</div>}
            onChange={ ( values ) => this.setForm({ project_types: values }) }
            objKeyId="id"
            multiselect
            apiUrl="projects/management/types"
            keepFocusOnSelect
            method='get'
        />
    }

    renderProjectFilter(options = {})
    {
        const filters = {
            onlyRoot: options.onlyRoot || false,
            addToSelect: ["id", "name", "project_code", "customer_id"],
            onlyCustomerName: true,
        }

        return <ApSelect key={"projectFilter2"}
            label={ tr('project') }
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'projects', true, [] ) }
            valueRenderer="project"
            optionRenderer="project"
            onChange={ ( values ) => this.setForm({ projects: values }) }
            objKeyId="id"
            multiselect
            apiUrl="report/projects/find"
            apiData={filters}
            keepFocusOnSelect
            noProject={options.noProject}
        />
    }

    renderStorageFilter() {
        //Rajaa filterin mukaan [Varastot/Projektivarastot/Molemmat]
        const storageTypes = this.state.form.storage_type;

        const showAll = !storageTypes || storageTypes.length && storageTypes[0].id == 1;
        const showProject = storageTypes && storageTypes.length && storageTypes[0].id == 3;

        return <ApSelect key="storageFilter"
            label={ tr('storage') }
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'storages', true, [] ) }
            valueRenderer={(v) => v.name }
            optionRenderer="storage_location_address"
            onChange={ ( values ) => { this.setForm({ storages: values.map(v => {
                return { id: v.id, name: v.name }
            }) }) }}
            objKeyId="id"
            multiselect
            apiUrl="storage/locations/find"
            apiData={{ 
                allTypes: showAll,
                projectStorage: showProject
            }}
            keepFocusOnSelect
        />
    }

    renderComponentFilter() {
        return <ApSelect key="componentFilter"
            label={ tr('component') }
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'components', true, [] ) }
            valueRenderer={(v) => v.name }
            optionRenderer={(v) => v.name }
            onChange={ ( values ) => { this.setForm({ components: values.map(v => {
                return { id: v.id, name: v.name }
            }) }) }}
            objKeyId="id"
            multiselect
            apiUrl="report/components/find"
            keepFocusOnSelect
        />
    }

    setOptionBar( field, value )
    {
        let data = keyExists( this.state.form, field, true, [] );
        if( data.includes( value ))
            data = data.filter( f => f !== value );
        else
            data.push( value );
        this.setForm({ [ field ]: data });
    }

    renderOptionBar( field, options )
    {
        let selected = keyExists( this.state.form, field, true, [] );
        const optionDoms = options.map(( o, i ) => {
            let className = [ 'option' ];
            if( selected.includes( o.value ))
                className.push('selected ');

            return <div className={ className.join(' ') } onClick={ () => this.setOptionBar( field, o.value ) } >
                <ApTooltip text={ o.tooltip ? o.tooltip : null } block position="topright">
                    { o.label }
                </ApTooltip>
            </div>
        })
        return <div>
            <div className={`apOptionBar count-${ options.length }`}>
                { optionDoms }
            </div>
        </div>
    }

    // ----------------------------------------
    //  Date options
    // ----------------------------------------

    setDateMode( mode )
    {
        let year = null;
        let value = null;
        let start = null;
        let end = null;

        if( mode === 'week' )
        {
            year = moment().get('year');
            value = moment().year( year ).get('week');

            start = moment().startOf('week').format('YYYY-MM-DD');
            end = moment().endOf('week').format('YYYY-MM-DD');
        }
        else if( mode === 'month' )
        {
            year = moment().get('year');
            value = moment().year( year ).get('month');

            start = moment().startOf('month').format('YYYY-MM-DD');
            end = moment().endOf('month').format('YYYY-MM-DD');
        }
        else if( mode === 'year' )
        {
            year = moment().get('year');

            start = moment().startOf('year').format('YYYY-MM-DD');
            end = moment().endOf('year').format('YYYY-MM-DD');
        }

        this.setForm({
            dateMode: mode,
            dateModeYear: year,
            dateModeValue: value,

            start: start,
            end: end,
        });
    }

    setDateYear( year )
    {
        const mode = this.state.form.dateMode;
        const yMoment = moment().year( year );

        let start = null;
        let end = null;
        let value = null;

        if( mode === 'week' )
        {
            if( parseInt( year, 10 ) === this.currentYear )
                value = yMoment.get('week');
            else
                value = 1;

            start = yMoment.week( value ).startOf('week').format('YYYY-MM-DD');
            end = yMoment.week( value ).endOf('week').format('YYYY-MM-DD');

        }
        else if( mode === 'month' )
        {
            if( parseInt( year, 10 ) === this.currentYear )
                value = yMoment.get('month');
            else
                value = 0;

            start = yMoment.month( value ).startOf('month').format('YYYY-MM-DD');
            end = yMoment.month( value ).endOf('month').format('YYYY-MM-DD');
        }
        else if( mode === 'year' )
        {
            start = yMoment.startOf('year').format('YYYY-MM-DD');
            end = yMoment.endOf('year').format('YYYY-MM-DD');
        }

        this.setForm({
            dateModeYear: year,
            dateModeValue: value,
            start: start,
            end: end,
        });
    }

    setDateValue( value )
    {
        const mode = this.state.form.dateMode;
        const yMoment = moment().year( this.state.form.dateModeYear );

        let start = null;
        let end = null;

        if( mode === 'week' )
        {
            start = yMoment.week( value ).startOf('week').format('YYYY-MM-DD');
            end = yMoment.week( value ).endOf('week').format('YYYY-MM-DD');

        }
        else if( mode === 'month' )
        {
            start = yMoment.month( value ).startOf('month').format('YYYY-MM-DD');
            end = yMoment.month( value ).endOf('month').format('YYYY-MM-DD');
        }

        this.setForm({
            dateModeValue: value,
            start: start,
            end: end,
        });
    }

    getYearsList()
    {
        let data = [];
        for (let i = this.startYear; i <= this.endYear; i++)
        {
            let label = i;

            if( i === this.currentYear )
                label = `${ label } *`;

            data.push( { value: i, label: label } );
        }
        return data;
    }

    getMonthList()
    {
        let now = moment();
        let yMoment = moment().year( this.state.form.dateModeYear );
        const data = [];
        for (var i = 0; i < 12; i++)
        {
            let month = yMoment.month( i );
            let start = month.startOf('month').format('D.M.');
            let end = month.endOf('month').format('D.M.');

            let label = `${ month.format('MMMM') } ( ${ start } - ${ end })`;
            if( month.isSame(now, 'month') )
                label = `${ label } *`;

            data.push({ value: i, label: label });
        }
        return data;
    }

    getWeekList()
    {
        let now = moment();
        let yMoment = moment().year( this.state.form.dateModeYear );
        const data = [];
        for (var i = 1; i <= yMoment.weeksInYear() ; i++)
        {
            let week = yMoment.week( i );
            let start = week.startOf('week').format('D.M.');
            let end = week.endOf('week').format('D.M.');

            let label = `${capitalize(tr('week'))} ${ i } ( ${ start } - ${ end })`;
            if( week.isSame(now, 'week') )
                label = `${ label } *`;

            data.push({ value: i, label: label });
        }
        return data;
    }

    renderDates()
    {
        let dateModeDom = <ApInput
            className="dateMode"
            type="select"
            id="dateMode"
            name="dateMode"
            value={ keyExists( this.state.form, 'dateMode', true, 'none' ) }
            options={ dateModeOptions }
            onChange={ ( e ) => { this.setDateMode( e.target.value ) } }
            loading={ this.state.loading }
            disabled={ this.state.loading }
            // width="300px"
        />

        const yearOptions = this.getYearsList();
        let dateYearDom  = <ApInput
            type="select"
            id="dateYearSelect"
            name="dateYear"
            value={ keyExists( this.state.form, 'dateModeYear', true, null ) }
            options={ yearOptions }
            onChange={ ( e ) =>{ this.setDateYear( e.target.value ) } }
            loading={ this.state.loading }
            disabled={ this.state.loading }
        />

        switch ( this.state.form.dateMode ) {
            case 'dates':
                return <div className="reportDates" key="dateFilter">
                    <ApInputStack gap="0">
                        { dateModeDom }
                    </ApInputStack>
                    <ApInputStack gap="0">
                        <ApInput
                            type="datetimeV2"
                            name="start"
                            id="start"
                            value={ keyExists( this.state.form, 'start', true, null ) }
                            label={ tr('from') }
                            onChange={ ( value ) => { this.setForm({ start: value }) } }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                            clearable
                            width="50%"
                            weekNumbers={true}
                        />
                        <ApAddon width="20" noLeftBorder noRightBorder> - </ApAddon>
                        <ApInput
                            type="datetimeV2"
                            name="end"
                            id="end"
                            value={ keyExists( this.state.form, 'end', true, null ) }
                            label={ tr('ending') }
                            onChange={ ( value ) => { this.setForm({ end: value }) } }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                            clearable
                            width="50%"
                            weekNumbers={true}
                        />
                    </ApInputStack>
                </div>

            case 'week':
                return <div className="reportDates" key="dateFilter">
                    <ApInputStack gap="0">
                        { dateModeDom }
                        { dateYearDom }
                        <ApInput
                            type="select"
                            id="dateWeek"
                            name="dateWeek"
                            value={ keyExists( this.state.form, 'dateModeValue', true, null ) }
                            options={ this.getWeekList() }
                            onChange={ ( e ) =>{ this.setDateValue( e.target.value ) } }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        />
                    </ApInputStack>
                </div>

            case 'month':
                return <div className="reportDates" key="dateFilter">
                    <ApInputStack gap="0">
                        { dateModeDom }
                        { dateYearDom }
                        <ApInput
                            type="select"
                            id="dateMonth"
                            name="dateMonth"
                            value={ keyExists( this.state.form, 'dateModeValue', true, null ) }
                            options={ this.getMonthList() }
                            onChange={ ( e ) =>{ this.setDateValue( e.target.value ) } }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        />
                    </ApInputStack>
                </div>
            case 'year':
                return <div className="reportDates" key="dateFilter">
                    <ApInputStack gap="0">
                        { dateModeDom }
                        { dateYearDom }
                    </ApInputStack>
                </div>
            default:
                return <div className="reportDates" key="dateFilter">
                    <ApInputStack gap="0">
                        { dateModeDom }
                    </ApInputStack>
                </div>
        }

    }

    renderDate()
    {
        return <ApInput
            type="dateSafe"
            name="date"
            id="date"
            value={ keyExists( this.state.form, 'date', true, null ) }
            label={ tr('to_date') }
            onChange={ ( e ) => { this.setForm({ date: e.target.value }) } }
            loading={ this.state.loading }
            disabled={ this.state.loading }
            clearable={true}
            key="date"
        />
    }

    renderStorageTypeFilter() {
        const typeOptions = [
            { id: 1, value: 'none', label: tr('no_type_limit') },
            { id: 2, value: 'storage', label: tr('storages') },
            { id: 3, value: 'project_storage', label: tr('project_storages') },
        ];
        
        return <ApSelect
            key='storageTypeFilter'
            label={ tr('storage_type') }
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'storage_type', true, [] ) }
            onChange={ ( values ) => this.setStorageType(values)}
            objKeyId="id"
            multiselect
            options={ typeOptions }
            optionRenderer={(v) => v.label}
            objKeySearchable="label"
            valueRenderer={(v) => v.label}
            keepFocusOnSelect
        />
    }

    // ----------------------------------------
    //  Table
    // ----------------------------------------

    getColumnsTt()
    {
        const formSubmitted = this.state.formSubmitted;
        const related = this.state.related;

        const splitIncludes = ( f ) => {
            if( !formSubmitted.splits ) return false;
            return formSubmitted.splits.includes( f );
        }

        const columnsIncludes = ( f ) => {
            if( !formSubmitted.columns ) return false;
            return formSubmitted.columns.includes( f );
        }

        let columns = [];

        if( splitIncludes( 'project' ) )
        {
            columns.push( {
                Header: tr('project'),
                columns: [{
                    id: 'project_name',
                    Header: tr('project_name'),
                    accessor: 'project.full_name',
                }, {
                    id: 'project_code',
                    Header: tr('project_code'),
                    accessor: 'project.project_code',
                }],
            });
        }

        if( splitIncludes( 'user' ) )
        {
            columns.push( {
                Header: tr('person'),
                columns: [{
                    id: 'user_name',
                    Header: tr('name'),
                    accessor: 'user.name',
                }],
            });
        }

        if( splitIncludes( 'description' ) )
        {
            columns.push( {
                Header: tr('description'),
                columns: [{
                    id: 'description',
                    Header: tr('description'),
                    accessor: 'description'
                }],
            });
        }
        
        if( splitIncludes( 'description_long' ) )
        {
            columns.push( {
                Header: tr('additional_information'),
                columns: [{
                    id: 'description_long',
                    Header: tr('additional_information'),
                    accessor: 'description_long'
                }],
            });
        }

        if( splitIncludes( 'cost_center' ) )
        {
            columns.push( {
                Header: tr('cost_center'),
                columns: [{
                    id: 'cost_center_name',
                    Header: tr('name'),
                    accessor: 'cost_center.name',
                },
                {
                    id: 'cost_center_code',
                    Header: tr('code'),
                    accessor: 'cost_center.code',
                }],
            });
        }
        
        if( splitIncludes( 'component' ) )
        {
            columns.push( {
                Header: tr('work_title'),
                columns: [{
                    id: 'component_name',
                    Header: tr('name'),
                    accessor: 'component.name',
                }, {
                    id: 'component_code',
                    Header: tr('work_storage_code'),
                    accessor: 'component.code',
                }],
            });
        }

        if( splitIncludes( 'date' ) )
        {
            columns.push( {
                Header: capitalize(tr('day')),
                columns: [{
                    id: 'date',
                    Header: tr('date'),
                    accessor: 'date',
                    filterable: false,

                    Cell: props => {
                        return <div>{ moment( props.value ).format('DD.MM.YYYY') }</div>
                    },
                }],
            });
        }

        if( splitIncludes( 'timer_in') ) {
            let timerCols = [];
            timerCols.push({
                id: 'timer_in',
                Header: tr('start_time'),
                accessor: 'timer_in_created_at',
                filterable: false,
                Cell: props => props.value ? <div>{ moment( props.value ).format('L HH:mm:ss') }</div> : null,

            }, {
                id: 'timer_out',
                Header: tr('end_time'),
                accessor: 'timer_out_created_at',
                filterable: false,
                Cell: props => props.value ? <div>{ moment( props.value ).format('L HH:mm:ss') }</div> : null,
            });
            
            if (columnsIncludes('address')) {
                timerCols.push({
                    id: 'address',
                    Header: tr('address'),
                    accessor: row => row.timer_in_address || row.timer_out_address,
                });
            }
            if (columnsIncludes('distance_to_project')) {
                timerCols.push(colPreset({
                    type: "numberNice",
                    decimals: 2,
                    unit: 'm',

                    id: 'distance_to_project',
                    Header: tr('distance_to_project'),
                    accessor: row => row.timer_in_distance_to_project_metres || row.timer_out_distance_to_project_metres,
                }));
            }
            columns.push({
                Header: tr('timer_save_points'),
                columns: timerCols,
            })
        }


        if( splitIncludes( 'customer_work_number' ) )
        {
            columns.push( {
                Header: tr('customer_work_number'),
                columns: [{
                    id: 'customer_work_number',
                    Header: tr('customer_work_number'),
                    accessor: 'customer_work_number',
                }],
            });
        }

        if( splitIncludes( 'customer' ) )
        {
            columns.push( {
                Header: tr('customer'),
                columns: [{
                    id: 'customer',
                    Header: tr('customer'),
                    accessor: 'customer.name',
                }],
            });
        }

        if (splitIncludes('status')) {
            columns.push({
                Header: tr('entry_status'),
                columns: [
                    {
                    id: 'entry_status',
                    Header: tr('entry_status'),
                        accessor: (row) => {
                            if (row.status)
                                return tr(row.status);
                            return null;
                        }
                    },
                    {
                        id: 'entry_status_count',
                        Header: tr('amount'),
                        accessor: 'status_count',
                    },
                ],
            });
        }

        if( related.hour_rows )
        {
            const sCols = related.hour_rows.map( item => {
                return colPreset({
                    type: "numberNice",
                    decimals: 2,
                    unit: 'h',

                    id: `hour_rows-${ item.id }`,
                    Header: item.name,
                    filterable: false,
                    accessor: ( row ) => {
                        if( row.hour_rows[ item.id ] )
                            return row.hour_rows[ item.id ].hours;
                        return null;
                    }
                })
            })
            columns.push( {
                Header: tr('hours'),
                columns: sCols,
            });
        }

        if( related.project_expense_chargeables )
        {
            const sCols = related.project_expense_chargeables.map( item => {

                return colPreset({
                    type: "numberNice",
                    decimals: 2,

                    id: `chargeable-${ item.id }`,
                    Header: item.name,
                    filterable: false,
                    accessor: ( row ) => {
                        if( row.project_expense_chargeables[ item.id ] )
                            return row.project_expense_chargeables[ item.id ].quantity;
                        return null;
                    }
                })
            })
            columns.push( {
                Header: tr('billing_costs'),
                columns: sCols,
            });
        }

        if( related.project_component_installations )
        {
            related.project_component_installations.forEach( item => {
                let sColumns = [];

                sColumns.push(colPreset({
                    type: "numberNice",
                    decimals: 2,
                    id: `installations-${ item.id }-quantity`,
                    Header: tr('amount'),
                    filterable: false,
                    accessor: ( row ) => {
                        let data = row.project_component_installations[ item.id ];
                        if( data ) return data.quantity;
                        return null;
                    },
                }));

                sColumns.push(colPreset({
                    type: "numberNice",
                    decimals: 2,
                    id: `installations-${ item.id }-hours`,
                    Header: tr('hours'),
                    filterable: false,
                    accessor: ( row ) => {
                        let data = row.project_component_installations[ item.id ];
                        if( data ) return data.hours;
                        return null;
                    },
                }));

                if( related.trackingunits )
                {
                    related.trackingunits.forEach( tu => {
                        sColumns.push(colPreset({
                            type: "numberNice",
                            decimals: 2,
                            id: `installations-${ item.id }-tu-${ tu.id }`,
                            Header: tu.name,
                            accessor: ( row ) => {
                                let data = row.project_component_installations[ item.id ];
                                if( !data ) return null;
                                if( !data.trackingunits ) return null;
                                if( !data.trackingunits[ tu.id ] ) return null;
                                return data.trackingunits[ tu.id ].value;
                            },
                        }));
                    });
                }
                columns.push( {
                    Header: `"${ item.name }" ${tr('installation').toLowerCase()}`,
                    columns: sColumns,
                });
            })
        }

        let absenceCols = [];
        if( splitIncludes( 'absence' ) )
        {
            absenceCols.push({
                id: 'absence_reason',
                Header: tr('absence_reason'),
                accessor: 'absence.name',
            });
        }
        if( columnsIncludes( 'absence' ) )
        {
            absenceCols.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: 'h',

                id: 'hours_absence',
                Header: tr('absence_amount'),
                accessor: 'hours_absence',
                filterable: false,
            }));
        }
        if( absenceCols.length )
        {
            columns.push( {
                Header: tr('absences'),
                columns: absenceCols,
            });
        }
        const flextimeCols = [];
        if (columnsIncludes('flextime')) {
            flextimeCols.push({
                type: "numberNice",
                decimals: 2,
                unit: 'h',

                id: `flextime_change_rows`,
                Header: tr('flexitime_change'),
                filterable: false,
                accessor: ( row ) => {
                    let data = row.flextime_changes || [];
                    let change = 0;
                    if (data.length > 0) {
                        data.forEach(d => {
                            change += parseFloat(d.change);
                        });
                        if (change > 0) {
                            return `+${change}`;
                        } else {
                            return change;
                        }
                    }
                    return null;
                }
            });
            flextimeCols.push({
                type: "numberNice",
                decimals: 2,
                unit: 'h',

                id: `flextime_balance_rows`,
                Header: tr('flexitime_balance'),
                filterable: false,
                accessor: ( row ) => {
                    let data = row.flextime_balance;
                    if (data !== null && data !== undefined) {
                        return data;
                    }
                    return null;
                }
            });
        }

        columns.push( {
            Header: 'Liukuma',
            columns: flextimeCols,
        });

        if ( splitIncludes( 'vehicle_type')) {
            columns.push({
                id: 'vehicle_type',
                Header: tr('vehicle_type'),
                accessor: 'vehicle_type.name',
            });
        }

        if( columnsIncludes( 'hours' ) )
        {
            columns.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: 'h',

                id: 'travel_hours',
                Header: tr('travel_time'),
                accessor: 'hours',
                filterable: false,
            }));
        }

        if( columnsIncludes( 'distance' ) )
        {
            columns.push(colPreset({
                type: "numberNice",
                decimals: 0,
                unit: 'km',

                id: 'distance',
                Header: tr('kilometers'),
                accessor: 'distance',
                filterable: false,
            }));
        }

        if( columnsIncludes( 'compensation_total' ) )
        {
            columns.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: this.state.currencySign,

                id: 'compensation_total',
                Header: tr('compensation'),
                accessor: 'compensation_total',
                filterable: false,
            }));
        }
        let allowanceCols = [];
        if( splitIncludes( 'allowance' ) )
        {
            allowanceCols.push({
                id: 'allowance_reason',
                Header: tr('allowance'),
                accessor: 'allowance.name',
            });
        }

        if( allowanceCols.length )
        {
            columns.push( {
                Header: tr('allowance'),
                columns: allowanceCols,
            });
        }

        if( columnsIncludes( 'allowance_sum' ) )
        {
            columns.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: this.state.currencySign,

                id: 'allowance_sum',
                Header: tr('allowance_sum'),
                accessor: 'allowance_sum',
                filterable: false,
            }));
        }

        if( columnsIncludes( 'money_expense' ) )
        {
            columns.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: this.state.currencySign,

                id: 'money_expense',
                Header: tr('expense_compensation'),
                accessor: 'money_expense',
                filterable: false,
            }));
        }

        const salaryExtraCols = [];
        if (columnsIncludes( 'salary_extra_name' )) {
            salaryExtraCols.push({
                id: 'salary_extra_name',
                Header: tr('name'),
                accessor: 'salary_extra_name',
            });
        }
        if (columnsIncludes( 'salary_extra_price' )) {
            salaryExtraCols.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: this.state.currencySign,

                id: 'salary_extra_total',
                Header: tr('price'),
                accessor: 'salary_extra_total',
                filterable: false,
            }));
        }
        if (salaryExtraCols.length) {
            columns.push( {
                Header: tr('fees_supplements'),
                columns: salaryExtraCols,
            });
        }

        return columns;
    }

    getColumnsTtTravel()
    {
        const formSubmitted = this.state.formSubmitted;
        const related = this.state.related;

        const splitIncludes = ( f ) => {
            if( !formSubmitted.splits ) return false;
            return formSubmitted.splits.includes( f );
        }

        const columnsIncludes = ( f ) => {
            if( !formSubmitted.columns ) return false;
            return formSubmitted.columns.includes( f );
        }

        let columns = [];

        if( splitIncludes( 'project' ) )
        {
            columns.push( {
                Header: tr('project'),
                columns: [{
                    id: 'project_name',
                    Header: tr('project_name'),
                    accessor: 'project.full_name',
                }, {
                    id: 'project_code',
                    Header: tr('project_code'),
                    accessor: 'project.project_code',
                }],
            });
        }

        if( splitIncludes( 'user' ) )
        {
            columns.push( {
                Header: tr('person'),
                columns: [{
                    id: 'user_name',
                    Header: tr('name'),
                    accessor: 'user.name',
                }],
            });
        }

        if( splitIncludes( 'date' ) )
        {
            columns.push( {
                Header: `Päivä`,
                columns: [{
                    id: 'date',
                    Header: tr('date'),
                    accessor: 'date',
                    filterable: false,

                    Cell: props => {
                        return <div>{ moment( props.value ).format('DD.MM.YYYY') }</div>
                    },
                }],
            });
        }

        if( splitIncludes( 'customer' ) )
        {
            columns.push( {
                Header: tr('customer'),
                columns: [{
                    id: 'customer',
                    Header: tr('customer'),
                    accessor: 'customer.name',
                }],
            });
        }

        if (splitIncludes('vehicle_type')) {
            columns.push({
                id: 'vehicle_type',
                Header: tr('vehicle_type'),
                accessor: 'vehicle_type.name',
            });
        }

        if( splitIncludes( 'route' ) )
        {
            columns.push( {
                id: 'route',
                Header: tr('route'),
                accessor: 'route',
            });
        }

        if( columnsIncludes( 'hours' ) )
        {
            columns.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: 'h',

                id: 'travel_hours',
                Header: tr('travel_time'),
                accessor: 'hours',
                filterable: false,
            }));
        }

        if( columnsIncludes( 'distance' ) )
        {
            columns.push(colPreset({
                type: "numberNice",
                decimals: 0,
                unit: 'km',

                id: 'distance',
                Header: tr('kilometers'),
                accessor: 'distance',
                filterable: false,
            }));
        }

        if( columnsIncludes( 'compensation_total' ) )
        {
            columns.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: this.state.currencySign,

                id: 'compensation_total',
                Header: tr('compensation'),
                accessor: 'compensation_total',
                filterable: false,
            }));
        }


        return columns;
    }

    getColumnsTtExpense()
    {
        const formSubmitted = this.state.formSubmitted;
        const related = this.state.related;

        const splitIncludes = ( f ) => {
            if( !formSubmitted.splits ) return false;
            return formSubmitted.splits.includes( f );
        }

        const columnsIncludes = ( f ) => {
            if( !formSubmitted.columns ) return false;
            return formSubmitted.columns.includes( f );
        }

        let columns = [];

        if( splitIncludes( 'project' ) )
        {
            columns.push( {
                Header: tr('project'),
                columns: [{
                    id: 'project_name',
                    Header: tr('project_name'),
                    accessor: 'project.full_name',
                }, {
                    id: 'project_code',
                    Header: tr('project_code'),
                    accessor: 'project.project_code',
                }],
            });
        }

        if( splitIncludes( 'user' ) )
        {
            columns.push( {
                Header: tr('person'),
                columns: [{
                    id: 'user_name',
                    Header: tr('name'),
                    accessor: 'user.name',
                }],
            });
        }

        if( splitIncludes( 'date' ) )
        {
            columns.push( {
                Header: tr('date'),
                columns: [{
                    id: 'date',
                    Header: tr('date'),
                    accessor: 'date',
                    filterable: false,

                    Cell: props => {
                        return <div>{ moment( props.value ).format('DD.MM.YYYY') }</div>
                    },
                }],
            });
        }

        if( splitIncludes( 'customer' ) )
        {
            columns.push( {
                Header: tr('customer'),
                columns: [{
                    id: 'customer',
                    Header: tr('customer'),
                    accessor: 'customer.name',
                }],
            });
        }

        if( splitIncludes( 'event' ) )
        {
            columns.push( {
                id: 'event',
                Header: tr('event'),
                accessor: 'event',
            });
        }
        if( splitIncludes( 'event_details' ) )
        {
            columns.push( {
                id: 'event_details',
                Header: tr('event_information'),
                accessor: 'event_details',
            });
        }

        if( columnsIncludes( 'money_expense' ) )
        {
            columns.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: this.state.currencySign,

                id: 'money_expense',
                Header: tr('cost'),
                accessor: 'money_expense',
                filterable: false,
            }));
        }

        return columns;
    }


    getColumnsProject()
    {
        const formSubmitted = this.state.formSubmitted;
        // const related = this.state.related;

        const splitIncludes = ( f ) => {
            if( !formSubmitted.splits ) return false;
            return formSubmitted.splits.includes( f );
        }

        const columnsIncludes = ( f ) => {
            if( !formSubmitted.columns ) return false;
            return formSubmitted.columns.includes( f );
        }

        let columns = [];

        if( splitIncludes( 'project' ) )
        {
            columns.push( {
                Header: tr('project'),
                columns: [{
                    id: 'project_name',
                    Header: tr('project_name'),
                    accessor: 'full_name',
                }, {
                    id: 'project_code',
                    Header: tr('project_code'),
                    accessor: 'project_code',
                }],
            });
        }

        if (columnsIncludes('cost_center')) {
            let costCenterCols = [
                {
                    id: 'cost_center_name',
                    Header: tr('cost_center_name'),
                    accessor: 'cost_center_full_name',
                },
                {
                    id: 'cost_center_code',
                    Header: tr('cost_center_code'),
                    accessor: 'cost_center_code',
                }
            ];
            columns.push({
                Header: tr('cost_center'),
                columns: costCenterCols,
            })
        }

        const orderCols = [];
        if (columnsIncludes('sales_order_billed')) {
            this.addColToPresets({columns: orderCols, id: 'sales_order_billed', Header: `${tr('billed')} €`, unit: '€'});
        }
        if (columnsIncludes('sales_order_paid')) {
            this.addColToPresets({columns: orderCols, id: 'sales_order_paid', Header: `${tr('paid')} €`, unit: '€'});
        }
        if (columnsIncludes('sales_order_total')) {
            this.addColToPresets({columns: orderCols, id: 'sales_order_total', Header: `${tr('total_sum')} €`, unit: '€'});
        }
        if( orderCols.length )
        {
            columns.push( {
                Header: tr('sales_order'),
                columns: orderCols,
            });
        }

        let workCols = [];
        if( splitIncludes( 'work' ) ) {
            this.addToColumns(workCols, 'work_name', tr('name'), 'work.name');
        }
        if( splitIncludes( 'work_person' ) ) {
            this.addToColumns(workCols, 'work_person_name', tr('registrar'), 'work_person.name');
        }

        if( columnsIncludes( 'work_alloc' ) ) {
            this.addColToPresets({columns: workCols, id: 'work_alloc', Header: tr('allocated'), unit: 'h'});
        }

        if( columnsIncludes( 'work_goal' ) ) {
            this.addColToPresets({columns: workCols, id: 'work_goal', Header: tr('goal'), unit: 'h'});
        }
        if( columnsIncludes( 'work_entry' ) ) {
            this.addColToPresets({columns: workCols, id: 'work_entry', Header: tr('recorded'), unit: 'h'});
        }
        if( columnsIncludes( 'work_remaining' ) ) {
            this.addColToPresets({columns: workCols, id: 'work_remaining', Header: tr('remaining'), unit: 'h',
            accessor: (row) => { return row.work_alloc - row.work_entry}});
        }
        if( columnsIncludes( 'work_alloc_used_percent' ) ) {

            this.addColToPresets({columns: workCols, id: 'work_alloc_used_percent', Header: tr('work_used_percentage'), unit: 'h',
            accessor: ( row ) => { if( !row.work_alloc ) return null;
                return row.work_entry / row.work_alloc * 100 ;
            }});
        }
        if (columnsIncludes('work_alloc_price')) {
            this.addColToPresets({columns: workCols, id: 'work_alloc_price', Header: `${tr('work_cost')} (${tr('allocated').toLowerCase()}) €`, unit: '€'});
        }
        if (columnsIncludes('work_billing_price')) {
            this.addColToPresets({columns: workCols, id: 'work_billing_price', Header: `${tr('work')} ${tr('billing_price').toLowerCase()} (${tr('hourly_billing').toLowerCase()}) €`, unit: '€'});
        }
        if (columnsIncludes('work_entry_price') || columnsIncludes('tt_entries_by_type')) {
            this.addColToPresets({columns: workCols, id: 'work_entry_price', Header: `${tr('work_cost')} (${tr('recorded').toLowerCase()}) €`, unit: '€'});
        }
        if (columnsIncludes('tt_entries_by_type')) {
            this.addColToPresets({
                columns: workCols,
                id: 'tt_entries_allowance',
                Header: tr('work_cost') + ' (' + tr('allowance') + ') €',
                unit: '€'
            });
            this.addColToPresets({
                columns: workCols,
                id: 'tt_entries_travel',
                Header: tr('work_cost') + ' (' + tr('travel_hours') + ') €',
                unit: '€'
            });
            this.addColToPresets({
                columns: workCols,
                id: 'tt_entries_expense',
                Header: tr('work_cost') + ' (' + tr('expense_compensations') + ') €',
                unit: '€'
            });
        }
        // Personnel costs in total
        if (columnsIncludes('work_entry_price_total')) {
            this.addColToPresets({
                columns: workCols,
                id: 'work_entry_price_total',
                Header: tr('personnel_costs'),
                unit: '€'
            });
        }
        if (columnsIncludes('work_alloc_entry_difference')) {
            this.addColToPresets({
                columns: workCols, 
                id: 'work_alloc_entry_difference', 
                Header: `${tr('work_costs_difference')} (${tr('recorded').toLowerCase()} - ${tr('allocated').toLowerCase()}) €`, unit: '€',
                accessor: 'work_alloc_entry_difference'
            });
        }
        if( columnsIncludes( 'work_estimate_count' ) ) {
            this.addColToPresets({columns: workCols, id: 'work_estimate_count', 
            Header: tr('hours_estimate'), unit: 'h'});
        }
        if( columnsIncludes( 'work_estimate_percent' ) )
        {
            this.addColToPresets({columns: workCols, id: 'work_estimate_percent', Header: 'Arvio %',
            accessor: ( row ) => { if( !row.work_alloc ) return null;
                return row.work_estimate_count / row.work_alloc * 100 ;
            }});
        }

        if( columnsIncludes( 'work_future_difference_percent' ) ) {
            this.addColToPresets({type: "percent", columns: workCols, id: 'work_future_difference_percent', 
            Header: `${tr('over_or_under')} %`, accessor: ( row ) => {
                if( !row.work_alloc ) return null;
                let futureCount = row.work_entry / ( row.work_estimate_count / row.work_alloc );
                return ( row.work_alloc / futureCount -1 ) * 100;
            }});
        }

        if( workCols.length )
        {
            columns.push( {
                Header: tr('works'),
                columns: workCols,
            });
        }

        let itemCols = [];

        if( splitIncludes( 'item' ) ) {
            this.addToColumns(itemCols, 'item_name', tr('name'), 'item.name');
        }

        if( columnsIncludes( 'item_alloc' ) ) {
            this.addColToPresets({columns: itemCols, id: 'item_alloc', Header: tr('allocated')});
        }

        if( columnsIncludes( 'item_installed_count' ) ) {

            this.addColToPresets({columns: itemCols, id: 'item_installed_count', Header: tr('installed')});
        }
        if( columnsIncludes( 'item_installed_price' ) ) {

            this.addColToPresets({columns: itemCols, id: 'item_installed_price', Header: tr('component_po_price')});
        }
        if( columnsIncludes( 'offer_po_price' ) ) {

            this.addColToPresets({columns: itemCols, id: 'offer_po_price', Header: tr('component_offer_po_price')});
        }
        if( columnsIncludes( 'offer_price' ) ) {

            this.addColToPresets({columns: itemCols, id: 'offer_price', Header: tr('component_offer_price')});
        }

        if( columnsIncludes( 'item_installed_percent' ) ) {

            this.addColToPresets({columns: itemCols, id: 'item_installed_percent', Header: `${tr('installed')} %`,
            accessor: ( row ) => { if( !row.item_alloc ) return null;
                return ( row.item_installed_count / row.item_alloc ) * 100;
            }});
        }

        if (this.state.companyIdentificators) {
            this.state.companyIdentificators.forEach(i => {
                if (columnsIncludes(i.value)) {
                    this.addColToPresets({columns: itemCols, id: i.value, Header: i.value,
                    type: 'text', accessor: ( row ) => { if (!row.identifiers) return null;
                        return row.identifiers[i.value];
                    }});
                }
            });
        }

        if( itemCols.length )
        {
            columns.push( {
                Header: tr('components_alt'),
                columns: itemCols,
            });
        }

        let expenseCols = [];

        if( splitIncludes( 'expense' ) ) {
            this.addToColumns(expenseCols, 'expense_name', tr('name'), 'expense.name');
        }
        if( columnsIncludes( 'expense_alloc' ) ) {
            this.addColToPresets({columns: expenseCols, id: 'expense_alloc', Header: tr('allocated')});
        }
        if( columnsIncludes( 'expense_chargeable' ) ) {
            this.addColToPresets({columns: expenseCols, id: 'expense_chargeable', Header: tr('billable')});
        }
        if( expenseCols.length )
        {
            columns.push( {
                Header: tr('costs'),
                columns: expenseCols,
            });
        }

        let poCols = [];

        if( columnsIncludes( 'po_price_total' ) ) {
            this.addColToPresets({columns: poCols, id: 'po_price_total', Header: `${tr('total_sum')} €`, unit: '€'});
        }

        if( columnsIncludes( 'po_item_price' ) ) {
            this.addColToPresets({columns: poCols, id: 'po_item_price', Header: `${tr('component_price_per')} €`, unit: '€'});
        }

        if( columnsIncludes( 'po_item_count' ) ) {
            this.addColToPresets({columns: poCols, id: 'po_item_count', Header: tr('components_purchased')});
        }

        if( columnsIncludes( 'po_item_total_price' ) ) {
            this.addColToPresets({columns: poCols, id: 'po_item_total_price', Header: `${tr('components_total_sum')} €`, unit: '€'});
        }
        if( columnsIncludes( 'po_by_type' ) ) {
            this.addColToPresets({ 
                columns: poCols, 
                id: 'po_components_price_total', 
                Header: `${capitalize(tr('total_sum')) } (${tr('components_alt')})`, 
                unit: '€' 
            });
            this.addColToPresets({
                columns: poCols,
                id: 'po_works_price_total',
                Header: `${capitalize(tr('total_sum')) } (${tr('works')})`, 
                unit: '€'
            });
            this.addColToPresets({
                columns: poCols,
                id: 'po_costs_price_total',
                Header: `${capitalize(tr('total_sum'))} (${tr('costs')})`, 
                unit: '€'
            });
        }

        if( poCols.length )
        {
            columns.push( {
                Header: tr('purchase_order'),
                columns: poCols,
            });
        }

        const storageTransferCols = [];
        if (columnsIncludes('storage_transfer')) {
            this.addColToPresets({ 
                columns: storageTransferCols, 
                id: 'storage_transfer_total_price', 
                Header: tr('storage_transfers') + ` (${tr('cost')})`
            });
        }
        if (storageTransferCols.length) {
            columns.push({
                Header: tr('storage_transfers'),
                columns: storageTransferCols,
            });
        }
        
        const profitabilityCols = [];
        if (columnsIncludes('sales_margin')) {
            this.addColToPresets({columns: profitabilityCols, id: 'sales_margin', Header: `${tr('sales_margin')} €`, unit: '€'});
        }
        if (columnsIncludes('sales_margin_percentage')) {
            this.addColToPresets({columns: profitabilityCols, id: 'sales_margin_percentage', Header: `${tr('sales_margin_percentage')}`, unit: '%'});
        }
        if( profitabilityCols.length )
        {
            columns.push( {
                Header: tr('profitability'),
                columns: profitabilityCols,
            });
        }

        return columns;
    }

    getColumnsProjectResults()
    {
        const formSubmitted = this.state.formSubmitted;
        // const related = this.state.related;

        const splitIncludes = ( f ) => {
            if( !formSubmitted.splits ) return false;
            return formSubmitted.splits.includes( f );
        }

        const columnsIncludes = ( f ) => {
            if( !formSubmitted.columns ) return false;
            return formSubmitted.columns.includes( f );
        }

        let overView = false;
        if (this.state.form.searchType.length) {
            overView = this.state.form.searchType[0].value == 'overview';
        }
        
        let columns = [];
        if( splitIncludes( 'project' ) ) {
            let project_info = [];

            if (columnsIncludes('project_name')) {
                this.addToColumns(project_info, 'project_name', tr('project_name'), 'full_name');
            }
            
            if (columnsIncludes('project_code')) {
                this.addToColumns(project_info, 'project_code', tr('project_code'));
            }

            if (!overView) {
                this.addToColumns(project_info, 'component_name', tr('component_name'), 'name');
            } else {
                this.addToColumns(project_info, 'project_overview_amount', tr('sum'), (acc) => {
                    if( !acc.total_sum) return null;
                    return `${acc.total_sum.toFixed(2)}€`;
                });
            }
            columns.push({
                Header: tr('project_info'),
                columns: project_info
            });
        }

        //Katso tarjouksen rivit
        this.setOfferColumns(columnsIncludes, columns, overView);


        if (!overView) {
            if ( splitIncludes('components')) {
                let project_columns = [];
    
                if (columnsIncludes('item_alloc')) {
                    project_columns.push({value: 'alloc_count_component', name: tr('allocated'), accessor: 'alloc_count'}); 
                }
    
                if (columnsIncludes('item_installed_count')) {
                    project_columns.push({value: 'installed_count_component', name: tr('installed'), accessor: 'installed_count'});
                }
    
                if (columnsIncludes('item_installed_percent')) {
                    project_columns.push({value: 'installed_percent', name: tr('installation_percentage'), accessor: ( row ) => { 
                        if( !row.installed_perc) return null;
                            return `${(row.installed_perc * 100).toFixed(2)}%`;
                        }
                    });
                }
    
                if (columnsIncludes('item_types')) {
                    project_columns.push({value: 'type', name: tr('type'), accessor: 'type'});
                }
    
                if (columnsIncludes('total_sum')) {
                    project_columns.push( { value: 'project_amount', name: tr('sum'), accessor: (row) => {
                        if (row.total_sum) {
                            const tot = parseFloat(row.total_sum); 
                            return `${tot.toFixed(2)}€`; 
                        }
                        return null;
                    }});
                }
    
                this.addToSplits(columns, tr('project'), project_columns);
            }
        }
        

        if (splitIncludes('difference')) {
            let diff_cols = [];

            if (columnsIncludes('total_sum')) {
                if (!overView) {
                    diff_cols.push({
                        value: 'amount_difference', name: tr('allocation_sum'), accessor: (row) => {
                            if(!row.offer) { return tr('no_offer') }

                            let sum =  row.offer.total_sum - row.total_sum;
                            return Math.abs(sum) > 0.009 ? `${sum.toFixed(2)}€` : tr('no_difference');
                        }
                    });
                } else {
                    if (columnsIncludes('item_alloc')) {
                        diff_cols.push({value: 'amount_difference', name: tr('allocation_sum'), accessor: (row) => {
                            if (!row.offer_sum) { return tr('no_offer') }
                            
                            let sum = row.offer_sum - row.total_sum;
                            return Math.abs(sum) > 0.009 ? `${sum.toFixed(2)}€` : tr('no_difference');
                        }});
                    }
                }
                
            }

            if (!overView) {
                if (columnsIncludes('item_alloc')) {
                    diff_cols.push({value: 'alloc_count_difference', name: `${tr('allocated')} ${tr('number_of_abb')}`, accessor: (row) => {
                        if (!row.offer) { return tr('no_offer') }

                        let sum = row.offer.count - row.alloc_count;
                        return Math.abs(sum) > 0.009 ? `${sum.toFixed(2)}` : tr('no_difference');
                    }
                });
                }
            }

            this.addToSplits(columns, tr('difference'), diff_cols);
        }

        if (splitIncludes('profit')) { //Kate rivit
            let profit_cols = [];

            if (!overView) {
                if (columnsIncludes('profit_perc')) {
                    profit_cols.push({value: 'profit_percentage', name: tr('gross_profit_percent'), accessor: (row) => {
                        return row.offer ? `${row.offer.balance_percent}%` 
                        : row.component 
                            ? `${parseFloat(row.component.profit_percent).toFixed(1)}%` 
                            : null }
                    });
                }

                if (columnsIncludes('profit_singular')) {
                    profit_cols.push({ value: 'profit_singular', name: tr('gross_profit'), accessor: "profit_individual"});
                }

                if (columnsIncludes('profit_all')) {
                    profit_cols.push({ value: 'profit_all', name: tr('total_gross_profit'), accessor: "profit_sum"});
                }
            } else { //Jos yleistilanne-näkymä

                profit_cols.push({ value: 'total_profit', name: tr('total_gross_profit'), 
                    accessor: (acc) =>  acc.profit_sum ? acc.profit_sum : null
                });
            }
            
            this.addToSplits(columns, 'Kate', profit_cols);
        }

        if (splitIncludes('payment_data')) { //Maksun rivit

            if (overView) {
                let payment_cols = [];
                payment_cols.push({ value: 'payment_profit', name: tr('payment_profit'), accessor: (acc) => {
                    if (!acc.payment_sum) return null;
                    const ps = parseFloat(acc.payment_sum).toFixed(2);
                    return `${ps}€`;
                }});
                this.addToSplits(columns, tr('payment'), payment_cols);
            }
        }
        
        return columns;
    }
    
    getColumnsStorage() {
        const formSubmitted = this.state.formSubmitted;
        // const related = this.state.related;

        const splitIncludes = ( f ) => {
            if( !formSubmitted.splits ) return false;
            return formSubmitted.splits.includes( f );
        }

        const columnsIncludes = ( f ) => {
            if( !formSubmitted.columns ) return false;
            return formSubmitted.columns.includes( f );
        }

        let columns = [];

        //Varastokolumnit
        let storage_info = [];

        if (columnsIncludes('storage_name')) {
            this.addToColumns(storage_info, 'storage_name', tr('storage_name'), 'location.name');
        }
        
        if (columnsIncludes('storage_code')) {
            this.addToColumns(storage_info, 'storage_code', tr('code'), 'location.code');
        }

        if (columnsIncludes('storage_type')) {
            this.addToColumns(storage_info, 'location.storage_type', tr('type'), (acc) => {
                return acc.type != 'P' ? tr('storage') : tr('project_storage');
            });
        }

        if (columnsIncludes('last_inventory')) {
            this.addToColumns(storage_info, 'last_inventory', tr('last_inventory'), acc => {
                if (!acc.location ||!acc.location.last_inventory) return;
                return moment(acc.location.last_inventory).format('L HH:mm');
            });
        }

        columns.push({
            Header: tr('storage_info'),
            columns: storage_info
        });
    
        //Komponenttirivit
        let component_rows = [];

        //Komponentin nimi aina
        if (splitIncludes('component') || columnsIncludes('component_name')) {
            this.addToColumns(component_rows, 'component.name', tr('name'), (acc => acc.component ? acc.component.name : ""));
        }

        if (columnsIncludes('unit')) {
            this.addToColumns(component_rows, 'unit', tr('unit'), 'component.unit');
        }

        if (columnsIncludes('balance')) {
            this.addToColumns(component_rows, 'balance', tr('storage_balance'), 'balance');
        }
        if (columnsIncludes('balance_available')) {
            this.addToColumns(component_rows, 'balance_available', tr('available_balance'), 'balance_available');
        }

        columns.push({
            Header: tr('component'),
            columns: component_rows
        });

        let price_rows = [];

        if (columnsIncludes('components_price')) {
            this.addColToPresets({columns: price_rows, id: 'total_value', Header: tr('stock_value'), unit: '€'});
        }
        if (columnsIncludes('stock_price_buy')) {
            this.addColToPresets({columns: price_rows, id: 'stock_price_buy', Header: tr('components_price'),unit: '€'});
        }

        if (columnsIncludes('stock_price_sell')) {
            this.addColToPresets({columns: price_rows, id: 'stock_price_sell', Header: tr('components_price_sell'),unit: '€'});
        }
        
        if (columnsIncludes('avg_price_buy')) {
            this.addColToPresets({columns: price_rows, id: 'avg_price_buy', Header: tr('storage_avg_price_buy'),unit: '€'});
        }


        if (columnsIncludes('price')) {
            this.addColToPresets({columns: price_rows, id: 'price', Header: tr('price'),unit: '€'});
        }

        if (columnsIncludes('price_sell')) {
            this.addColToPresets({columns: price_rows, id: 'price_sell', Header: tr('selling_price'),unit: '€'});
        }

        if (columnsIncludes('last_price')) {
            this.addColToPresets({columns: price_rows, id: 'last_price', Header: tr('last_purchase_price'),unit: '€'});
        }
        
        if (columnsIncludes('avg_price')) {
            this.addColToPresets({columns: price_rows, id: 'avg_price', Header: tr('average_purchase_price'),unit: '€'});

        }

        //Varastoarvo
        if (columnsIncludes('storage_value')) {
            this.addToColumns(component_rows, 'storage_value', tr('storage_value'), (acc) => {
                if (!acc.component || !acc.component.price) { return; }

                let val = acc.component.price;
                const bNow = acc.component.pivot.balance != null ? acc.component.pivot.balance : 0
                const bInc = acc.component.pivot.balance_free != null ? acc.component.pivot.balance_free : 0;

                const quant = parseFloat(bNow) + parseFloat(bInc); 

                val = parseFloat(val) * quant;
                return `${val.toFixed(2)}€`;
            });
        }

        columns.push({
            Header: tr('prices'),
            columns: price_rows
        });
        
        return columns;
    }
    
    setOfferColumns(columnsIncludes, columns, overView) {
        let offer_columns = [];

        if (!overView) {
            if (columnsIncludes('item_alloc')) {
                offer_columns.push({value: 'alloc_count_offer', name: tr('allocated'), accessor: 'offer.count'});
            }
        }
        
        if (columnsIncludes('total_sum')) {
            const accessor = !overView 
            ? (row) => { return row.offer ? `${(row.offer.total_sum).toFixed(2)}€` : null; } 
            : (row) => { return row.offer_sum ? `${(row.offer_sum).toFixed(2)}€` : null; } 
            offer_columns.push( { value: 'offer_amount', name: tr('sum'), accessor } );
        }
    
        if (offer_columns.length > 0) { this.addToSplits(columns, tr('offer'), offer_columns); }
    }

    submitHas( field, value )
    {
        const formSubmitted = this.state.formSubmitted;

        if( field === 'split' )
        {
            const splitIncludes = ( f ) => {
                if( !formSubmitted.splits ) return false;
                return formSubmitted.splits.includes( f );
            }
            return splitIncludes(  value );
        }
        if( field === 'columns' )
        {
            const columnsIncludes = ( f ) => {
                if( !formSubmitted.columns ) return false;
                return formSubmitted.columns.includes( f );
            }
            return  columnsIncludes(  value );
        }
        return false;
    }

    getColumnsComponent()
    {
        // const related = this.state.related;
        let columns = [];

        if( this.submitHas( 'split', 'component' ) )
        {
            columns.push( {
                Header: tr('storage_components'),
                columns: [{
                    id: 'name',
                    Header: tr('name'),
                    accessor: 'name',
                }, {
                    id: 'code',
                    Header: tr('code'),
                    accessor: 'code',
                }],
            });
        }
        return columns;
    }

    getColumnsSale()
    {

        const formSubmitted = this.state.formSubmitted;

        // const related = this.state.related;
        let columns = [];

        columns.push( {
            columns: [{
                id: 'related_type',
                Header: tr('type'),
                accessor: 'related_type',
            }, colPreset({
                id: 'price',
                type: "numberNice",
                decimals: 2,
                unit: this.state.currencySign,
                Header: tr('sum'),
                accessor: 'price',
            })],
        });
        
        return columns;
    }
    

    getColumnsClock()
    {
        const formSubmitted = this.state.formSubmitted;
        const splitIncludes = ( f ) => {
            if( !formSubmitted.splits ) return false;
            return formSubmitted.splits.includes( f );
        }

        const columnsIncludes = ( f ) => {
            if( !formSubmitted.columns ) return false;
            return formSubmitted.columns.includes( f );
        }

        // const related = this.state.related;
        let columns = [];

        if( splitIncludes( 'user' ) )
        {
            columns.push( {
                Header: tr('person'),
                columns: [{
                    id: 'user',
                    Header: tr('name'),
                    accessor: 'user.name',
                }]
            });
        }

        let component_rows = [];

        if (columnsIncludes('date')) {
            columns.push( {
                    id: 'date',
                    Header: tr('date'),
                    accessor: 'date',
                    filterable: false,
                    Cell: props => {
                        return <div>{ moment( props.value ).format('DD.MM.YYYY') }</div>
                    },
            });
        }

        if (columnsIncludes('type')) {
            this.addToColumns(component_rows, 'type', tr('type'), 'type', props => tr(props.value));
        }

        if (columnsIncludes('description')) {
            this.addToColumns(component_rows, 'description', tr('description'), 'description');
        }

        if (columnsIncludes('customer_work_number')) {
            this.addToColumns(component_rows, 'customer_work_number', tr('customer_work_number'), 'customer_work_number');
        }

        if (columnsIncludes('location')) {
            this.addToColumns(component_rows, 'address', tr('location'), 'address');
        }

        if (columnsIncludes('timestamp')) {
            component_rows.push( {
                id: 'created_at',
                Header: tr('event_date'),
                accessor: 'created_at',
                filterable: false,
                Cell: props => {
                    return <div>{ moment( props.value ).format('DD.MM.YYYY HH:mm:ss') }</div>
                },
            });
        }

        if (columnsIncludes('distance_to_project')) {
            component_rows.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: 'm',

                id: 'distance_to_project',
                Header: tr('distance_to_project'),
                accessor: 'distance_to_project_metres',
            }))
        }

        columns.push({
            Header: capitalize(tr("pluralize_entry")),
            columns: component_rows
        });



        return columns;
    }

    getColumnsProjectInstallation()
    {
        const formSubmitted = this.state.formSubmitted;
        const related = this.state.related;

        const splitIncludes = ( f ) => {
            if( !formSubmitted.splits ) return false;
            return formSubmitted.splits.includes( f );
        }

        const columnsIncludes = ( f ) => {
            if( !formSubmitted.columns ) return false;
            return formSubmitted.columns.includes( f );
        }

        let columns = [];


        /*
        if( splitIncludes( 'user' ) )
        {
            columns.push( {
                Header: `Henkilö`,
                columns: [{
                    id: 'user_name',
                    Header: 'Nimi',
                    accessor: 'user.name',
                }],
            });
        }

        if( splitIncludes( 'component' ) )
        {
            columns.push( {
                Header: `Työnimike`,
                columns: [{
                    id: 'component_name',
                    Header: 'Nimi',
                    accessor: 'component.name',
                }, {
                    id: 'component_code',
                    Header: 'Työn varastokoodi',
                    accessor: 'component.code',
                }],
            });
        }

        if( splitIncludes( 'date' ) )
        {
            columns.push( {
                Header: `Päivä`,
                columns: [{
                    id: 'date',
                    Header: 'Päivä',
                    accessor: 'date',
                    filterable: false,

                    Cell: props => {
                        return <div>{ moment( props.value ).format('DD.MM.YYYY') }</div>
                    },
                }],
            });
        }

        if( splitIncludes( 'customer_work_number' ) )
        {
            columns.push( {
                Header: 'Asiakastyönumero',
                columns: [{
                    id: 'customer_work_number',
                    Header: 'Asiakastyönumero',
                    accessor: 'customer_work_number',
                }],
            });
        }

        if( splitIncludes( 'customer' ) )
        {
            columns.push( {
                Header: 'Asiakas',
                columns: [{
                    id: 'customer',
                    Header: 'Asiakas',
                    accessor: 'customer.name',
                }],
            });
        }

        if( related.hour_rows )
        {
            const sCols = related.hour_rows.map( item => {
                return colPreset({
                    type: "numberNice",
                    decimals: 2,
                    unit: 'h',

                    id: `hour_rows-${ item.id }`,
                    Header: item.name,
                    filterable: false,
                    accessor: ( row ) => {
                        if( row.hour_rows[ item.id ] )
                            return row.hour_rows[ item.id ].hours;
                        return null;
                    }
                })
            })
            columns.push( {
                Header: `Tunnit`,
                columns: sCols,
            });
        }
        */

        /*
        if( related.project_expense_chargeables )
        {
            const sCols = related.project_expense_chargeables.map( item => {

                return colPreset({
                    type: "numberNice",
                    decimals: 2,

                    id: `chargeable-${ item.id }`,
                    Header: item.name,
                    filterable: false,
                    accessor: ( row ) => {
                        if( row.project_expense_chargeables[ item.id ] )
                            return row.project_expense_chargeables[ item.id ].quantity;
                        return null;
                    }
                })
            })
            columns.push( {
                Header: 'Laskutettavat kustannukset',
                columns: sCols,
            });
        }

        if( related.project_component_installations )
        {
            related.project_component_installations.map( item => {
                let sColumns = [];

                sColumns.push(colPreset({
                    type: "numberNice",
                    decimals: 2,
                    id: `installations-${ item.id }-quantity`,
                    Header: 'Määrä',
                    filterable: false,
                    accessor: ( row ) => {
                        let data = row.project_component_installations[ item.id ];
                        if( data ) return data.quantity;
                        return null;
                    },
                }));

                sColumns.push(colPreset({
                    type: "numberNice",
                    decimals: 2,
                    id: `installations-${ item.id }-hours`,
                    Header: 'Tunnit',
                    filterable: false,
                    accessor: ( row ) => {
                        let data = row.project_component_installations[ item.id ];
                        if( data ) return data.hours;
                        return null;
                    },
                }));

                if( related.trackingunits )
                {
                    related.trackingunits.map( tu => {
                        sColumns.push(colPreset({
                            type: "numberNice",
                            decimals: 2,
                            id: `installations-${ item.id }-tu-${ tu.id }`,
                            Header: tu.name,
                            accessor: ( row ) => {
                                let data = row.project_component_installations[ item.id ];
                                if( !data ) return null;
                                if( !data.trackingunits ) return null;
                                if( !data.trackingunits[ tu.id ] ) return null;
                                return data.trackingunits[ tu.id ].value;
                            },
                        }));
                    });
                }
                columns.push( {
                    Header: `"${ item.name }" asennus`,
                    columns: sColumns,
                });
            })
        }

        let absenceCols = [];
        if( splitIncludes( 'absence' ) )
        {
            absenceCols.push({
                id: 'absence_reason',
                Header: 'Poissaolon syy',
                accessor: 'absence.name',
            });
        }
        if( columnsIncludes( 'absence' ) )
        {
            absenceCols.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: 'h',

                id: 'hours_absence',
                Header: 'Poissaolon määrä',
                accessor: 'hours_absence',
                filterable: false,
            }));
        }
        if( absenceCols.length )
        {
            columns.push( {
                Header: 'Poissaolot',
                columns: absenceCols,
            });
        }
        */

        if( splitIncludes( 'component' ) )
        {
            columns.push( {
                Header: tr('storage_component'),
                columns: [{
                    id: 'component_name',
                    Header: tr('name'),
                    accessor: 'component.name',
                }, {
                    id: 'component_code',
                    Header: tr('code'),
                    accessor: 'component.code',
                }],
            });
        }

        if( splitIncludes( 'project' ) )
        {
            columns.push( {
                Header: tr('project'),
                columns: [{
                    id: 'project_name',
                    Header: tr('project_name'),
                    accessor: 'project.full_name',
                }, {
                    id: 'project_code',
                    Header: tr('project_code'),
                    accessor: 'project.project_code',
                }],
            });
        }

        if( splitIncludes( 'user' ) )
        {
            columns.push( {
                Header: tr('person'),
                columns: [{
                    id: 'user_name',
                    Header: tr('name'),
                    accessor: 'user.name',
                }],
            });
        }


        let dataCols = [];
        if( columnsIncludes( 'hours' ) )
        {
            dataCols.push(colPreset({
                type: "numberNice",
                decimals: 2,
                id: 'hours_hours',
                Header: tr('time'),
                accessor: 'hours',
                unit: 'h',
            }));
        }

        if (related.calculatable_values && columnsIncludes('hours') && columnsIncludes('meters')) {
            related.calculatable_values.forEach(calculatable => {
                dataCols.push({
                    type: "numberNice",
                    decimals: 2,
                    id: `calculatable-installation-time-${ calculatable.id }`,
                    Header: `${tr('calculation')}: ${calculatable.name}`,
                    accessor: ( row ) => {
                        if( !row.calculatable_values ) return null;
                        if( !row.calculatable_values[ calculatable.id ] ) return null;
                        return (row.calculatable_values[ calculatable.id ].value * Number(row.quantity)).toFixed(2);
                    },
                    unit: 'h'
                })
            });
        }

        if( columnsIncludes( 'quantity' ) )
        {
            dataCols.push(colPreset({
                type: "numberNice",
                decimals: 2,
                id: 'hours_quantity',
                Header: tr('amount'),
                accessor: 'quantity',
                unit: r => r.unit,
            }));
        }

        if( dataCols.length )
        {
            columns.push({
                Header: tr('installation'),
                columns: dataCols,
            });
        }

        const calculatableCols = [];
        if (related.calculatable_values && columnsIncludes('meters')) {
            related.calculatable_values.forEach(calculatable => {
                calculatableCols.push({
                    type: "numberNice",
                    decimals: 2,
                    id: `calculatable-${ calculatable.id }`,
                    Header: calculatable.name,
                    accessor: ( row ) => {
                        if( !row.calculatable_values ) return null;
                        if( !row.calculatable_values[ calculatable.id ] ) return null;
                        return row.calculatable_values[ calculatable.id ].value;
                    },
                    unit: 'h'
                })
            })
        }
        if (calculatableCols.length) {
            columns.push({
                Header: tr('meters'),
                columns: calculatableCols,
            });
        }

        const efficiencyCols = [];
        if (columnsIncludes( 'efficiency' )) {
            efficiencyCols.push({
                type: "numberNice",
                decimals: 2,
                id: 'efficiency-pcs-h',
                Header: `${tr('unit')} / h`,
                accessor: row => {
                    if (!Number(row.hours) || !Number(row.quantity)) return null; // Division by zero check
                    return (Number(row.quantity) / Number(row.hours)).toFixed(2);
                },
                unit: row => `${row.unit ? row.unit : tr('pcs')} / h`
            })
            efficiencyCols.push({
                type: "numberNice",
                decimals: 2,
                id: 'efficiency-h-pcs',
                Header: `h / ${tr('unit')}`,
                accessor: row => {
                    if (!Number(row.hours) || !Number(row.quantity)) return null; // Division by zero check
                    return (Number(row.hours) / Number(row.quantity)).toFixed(2);
                },
                unit: row => `${row.unit ? row.unit : tr('pcs')} / h`
            })
        }

        if (efficiencyCols.length) {
            columns.push({
                Header: tr('efficiency'),
                columns: efficiencyCols,
            });

            if (related.calculatable_values) {
                related.calculatable_values.forEach(calculatable => {
                    efficiencyCols.push({
                        type: "numberNice",
                        decimals: 2,
                        id: `calculatable-efficiency-${ calculatable.id }`,
                        Header: `${calculatable.name} ${tr('efficiency')}`,
                        accessor: ( row ) => {
                            if( !row.calculatable_values ) return null;
                            if( !row.calculatable_values[ calculatable.id ] ) return null;
                            if (!row.hours || !row.quantity) return null;
                            return (row.calculatable_values[ calculatable.id ].value - (Number(row.hours) / Number(row.quantity))).toFixed(2);
                        },
                        unit: 'h'
                    })
                });
            }
        }

        let tuCols = [];
        if( related.trackingunits )
        {
            related.trackingunits.forEach( tu => {
                tuCols.push(colPreset({
                    type: "numberNice",
                    decimals: 2,
                    id: `tu-${ tu.id }`,
                    Header: tu.name,
                    accessor: ( row ) => {
                        if( !row.trackingunits ) return null;
                        if( !row.trackingunits[ tu.id ] ) return null;
                        return row.trackingunits[ tu.id ].value;
                    },
                    unit: tu.unit,

                }));
            });
        }
        columns.push({
            Header: tr('monitoring_units'),
            columns: tuCols,
        });


        return columns;
    }

    getColumnsClient()
    {
        const formSubmitted = this.state.formSubmitted;
        const related = this.state.related;

        const splitIncludes = ( f ) => {
            if( !formSubmitted.splits ) return false;
            return formSubmitted.splits.includes( f );
        }

        const columnsIncludes = ( f ) => {
            if( !formSubmitted.columns ) return false;
            return formSubmitted.columns.includes( f );
        }

        let columns = [];

        if (splitIncludes('crm_company')) {
            let cols = [{value: 'name', name: tr('company_name')}];
            if (columnsIncludes('turnover')) {
                cols.push({value: 'turnover', name: tr('turnover')});
            }
            if (columnsIncludes('employs')) {
                cols.push({value: 'employs', name: tr('personnel')});
            }
            if (columnsIncludes('taxnumber')) {
                cols.push({ value: "taxnumber", name: tr('business_id') });
            }

            if (columnsIncludes('city')) {
                cols.push({value: 'city', name: tr('locality'), accessor: (row) => {
                    if (row.addresses.length == 0) { return; }

                    //Hae ensin yleistä osoitetta, jos ei löydy, anna mikä tahansa muu
                    let addr = row.addresses.find(a => a.type == 'general');
                    return addr != undefined ? addr.city : row.addresses[0].city;
                }});
            }

            if (columnsIncludes('zip')) {
                cols.push({value: 'zip', name: tr('postal_code'), accessor: (row) => {
                    if (row.addresses.length == 0) { return; }

                    //Hae ensin yleistä osoitetta, jos ei löydy, anna mikä tahansa muu
                    let addr = row.addresses.find(a => a.type == 'general');
                    return addr != undefined ? addr.postal_code : row.addresses[0].postal_code;
                }});
            }
            this.addToSplits(columns, tr('company'), cols);
        }
        
        if (splitIncludes('contacts')) {
            let cols = [
                { value: 'first_name', name: tr('first_name'), accessor: 'first_name'},
                { value: 'last_name', name: tr('last_name'), accessor: 'last_name'},
            ];
            if (columnsIncludes('title')) {
                cols.push({value: 'title', name: tr('title')});
            }
            if (columnsIncludes('contact_email')) {
                cols.push({value: 'contact_email', name: tr('contact_email')});
            }
            if (columnsIncludes('contact_phones')) {
                cols.push({value: 'contact_phone1', name: tr('contact_phones')});
                cols.push({value: 'contact_phone2', name: tr('contact_phones')});
            }

            this.addToSplits(columns, tr('contact_person'), cols);
        }

        if( splitIncludes( 'client_group' )) {
            this.addToSplits(columns, tr('customer_groups'), [
                { value: 'group_name', name: tr('customer_groups'), accessor: 'group_name'},
            ]);

        }

        if( splitIncludes( 'contact_group' ) ) {
            let groupTypeCols = [];

            this.addToColumns(groupTypeCols, 'contact_group_type', tr('contact_groups_types'), (row) => {
                const groups = [];
                row.contacts.forEach(c => {
                    c.groups.forEach(g => {
                        if (!groups.includes(g.name)) { groups.push(g.name); }
                    })
                })

                return groups.map(g => {
                    return `${g}  `;
                });
            });
            groupTypeCols = groupTypeCols.map(gt => { return {value: gt.id, name: gt.Header, accessor: gt.accessor} });
            this.addToSplits(columns, tr('contact_groups'), groupTypeCols);
        }

        if (columnsIncludes('cost_center')) {
            let costCenterCols = [
                {
                    id: 'cost_center_name',
                    Header: tr('cost_center_name'),
                    accessor: 'cost_center_full_name',
                },
                {
                    id: 'cost_center_code',
                    Header: tr('cost_center_code'),
                    accessor: 'cost_center_code',
                }
            ];
            columns.push({
                Header: tr('cost_center'),
                columns: costCenterCols,
            });
        }

        // Columns for contact details: email, phone, website
        let contactCols = [];

        let needed = ['email', 'phone', 'website'];
        const colOptions = this.state.form.type.columnOptions
            .filter(c => needed.includes(c.value));

        //Lisää optiot automaattisesti
        colOptions.forEach(co => {
            if (columnsIncludes(co.value)) {
                this.addToColumns(contactCols, co.value, co.label);    
            }
        });

        if(columnsIncludes('industry')) {
            this.addToSplits(columns, tr('industry'), [{
                value: 'industry_name', name: tr('industry_type'), accessor: 'industry.name'
            }]);
        }

        if (columnsIncludes('account_manager') || splitIncludes('account_manager')) {
            this.addToSplits(columns, tr('customer_manager'), [
                { value: 'account_manager_first_name', name: tr('first_name'), accessor: 'account_manager_details.first_name'},
                { value: 'account_manager_last_name', name: tr('last_name'), accessor: 'account_manager_details.last_name'}
            ]);
        }
      
        if( contactCols.length ) {
            columns.push( {
                Header: tr('contact_details'),
                columns: contactCols,
            });
        }

        if (columnsIncludes('project_searches')) {
            const handleIncomeCell = (row, type, unit = '€', percent = false, accessor=false) => {
                const renderEl = (val) => {
                    if (accessor) return Number(val) || val || 0;
                    return <div className="numberCell">
                        { val }<span className="unit"> {unit}</span>
                    </div>
                }
                
                let total = 0;
                let revenue = 0;
                let costs = 0;
                const projectSearches = Object.values(row.project_searches);
                // if (projectSearches.length === 0) return renderEl(total);
                projectSearches.forEach(ps => {
                    if (['profit', 'profitPercent', 'profit_percent_total'].includes(type)) { // profit, profitPercent - total columns
                        const purchaseOrders = Object.values(ps.purchase_orders);
                        purchaseOrders.forEach(po => {
                            total -= (parseFloat(po.price) || 0);
                            costs += (parseFloat(po.price) || 0);
                        });

                        const timetrackingEntries = Object.values(ps.timetracking_entries);
                        timetrackingEntries.forEach(tt => {
                            total -= (parseFloat(tt.total_money) || 0);
                            costs += (parseFloat(tt.total_money) || 0);
                        });
                    } else { // sales, purchases, personnel_costs
                        // if type ends with _percent, remove it
                        let objKey = type;
                        if (objKey.endsWith('_percent')) {
                            objKey = objKey.slice(0, -8);
                        }
                        const colType = typeof ps[objKey] === 'object' ? Object.values(ps[objKey]) : [];
                        if (colType.length === 0) return renderEl(total);
                        colType.forEach(p => {
                            const key = objKey === 'timetracking_entries' ? 'total_money' : 'price';
                            total += (parseFloat(p[key]) || 0);
                        });
                    }
                });


                if (['orders', 'profit', 'profitPercent', 'orders_percent', 'profit_percent_total'].includes(type)) {
                    const orders = Object.values(row.orders);
                    if (orders.length === 0 && !['profit_percent_total', 'profitPercent'].includes(type)) return renderEl(total);
                    orders.forEach(o => {
                        const components = Object.values(o.components);
                        components.forEach(c => {
                            const totalPrice = c.order_price * c.count;
                            const discount = c.discount_percent ? c.discount_percent : 0;
                            const discountPrice = totalPrice - (totalPrice * discount / 100);
                            total += (parseFloat(discountPrice) || 0);
                            revenue += (parseFloat(discountPrice) || 0);
                        });
                    });
                }

                if (type === 'profitPercent') {
                    if (total === 0) return renderEl(0);
                    if (revenue === 0) return renderEl(0);
                    total = (revenue - costs) / revenue * 100;
                } else if (percent) {
                    if (type === 'orders_percent') {
                        total = (total / this.state.totals.sales || 0) * 100;
                    }
                    else if (type === 'purchase_orders_percent') {
                        total = (total / this.state.totals.purchases || 0) * 100;
                    }
                    else if (type === 'timetracking_entries_percent') {
                        total = (total / this.state.totals.timetracking || 0) * 100;
                    }
                }
                return renderEl(Number(total.toFixed(2)));
            }

            const handleColumn = (type, header, unit = '€', percent = false) => {
                const col = {
                    id: header,
                    Header: tr(header),
                    accessor: row => {
                        return handleIncomeCell(row, type, unit, percent, true);
                    },
                    Cell: row => {
                        return handleIncomeCell(row.original, type, unit, percent);
                    }
                };
                return col;
            }

            const incomeCols = [];

            incomeCols.push(handleColumn('orders', 'sales'));
            if(columnsIncludes('income_percentages')) {
                incomeCols.push(handleColumn('orders_percent', 'percentage_sales', '%', true))
            }

            incomeCols.push(handleColumn('purchase_orders', 'purchases'));
            if(columnsIncludes('income_percentages')) {
                incomeCols.push(handleColumn('purchase_orders_percent', 'percentage_purchases', '%', true))
            }

            incomeCols.push(handleColumn('timetracking_entries', 'personnel_costs'));
            if(columnsIncludes('income_percentages')) {
                incomeCols.push(handleColumn('timetracking_entries_percent', 'percentage_personnel_costs', '%', true))
            }

            incomeCols.push(handleColumn('profit', 'sales_margin'));

            incomeCols.push(handleColumn('profitPercent', 'sales_margin_percentage', '%'));

            columns.push({
                Header: tr('income'),
                columns: incomeCols
            })
        }
        
        return columns; 
    }

    getColumnsInventory() {
        const formSubmitted = this.state.formSubmitted;

        const splitIncludes = ( f ) => {
            if( !formSubmitted.splits ) return false;
            return formSubmitted.splits.includes( f );
        }

        const columnsIncludes = ( f ) => {
            if( !formSubmitted.columns ) return false;
            return formSubmitted.columns.includes( f );
        }

        let columns = [];

        //Varastokolumnit
        let storage_info = [];

        if (columnsIncludes('storage_name')) {
            this.addToColumns(storage_info, 'storage_name', tr('storage_name'), 'location.name');
        }
        
        if (columnsIncludes('storage_code')) {
            this.addToColumns(storage_info, 'storage_code', tr('code'), 'location.code');
        }

        if (columnsIncludes('storage_type')) {
            this.addToColumns(storage_info, 'location.storage_type', tr('type'), (acc) => {
                return acc.type != 'P' ? tr('storage') : tr('project_storage');
            });
        }

        if (columnsIncludes('last_inventory')) {
            this.addToColumns(storage_info, 'last_inventory', tr('last_inventory'), acc => {
                if (!acc.location ||!acc.location.last_inventory) return;
                return moment(acc.location.last_inventory).format('L H:mm');
            });
        }

        if (!splitIncludes('component') && columnsIncludes('balance_before')) {
            this.addToColumns(storage_info, 'balance_before', tr('balance_before'), acc => Number(acc.balance_before).toFixed(2));
        }
        
        if (!splitIncludes('component') && columnsIncludes('balance_after')) {
            this.addToColumns(storage_info, 'balance_after', tr('balance_after'), acc => Number(acc.balance_after).toFixed(2));
        }

        if (!splitIncludes('component') && columnsIncludes('balance_change')) {
            this.addToColumns(storage_info, 'balance_change', tr('balance_change'), acc => Number(acc.balance_change).toFixed(2));
        }

        if (!splitIncludes('component') && columnsIncludes('storage_value_change')) {
            this.addToColumns(storage_info, 'storage_value_change', tr('storage_value_change'), (acc) => {
                if (!acc.storage_value_change) return;

                let val = acc.storage_value_change;
                return `${val.toFixed(2)}€`;
            });
        }

        if (columnsIncludes('date')) {
            this.addToColumns(
                storage_info,
                'inventory.created_at',
                tr('date'), 
                acc => moment(acc.inventory.created_at).format('L HH:mm'));
        }

        columns.push({
            Header: tr('storage_info'),
            columns: storage_info
        });
    
        //Komponenttirivit
        let component_rows = [];

        //Komponentin nimi aina
        if (splitIncludes('component') || columnsIncludes('component_name')) {
            this.addToColumns(component_rows, 'component.name', tr('name'), (acc => acc.component ? acc.component.name : ""));
        }

        if (splitIncludes('component') && columnsIncludes('balance_before')) {
            this.addToColumns(component_rows, 'balance_before', tr('balance_before'), acc => Number(acc.balance_before).toFixed(2));
        }
        
        if (splitIncludes('component') && columnsIncludes('balance_after')) {
            this.addToColumns(component_rows, 'balance_after', tr('balance_after'), acc => Number(acc.balance_after).toFixed(2));
        }

        if (splitIncludes('component') && columnsIncludes('balance_change')) {
            this.addToColumns(component_rows, 'balance_change', tr('balance_change'), acc => Number(acc.balance_change).toFixed(2));
        }

        if (columnsIncludes('unit')) {
            this.addToColumns(component_rows, 'unit', tr('unit'), 'component.unit');
        }

        if (columnsIncludes('order_amount')) {
            this.addToColumns(component_rows, 'order_amount', tr('order_amount'), 'inventory.order_amount');
        }
        
        if (columnsIncludes('other_changes_amount')) {
            this.addToColumns(component_rows, 'other_changes_amount', tr('other_changes'), 'inventory.other_changes_amount');
        }

        columns.push({
            Header: tr('component'),
            columns: component_rows
        });

        let price_rows = [];

        //Varastoarvon muutos
        if (columnsIncludes('components_value_before')) {
            this.addToColumns(component_rows, 'components_value_before', tr('components_value_before'), (acc) => {
                if (!acc.components_value_before) return;

                let val = Number(acc.components_value_before);
                return `${val.toFixed(2)}€`;
            });
        }

        if (columnsIncludes('components_value_after')) {
            this.addToColumns(component_rows, 'components_value_after', tr('components_value_after'), (acc) => {
                if (!acc.components_value_after) return;

                let val = Number(acc.components_value_after);
                return `${val.toFixed(2)}€`;
            });
        }

        if (columnsIncludes('storage_value_change')) {
            this.addToColumns(component_rows, 'storage_value_change', tr('storage_value_change'), (acc) => {
                if (!acc.storage_value_change) return;

                let val = acc.storage_value_change;
                return `${val.toFixed(2)}€`;
            });
        }

        columns.push({
            Header: tr('prices'),
            columns: price_rows
        });
        
        return columns;
    }

    getColumnsSalesOrders() {
        const formSubmitted = this.state.formSubmitted;

        const columnsIncludes = ( f ) => {
            if( !formSubmitted.columns ) return false;
            return formSubmitted.columns.includes( f );
        }

        const splitIncludes = ( f ) => {
            if( !formSubmitted.splits ) return false;
            return formSubmitted.splits.includes( f );
        }

        let columns = [];

        if (splitIncludes('order')) {
            this.addToColumns(columns, 'number', tr('number'), 'number');
        }
        if (columnsIncludes('date_of_billing')) {
            this.addToColumns(
                columns,
                'date_of_billing',
                tr('date_of_billing'),
                'date',
                props => props.original.date ? moment(props.original.date).format('L') : null
            );
        }
        if (columnsIncludes('due_date')) {
            this.addToColumns(
                columns, 
                'due_date', 
                tr('due_date'), 
                'due_date', 
                props => props.original.due_date ? moment(props.original.due_date).format('L') : null
            );
        }

        if (columnsIncludes('delivery_date')) {
            this.addToColumns(
                columns,
                'delivery_date',
                tr('delivery_date'),
                'delivery_date',
                props => props.original.delivery_date ? moment(props.original.delivery_date).format('L') : null
            );
        }

        if (columnsIncludes('need_date')) {
            this.addToColumns(
                columns,
                'need_date',
                tr('day_of_need'),
                'need_date',
                props => props.original.need_date ? moment(props.original.need_date).format('L') : null
            );
        }

        if (splitIncludes('customer')) {
            this.addToColumns(columns, 'customer_name', tr('customer_name'), 'crm_company_name');
        }

        if (splitIncludes('customer_manager')) {
            this.addToColumns(columns, 'customer_manager', tr('customer_manager'), 'customer_manager.person_detail.full_name');
        }
        
        if (splitIncludes('cost_center')) {
            this.addToColumns(columns, 'cost_center', tr('cost_center'), 'cost_center_name');
        }

        if (columnsIncludes('amount')) {
            this.addToColumns(columns, 'amount', tr('amount'), 'count');
        }

        if (columnsIncludes('price_tax_free')) {
            this.addToColumns(columns, 'price_tax_free', tr('tax_free_price'), 'tax_free_price', props => {
                if (!props.original.tax_free_price) return '0.00 €';
                return formatCurrency(props.original.tax_free_price);
            });
        }

        if (columnsIncludes('price_total')) {
            this.addToColumns(columns, 'price_total', tr('price_total'), 'price', props => {
                if (!props.original.price) return '0.00 €';
                return formatCurrency(props.original.price);
            });
        }

        if (splitIncludes('project')) {
            this.addToColumns(columns, 'project_name', tr('project'), acc => {
                if (!acc.project) return tr('not_related_to_project');
                return acc.project.name
            });
        }
        if (splitIncludes('project')) {
            this.addToColumns(columns, 'project_code', tr('project_code'), acc => {
                if (!acc.project) return tr('not_related_to_project');
                return acc.project.project_code
            });
        }
        if (splitIncludes('project_manager')) {
            this.addToColumns(columns, 'project_manager', tr('project_manager'), 'project_manager.person_detail.full_name')
        }

        if (splitIncludes('paid')) {
            this.addToColumns(columns, 'paid', tr('paid'), acc => {
                if (!acc.paid) return tr('not_paid');
                return tr('paid');
            });
        }

        return columns;
    }

    getColumnsPurchaseOrders() {
        const formSubmitted = this.state.formSubmitted;

        const columnsIncludes = ( f ) => {
            if( !formSubmitted.columns ) return false;
            return formSubmitted.columns.includes( f );
        }

        const splitIncludes = ( f ) => {
            if( !formSubmitted.splits ) return false;
            return formSubmitted.splits.includes( f );
        }

        let columns = [];

        if (splitIncludes('po')) {
            this.addToColumns(columns, 'number', tr('number'), 'number');
        }
        if (columnsIncludes('date_of_billing')) {
            this.addToColumns(
                columns,
                'date_of_billing',
                tr('date_of_billing'),
                'date',
                props => props.original.date ? moment(props.original.date).format('L') : null
            );
        }
        if (columnsIncludes('due_date')) {
            this.addToColumns(
                columns, 
                'due_date', 
                tr('due_date'), 
                'due_date', 
                props => props.original.due_date ? moment(props.original.due_date).format('L') : null
            );
        }

        if (columnsIncludes('delivery_date')) {
            this.addToColumns(
                columns, 
                'delivery_date', 
                tr('delivery_date'), 
                'delivery_date', 
                props => props.original.delivery_date ? moment(props.original.delivery_date).format('L') : null
            );
        }

        if (columnsIncludes('need_date')) {
            this.addToColumns(
                columns, 
                'need_date', 
                tr('day_of_need'), 
                'need_date', 
                props => props.original.need_date ? moment(props.original.need_date).format('L') : null
            );
        }

        if (splitIncludes('supplier')) {
            this.addToColumns(columns, 'supplier', tr('supplier'), 'supplier.crm_company.name');
        }

        if (splitIncludes('customer_manager')) {
            this.addToColumns(columns, 'customer_manager', tr('customer_manager'), 'customer_manager.person_detail.full_name');
        }

        if (splitIncludes('cost_center')) {
            this.addToColumns(columns, 'cost_center', tr('cost_center'), 'cost_center_name');
        }
        
        if (columnsIncludes('amount')) {
            this.addToColumns(columns, 'amount', tr('amount'), 'count');
        }

        if (columnsIncludes('price_total')) {
            this.addToColumns(columns, 'price_total', tr('price_total'), acc => {
                if (!acc.price) return '0.00 €';
                return `${parseFloat(acc.price).toFixed(2)} €`
            });
        }

        if (splitIncludes('project')) {
            this.addToColumns(columns, 'project_name', tr('project'), acc => {
                if (!acc.project) return tr('not_related_to_project');
                return acc.project.name
            });
        }
        if (splitIncludes('project')) {
            this.addToColumns(columns, 'project_code', tr('project_code'), acc => {
                if (!acc.project) return tr('not_related_to_project');
                return acc.project.project_code
            });
        }
        if (splitIncludes('project_manager')) {
            this.addToColumns(columns, 'project_manager', tr('project_manager'), 'project_manager.person_detail.full_name')
        }

        if (splitIncludes('paid')) {
            this.addToColumns(columns, 'paid', tr('paid'), acc => {
                if (!acc.paid) return tr('not_paid');
                return tr('paid');
            });
        }

        return columns;
    }

    getColumnsCrmEvents() {
        const formSubmitted = this.state.formSubmitted;

        const columnsIncludes = ( f ) => {
            if( !formSubmitted.columns ) return false;
            return formSubmitted.columns.includes( f );
        }

        const splitIncludes = ( f ) => {
            if( !formSubmitted.splits ) return false;
            return formSubmitted.splits.includes( f );
        }

        let columns = [];

        if (splitIncludes('crmCompany')) {
            this.addToColumns(columns, 'customer', tr('customer'), 'crmCompany.name');
        }

        if (splitIncludes('event')) {
            this.addToColumns(columns, 'title', tr('header'), 'title');
        }
        
        if (splitIncludes('user')) {
            this.addToColumns(columns, 'user', tr('person'), 'user.person_detail.full_name');
        }
        
        if (splitIncludes('type')) {
            this.addToColumns(columns, 'type', tr('type'), 'type', props => {
                if (props.original.type === '-') {
                    return tr('other');
                }
                else if (props.original.type === 'visit') {
                    return tr('meeting');
                }
                else {
                    return tr(props.original.type);
                }
            });
        }

        if (columnsIncludes('occurred_at')) {
            this.addToColumns(columns, 'occurred_at', tr('occurred_at'), 'occurred_at', props => {
                return props.original.occurred_at 
                    ? moment(props.original.occurred_at).format('L HH:mm')
                    : null;
            });
        }

        if (columnsIncludes('description')) {
            this.addToColumns(columns, 'description', tr('description'), 'description');
        }

        if (columnsIncludes('action')) {
            this.addToColumns(columns, 'action', tr('actions'), 'action');
        }

        if (columnsIncludes('reminded_at')) {
            this.addToColumns(columns, 'reminded_at', tr('reminder'), 'reminded_at', props => {
                return props.original.reminded_at 
                    ? moment(props.original.reminded_at).format('L HH:mm')
                    : null;
            });
        }
        
        if (columnsIncludes('further_actions')) {
            this.addToColumns(columns, 'further_actions', tr('further_actions'), 'further_actions');
        }
        
        if (columnsIncludes('count')) {
            this.addToColumns(columns, 'count', tr('amount'), 'count');
        }

        return columns;
    }

    getColumnsBillingEntries() {
        const formSubmitted = this.state.formSubmitted;

        const columnsIncludes = ( f ) => {
            if( !formSubmitted.columns ) return false;
            return formSubmitted.columns.includes( f );
        }

        const splitIncludes = ( f ) => {
            if( !formSubmitted.splits ) return false;
            return formSubmitted.splits.includes( f );
        }

        let columns = [];

        if (splitIncludes('date')) {
            this.addToColumns(
                columns,
                'date',
                tr('date'),
                'date',
                props => props.original.date ? moment(props.original.date).format('L') : null
            );
        }

        if (splitIncludes('type')) {
            const getValue = (props) => {
                if (!props.original.type) return null;
                const typeOptions = getBillingEntryTypes();
                const type = typeOptions[props.original.type];
                if (!type) return null;
                return type;
            }

            this.addToColumns(
                columns, 
                'type', 
                tr('type'), 
                'type', 
                props => getValue(props)
            );
        }
        if (splitIncludes('customer')) {
            this.addToColumns(columns, 'customer', tr('customer'), 'customer.name');
        }
        if(splitIncludes('customer_work_number')) {
            this.addToColumns(columns, 'customer_work_number', tr('customer_work_number'), 'customer_work_number');
        }
        if (splitIncludes('project')) {
            this.addToColumns(columns, 'project', tr('project'), 'project.name');
            this.addToColumns(columns, 'project_code', tr('project_code'), 'project.project_code');
        }
        if (splitIncludes('user')) {
            this.addToColumns(columns, 'user', tr('person'), 'user.person_detail.full_name');
        }
        if (columnsIncludes('entry_description')) {
            this.addToColumns(columns, 'entry_description', tr('description'), 'entry_description');
        }
        if (splitIncludes('description')) {
            this.addToColumns(columns, 'description', tr('storage_component'), 'description');
        }
        if (columnsIncludes('amount')) {
            this.addColToPresets({columns: columns, id: 'amount', Header: tr('amount'), unit: ''});
        }
        if (columnsIncludes('unit')) {
            this.addToColumns(columns, 'unit', tr('unit'));
        }
        if (columnsIncludes('unit_price')) {
            this.addColToPresets({columns: columns, id: 'unit_price', Header: tr('unit_price'), unit: '€'});
        }
        if (columnsIncludes('price_total')) {
            this.addColToPresets({columns: columns, id: 'price_total', Header: tr('price_total'), unit: '€'});
        }
        if (splitIncludes('cost_center')) {
            this.addToColumns(columns, 'cost_center', tr('cost_center'), 'cost_center.full_name');
        }
        if (splitIncludes('status')) {
            this.addToColumns(columns, 'status', tr('status'), 'status', props => {
                if (props.original.status === 'pending')
                    return tr('waiting_for_approval1');
                else if (props.original.status === 'approved')
                    return tr('ready_for_invoicing');
                return props.original.status ? tr(props.original.status) : null;
            });
        }

        return columns;
    }

    getColumnsCostCenter() {
        const formSubmitted = this.state.formSubmitted;
        const columnsIncludes = ( f ) => {
            if( !formSubmitted.columns ) return false;
            return formSubmitted.columns.includes( f );
        }
        const splitIncludes = ( f ) => {
            if( !formSubmitted.splits ) return false;
            return formSubmitted.splits.includes( f );
        }
        let columns = [];

        if (splitIncludes('cost_center')) {
            this.addToColumns(columns, 'full_name', tr('cost_center_name'), 'full_name');
        }

        if (splitIncludes('cost_center')) {
            this.addToColumns(columns, 'code', tr('cost_center_code'), 'code');
        }

        if (splitIncludes('group')) {
            this.addToColumns(columns, 'group_name', tr('cost_center_group_name'), 'group.name');
        }

        if (columnsIncludes('order_components')) {
            columns.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: '€',
                id: 'order_components',
                Header: tr('sales'),
                accessor: 'order_components',
                Cell: props => {
                    let sum = Object.values(props.original.order_components).reduce((acc, entry) => acc + entry.row_total, 0);
                    return sum.toFixed(2) + ' €';
                }
            }));
        }

        if (columnsIncludes('order_components_vat')) {
            columns.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: '€',
                id: 'order_components_vat',
                Header: `${tr('sales')} (${tr('inc_vat')})`,
                accessor: 'order_components_vat',
                Cell: props => {
                    let sum = Object.values(props.original.order_components).reduce((acc, entry) => acc + entry.row_total_vat, 0);
                    return sum.toFixed(2) + ' €';
                }
            }));
        }

        if (columnsIncludes('po_components')) {
            columns.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: '€',
                id: 'po_components',
                Header: tr('purchases'),
                accessor: 'po_components',
                Cell: props => {
                    let sum = Object.values(props.original.po_components).reduce((acc, entry) => acc + entry.row_total, 0);
                    return sum.toFixed(2) + ' €';
                }
            }));
        }

        if (columnsIncludes('timetracking_entries')) {
            columns.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: '€',
                id: 'timetracking_entries',
                Header: tr('personnel_costs'),
                accessor: 'timetracking_entries',
                Cell: props => {
                    let sum = Object.values(props.original.timetracking_entries).reduce((acc, entry) => acc + entry.total_money, 0);
                    return sum.toFixed(2) + ' €';
                }
            }));
        }

        if (columnsIncludes('income')) {
            columns.push(colPreset({
                type: "numberNice",
                decimals: 2,
                unit: '€',
                id: 'income',
                Header: tr('income'),
                accessor: 'income',
                Cell: props => {
                    const poComponents = Object.values(props.original.po_components).reduce((acc, entry) => acc + entry.row_total, 0);
                    const orderComponents = Object.values(props.original.order_components).reduce((acc, entry) => acc + entry.row_total, 0);
                    const ttEntries = Object.values(props.original.timetracking_entries).reduce((acc, entry) => acc + entry.total_money, 0);
                    return (orderComponents - poComponents - ttEntries).toFixed(2) + ' €';
                }
            }));
        }

        return columns;
    }

    getColumns()
    {
        switch( this.state.formSubmitted.type )
        {
            case 'tt':
                return this.getColumnsTt();
            case 'tt_travel':
                return this.getColumnsTtTravel();
            case 'tt_expense':
                return this.getColumnsTtExpense();
            case 'project':
                return this.getColumnsProject();
            case 'component':
                return this.getColumnsComponent();
            case 'project_installation_entries':
                return this.getColumnsProjectInstallation();
            case 'clients':
                return this.getColumnsClient();
            case 'project_results':
                return this.getColumnsProjectResults();
            case 'storage_report_new':
                return this.getColumnsStorage();
            case 'cash_sale_report':
                return this.getColumnsSale();
            case 'storage_inventory':
                return this.getColumnsInventory();
            case 'clock_report':
                return this.getColumnsClock(); 
            case 'sales_orders':
                return this.getColumnsSalesOrders();
            case 'purchase_orders':
                return this.getColumnsPurchaseOrders();
            case 'crm_events':
                return this.getColumnsCrmEvents();
            case 'billing_entries':
                return this.getColumnsBillingEntries();
            case 'cost_center':
                return this.getColumnsCostCenter();
            default:
                return [];
        }
    }

    addToColumns(columns, id, header, accessor = null, cell = null) {
        columns.push({
            id: id,
            Header: header,
            accessor: accessor ? accessor : id,
            Cell: cell
        });
    }

    addColToPresets(data) {
        data.columns.push(colPreset({
            type: data.type ? data.type : "numberNice",
            decimals: data.decimals ? data.decimals : 2,
            unit: data.unit ? data.unit : null,
            id: data.id,
            Header: data.Header,
            accessor: data.accessor ? data.accessor : data.id,
        }))
    }

    addToSplits(splits, header, columns) {

        let addCols = []; 
        columns.forEach(e => {
            addCols.push({
                id: e.value,
                Header: e.name,
                accessor: e.accessor ? e.accessor : e.value
            });  
        });

        splits.push({
            Header: header,
            columns: addCols
        });
    }

    renderTable()
    {
        if( !this.state.formSubmitted ) return null;
        return <ApReactTable
            columns={ this.getColumns() }
            data={ this.state.data }
            loading={ this.state.loading }

            filterable
        />
    }

    renderExtra() {

        let retDiv = this.state.summary ? <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', alignItems: 'center' }}>
            <h4 style={{ marginRight: '40px' }}>{ tr('in_total') }</h4>
            <div>
                <h6>{ tr('offer') }: {this.state.summary.offer_total.toFixed(2)}€</h6>
                <h6>{ tr('project') }: {this.state.summary.project_total.toFixed(2)}€</h6>
                <h6>{ tr('difference') }: {(this.state.summary.offer_total - this.state.summary.project_total).toFixed(2)}€</h6>
                <h6>{ tr('gross_profit') }: {(this.state.summary.profit_total.toFixed(2))}€</h6>
            </div>
        </div> : '';

        return retDiv;
    }

    // ----------------------------------------
    //  Saved forms
    // ----------------------------------------


    deleteForm()
    {
        const id = this.state.formDelete.id;
        this.setState({ loading: true });
        api({
            method: 'post',
            url: `report/form/delete/${ id }`,
            errorPopper: tr('save_error'),
        }).then(( response ) => {
            this.setState({ loading: false });
            this.fetchSavedForms();
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });

    }

    renderTemplateList()
    {
        return <div>
            <ApReactTable
                columns={[{
                    id: 'name',
                    Header: tr('name'),
                    accessor: 'name',
                    onClick: ( row ) => { this.selectForm( row )}
                },
                colPreset({
                        type:       "date",
                        id:         "date",
                        Header:     tr('created_at'),
                        accessor:   "date",
                        width: 200,
                }),
                ]}

                data={ this.state.forms }
                loading={ this.state.loading }
                filterable
                rowActions={[
                    {
                        icon: "edit",
                        label: tr('rename'),
                        action: (id) => {
                            let row = this.state.forms.find( f => f.id === id );
                            this.setState({ formModal: {
                                show: true,
                                id: row.id,
                                name: row.name,
                            } });
                        },
                    },{
                        icon: "trash-alt",
                        label: tr('delete'),
                        action: (id) => { this.setState({
                            formDelete: {
                                show: true,
                                id: id,
                            }
                        })},
                    }
                ]}
            />

            <ApConfirm
                show={Boolean( this.state.formDelete.show ) }
                header={ `${tr('delete_confirm')}?` }
                body={ `${tr('delete_sure')}?` }
                onConfirm={this.deleteForm }
                onClose={ () => this.setState({ formDelete: {} })}
            />
        </div>
    }

    // ----------------------------------------
    //  Form modal
    // ----------------------------------------

    setFormModal( data, func )
    {
        let formModal = { ...this.state.formModal, ...data };
        this.setState({ formModal: formModal }, func );
    }

    setFormModalDiff( field, value )
    {
        let regExp = /[^0-9-]/g;
        value = value.replace(regExp, '');
        this.setFormModal({ [ field ]: value });
    }

    renderFormModal()
    {
        const formModal = this.state.formModal;

        let valid = true;
        let autoTimeDom = null;

        const hasDateLimiter = [
            'tt',
            'tt_expense',
            'tt_travel',
            'crm_events',
            'storage_inventory',
            'clock_report',
            'cost_center',
            'billing_entries',
            'clients',
            'purchase_orders',
            'sales_orders',
            'project',
        ];

        if(formModal.show && !formModal.id && hasDateLimiter.includes( keyExists( this.state, "form.type.value", true, null ) ))
        {
            let timeForm = null;
            const timeOptions = [
                { value: 'none', label: tr('select_time_limit') },
                { value: 'dates', label: tr('time_period') },
                { value: 'week', label: capitalize(tr('week')) },
                { value: 'month', label: tr('month') },
                { value: 'year', label: tr('year') },
            ];

            let info = null;
            let diff = null;
            let diff2 = null;

            if( keyExists( formModal, 'autoTimeMode', true, 'none' ) !== 'none' )
            {
                diff = <ApInput
                    id="diff"
                    type="text"
                    name="diff"
                    label={ ( formModal.autoTimeMode === 'dates' ) ? tr('from') : '' }
                    value={ keyExists( formModal, "autoTimeDiff", true, 0 ) }
                    onChange={ ( e ) => { this.setFormModalDiff( "autoTimeDiff", e.target.value ) } }
                    // validationState={ keyExists( formModal, "autoTimeDiff", true, 0 ) ? "" : "error" }
                />

                let infos = {
                    dates: tr('time_limit_dates_info'),
                    week: tr('time_limit_week_info'),
                    month: tr('time_limit_month_info'),
                    year: tr('time_limit_year_info'),
                }
                info = infos[ formModal.autoTimeMode ];

                if( keyExists( formModal, 'autoTimeMode', true, 'none' ) === 'dates' )
                {
                    diff2 = <ApInput
                        id="diff2"
                        type="text"
                        name="diff2"
                        label={ tr('ending') }
                        value={ keyExists( formModal, "autoTimeDiff2", true, 0 ) }
                        onChange={ ( e ) => { this.setFormModalDiff( "autoTimeDiff2", e.target.value ) } }
                        // validationState={ keyExists( formModal, "autoTimeDiff2", true, 0 ) ? "" : "error" }
                    />
                }
            }
            timeForm = <Collapse in={ keyExists( formModal, 'autoTime', true, false) }>
                <div>
                    <ApInputStack gap="0">
                        <ApInput
                            className="dateMode"
                            type="select"
                            id="dateMode"
                            name="dateMode"
                            value={ keyExists( formModal, 'autoTimeMode', true, 'none' ) }
                            options={ timeOptions }
                            onChange={ ( e ) => { this.setFormModal({ 'autoTimeMode': e.target.value }) } }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                            // width="300px"
                        />
                        { diff }
                        { diff2 }
                    </ApInputStack>

                    { Boolean( info ) && <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                        { info }
                    </div> }

                </div>
            </Collapse>

            if( !formModal.name ) valid = false;
            else if( keyExists( formModal, 'autoTime', true, false) )
            {
                let atm = keyExists( formModal, 'autoTimeMode', true, 'none' );
                let value = keyExists( formModal, "autoTimeDiff", true, null );
                let value2 = keyExists( formModal, "autoTimeDiff2", true, null );

                if( atm === 'none' )
                    valid = false;
                else if( atm === 'dates' )
                {
                    if( !value && !value2 )
                        valid = false;
                }
                else
                {
                    if( !value )
                        valid = false;
                }
            }

            autoTimeDom = <div>
                <div className="apSwitchBlock">
                    <label htmlFor="form-modal-automatic-time-switch" className="info">
                        { tr('automatic_time_limit') }
                        <small>{ tr('automatic_time_limit_info') }</small>
                    </label>
                    <ApSwitch
                        id="form-modal-automatic-time-switch"
                        on={ formModal.autoTime || false }
                        onChange={ () => this.setFormModal({ autoTime: !formModal.autoTime }) }
                        disabled={ this.state.loading }
                    />
                </div>
                { timeForm }
            </div>
        }

        return <ApModal
            className="narrow"
            show={ Boolean( formModal.show ) }
            handleClose={ () => this.setState({ formModal: {} }) }
            closeFromBg
            header={
                <div className="padding-small">
                    <h3>{ tr('save_report_criteria') }</h3>
                </div>
            }
            body={
                <div className="padding">
                    <ApInput
                        type="text"
                        id="saveModalName"
                        name="name"
                        label={ tr('name') }
                        value={ formModal.name || '' }
                        onChange={ ( e ) => { this.setFormModal({ name: e.target.value }) } }
                        validationState={ keyExists( formModal, "name", true, '' ) ? "" : "error" }
                    />
                    { autoTimeDom }
                </div>
            }
            footer={
                <div className="padding-small">
                    <ApButton onClick={ () => this.setState({ formModal: {} }) }>
                        <SvgIcon icon="times" type="solid" />
                        { tr('cancel') }
                    </ApButton>
                    <ApButton className="applyButton" color="green" onClick={ this.formSave } disabled={ !valid }>
                        <SvgIcon icon="save" type="solid" />
                       { tr('save') }
                    </ApButton>
                </div>
            }
            />

    }

    // ----------------------------------------
    //  Render
    // ----------------------------------------

    renderTypeSelect()
    {
        return <ApInputStack gap="0">
            <ApAddon noRightBorder>
                { tr('target') }
            </ApAddon>
            <ApInput
                type="select"
                id="target_type"
                name="target_type"
                value={ keyExists( this.state.form, 'type.value', true, '' ) }
                options={ types }
                onChange={  ( e ) => { this.setType( e.target.value ) } }
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />
        </ApInputStack>
    }

    renderButtonBar()
    {
        if( !this.state.form.type ) return null;
        return <div className="buttonBar">
            <div>
            <ApButton color="green" size="small" onClick={ () => this.fetchReport() }  key="playButton">
                { tr('create_report') }
                <SvgIcon icon="play" type="solid" />
            </ApButton>

            <ApButton color="blue" size="small" onClick={ () => this.fetchExcel() }  key="excelButton">
                { tr('create_excel') }
                <SvgIcon icon="file-excel" type="solid" />
            </ApButton>
            </div>
            <div className="rightButton">
                <ApButton color="blue" size="small" onClick={ () => this.setState({ formModal: { show: true } })} key="saveButton">
                    <SvgIcon icon="save" type="solid" />
                    { tr('save_search_criteria') }
                </ApButton>
            </div>
        </div>

    }

    renderFilters()
    {
        const type = keyExists( this.state, "form.type.value", true, null );
        let doms = [];
        if( type === 'tt' )
        {
            doms.push( this.renderDates() );
            doms.push( this.renderUserFilter() );
            doms.push( this.renderComponentFilter() );
            doms.push( this.renderProjectFilter() );
            doms.push( this.renderTimetrackingTypeSelect() );
        }
        else if( type === 'tt_travel' )
        {
            doms.push( this.renderDates() );
            doms.push( this.renderUserFilter() );
            doms.push( this.renderProjectFilter() );
            doms.push( this.renderTimetrackingTypeSelect() );
        }
        else if( type === 'tt_expense' )
        {
            doms.push( this.renderDates() );
            doms.push( this.renderUserFilter() );
            doms.push( this.renderProjectFilter() );
            doms.push( this.renderTimetrackingTypeSelect() );
        }
        else if( type === 'project' )
        {
            doms.push( this.renderDates() );
            doms.push( this.renderActivityFilter());
            doms.push( this.renderProjectFilter() );
            doms.push( this.renderProjectTypeFilter() );
        } else if ( type === 'project_results') {
            doms.push( this.renderDates() );
            doms.push( this.renderActivityFilter());
            doms.push( this.renderProjectFilter({onlyRoot: true}) );
        }
        else if( type === 'component' )
        {
            doms.push( this.renderComponentTypeSelect() );
        }
        else if( type === 'project_installation_entries' )
        {
            doms.push( this.renderDates() );
            doms.push( this.renderProjectFilter() );
        }
        else if( type === 'clients' )
        {
            doms.push( this.renderDates() );
            doms.push( this.renderContactGroup() );
            doms.push( this.renderClientGroup() );
            doms.push( this.renderAccountManager() );
            doms.push( this.renderLocality() );
            doms.push( this.renderIndustry() );
            doms.push( this.renderTurnover() );
            doms.push( this.renderStaff() );
            // if columns include project_searches, render project search filter and project activity filter
            const foundProjectSearches = this.state.form?.columns?.find(column => column.value === 'project_searches');
            if (foundProjectSearches) {
                doms.push( this.renderActivityFilter());
                doms.push( this.renderProjectFilter({onlyRoot: true}));
                doms.push( this.renderProjectTypeFilter());
            }
        } else if ( type === 'storage_report' ) {
            doms.push( this.renderDates() );
            doms.push( this.renderStorageTypeFilter() );
            doms.push( this.renderStorageFilter() );
            doms.push( this.renderComponentFilter() );
            doms.push( this.renderCountFilter() );
            doms.push( this.renderEmptyFilter() );
        } else if ( type === 'storage_report_new' ) {
            doms.push( this.renderStorageTypeFilter() );
            doms.push( this.renderStorageFilter() );
            doms.push( this.renderComponentFilter() );
        } else if ( type === 'cash_sale_report' ) {
            doms.push( this.renderDates() );
        } else if ( type === 'storage_inventory') {
            doms.push( this.renderDates() );
            doms.push(this.renderStorageFilter());
            doms.push( this.renderComponentFilter() );
        } else if ( type === 'clock_report' ) {
            doms.push( this.renderUserFilter() );
            doms.push( this.renderDates() );
        } else if (type === 'sales_orders') {
            doms.push(this.renderDates());
            doms.push(this.renderProjectFilter({noProject: true}));
            doms.push(this.renderPaidStatusFilter());
            doms.push(this.renderCustomerFilter());
            doms.push(this.renderUserFilter({client_manager: true}, tr('customer_manager')));
            doms.push(this.renderUserFilter({project_manager: true}, tr('project_manager'), 'project_managers', 'projectManagerFilter'));
        } else if (type === 'purchase_orders') {
            doms.push(this.renderDates());
            doms.push(this.renderProjectFilter({noProject: true}));
            doms.push(this.renderPaidStatusFilter());
            doms.push(this.renderCustomerFilter(true));
            doms.push(this.renderUserFilter({project_manager: true}, tr('project_manager'), 'project_managers', 'projectManagerFilter'));
        } else if (type === 'crm_events') {
            doms.push(this.renderDates());
            doms.push(this.renderCustomerFilter());
            // doms.push(this.renderUserFilter({client_manager: true}, tr('customer_manager')));
            doms.push(this.renderUserFilter({is_active: true}));
        } else if (type === 'billing_entries') {
            doms.push(this.renderDates());
            doms.push(this.renderCustomerFilter());
            doms.push(this.renderUserFilter({is_active: true}));
            doms.push(this.renderProjectFilter());
            doms.push(this.renderBillingEntryStatusFilter());
        } else if (type === 'cost_center') {
            doms.push(this.renderDates());
            doms.push(this.renderCostCenterFilter());
            doms.push(this.renderCostCenterGroupFilter());
        }

        return doms;
    }

    renderCostCenterFilter() {
        return <ApSelect 
            key="costCenterFilter"
            label={tr('cost_center') }
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'cost_centers', true, [] ) }
            valueRenderer={(item) => item.full_name}
            optionRenderer={(item) => <div>{item.full_name} <small style={{display: 'block'}}>{item.code}</small></div>}
            onChange={ ( values ) => this.setForm({ cost_centers: values }) }
            objKeyId="id"
            multiselect
            apiUrl={"costCenter/search"}
            apiData={{listAll: true}}
            keepFocusOnSelect
        />
    }

    renderCostCenterGroupFilter() {
        return <ApSelect 
            key="costCenterGroupFilter"
            label={tr('group') }
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'cost_center_groups', true, [] ) }
            valueRenderer={(item) => item.name}
            optionRenderer={(item) => <div>{item.name} <small style={{display: 'block'}}>{item.code}</small></div>}
            onChange={ ( values ) => this.setForm({ cost_center_groups: values }) }
            objKeyId="id"
            multiselect
            apiUrl={"costCenter/group/search"}
            apiData={{listAll: true}}
            keepFocusOnSelect
        />
    }

    renderPaidStatusFilter() {
        return <ApSelect key="columnsSelect"
            label={ tr('status') }
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'paidStatus', true, [] ) }
            onChange={ ( values ) => this.setForm({ paidStatus: values }) }
            objKeyId="value"
            multiselect
            options={ paidStatusOptions }
            optionRenderer="label"
            objKeySearchable="label"
            valueRenderer="label"
            keepFocusOnSelect
        />
    }

    renderBillingEntryStatusFilter() {
        return <ApSelect key="columnsSelect"
            label={ tr('status') }
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'billingEntryStatus', true, [] ) }
            onChange={ ( values ) => this.setForm({ billingEntryStatus: values }) }
            objKeyId="value"
            multiselect
            options={ billingEntryStatusOptions }
            optionRenderer="label"
            objKeySearchable="label"
            valueRenderer="label"
            keepFocusOnSelect
        />
    }

    renderCustomerFilter(onlySuppliers = false) {
        return <ApSelect key="customerFilter"
            label={onlySuppliers ? tr('supplier') : tr('customer') }
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'crmCompanies', true, [] ) }
            valueRenderer={(item) => item.name}
            optionRenderer="crm_company"
            onChange={ ( values ) => this.setForm({ crmCompanies: values }) }
            objKeyId="id"
            multiselect
            apiUrl={"crm/companies/find"}
            apiData={{ onlySuppliers: onlySuppliers }}
            keepFocusOnSelect
        />
    }

    renderTimetrackingTypeSelect()
    {
        return <ApSelect key="columnsSelect"
            label={ tr('entry_status') }
            loading={ this.state.loading }
            value={ keyExists( this.state.form, 'ttstatus', true, [] ) }
            onChange={ ( values ) => this.setForm({ ttstatus: values }) }
            objKeyId="value"
            multiselect
            options={ ttStatustOptions }
            optionRenderer="label"
            objKeySearchable="label"
            valueRenderer="label"
            keepFocusOnSelect
        />


    }

    renderComponentTypeSelect()
    {
        let options = [
            { value: 'item', label: tr('components_alt'), },
            { value: 'work', label: tr('works'), },
            { value: 'other', label: tr('costs'), },
        ];
        return this.renderOptionBar( 'ctype', options );
    }

    // Helpers

    /**
     * 
     * Calculate totals for sales, purchases and timetracking for percentage calculations
     * @param {Object[]} data - data from the report
     */
    calculateTotalsForClientsReport(data) {
        const totals = {
            sales: 0,
            purchases: 0,
            timetracking: 0,
        }
        data.forEach(row => {
            Object.values(row.orders).forEach(order => {
                Object.values(order.components).forEach(c => {
                    const totalPrice = c.order_price * c.count;
                    const discount = c.discount_percent ? c.discount_percent : 0;
                    const discountPrice = totalPrice - (totalPrice * discount / 100);
                    totals.sales += (parseFloat(discountPrice) || 0);
                });
            });

            Object.values(row.project_searches).forEach(search => {
                const purchaseOrders = Object.values(search.purchase_orders);
                purchaseOrders.forEach(po => {
                    totals.purchases += parseFloat(po.price || 0);
                });

                const ttEntries = Object.values(search.timetracking_entries);
                ttEntries.forEach(tt => {
                    totals.timetracking += parseFloat(tt.total_money || 0);
                });
            });
        });

        this.setState({totals: totals});
    }

    render()
    {
        if( !hasPermissions( permissions ) ) return <Notfound />

        let searchOptionsDom = null;
        if( this.state.form.type )
        {
            searchOptionsDom = <div className="clear">
                <div className="apColumn w50">
                    <div className="padding">
                        <h4 className="formHeader">
                            <SvgIcon icon="columns" type="solid" /> { tr('columns') }
                        </h4>
                        <div className="apInfo small">
                            <SvgIcon icon="info-circle" type="solid" />
                            { tr('columns_info') }
                        </div>
                        { this.renderSplitsSelect() }
                        { this.renderColumnSelect() }
                        { this.renderDepthInputs() }
                    </div>
                </div>


                <div className="apColumn w50">
                    <div className="padding">
                        <h4 className="formHeader">
                            <SvgIcon icon="filter" type="solid" /> { tr('limiters') }
                        </h4>

                        <div className="apInfo small">
                            <SvgIcon icon="info-circle" type="solid" />
                            { tr('limiters_info') }
                        </div>
                        { this.renderFilters() }
                    </div>
                </div>
            </div>
        }

        return <div className="apBox" id="reportsPage">
                <div className="apBoxHeader">
                    <h1>{ tr('reports') }</h1>
                    <p>{ tr('reports_info') }</p>
                </div>

                <ApTabs ref={this.tabsRef }>
                    <ApTab icon="share" label={ tr('saved_templates') }>
                        <div className="padding">
                            { this.renderTemplateList() }
                        </div>


                    </ApTab>

                    <ApTab icon="table" label={ tr('report') }>
                        <div className="padding">
                            { this.renderTypeSelect() }
                            { searchOptionsDom }
                            { this.renderButtonBar() }
                        </div>
                        <div className="padding">
                            { this.renderTable() }
                            { this.renderExtra() }
                        </div>


                    </ApTab>
                </ApTabs>

                { this.renderFormModal() }
        </div>
    }

}

export default Reports;
