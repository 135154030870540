import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
//import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';

import ComponentTooltip from 'modules/Storage/common/ComponentTooltip/ComponentTooltip.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import { ApInputSmall } from 'common/ApInputSmall/ApInputSmall.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApButton from 'common/ApButton/ApButton.js';

import { getTypeIcon } from 'modules/Storage/common/StorageHelpers.js';

import { onlyNumber, tr } from 'services/Helpers/Helpers.js';

import './ComponentRow.css';

//import SvgIcon from 'common/SvgIcon/SvgIcon.js';
//import ApReactTable from 'common/ApReactTable/ApReactTable.js';
//import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
//import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';

class ComponentRows extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            showBillingDetails: false,

            currencySign: auth.getCurrencySign(),
        };
        autoBind(this);
    }

    updateRow( func )
    {
        this.props.updateTreeItem( this.props.project.id, func );
    }

    updateHourTypes( c )
    {
        return c.hour_types.map( h => this.updateHourType( c, h ) );
    }
    updateHourType( c, h )
    {
        //console.log('h', h);

        if (!h.project_price_manual) {
            const price = h.hour_price || c.billing_price;
            h.value = h.salary_multiplier * h.component_multiplier * price;
        }

        return h;
    }

    // ----------------------------------------
    //  Component input edits
    // ----------------------------------------

    changeComponentDone( component )
    {
        this.updateRow( item => {
            item.components = item.components.map( ( c ) => {
                if( c.id === component.id )
                {
                    if( !c.done ) c.done = moment().format('YYYY-MM-DD');
                    else c.done = null;
                }
                return c;
            })
            return item;
        });
    }

    changeComponentInTimetracking( component )
    {
        this.updateRow( item => {
            item.components = item.components.map( ( c ) => {
                if( c.id === component.id )
                    c.in_timetracking = !c.in_timetracking;
                return c;
            })
            return item;
        });
    }

    changeComponentName( component, value )
    {
        this.updateRow( item => {
            item.components = item.components.map( ( c ) => {
                if( c.id === component.id )
                    c.name = value;
                return c;
            })
            return item;
        });
    }

    changeComponentAllocCount( component, value, blur = false )
    {
        this.updateRow( item => {
            item.components = item.components.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.alloc_count = onlyNumber( value );
                    c.alloc_price_all = c.alloc_count * c.alloc_price_single;
                    c.installed_percent = ( c.installed_count / c.alloc_count ) * 100;
                    if( !isFinite( c.installed_percent ) ) c.installed_percent = null;
                }
                return c;
            })
            return item;
        });
    }

    changeComponentAllocPriceSingle( component, value, blur = false )
    {
        this.updateRow( item => {
            item.components = item.components.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.alloc_price_single = onlyNumber( value );
                    c.alloc_price_all = c.alloc_count * c.alloc_price_single;
                }
                return c;
            })
            return item;
        });
    }

    changeComponentAllocPriceAll( component, value, blur = false )
    {
        this.updateRow( item => {
            item.components = item.components.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.alloc_price_all = onlyNumber( value );
                    c.alloc_price_single = c.alloc_price_all / c.alloc_count;
                }
                return c;
            })
            return item;
        });
    }

    changeComponentInstalledCount( component, value, blur = false )
    {
        this.updateRow( item => {
            item.components = item.components.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.installed_count = onlyNumber( value );
                    c.installed_percent = ( c.installed_count / c.alloc_count ) * 100;
                    if( !isFinite( c.installed_percent ) ) c.installed_percent = null;
                }
                return c;
            })
            return item;
        });
    }

    changeComponentBilling( component )
    {
        this.updateRow( item => {
            item.components = item.components.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.billing = !c.billing;
                }
                return c;
            })
            return item;
        });
    }

    changeComponentBillingPriceManual( component )
    {
        this.updateRow( item => {
            item.components = item.components.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.billing_price_manual = !c.billing_price_manual;
                    if( !c.billing_price_manual )
                        c.billing_price = c.billing_price_original;
                }
                return c;
            })
            return item;
        });
    }

    changeComponentBillingPriceManualValue( component, value, blur = false )
    {
        this.updateRow( item => {
            item.components = item.components.map( ( c ) => {
                if( c.id === component.id )
                    c.billing_price = onlyNumber( value );
                return c;
            })
            return item;
        });
    }

    // ----------------------------------------
    //  Work input edits
    // ----------------------------------------

    changeWorkDone( component )
    {
        this.updateRow( item => {
            item.works = item.works.map( ( c ) => {
                if( c.id === component.id )
                {
                    if( !c.done )
                    {
                        c.done = moment().format('YYYY-MM-DD');
                        c.done_estimate_backup = c.done_estimate;
                        c.done_estimate = 100;
                    }
                    else
                    {
                        c.done = null;
                        if( typeof c.done_estimate_backup !== 'undefined' )
                            c.done_estimate = c.done_estimate_backup;
                    }
                }
                return c;
            })
            return item;
        });
    }


    changeWorkRequiredInstallation( component )
    {
        this.updateRow( item => {
            item.works = item.works.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.installation_required = !c.installation_required;
                }
                return c;
            })
            return item;
        });
    }

    changeWorkName( component, value )
    {
        this.updateRow( item => {
            item.works = item.works.map( ( c ) => {
                if( c.id === component.id )
                    c.name = value;
                return c;
            })
            return item;
        });
    }

    changeWorkAllocCount( component, value, blur = false )
    {
        this.updateRow( item => {
            item.works = item.works.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.alloc_count = onlyNumber( value );
                    c.alloc_price_all = c.alloc_count * c.alloc_price_single;
                    c.entry_percent = ( c.entry_count / c.alloc_count ) * 100;
                    if( !isFinite( c.entry_percent ) ) c.entry_percent = null;
                }
                return c;
            })
            return item;
        });
    }

    changeWorkAllocPriceSingle( component, value, blur = false )
    {
        this.updateRow( item => {
            item.works = item.works.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.alloc_price_single = onlyNumber( value );
                    c.alloc_price_all = c.alloc_count * c.alloc_price_single;
                }
                return c;
            })
            return item;
        });
    }

    changeWorkAllocPriceAll( component, value, blur = false )
    {
        this.updateRow( item => {
            item.works = item.works.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.alloc_price_all = onlyNumber( value );
                    c.alloc_price_single = c.alloc_price_all / c.alloc_count;
                }
                return c;
            })
            return item;
        });
    }

    changeWorkDoneEstimate( component, value, blur = false )
    {
        this.updateRow( item => {
            item.works = item.works.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.done_estimate = onlyNumber( value );
                    if( parseFloat( c.done_estimate ) > 100)
                        c.done_estimate = 100;

                    if( blur )
                    {
                        if( parseFloat( c.done_estimate ) === 100 && c.done === null )
                            this.changeWorkDone( component );
                        else if( parseFloat( c.done_estimate ) !== 100 && c.done !== null )
                            this.changeWorkDone( component );
                    }
                }
                return c;
            })
            return item;
        });
    }

    changeWorkBilling( component )
    {
        this.updateRow( item => {
            item.works = item.works.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.billing = !c.billing;
                }
                return c;
            })
            return item;
        });
    }

    changeWorkBillingPriceManual( component )
    {
        this.updateRow( item => {
            item.works = item.works.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.billing_price_manual = !c.billing_price_manual;

                    if( !c.billing_price_manual )
                        c.billing_price = c.billing_price_original;

                    c.hour_types = this.updateHourTypes( c );
                }
                return c;
            })
            return item;
        });
    }

    changeWorkBillingPriceManualValue( component, value, blur = false )
    {
        this.updateRow( item => {
            item.works = item.works.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.billing_price = onlyNumber( value );
                    c.hour_types = this.updateHourTypes( c );
                }
                return c;
            })
            return item;
        });
    }

    changeWorkBillingHourTypeNoBilling( component, hourType )
    {
        this.updateRow( item => {
            item.works = item.works.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.hour_types = c.hour_types.map( h => {
                        if( h.id === hourType.id )
                            h.project_no_billing = !h.project_no_billing;
                        return h;
                    })
                }
                return c;
            })
            return item;
        });
    }
    
    changeSaveAsNew( component )
    {
    	
        this.updateRow( item => {
            item.components = item.components.map( ( c ) => {
                if( c.id === component.id )
                {
                	c.save_as_new=!c.save_as_new;
                }
                return c;
            })
            return item;
        });
    }


    changeWorkProjectPriceManual( component, hourType )
    {
        this.updateRow( item => {
            item.works = item.works.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.hour_types = c.hour_types.map( h => {
                        if( h.id === hourType.id )
                        {
                            h.project_price_manual = !h.project_price_manual;
                            this.updateHourType( c, h );
                        }
                        return h;
                    })
                }
                return c;
            })
            return item;
        });
    }

    changeWorkProjectPrice( component, hourType, value, blur = false )
    {
        this.updateRow( item => {
            item.works = item.works.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.hour_types = c.hour_types.map( h => {
                        if( h.id === hourType.id )
                            h.value = onlyNumber( value );
                        return h;
                    })
                }
                return c;
            })
            return item;
        });

    }


    // ----------------------------------------
    //  Expense input edits
    // ----------------------------------------

    changeExpenseInTimetracking( component )
    {
        this.updateRow( item => {
            item.expenses = item.expenses.map( ( c ) => {
                if( c.id === component.id )
                    c.in_timetracking = !c.in_timetracking;
                return c;
            })
            return item;
        });
    }

    changeExpenseName( component, value )
    {
        this.updateRow( item => {
            item.expneses = item.expenses.map( ( c ) => {
                if( c.id === component.id )
                    c.name = value;
                return c;
            })
            return item;
        });
    }

    changeExpenseAllocCount( component, value, blur = false )
    {
        this.updateRow( item => {
            item.expenses = item.expenses.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.alloc_count = onlyNumber( value );
                    c.alloc_price_all = c.alloc_count * c.alloc_price_single;
                    if( !isFinite( c.entry_percent ) ) c.entry_percent = null;
                }
                return c;
            })
            return item;
        });
    }

    changeExpenseAllocPriceSingle( component, value, blur = false )
    {
        this.updateRow( item => {
            item.expenses = item.expenses.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.alloc_price_single = onlyNumber( value );
                    c.alloc_price_all = c.alloc_count * c.alloc_price_single;
                }
                return c;
            })
            return item;
        });
    }

    changeExpenseAllocPriceAll( component, value, blur = false )
    {
        this.updateRow( item => {
            item.expenses = item.expenses.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.alloc_price_all = onlyNumber( value );
                    c.alloc_price_single = c.alloc_price_all / c.alloc_count;
                }
                return c;
            })
            return item;
        });
    }

    changeExpenseBilling( component )
    {
        this.updateRow( item => {
            item.expenses = item.expenses.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.billing = !c.billing;
                }
                return c;
            })
            return item;
        });
    }

    changeExpenseBillingPriceManual( component )
    {
        this.updateRow( item => {
            item.expenses = item.expenses.map( ( c ) => {
                if( c.id === component.id )
                {
                    c.billing_price_manual = !c.billing_price_manual;
                    if( !c.billing_price_manual )
                        c.billing_price = c.billing_price_original;
                }
                return c;
            })
            return item;
        });
    }

    changeExpenseBillingPriceManualValue( component, value, blur = false )
    {
        this.updateRow( item => {
            item.expenses = item.expenses.map( ( c ) => {
                if( c.id === component.id )
                    c.billing_price = onlyNumber( value );
                return c;
            })
            return item;
        });
    }

    // ----------------------------------------
    //  Render
    // ----------------------------------------

    renderInputCols( c )
    {
        const project = this.props.project;
        const colShow = this.props.colShow;

        let itemCols = [];
        this.props.cols.forEach( ( col, i ) => {
            if( col.hidden ) return null;

            let o = col;
            if( col.toggle )
                o = col.options.find( f => f.showAs === colShow[ col.toggle ] );
            if( !o ) return null;

            let unit = o.unit

            if (typeof o.unit === 'function') {
                unit = o.unit(c)
            }

            if (unit !== '%' && unit !== "€" && c.component) {
                unit = c.component.unit
            }

            //const unit = ( typeof o.unit === 'function' ) ? o.unit( c ) : o.unit;
            const value = ( typeof o.value === 'function' ) ? o.value( c ) : c[ o.value ];
            let tooltip = col.label;

            if( o.tooltip ) tooltip = o.tooltip;

            if( col.type === 'boolean' )
            {
                itemCols.push( <td key={ i } className="fixed">
                    <ApTooltip text={ tooltip } block position="top">
                        <ApSwitch
                            id={ `${ c.type_id }-${ c.id }-${ i }-switch` }
                            small
                            on={ Boolean( value ) }
                            onChange={ ( ) => { this[ o.funcName ]( c ) } }
                            disabled={ o.disabled || this.props.locked }
                        />
                    </ApTooltip>
                </td>)
            }
            else if( col.type === 'billing' )
            {
                itemCols.push( this.renderBillingTd( c, i, o ) );
            }
            else if( col.type === 'not_billed' && !c.billing )
            {
                itemCols.push( <td key={ i } className="fixed"></td>)
            }
            else if( col.type === 'storage_balance'  )
            {
                if (c.balance===null) {
                    itemCols.push( <td key={ i } className="fixed"> - </td>)
                }
                else if (c.balance!=c.balance_available) {
                    itemCols.push( <td key={ i } className="fixed">{c.balance} {unit} <br/><small> {c.balance_available} </small></td>)
                }
                else {
                    itemCols.push( <td key={ i } className="fixed">{c.balance} {unit} </td>)
                }
                
            }
            else
            {

                let decimal = 2;
                if( typeof col.decimal !== 'undefined') decimal = col.decimal;
                if( typeof o.decimal !== 'undefined') decimal = o.decimal;

                let infoButton = null;
                if( value && col.infoButton && parseInt( c.id, 10 ) )
                {
                    infoButton = <div className="tallyButton" onClick={ () => { this.props.tallyShow( project, c, col.infoButton ); } }>
                        <SvgIcon icon="info-circle" type="solid" />
                    </div>
                }

                itemCols.push( <td key={ i } className="fixed">

                        <ApInputSmall
                            value={ value }
                            onChange={ ( e ) => { this[ o.funcName ]( c, e.target.value ) } }
                            onBlur={   ( e ) => { this[ o.funcName ]( c, e.target.value, true ) } }
                            disabled={ o.disabled || this.props.locked }
                            unit={ unit }
                            noFocusDecimals={ decimal }
                            tooltip= { tooltip }
                        />
                        { infoButton }
                </td>)
            }
        });
        return itemCols;
    }

    renderBillingTd( c, i )
    {
        if( !c.billing )
            return <td key={ i } className="fixed"></td>

        return <td key={ i } className="fixed">
            <div className="billingTdInput">
                <ApInputSmall
                    value={ c.billing_price }
                    //onChange={ ( e ) => { this[ o.funcName ]( project, c, e.target.value ) } }
                    //onBlur={   ( e ) => { this[ o.funcName ]( project, c, e.target.value, true ) } }
                    disabled={ true }
                    unit={ this.state.currencySign || null }
                    noFocusDecimals={ 2 }
                    // tooltip= { tooltip }
                />
            </div>
            <div className="billingTdIcon" onClick={ () => { this.setState({ showBillingDetails: !this.state.showBillingDetails }) } }>
                <ApTooltip text={ tr('show_billing_price_additional_info') } block position="topright">
                    <SvgIcon icon="angle-left" type="solid" />
                </ApTooltip>
            </div>
        </td>
    }

    renderTdCheckbox( c, checked )
    {
        return <td key="check" className="select" onClick={ () => this.props.selectComponent(c) }>
            <input
                type="checkbox"
                checked={ checked }
                readOnly
            />
        </td>
    }

    renderTdComponentInfo( c )
    {
        const connectedTooltip = ( c.component ) ? <ComponentTooltip component={ c.component } /> : <div>{ tr('external_storage_component') }</div>;

        return <td key="conncted" className="icon" onClick={ () => this.props.selectComponent(c) }>
            <ApTooltip position="top" text={ connectedTooltip }>
                <SvgIcon className={ c.component ? '' : 'outsideStorage' } icon={ getTypeIcon( c.type_name ) } type="solid" />
            </ApTooltip>
        </td>
    }

    renderTdName( c )
    {
        const funcs = {
            item: this.changeComponentName,
            work: this.changeWorkName,
            other: this.changeExpenseName,
        }

        let niClass = [ 'componentNameInput' ];

        return <td className="name">
            <input
                className={ niClass.join( ' ' ) }
                type="text"
                value={ c.name ? c.name : '' }
                onChange={ ( e ) => { funcs[ this.props.type ]( c, e.target.value ) } }
            />
        </td>
    }
    renderTdComment(c) {
        const funcs = {
            item: this.changeComponentName,
            work: this.changeWorkName,
            other: this.changeExpenseName,
        }
        return <td className="comment">
            <ApTooltip position={'top'} text={c.comment} >
                <ApButton size="xtiny" color={c.comment ? "orange" : "white"} onClick={(e) => { this.props.openEditComment({ projectId: this.props.project.id, type: this.props.type, component: c, target: e.target })}}>
                    <SvgIcon icon="plus" type="solid" />
                </ApButton>
            </ApTooltip>
        </td>
        
    }

    renderTdSaveNew( c )
    {
    	if (c.save_as_new!== undefined) {
    		return <td className="fixed saveNew">
    		<ApTooltip text={ tr('save_as_new_component') } block position="top">
    		<SvgIcon icon="save" type="solid" />
                <ApSwitch
	                id={ `save_as_new-${ c.id }-switch` }
	                small
	                on={ c.save_as_new }
	                onChange={ ( ) => { this.changeSaveAsNew( c ) } }
	                disabled={ this.props.loading || this.props.locked }
	            />
                
              </ApTooltip>
	        </td>
    	}
    	else {
    		return <td className=""></td>
    	}
        
    }
    
    renderTdMenu( c )
    {
        return <td className="fixed menu">
            <ApDropdown actions={ this.props.actions( c ) } />
        </td>
    }

    renderBillingWork( c )
    {
        if( !c.hour_types || !c.hour_types.length )
            return null;

        let hourTypeDoms = c.hour_types.map( h => {
            let manualSwitchDom = null;
            let manualInputDom = null;

            if( !h.component_no_billing && !h.project_no_billing )
            {
                manualSwitchDom = <ApTooltip text={ tr('define_fixed_value') } block position="top">
                    <ApSwitch
                        id={ `billing-static-${ c.id }-${ h.id }-switch` }
                        small
                        on={ h.project_price_manual }
                        onChange={ ( ) => { this.changeWorkProjectPriceManual( c, h ) } }
                        disabled={ this.props.loading || this.props.locked }
                    />
                </ApTooltip>

                manualInputDom = <ApInputSmall
                    value={ h.value }
                    onChange={ ( e ) => { this.changeWorkProjectPrice( c, h, e.target.value ) } }
                    onBlur={   ( e ) => { this.changeWorkProjectPrice( c, h, e.target.value, true ) } }
                    disabled={ !h.project_price_manual }
                    unit={ this.state.currencySign || null }
                    noFocusDecimals={ 2 }
                    // tooltip= { tooltip }
                />
            }

            let noBillingTooltip = tr('will_be_billed');
            if( h.component_no_billing )
                noBillingTooltip = tr('billing_turned_off_storage');

            return <tr key={ h.id }>
                <td>{ h.name }</td>
                <td className="fixed-switch">
                    <ApTooltip text={ noBillingTooltip } block position="top">
                        <ApSwitch
                            id={ `billing-${ c.id }-${ h.id }-switch` }
                            small
                            on={ !h.project_no_billing && !h.component_no_billing }
                            onChange={ ( ) => { this.changeWorkBillingHourTypeNoBilling( c, h ) } }
                            disabled={ this.props.loading || this.props.locked || h.component_no_billing }
                        />
                    </ApTooltip>
                </td>
                <td className="fixed-switch">
                    { manualSwitchDom }
                </td>
                <td className="fixed">
                    { manualInputDom }
                </td>
            </tr>
        });

        let defaultPriceDom = <tr key="default">
            <td>
                <strong>{ tr('project_specific_price') }</strong>
            </td>
            <td></td>
            <td className="fixed-switch">
                <ApTooltip text={ tr('define_project_specific_price') } block position="top">
                    <ApSwitch
                        id={ `billing-price-manual-${ c.id }-switch` }
                        small
                        on={ Boolean( c.billing_price_manual ) }
                        onChange={ ( ) => { this.changeWorkBillingPriceManual(c) } }
                        disabled={ this.props.loading || this.props.locked }
                    />
                </ApTooltip>
            </td>

            <td className="fixed">
                <ApInputSmall
                    value={ c.billing_price }
                    onChange={ ( e ) => { this.changeWorkBillingPriceManualValue(c, e.target.value ) } }
                    onBlur={   ( e ) => { this.changeWorkBillingPriceManualValue(c, e.target.value, true ) } }

                    disabled={ !c.billing_price_manual }
                    unit={ this.state.currencySign || null }
                    noFocusDecimals={ 2 }
                    // tooltip= { tooltip }
                />
            </td>
        </tr>

        return <table className="hourTypesTable"><tbody>
            { defaultPriceDom }
            { hourTypeDoms }
        </tbody></table>
    }

    renderBillingComponent( c )
    {
        let defaultPriceDom = <tr key="default">
            <td>
                <strong>{ tr('project_specific_price') }</strong>
            </td>
            <td></td>
            <td className="fixed-switch">
                <ApTooltip text={ tr('define_project_specific_price') } block position="top">
                    <ApSwitch
                        id={ `billing-price-manual-${ c.id }-switch` }
                        small
                        on={ Boolean( c.billing_price_manual ) }
                        onChange={ ( ) => { this.changeComponentBillingPriceManual(c) } }
                        disabled={ this.props.loading || this.props.locked }
                    />
                </ApTooltip>
            </td>

            <td className="fixed">
                <ApInputSmall
                    value={ c.billing_price }
                    onChange={ ( e ) => { this.changeComponentBillingPriceManualValue(c, e.target.value ) } }
                    onBlur={   ( e ) => { this.changeComponentBillingPriceManualValue(c, e.target.value, true ) } }
                    disabled={ !c.billing_price_manual }
                    unit={ this.state.currencySign || null }
                    noFocusDecimals={ 2 }
                    // tooltip= { tooltip }
                />
            </td>
        </tr>

        return <table className="hourTypesTable"><tbody>
            { defaultPriceDom }
        </tbody></table>

    }

    renderBillingExpense( c )
    {
        let defaultPriceDom = <tr key="default">
            <td>
                <strong>{ tr('project_specific_price') }</strong>
            </td>
            <td></td>
            <td className="fixed-switch">
                <ApTooltip text={ tr('define_project_specific_price') } block position="top">
                    <ApSwitch
                        id={ `billing-price-manual-${ c.id }-switch` }
                        small
                        on={ Boolean( c.billing_price_manual ) }
                        onChange={ ( ) => { this.changeExpenseBillingPriceManual(c) } }
                        disabled={ this.props.loading || this.props.locked }
                    />
                </ApTooltip>
            </td>

            <td className="fixed">
                <ApInputSmall
                    value={ c.billing_price }
                    onChange={ ( e ) => { this.changeExpenseBillingPriceManualValue(c, e.target.value ) } }
                    onBlur={   ( e ) => { this.changeExpenseBillingPriceManualValue(c, e.target.value, true ) } }
                    disabled={ !c.billing_price_manual }
                    unit={ this.state.currencySign || null }
                    noFocusDecimals={ 2 }
                    // tooltip= { tooltip }
                />
            </td>
        </tr>

        return <table className="hourTypesTable"><tbody>
            { defaultPriceDom }
        </tbody></table>

    }

    render()
    {
        const c = this.props.c;

        const open = this.state.showBillingDetails;
        const checked = Boolean( this.props.selected.find( f => f.id === c.id ) );

        let trClasses = [ 'component' ,this.props.className];
        if( checked ) trClasses.push('selected');
        let billingDetails = null;
        if( c.billing && open )
        {
            //console.log('ccc', c );

            let billingDetailDom = null;
            if( c.type_name === 'work' )
                billingDetailDom = this.renderBillingWork( c );
            else if( c.type_name === 'item' )
                billingDetailDom = this.renderBillingComponent( c );
            else if( c.type_name === 'other' )
                billingDetailDom = this.renderBillingExpense( c );
            else if( c.type_name === 'storage' ) {
                billingDetailDom = "";
            }
                

            trClasses.push('billingOpen');
            billingDetails = <tr className="billingTr">
                <td colSpan="2"></td>
                <td colSpan={ this.props.cols.length + 1 }>
                    { billingDetailDom }
                </td>
                <td colSpan="1"></td>
            </tr>
        }

        return <React.Fragment>
            <tr className={ trClasses.join(' ')}>
                {this.renderTdCheckbox(c, checked)}
                <td className="groupTd"></td>
                {this.renderTdComponentInfo(c)}
                {this.renderTdName(c)}
                {this.renderTdComment(c)}
                {this.renderTdSaveNew(c)}
                {this.renderInputCols(c)}
                {this.renderTdMenu(c)}
            </tr>
            { billingDetails }
        </React.Fragment>


    }
}

export default ComponentRows;
